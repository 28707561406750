
  import { defineComponent } from 'vue';
  import { FilterMatchMode, FilterOperator } from 'primevue/api';
  import DataTable, {
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableSortEvent
  } from 'primevue/datatable';
  import { toGBDateString } from '../../composables/UtilityFunctions';
  import { casesDashboard } from '../../stores/casesDashboard.store';
  //import { dropdownsStore } from '@/stores/dropdowns.store';
  //import Dropdown from "primevue/dropdown"
  import { UserCaseDto } from '@/clients/client.gen';
  import Calendar from 'primevue/calendar';
  import router from '@/router';

  const baseFilter: DataTableFilterMeta = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    owner: { value: null, matchMode: FilterMatchMode.EQUALS },
    caseStatusName: { value: null, matchMode: FilterMatchMode.EQUALS },
    description: { value: null, matchMode: FilterMatchMode.EQUALS }
  };
  export default defineComponent({
    components: { Calendar, DataTable /*Dropdown*/ },
    data() {
      return {
        filters: { ...baseFilter },
        selectedRow: null,
        casesDashboard,
        //            userTaskTypes: [] as any[],
        cases: [] as Array<UserCaseDto>,
        debounceTimer: null as any,
        globalFilterFields: [
          'owner',
          // "assignedUserName",
          'description',
          'caseType'
        ]
      };
    },
    computed: {
      //availableAssignedTo() {
      //    const uniqueNames = new Set<string>();
      //    const availableAssignedTo: { name: string | null | undefined, id: string | null | undefined }[] = [];

      //    casesDashboard.cases.forEach(c => {
      //        if (c.assignedUserName && !uniqueNames.has(c.assignedUserName)) {
      //            uniqueNames.add(c.assignedUserName);
      //            availableAssignedTo.push({ name: c.assignedUserName, id: c.assignedUserName });
      //        }
      //    });

      //    availableAssignedTo.push({ name: "All users", id: null });
      //    return availableAssignedTo;
      //},
      availableCasesStatuses() {
        const uniqueStatuses = new Set<string>();
        const availableCasesStatuses: { name: string; id: string | null }[] =
          [];

        casesDashboard.dlCases.forEach((c) => {
          if (c.caseStatusName && !uniqueStatuses.has(c.caseStatusName)) {
            uniqueStatuses.add(c.caseStatusName);
            availableCasesStatuses.push({
              name: c.caseStatusName,
              id: c.caseStatusName
            });
          }
        });

        availableCasesStatuses.push({ name: 'All types', id: null });
        return availableCasesStatuses;
      },
      //cases() {
      //    if (casesDashboard.dlCases.length == 0) this.getData();
      //    return casesDashboard.dlCases;
      //},
      loading() {
        return casesDashboard.dlsloading;
      }
    },
    async created() {
      await this.init();
    },
    methods: {
      async onRowSelect() {
        router.push({
          name: 'caseProfile',
          params: { id: (this.selectedRow as any).caseId }
        });
      },
      async getData() {
        await casesDashboard.getDLCases();
        this.cases = casesDashboard.dlCases;
      },
      toGBDateString,
      async clearFilter() {
        await this.init();
      },
      //getTypeString(typeId: number) {
      //    return this.userTaskTypes.find(x => x.userTaskTypeId === typeId)?.name;
      //},
      async applySort(event: DataTableSortEvent) {
        casesDashboard.sortField = event.sortField?.toString() ?? null;
        casesDashboard.sortOrder = event.sortOrder ?? null;
        await this.getData();
      },
      async filter() {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }

        // Set a new debounce timer
        this.debounceTimer = setTimeout(() => {
          // Process the input value after the debounce delay
          casesDashboard.searchTerm = (
            this.filters.global as DataTableFilterMetaData
          ).value;
          this.getData();
          // Clear the debounce timer
          this.debounceTimer = null;
        }, 250); // Adjust the delay as needed (250ms in this example)
      },
      async init() {
        //this.userTaskTypes = await dropdownsStore.getTaskTypes();
        //this.userTaskTypes.push({ userTaskTypeId: null, name: "All types" });
        this.filters = { ...baseFilter };
        await this.getData();
      },
      onClearFiltersClick() {
        this.init();
        casesDashboard.setFilter((task) => task == task);
      }
    }
  });
