import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1471913a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "value"]
const _hoisted_2 = { class: "p-checkbox-box" }
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Divider, { class: "mt-0" }),
    _createElementVNode("div", {
      class: "p-multiselect-item p-0",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateGroupUserIds()))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["p-checkbox p-component", {
        'p-highlight': $setup.hasAllUsersSelected()
      }])
      }, [
        _createElementVNode("input", {
          id: 'ug-' + $setup.props.userGroup.groupName,
          tabindex: "-1",
          type: "checkbox",
          class: "p-checkbox-input",
          name: $setup.props.userGroup.groupName,
          value: {
          label: $setup.props.userGroup.groupName,
          value: $setup.props.userGroup.groupName
        }
        }, null, 8 /* PROPS */, _hoisted_1),
        _createElementVNode("div", _hoisted_2, [
          ($setup.hasAllUsersSelected())
            ? (_openBlock(), _createBlock($setup["CheckIcon"], {
                key: 0,
                class: "p-checkbox-icon"
              }))
            : _createCommentVNode("v-if", true)
        ])
      ], 2 /* CLASS */),
      _createElementVNode("label", {
        for: 'ug-' + $setup.props.userGroup.groupName
      }, _toDisplayString($setup.props.userGroup.groupName), 9 /* TEXT, PROPS */, _hoisted_3)
    ])
  ], 64 /* STABLE_FRAGMENT */))
}