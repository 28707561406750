import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ScrollPanel, { style: {"height":"100%"} }, {
      default: _withCtx(() => [
        _createVNode($setup["GeneratedTable"], {
          ref: "table",
          client: $setup.caseExpenseClient,
          "extend-get-all": $setup.extendGetAll,
          "before-save": $setup.processModel,
          "create-button-label": "New Expense",
          "form-readonly": $setup.quoteFormReadonly,
          "highlight-specific-row": $setup.highlightExpense,
          "after-load": $setup.afterLoadForm,
          onFormInputDefinitionsLoaded: $setup.formOpening
        }, {
          "filter-statusName": _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              options: $setup.statusOptions,
              "option-label": "name",
              "option-value": "value",
              placeholder: "Select One",
              class: "p-column-filter",
              style: {"min-width":"12rem"},
              "show-clear": true,
              onChange: ($event: any) => (filterCallback())
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange"])
          ]),
          "column-amount": _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString($setup.formatCurrency(slotProps.value.data.amount)), 1 /* TEXT */)
          ]),
          "column-expectedAmount": _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString($setup.formatCurrency(slotProps.value.data.expectedAmount)), 1 /* TEXT */)
          ]),
          "input-CaseDescription": _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.value.value || $setup.caseStore.case.description), 1 /* TEXT */)
          ]),
          "column-createdDate": _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(new Date(slotProps.value.data.createdDate).toLocaleDateString()), 1 /* TEXT */)
          ]),
          "input-PetIds": _withCtx((slotProps) => [
            _createVNode($setup["PetSelector"], {
              "owner-id": $setup.caseStore.case.contactId,
              "selected-pet-ids": slotProps.value.value,
              placeholder: "Select relevant pets for this expense.",
              "onUpdate:selectedPets": ($event: any) => (slotProps.value.value = $event)
            }, null, 8 /* PROPS */, ["owner-id", "selected-pet-ids", "onUpdate:selectedPets"])
          ]),
          "input-Supplier": _withCtx((slotProps) => [
            _createVNode($setup["VetSelector"], {
              "selected-vet": slotProps.value.value,
              placeholder: "Start typing for suggestions",
              "onUpdate:selectedVet": ($event: any) => (slotProps.value.value = $event)
            }, null, 8 /* PROPS */, ["selected-vet", "onUpdate:selectedVet"])
          ]),
          "input-DateOfService": _withCtx((slotProps) => [
            _createVNode($setup["FormDatePicker"], {
              id: slotProps.value.inputSlotName,
              modelValue: slotProps.value.value,
              "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
              class: "w-full",
              disabled: slotProps.value.readOnly,
              "max-date": new Date()
            }, null, 8 /* PROPS */, ["id", "modelValue", "onUpdate:modelValue", "disabled", "max-date"])
          ]),
          "input-Approved": _withCtx((slotProps) => [
            _createVNode(_component_InputSwitch, {
              id: slotProps.value.inputSlotName,
              modelValue: slotProps.value.value,
              "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
              label: slotProps.value.label,
              disabled: slotProps.value.readOnly
            }, null, 8 /* PROPS */, ["id", "modelValue", "onUpdate:modelValue", "label", "disabled"])
          ]),
          "column-actions": _withCtx((slotProps) => [
            _createVNode($setup["EllipsisButton"], null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ellipsisMenuOptions, (option, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    (option.visible(slotProps.data))
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          class: "flex",
                          text: "",
                          small: "",
                          label: option.label,
                          onClick: ($event: any) => (option.clickAction(slotProps.data))
                        }, null, 8 /* PROPS */, ["label", "onClick"]))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["client", "form-readonly", "highlight-specific-row"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_Dialog, {
      modal: "",
      closable: false,
      visible: $setup.invoiceModalVisible,
      onAfterHide: $setup.resetInvoiceModal
    }, {
      default: _withCtx(() => [
        _createVNode($setup["GeneratedForm"], {
          id: $setup.invoiceEditId,
          client: $setup.caseExpenseInvoiceClient,
          style: {"width":"40vw"},
          "save-label": "Update Expense",
          readonly: $setup.invoiceModalReadonly,
          "manage-form": $setup.manageIncomeForm,
          onItemSaved: $setup.onInvoiceSave,
          onCancel: _cache[0] || (_cache[0] = ($event: any) => ($setup.invoiceModalVisible = false)),
          onDefinitionExposed: $setup.onIncomeDefinitionExposed
        }, null, 8 /* PROPS */, ["id", "client", "readonly"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ], 64 /* STABLE_FRAGMENT */))
}