
  import { defineComponent } from 'vue';
  import FormFieldMixin from './FormFieldMixin';

  export default defineComponent({
    mixins: [FormFieldMixin],
    props: {
      name: {
        type: String,
        required: false
      },
      enableMentions: {
        type: Boolean,
        required: false
      },
      rows: {
        type: Number,
        required: false,
        default: 2
      },
      cols: {
        type: Number,
        required: false
      }
    },
    emits: ['update:modelValue'],
    data() {
      return {
        internalValue: this.modelValue
      };
    },
    watch: {
      modelValue(newVal) {
        this.internalValue = newVal;
      },
      internalValue(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  });
