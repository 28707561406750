import {
  CaseVolunteerStatusEnum,
  FlattenedCaseVolunteer,
  NoteDto,
  SimpleCaseDto,
  SimplePetDto
} from '@/clients/client.gen';
import { FilterResult } from '@/stores/commsAndNotes.store';

const volunteerTagStatusOrder = [
  CaseVolunteerStatusEnum.Assigned,
  CaseVolunteerStatusEnum.Backup,
  CaseVolunteerStatusEnum.OnHold,
  CaseVolunteerStatusEnum.MaybeLater,
  CaseVolunteerStatusEnum.VolunteerStopped,
  CaseVolunteerStatusEnum.OwnerStopped,
  CaseVolunteerStatusEnum.CTStopped,
  CaseVolunteerStatusEnum.DidntStart,
  CaseVolunteerStatusEnum.Selected,
  CaseVolunteerStatusEnum.Contacted,
  CaseVolunteerStatusEnum.Declined
];
const statusOrderMap: { [key: number]: number } = {};
volunteerTagStatusOrder.forEach((statusId, index) => {
  statusOrderMap[statusId] = index;
});

export const sortByVolunteerTagStatusOrder = (
  a: FlattenedCaseVolunteer,
  b: FlattenedCaseVolunteer
) => statusOrderMap[a.statusId ?? 1] - statusOrderMap[b.statusId ?? 1];

export const filterCaseOptions = (cases: SimpleCaseDto[]): FilterResult[] => {
  let listOfCases = [] as FilterResult[];

  listOfCases = cases.map((c) => ({
    value: c.caseId ?? c,
    name: c.description
  }));
  return listOfCases;
};

export const allCaseIds = (caseNotes: NoteDto[]): number[] => {
  const caseIds: number[] = caseNotes
    .flatMap((x) => x.noteCases ?? [])
    .map((c) => c.caseId); // Filter out null CaseIds
  return caseIds;
};

export const filterPetOptions = (pets: SimplePetDto[]): FilterResult[] => {
  let listOfPets = [] as FilterResult[];

  listOfPets = pets.map((n) => ({
    value: n.petId,
    name: n.petName ?? ''
  }));
  return listOfPets;
};

export const getNoteText = (
  note: NoteDto,
  routeName: string | undefined
): string | undefined => {
  if (
    routeName === 'caseProfile' ||
    !note.noteCases ||
    note.noteCases.length == 0
  )
    return note.noteText;

  return `${note.noteCases[0].description} - ${note.noteText}`;
  // had to remove pet details as this needs to be dealt with differently
  // and on every screen we need to set up some "display name" for case so ti is consistant across
};
