import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field grid w-full flex align-items-center" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["for"]
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "col-12 mb-3 md:col-3 md:mb-0"
    }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_2),
    _createElementVNode("div", {
      id: _ctx.id,
      class: "col-12 md:col-9 flex align-items-center"
    }, [
      _createVNode(_component_RadioButton, {
        modelValue: _ctx.internalValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
        readonly: _ctx.readOnly,
        disabled: _ctx.readOnly,
        "input-id": _ctx.yesInputId,
        value: true
      }, null, 8 /* PROPS */, ["modelValue", "readonly", "disabled", "input-id"]),
      _createElementVNode("label", {
        for: _ctx.yesInputId,
        class: "p-2"
      }, "Yes", 8 /* PROPS */, _hoisted_4),
      _createVNode(_component_RadioButton, {
        modelValue: _ctx.internalValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalValue) = $event)),
        "input-id": _ctx.noInputId,
        readonly: _ctx.readOnly,
        disabled: _ctx.readOnly,
        value: false
      }, null, 8 /* PROPS */, ["modelValue", "input-id", "readonly", "disabled"]),
      _createElementVNode("label", {
        for: _ctx.noInputId,
        class: "p-2"
      }, "No", 8 /* PROPS */, _hoisted_5)
    ], 8 /* PROPS */, _hoisted_3)
  ]))
}