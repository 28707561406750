import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"calc(100% - 56px)"} }
const _hoisted_2 = { class: "flex justify-content-end align-items-center m-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["Button"], {
        icon: "pi pi-plus",
        size: "small",
        label: "Add Supplier",
        onClick: $setup.showAddSupplier
      }),
      _createVNode($setup["CaseSupplierPicker"], {
        ref: "caseSupplierPicker",
        supplier: $setup.supplierToEdit,
        onClosed: $setup.reset,
        onSupplierSelected: $setup.onCaseSupplierSelected,
        onSupplierEdited: $setup.onCaseSupplierEdited
      }, null, 8 /* PROPS */, ["supplier"])
    ]),
    _createVNode($setup["CaseSupplierDataTable"], {
      "case-suppliers": $setup.caseSuppliers,
      onReload: $setup.getCaseSuppliers,
      onEdit: $setup.editSupplierLink
    }, null, 8 /* PROPS */, ["case-suppliers"])
  ]))
}