
  import { OwnerHistoryDto } from '@/clients/client.gen';
  import { contactStore } from '@/stores';
  import { defineComponent } from 'vue';
  import router from '@/router';

  export default defineComponent({
    components: {},
    data() {
      return {
        tableData: [] as OwnerHistoryDto[]
      };
    },
    async created() {
      this.tableData = await contactStore.fetchLTFPets();
    },
    methods: {
      goToCase(caseId: number | undefined) {
        if (caseId === undefined) return;
        const route = router.resolve({
          name: 'caseProfile',
          params: { id: caseId }
        });
        window.open(route.href, '_blank');
      },
      goToPet(petId: number) {
        if (petId === undefined) return;
        const route = router.resolve({
          name: 'pets',
          params: { id: petId }
        });
        window.open(route.href, '_blank');
      },
      viewComms(petId: number) {
        console.log('this needs implementing', petId);
      }
    }
  });
