import { reactive } from 'vue';
import {
  UserCaseDto,
  DashboardClient,
  AftercareFilterModel,
  DLCasesFilterModel
} from '../clients/client.gen';

export const openLTF = (task: UserCaseDto) =>
  task.caseStatusName === 'Open' && task.caseType === 'LTF';
export const open = (task: UserCaseDto) => task.caseStatusName === 'Open';
export const assignedToMe = (task: UserCaseDto) =>
  task.caseStatusName === 'Open';

export const casesDashboard = reactive({
  cases: [] as Array<UserCaseDto>,
  ltfCases: [] as Array<UserCaseDto>,
  aftercareCases: [] as Array<UserCaseDto>,
  dlCases: [] as Array<UserCaseDto>,
  client: new DashboardClient(),
  filteredCases: [] as Array<UserCaseDto>,
  loading: false,
  dlsloading: false,
  isGroupView: false,
  currentView: 'standard' as 'standard' | 'ltf',
  sortField: null as string | null,
  sortOrder: null as number | null,
  searchTerm: null as string | null,
  caseId: null as number | null,
  statusId: null as number | null,
  createdById: null as string | null,
  totalRecords: 0,
  currentPage: 0,
  pageSize: 10,
  followUpDate: undefined as string | undefined,
  stage: undefined as number | undefined,
  async getData() {
    this.loading = true;
    try {
      if (this.currentView === 'standard') {
        const cases = await this.client.getAll(
          this.caseId,
          this.statusId,
          this.createdById,
          this.currentPage,
          this.pageSize,
          this.sortField,
          this.sortOrder,
          this.searchTerm
        );
        if (cases.data) {
          this.cases = cases.data;
          this.totalRecords = cases.total ?? 0;
        }
      }
      if (this.currentView === 'ltf') {
        const cases = await this.client.getAllLTFCases(
          this.caseId,
          this.statusId,
          this.createdById,
          this.currentPage,
          this.pageSize,
          this.sortField,
          this.sortOrder,
          this.searchTerm
        );
        if (cases.data) {
          this.cases = cases.data;
          this.totalRecords = cases.total ?? 0;
        }
      }

      //this.aftercareCases = await this.client.getAftercareCases();

      this.filteredCases = [...this.cases];
    } catch {
      this.loading = false;
    }
    this.loading = false;
  },
  async changeView(viewName: 'standard' | 'ltf') {
    this.currentView = viewName;
    await this.resetTableMeta();
  },
  async resetTableMeta() {
    this.caseId = null;
    this.statusId = null;
    this.createdById = null;
    this.currentPage = 0;
    this.pageSize = 10;
    this.sortField = null;
    this.sortOrder = null;
    this.searchTerm = null;
    await this.getData();
  },
  async getAftercareCases() {
    this.loading = true;
    try {
      const model = new AftercareFilterModel();
      model.currentPage = this.currentPage;
      model.pageSize = this.pageSize;
      model.sortField = this.sortField ?? '';
      model.sortType = this.sortOrder ?? 1;
      model.followUpDate = this.followUpDate;
      model.stage = this.stage;
      model.searchTerm = this.searchTerm ?? '';
      model.assignedUserId = '';
      const data = await this.client.getAftercareCases(model);
      this.aftercareCases = data.data ?? [];
    } catch (e) {
      console.log(e);
      this.loading = false;
    }
    this.loading = false;
  },
  async getDLCases() {
    this.dlsloading = true;
    try {
      const request = new DLCasesFilterModel();
      const data = await this.client.getDLCases(request);
      this.dlCases = data.data ?? [];
    } catch {
      this.dlsloading = false;
    }
    this.dlsloading = false;
  },
  setFilter(filter: (task: UserCaseDto) => void) {
    const filtered = this.cases.filter(filter);
    this.filteredCases = filtered ? [...filtered] : [...this.cases];
  },
  getCasesCount(filter: (task: UserCaseDto) => void) {
    return this.cases.filter(filter).length;
  },
  setLTFFilter(filter: (task: UserCaseDto) => void) {
    const filtered = this.ltfCases.filter(filter);
    this.filteredCases = filtered ? [...filtered] : [...this.cases];
  },
  async getLTFCasesCount() {
    const cases = await this.client.getAllLTFCases(
      null,
      null,
      null,
      0,
      0,
      null,
      null,
      null
    );
    return cases.total;
  }
});
