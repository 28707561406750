import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["IncomeGrid"], {
    incomes: $setup.incomes,
    onAddIncome: $setup.addIncome,
    onSaveIncome: $setup.saveIncome,
    onUpdateIncome: $setup.updateIncome,
    onUndeleteIncome: $setup.undeleteIncome,
    onDeleteIncome: $setup.deleteIncome
  }, null, 8 /* PROPS */, ["incomes"]))
}