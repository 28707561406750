
  import { defineComponent } from 'vue';
  import { FilterMatchMode, FilterOperator } from 'primevue/api';
  import DataTable, {
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableSortEvent
  } from 'primevue/datatable';
  import { toGBDateString } from '../../composables/UtilityFunctions';
  import { casesDashboard } from '../../stores/casesDashboard.store';
  //import { dropdownsStore } from '@/stores/dropdowns.store';
  import { userStore } from '@/stores/user.store';
  import { currentUserStore } from '@/stores/currentUser.store';
  import {
    CaseClient,
    ReassignCasesModel,
    UserCaseDto
  } from '@/clients/client.gen';
  import Dropdown from 'primevue/dropdown';
  import Calendar from 'primevue/calendar';
  import router from '@/router';

  const baseFilter: DataTableFilterMeta = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    owner: { value: null, matchMode: FilterMatchMode.EQUALS },
    caseStageName: { value: null, matchMode: FilterMatchMode.EQUALS },
    description: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedUserName: { value: null, matchMode: FilterMatchMode.EQUALS },
    dateFollowUp: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
    }
  };
  export default defineComponent({
    components: { Calendar, DataTable, Dropdown },
    data() {
      return {
        filters: { ...baseFilter },
        selectedRows: [] as any[],
        assignTo: null,
        isAftercareAdmin: false,
        debounceTimer: null as any,
        disableReassign: true,
        casesDashboard,
        users: [] as any[],
        cases: [] as Array<UserCaseDto>,
        //userTaskTypes: [] as any[],
        globalFilterFields: [
          'owner',
          'assignedUserName',
          'freetext',
          'caseType',
          'dateFollowUp'
        ]
      };
    },
    computed: {
      availableAssignedTo() {
        const uniqueNames = new Set<string>();
        const availableAssignedTo: {
          name: string | null | undefined;
          id: string | null | undefined;
        }[] = [];

        casesDashboard.aftercareCases.forEach((c) => {
          if (c.assignedUserId && !uniqueNames.has(c.assignedUserId)) {
            uniqueNames.add(c.assignedUserId);
            availableAssignedTo.push({
              name: this.getUserNameFromId(c.assignedUserId),
              id: c.assignedUserId
            });
          }
        });

        availableAssignedTo.push({ name: 'All users', id: null });
        return availableAssignedTo;
      },
      availableCasesStages() {
        const uniqueStages = new Set<string>();
        const availableCasesStages: { name: string; id: string | null }[] = [];

        casesDashboard.aftercareCases.forEach((c) => {
          if (c.caseStageName && !uniqueStages.has(c.caseStageName)) {
            uniqueStages.add(c.caseStageName);
            availableCasesStages.push({
              name: c.caseStageName,
              id: c.caseStageName
            });
          }
        });

        availableCasesStages.push({ name: 'All stages', id: null });
        return availableCasesStages;
      },
      loading() {
        return casesDashboard.loading;
      }
    },
    async created() {
      await this.init();
    },
    methods: {
      async onRowSelect(event: any) {
        if (event.type != 'checkbox')
          router.push({
            name: 'caseProfile',
            params: { id: (event.data as any).caseId }
          });
      },
      async getData() {
        await casesDashboard.getAftercareCases();
      },
      toGBDateString,
      async clearFilter() {
        await this.init();
      },
      async applySort(event: DataTableSortEvent) {
        casesDashboard.sortField = event.sortField?.toString() ?? null;
        casesDashboard.sortOrder = event.sortOrder ?? null;
        await this.getData();
      },
      async filter() {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }

        // Set a new debounce timer
        this.debounceTimer = setTimeout(() => {
          // Process the input value after the debounce delay
          casesDashboard.searchTerm = (
            this.filters.global as DataTableFilterMetaData
          ).value;
          this.getData();
          // Clear the debounce timer
          this.debounceTimer = null;
        }, 250); // Adjust the delay as needed (250ms in this example)
      },
      async init() {
        if (userStore.users.length == 0) await userStore.getData();
        this.users = userStore.users;
        //this.userTaskTypes = await dropdownsStore.getTaskTypes();
        //this.userTaskTypes.push({ userTaskTypeId: null, name: "All types" });
        this.filters = { ...baseFilter };
        await this.getData();

        await currentUserStore.getCurrentUserGroups();
        this.isAftercareAdmin = currentUserStore.isAftercareAdmin;
      },
      onClearFiltersClick() {
        this.init();
        casesDashboard.getAftercareCases();
      },
      getUserNameFromId(userId: string) {
        const userMatch = this.users.find((u) => u.userId == userId);
        return userMatch?.displayName;
      },
      assignCallback() {
        this.disableReassign = false;
      },
      async onReassignSelected() {
        const selectedRowIds = this.selectedRows.map((sr) => sr.caseId);

        const client = new CaseClient();
        const model = new ReassignCasesModel();
        model.assignedUserId = this.assignTo ?? undefined;
        model.caseIds = selectedRowIds;

        const result = await client.reassignCases(model);
        if (result.success) {
          //reload cases!
          await this.getData();
        }
        this.assignTo = null;
      }
    }
  });
