
  import { defineComponent } from 'vue';
  import ContactAutocomplete from '../../Common/ContactAutocomplete.vue';
  import OverlayPanel from 'primevue/overlaypanel';
  export default defineComponent({
    components: { ContactAutocomplete, OverlayPanel },
    emits: ['created'],
    data(): any {
      return {
        contactId: 0
      };
    },
    methods: {
      toggleOverlayPanel(event: any) {
        (this.$refs.create as any).toggle(event);
      },
      selectContact(constactId: number) {
        this.contactId = constactId;
      },
      async createCase(event: any) {
        const routeURL = this.$router.resolve({
          name: 'caseProfile',
          params: { id: 0 },
          query: { contactId: this.contactId }
        }).href;

        window.open(routeURL, '_blank');

        this.toggleOverlayPanel(event);
      }
    }
  });
