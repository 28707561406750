
  export interface IData {
    internalExpense: ExpenseDto;
    selectedExpenseTypes: Array<DropDownValues>;
    costCentres: Array<StringDropDownValues>;
    expenseTypes: Array<DropDownValues>;
  }

  import { defineComponent, PropType } from 'vue';
  import { FormFieldSelect } from '../';
  import Checkbox from 'primevue/checkbox';
  import { dropdownsStore } from '../../../stores/dropdowns.store';
  import {
    DropDownValues,
    ExpenseCodeDto,
    ExpenseDto,
    ExpenseTypeEnum,
    FlattenedCasePetDto,
    FlattenedCaseVolunteer,
    StringDropDownValues
  } from '../../../clients/client.gen';

  export default defineComponent({
    components: {
      Checkbox,
      FormFieldSelect
    },
    props: {
      volunteers: {
        type: Array as PropType<Array<FlattenedCaseVolunteer>>,
        required: true
      },
      pets: {
        type: Array as PropType<Array<FlattenedCasePetDto>>,
        required: true
      },
      expense: {
        type: ExpenseDto,
        required: true
      }
    },
    emits: ['update:modelValue'],
    data(): IData {
      return {
        internalExpense: new ExpenseDto(),
        selectedExpenseTypes: new Array<DropDownValues>(),
        costCentres: new Array<StringDropDownValues>(),
        expenseTypes: new Array<DropDownValues>()
      };
    },
    computed: {
      maxDate() {
        return new Date();
      },
      minDate() {
        const currentDate = new Date();

        const yearsAgo = new Date(currentDate);
        yearsAgo.setFullYear(currentDate.getFullYear() - 1);
        return yearsAgo;
      }
    },
    async created() {
      this.internalExpense = Object.assign(new ExpenseDto(), this.expense);
      this.selectedExpenseTypes = this.getExpenseTypesFromCodes(
        this.internalExpense.expenseCodes
      );
      await this.populateDropdowns();
    },
    methods: {
      clearCentres() {
        this.internalExpense.costCentreCode = undefined;
      },
      async populateDropdowns() {
        this.expenseTypes = await dropdownsStore.getExpenseTypes(); //["Travel", "Food", "Vet", "Other"];
        //ToDo: Fix me felix.
        this.costCentres = await dropdownsStore.getCostCentres('7200');
        if (this.costCentres.length > 0) {
          console.log(this.costCentres);
          this.internalExpense.costCentreCode =
            this.costCentres.find(
              (cc: StringDropDownValues) => cc.code === '03'
            )?.code ?? '';
        } else {
          this.internalExpense.costCentreCode = '03';
        }
        this.internalExpense.petIds = this.internalExpense.pets?.map(
          (pet) => pet.petId
        );
        this.updateVal();
      },
      selectTypes(types: any) {
        this.internalExpense.expenseCodes = types.value.map(
          (et: DropDownValues) => {
            const ec = new ExpenseCodeDto();
            ec.expenseTypeId = et.id;
            ec.codeTotal = 0;
            ec.expenseItems = [];
            return ec;
          }
        );
        this.updateVal();
      },
      updatePets(value: number[]) {
        this.internalExpense.petIds = value;
        this.updateVal();
      },
      updateVolunteer(value: number) {
        this.internalExpense.caseVolunteerId = value;
        this.updateVal();
      },
      updateCCC(value: string) {
        this.internalExpense.costCentreCode = value;
        this.updateVal();
      },
      updateDescription(value: string) {
        this.internalExpense.description = value;
        this.updateVal();
      },
      updateApproval(value: boolean) {
        this.internalExpense.needsApproval = value;
        this.updateVal();
      },
      updateBankTask(value: boolean) {
        this.internalExpense.bankTaskRequired = value;
        this.updateVal();
      },
      updateSubmitted(value: Date) {
        this.internalExpense.submittedDate = value;
        this.updateVal();
      },
      updateVal() {
        const entity = new ExpenseDto();
        const newExpense = Object.assign(entity, this.expense);
        newExpense.petIds = this.internalExpense.petIds;
        newExpense.caseVolunteerId = this.internalExpense.caseVolunteerId;
        newExpense.needsApproval = this.internalExpense.needsApproval;
        newExpense.submittedDate = this.internalExpense.submittedDate;
        newExpense.bankTaskRequired = this.internalExpense.bankTaskRequired;
        newExpense.nominalCode = this.internalExpense.nominalCode;
        newExpense.costCentreCode = this.internalExpense.costCentreCode;
        newExpense.expenseCodes = this.internalExpense.expenseCodes;
        newExpense.description = this.internalExpense.description;
        this.$emit('update:modelValue', newExpense);
      },
      getExpenseTypesFromCodes(codes: ExpenseCodeDto[] | undefined) {
        if (codes === undefined) return [];
        let expenseTypes = new Array<DropDownValues>();
        expenseTypes = codes.map((ec) => {
          const et = new DropDownValues();
          et.id = ec.expenseTypeId;
          et.name = ExpenseTypeEnum[ec.expenseTypeId];
          return et;
        });
        return expenseTypes;
      }
    }
  });
