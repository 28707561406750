
  import { defineComponent } from 'vue';
  import DocumentList from '@/components/Common/DocumentList.vue';
  import { DocumentClient, FileParameter } from '@/clients/client.gen';
  import { petStore } from '@/stores/pet.store';

  export default defineComponent({
    components: {
      DocumentList
    },
    data(): any {
      return {};
    },
    computed: {
      petStore() {
        return petStore;
      }
    },
    async created() {},
    methods: {
      async update() {
        await this.petStore.getDocumentData(this.petStore.petOverview.petId);
      },
      async uploadFile(
        categories: string | undefined,
        description: string | undefined,
        dateReceived: Date | undefined,
        fileData: FileParameter
      ) {
        const client = new DocumentClient();
        const result = await client.uploadDocument(
          fileData,
          categories,
          description,
          dateReceived,
          null,
          null,
          this.petStore.petOverview.petId,
          null,
          null
        );
        if (result) {
          await this.update();
          this.$refs.documentList.clearUpload();
        }
      }
    }
  });
