import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "invalid-text col-9 col-offset-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass($props.labelClass)
    }, [
      _renderSlot(_ctx.$slots, $props.inputDefinition.labelSlotName, {}, () => [
        _createElementVNode("label", {
          for: $props.inputDefinition.inputSlotName,
          class: _normalizeClass({ 'invalid-text': !$props.inputDefinition.isValid })
        }, [
          _createTextVNode(_toDisplayString($props.inputDefinition.label) + " ", 1 /* TEXT */),
          ($props.inputDefinition.required)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("*")
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ], 10 /* CLASS, PROPS */, _hoisted_1)
      ])
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass({
      'col-12': $props.labelAbove,
      'col-9': !$props.labelAbove
    })
    }, [
      _renderSlot(_ctx.$slots, $props.inputDefinition.inputSlotName, { value: $props.inputDefinition }, () => [
        ($props.inputDefinition.type === 'String')
          ? (_openBlock(), _createBlock(_component_InputText, {
              key: 0,
              id: $props.inputDefinition.inputSlotName,
              modelValue: $setup.model,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
                _cache[2] || (_cache[2] = ($event: any) => ($props.onFieldUpdated($props.inputDefinition)))
              ],
              label: $props.inputDefinition.label,
              readonly: $props.inputDefinition.readOnly === true,
              disabled: $setup.props.readonly || $props.inputDefinition.readOnly === true,
              onFocus: _cache[1] || (_cache[1] = ($event: any) => ($props.onFieldFocus($props.inputDefinition)))
            }, null, 8 /* PROPS */, ["id", "modelValue", "label", "readonly", "disabled"]))
          : ($props.inputDefinition.type === 'TextArea')
            ? (_openBlock(), _createBlock(_component_Textarea, {
                key: 1,
                id: $props.inputDefinition.inputSlotName,
                modelValue: $setup.model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.model) = $event)),
                label: $props.inputDefinition.label,
                disabled: $setup.props.readonly || $props.inputDefinition.readOnly === true
              }, null, 8 /* PROPS */, ["id", "modelValue", "label", "disabled"]))
            : (
          $props.inputDefinition.type === 'Int32' || $props.inputDefinition.type === 'Decimal'
        )
              ? (_openBlock(), _createBlock(_component_InputNumber, {
                  key: 2,
                  id: $props.inputDefinition.inputSlotName,
                  modelValue: $setup.model,
                  "onUpdate:modelValue": [
                    _cache[4] || (_cache[4] = ($event: any) => (($setup.model) = $event)),
                    _cache[6] || (_cache[6] = ($event: any) => ($props.onFieldUpdated($props.inputDefinition)))
                  ],
                  class: "w-full",
                  readonly: $setup.props.readonly || $props.inputDefinition.readOnly === true,
                  onFocus: _cache[5] || (_cache[5] = ($event: any) => ($props.onFieldFocus($props.inputDefinition)))
                }, null, 8 /* PROPS */, ["id", "modelValue", "readonly"]))
              : ($props.inputDefinition.type === 'Currency')
                ? (_openBlock(), _createBlock(_component_InputNumber, {
                    key: 3,
                    id: $props.inputDefinition.inputSlotName,
                    modelValue: $setup.model,
                    "onUpdate:modelValue": [
                      _cache[7] || (_cache[7] = ($event: any) => (($setup.model) = $event)),
                      _cache[9] || (_cache[9] = ($event: any) => ($props.onFieldUpdated($props.inputDefinition)))
                    ],
                    mode: "currency",
                    currency: "GBP",
                    class: "w-full",
                    locale: "en-GB",
                    readonly: $setup.props.readonly || $props.inputDefinition.readOnly === true,
                    onFocus: _cache[8] || (_cache[8] = ($event: any) => ($props.onFieldFocus($props.inputDefinition)))
                  }, null, 8 /* PROPS */, ["id", "modelValue", "readonly"]))
                : ($props.inputDefinition.type === 'Boolean')
                  ? (_openBlock(), _createBlock(_component_InputSwitch, {
                      key: 4,
                      id: $props.inputDefinition.inputSlotName,
                      modelValue: $setup.model,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.model) = $event)),
                      label: $props.inputDefinition.label,
                      disabled: $props.inputDefinition.readOnly === true || $setup.props.readonly,
                      onChange: _cache[11] || (_cache[11] = ($event: any) => ($props.onFieldUpdated($props.inputDefinition)))
                    }, null, 8 /* PROPS */, ["id", "modelValue", "label", "disabled"]))
                  : (
          $props.inputDefinition.type === 'DateTime' ||
          $props.inputDefinition.type === 'DateTimeOffset'
        )
                    ? (_openBlock(), _createBlock($setup["FormDatePicker"], {
                        key: 5,
                        id: $props.inputDefinition.inputSlotName,
                        modelValue: $setup.model,
                        "onUpdate:modelValue": [
                          _cache[12] || (_cache[12] = ($event: any) => (($setup.model) = $event)),
                          _cache[13] || (_cache[13] = ($event: any) => ($props.onFieldUpdated($props.inputDefinition)))
                        ],
                        class: "w-full",
                        disabled: $setup.props.readonly || $props.inputDefinition.readOnly === true
                      }, null, 8 /* PROPS */, ["id", "modelValue", "disabled"]))
                    : ($props.inputDefinition.type === 'DropDown')
                      ? (_openBlock(), _createBlock(_component_Dropdown, {
                          key: 6,
                          id: $props.inputDefinition.name + '-dropdown',
                          modelValue: $setup.model,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.model) = $event)),
                          class: "w-full",
                          "option-label": "name",
                          "option-value": "id",
                          "show-clear": !$props.inputDefinition.required,
                          options: $props.inputDefinition.options,
                          disabled: $setup.props.readonly || $props.inputDefinition.readOnly === true,
                          onChange: _cache[15] || (_cache[15] = ($event: any) => ($props.onFieldUpdated($props.inputDefinition)))
                        }, null, 8 /* PROPS */, ["id", "modelValue", "show-clear", "options", "disabled"]))
                      : ($props.inputDefinition.type === 'File')
                        ? (_openBlock(), _createBlock($setup["FormFieldFileUploader"], {
                            key: 7,
                            id: $props.inputDefinition.inputSlotName,
                            "selected-document": $props.inputDefinition.value,
                            class: "w-full",
                            "onUpdate:file": _cache[16] || (_cache[16] = ($event: any) => ($setup.model = $event.value))
                          }, null, 8 /* PROPS */, ["id", "selected-document"]))
                        : _createCommentVNode("v-if", true)
      ]),
      ($props.inputDefinition.errors)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.inputDefinition.errors, (error, errorIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: errorIndex,
                class: "p-error"
              }, _toDisplayString(error.message), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    (!$props.inputDefinition.isValid && $props.inputDefinition.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.inputDefinition.errorMessage), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}