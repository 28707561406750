import { reactive } from 'vue';
import {
  AuditEntryDto,
  AuditHistoryClient,
  ContactAdditionalNotesClient,
  ContactClient,
  ContactDetailsViewModel,
  ContactPetOverviewDto,
  ContactViewModel,
  DocumentClient,
  DropDownValues,
  GetDocumentsResponse,
  NoteDto,
  PetClient,
  SaveAdditionalNoteRequest,
  ContactTypeDto,
  LinkedContactDto,
  LinkedContactClient,
  OwnerHistoryDto,
  ContactListViewModel,
  ContactRelationshipTypeDto,
  CreateContactResult,
  Title,
  VolunteerClient
} from '../clients/client.gen';

import RecentlyViewedService, {
  IRecentlyViewedItem
} from '../services/RecentlyViewedService';
import { dropdownsStore } from './dropdowns.store';

const getContact = (): ContactViewModel => {
  const contact = new ContactViewModel();
  contact.contactDetails = new ContactDetailsViewModel();
  contact.contactDetails.contactId = 0;
  return contact;
};

export interface RecentlyViewedContact extends IRecentlyViewedItem {
  contactId: number;
  fullName?: string;
  county?: string;
  postcode?: string;
}

const recentlyViewedContact = new RecentlyViewedService<RecentlyViewedContact>(
  'Contact'
);

export type ClipboardContact = {
  title?: string;
  firstName?: string;
  lastName?: string;
  addressString?: string;
  mobile?: string;
  alternativeMobile?: string;
};

export interface ProcessCompleteEvent {
  result: number | undefined;
}

export class ContactPickerReturnData {
  relationshipType: ContactRelationshipTypeDto | undefined;
  contactId: number | undefined;
  distance: number | undefined;
}

export interface Dictionary<Type> {
  [key: string]: Type;
}

export const contactStore = reactive({
  contact: getContact(),
  linkedContacts: [] as LinkedContactDto[],
  auditHistory: [] as AuditEntryDto[],
  contactTypes: [] as ContactTypeDto[],
  documents: {} as GetDocumentsResponse,
  shownTabs: [] as string[],
  recentlyViewedContacts:
    recentlyViewedContact.listOfItems as RecentlyViewedContact[],
  mode: '',
  notes: [] as NoteDto[],
  reload: false,
  loading: false,
  duplicateContacts: [] as ContactListViewModel[],
  duplicateContactToSave: undefined as ContactDetailsViewModel | undefined,
  showBanners: false,

  saveNavigationHistory() {
    if (!this.contact || !this.contact.contactDetails?.contactId) return;
    const myContact: RecentlyViewedContact = {
      uniqueIdentifier: this.contact.contactDetails.contactId.toString(),
      contactId: this.contact.contactDetails.contactId,
      fullName: `${Title[this.contact.contactDetails.title as number] ?? ''} ${this.contact.contactDetails.firstName} ${this.contact.contactDetails.lastName}`,
      county: this.contact.contactDetails.address?.county,
      postcode: this.contact.contactDetails.address?.postCode
    };
    recentlyViewedContact.persist(myContact);
  },

  async fetchLTFPets(): Promise<OwnerHistoryDto[]> {
    if (!this.contact.contactDetails.contactId) {
      return [];
    }
    const client = new ContactClient();
    try {
      return await client.getLTFPets(this.contact.contactDetails.contactId);
    } catch {
      return [];
    }
  },

  async fetchPets(): Promise<ContactPetOverviewDto[]> {
    if (!this.contact.contactDetails.contactId) {
      return [];
    }
    const client = new PetClient();
    try {
      return await client.getPets(this.contact.contactDetails.contactId);
    } catch {
      return [];
    }
  },

  async fetchLinkedContactNotes() {
    if (!this.contact.contactDetails.contactId) {
      return '';
    }
    const client = new ContactAdditionalNotesClient();
    try {
      return await client.getLinkedContactAdditionalNote(
        this.contact.contactDetails.contactId
      );
    } catch {
      return '';
    }
  },

  async deletePets(pets: number[]) {
    for (const pet of pets) {
      await this.deletePet(pet);
    }
  },

  async deletePet(pet: number) {
    const client = new PetClient();
    await client.delete(pet);
  },

  async undeletePet(pet: number) {
    const client = new PetClient();
    await client.undelete(pet);
  },

  async addLinkedContactNote(text: string, keySafe: string | undefined) {
    const client = new ContactAdditionalNotesClient();
    const command = new SaveAdditionalNoteRequest();
    command.text = text;
    command.keySafe = keySafe;
    client.saveLinkedContactAdditionalNote(
      command,
      this.contact.contactDetails.contactId
    );
  },

  async getVolunteerByContactId() {
    const client = new VolunteerClient();
    this.contact.volunteerDetails = await client.getForContact(
      this.contact.contactDetails.contactId
    );
  },

  async setLinkedContacts() {
    if (!this.contact.contactDetails.contactId) return;
    const client = new LinkedContactClient();
    const result = await client.getByContactId(
      this.contact.contactDetails.contactId
    );
    this.linkedContacts = result ? result : [];
  },

  async getData(id?: number) {
    this.contactTypes = dropdownsStore.contactTypes.map(function (
      type: DropDownValues
    ) {
      return { contactTypeId: type.id, name: type.name } as ContactTypeDto;
    });

    if (!id) {
      this.contact = getContact();
      this.contact.mode = 'create';
      return;
    }
    this.loading = true;

    //Load all as async operations so that they don't block each other from rendering.
    await this.getContactData(id);

    this.updateDisplayedTabs();

    this.loading = false;
  },

  async getContactData(id: number) {
    const client = new ContactClient();
    try {
      this.contact = await client.getById(id);
    } catch {
      this.contact = getContact();
    }
  },

  getDocumentData(id: number) {
    const client = new DocumentClient();
    try {
      return client.getContactDocuments(id).then((result) => {
        this.documents = result;
      });
    } catch {
      return new GetDocumentsResponse();
    }
  },

  async getAuditHistory(id: number) {
    const auditClient = new AuditHistoryClient();
    const result = await auditClient.getForContact(id);
    if (result.success && result.result) {
      this.auditHistory = result.result;
    } else {
      this.auditHistory = [];
    }
  },

  async saveContact(
    contact: ContactDetailsViewModel | undefined,
    force: boolean = false,
    allowLoading: boolean = true
  ): Promise<CreateContactResult | undefined> {
    // ensure the volunteer id is correctly set so that it doesn't break the existing connections.
    // volunteerId doesn't exist on contactDetails object
    // this.contactStore.contact.contactDetails.volunteerId =
    //     this.contactStore.contact.volunteerDetails.volunteerId;

    if (allowLoading) this.loading = true;
    try {
      const client = new ContactClient();
      const command = {
        ...contact
      } as ContactDetailsViewModel;
      const result = await client.createContact(command, force);

      if (!result.success && allowLoading) {
        this.loading = false;
      }

      if (result.result?.isDuplicate) {
        this.duplicateContacts = result.result?.duplicateContacts ?? [];
        this.duplicateContactToSave = contact;
      }
      return result.result;
    } catch (error) {
      console.log(error);
      return undefined;
    } finally {
      if (allowLoading) this.loading = false;
    }
  },

  async copyContactToClipboard(contact: ClipboardContact) {
    let copyText = `Name: ${contact.firstName} ${contact.lastName}\n`;
    if (contact.title) {
      copyText = `Name: ${contact.title} ${contact.firstName} ${contact.lastName}\n`;
    }
    if (contact.mobile) {
      copyText += `Telephone: ${contact.mobile}\n`;
    }
    if (contact.alternativeMobile) {
      copyText += `Other Telephone: ${contact.alternativeMobile}\n`;
    }
    if (contact.addressString) {
      copyText += `Address: ${contact.addressString}\n`;
    }

    await navigator.clipboard.writeText(copyText);
  },

  updateDisplayedTabs() {
    if (this?.contact?.contactDetails?.contactTypes == null) {
      return;
    }
    const tabMatrix: Dictionary<string[]> = {
      Owner: ['Audit', 'Linked Contacts', 'Pets', 'Cases', 'Documents'],
      Volunteer: [
        'Audit',
        'Volunteer',
        'Linked Contacts',
        'Cases',
        'References',
        'Documents'
      ],
      LTF: ['Audit', 'LTF', 'Documents', 'Cases', 'Pets', 'Income'],
      LTFPrevious: ['Audit', 'LTF', 'Documents', 'Cases', 'Pets', 'Income'],
      LTFEnquirer: ['Audit', 'LTF Enquirer', 'Documents', 'Homecheck'],
      Purchaser: ['Audit', 'Documents', 'Income'],
      Member: ['Audit', 'Documents', 'Income'],
      Reference: ['Audit'],
      Supporter: ['Audit', 'Documents', 'Income'],
      Enquirer: ['Audit'],
      Organiser: ['Audit', 'Documents', 'Cases', 'Income'],
      Legacy: ['Audit', 'Legacy', 'Income'],
      LifeMember: ['Audit', 'Documents', 'Income'],
      NextofKin: ['Audit', 'Linked Contacts'],
      StandingOrder: ['Audit', 'Documents', 'Income'],
      VIP: ['Audit'],
      Archived: ['Audit']
    };

    const tabsToShow = new Array<string>();

    const names = new Array<string>();

    this.contact.contactDetails.contactTypes.forEach((v: ContactTypeDto) => {
      names.push(v.name.replaceAll(' ', ''));
    });

    //need a quick array...
    for (const [key, value] of Object.entries(tabMatrix)) {
      if (names.includes(key)) {
        value.forEach((val) => {
          if (!tabsToShow.includes(val)) tabsToShow.push(val);
        });
      }
    }
    this.shownTabs = tabsToShow;
  }
});
