import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withKeys as _withKeys, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid"
}
const _hoisted_2 = { class: "col-2" }
const _hoisted_3 = {
  key: 0,
  class: "col-4 pr-4"
}
const _hoisted_4 = {
  key: 1,
  class: "col-2"
}
const _hoisted_5 = {
  key: 2,
  class: "col-4"
}
const _hoisted_6 = { class: "col-2" }
const _hoisted_7 = {
  key: 1,
  class: "grid"
}
const _hoisted_8 = { class: "col-8" }
const _hoisted_9 = { class: "mt-2" }
const _hoisted_10 = { class: "col-4" }
const _hoisted_11 = { class: "col-2" }
const _hoisted_12 = { class: "flex flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      ref: "file",
      type: "file",
      style: {"display":"none"},
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newImageSelected($event)))
    }, null, 544 /* NEED_HYDRATION, NEED_PATCH */),
    _createElementVNode("h3", null, "Add " + _toDisplayString(_ctx.ExpenseTypeEnum[_ctx.expenseCode.expenseTypeId]) + " Expenses", 1 /* TEXT */),
    _createVNode(_component_DataTable, {
      editingRows: _ctx.editingRows,
      "onUpdate:editingRows": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editingRows) = $event)),
      loading: _ctx.loading,
      scrollable: "",
      "edit-mode": "row",
      value: _ctx.expenses,
      "data-key": "expenseItemId",
      class: "col-12 mb-3 p-datatable-sm",
      "table-class": "editable-cells-table",
      onRowEditSave: _ctx.onRowEditSave
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          sortable: "",
          field: "spentOn",
          header: "Date"
        }, {
          body: _withCtx(({ data, field }) => [
            _createTextVNode(_toDisplayString(_ctx.toGBDateString(data[field])), 1 /* TEXT */)
          ]),
          editor: _withCtx(({ data, field }) => [
            _createVNode(_component_Calendar, {
              modelValue: data[field],
              "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
              "show-icon": "",
              "date-format": "dd/mm/yy"
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1 /* STABLE */
        }),
        (_ctx.showDescriptionColumn())
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              sortable: "",
              field: "description",
              header: "Description"
            }, {
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputText, {
                  modelValue: data[field],
                  "onUpdate:modelValue": ($event: any) => ((data[field]) = $event)
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (_ctx.showDescriptionColumn())
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 1,
              sortable: "",
              field: "costTotal",
              header: "Cost"
            }, {
              body: _withCtx(({ data, field }) => [
                _createTextVNode(_toDisplayString(_ctx.formatCurrency(data[field])), 1 /* TEXT */)
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  modelValue: data[field],
                  "onUpdate:modelValue": ($event: any) => ((data[field]) = $event),
                  mode: "currency",
                  currency: "GBP",
                  locale: "en-GB"
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (_ctx.showMileageColumn())
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 2,
              sortable: "",
              field: "itemValue",
              header: "Mileage"
            }, {
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  modelValue: data[field],
                  "onUpdate:modelValue": ($event: any) => ((data[field]) = $event)
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (_ctx.showMileageColumn())
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 3,
              sortable: "",
              field: "costTotal",
              header: "Cost @ 45p/Mile"
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(_ctx.formatCurrency(_ctx.calculateMileage(data.itemValue))), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_Column, {
          field: "receiptId",
          header: "File"
        }, {
          body: _withCtx((data) => [
            _createTextVNode(_toDisplayString(data.data.receipt?.fileName), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, { header: "" }, {
          body: _withCtx(({ data }) => [
            _createVNode(_component_Button, {
              class: "flex",
              icon: "pi pi-trash",
              severity: "danger",
              onClick: () => _ctx.deleteRecord(data)
            }, null, 8 /* PROPS */, ["onClick"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          "row-editor": true,
          style: {"width":"10%","min-width":"8rem"},
          "body-style": "text-align:center"
        }),
        _createVNode(_component_ColumnGroup, { type: "footer" }, {
          default: _withCtx(() => [
            _createVNode(_component_Row, null, {
              default: _withCtx(() => [
                _createVNode(_component_Column, { footer: "Totals:" }),
                _createVNode(_component_Column),
                _createVNode(_component_Column, {
                  footer: _ctx.formatCurrency(_ctx.subTotal)
                }, null, 8 /* PROPS */, ["footer"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["editingRows", "loading", "value", "onRowEditSave"]),
    (_ctx.hasData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Calendar, {
              modelValue: _ctx.blankRow.spentOn,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.blankRow.spentOn) = $event)),
              class: "w-full",
              "show-icon": "",
              "date-format": "dd/mm/yy"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          (_ctx.showDescriptionColumn())
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_InputText, {
                  modelValue: _ctx.blankRow.description,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.blankRow.description) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.showDescriptionColumn())
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_InputNumber, {
                  modelValue: _ctx.blankRow.costTotal,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.blankRow.costTotal) = $event)),
                  mode: "currency",
                  currency: "GBP",
                  locale: "en-GB",
                  onKeyup: _withKeys(_ctx.addNewRecord, ["enter"])
                }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.showMileageColumn())
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_InputNumber, {
                  modelValue: _ctx.blankRow.itemValue,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.blankRow.itemValue) = $event)),
                  onKeyup: _withKeys(_ctx.addNewRecord, ["enter"])
                }, null, 8 /* PROPS */, ["modelValue", "onKeyup"])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.blankRow.receiptId === undefined || _ctx.blankRow.receiptId === 0)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  class: "flex",
                  text: "",
                  rounded: "",
                  icon: "pi pi-upload",
                  onClick: _ctx.openFileDialog
                }, null, 8 /* PROPS */, ["onClick"]))
              : _createCommentVNode("v-if", true),
            (_ctx.blankRow.receiptId > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_ScrollPanel, { class: "w-full" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.blankRow.receipt.fileName), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_Button, {
                      class: "flex",
                      text: "",
                      rounded: "",
                      icon: "pi pi-trash",
                      onClick: _ctx.removeReceiptFile
                    }, null, 8 /* PROPS */, ["onClick"])
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_Button, {
                text: "",
                icon: "pi pi-plus",
                label: "Add record",
                size: "small",
                severity: "warning",
                rounded: "",
                class: "pr-4",
                onClick: _ctx.addNewRecord
              }, null, 8 /* PROPS */, ["onClick"])
            ])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}