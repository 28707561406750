import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.hasLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: $setup.computedId,
          class: _normalizeClass($setup.labelClass)
        }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(["col-9", { 'col-12': _ctx.labelAbove || $setup.hasLabel == false }])
    }, [
      _createVNode(_component_Calendar, _mergeProps({ "input-id": $setup.computedId }, _ctx.$attrs, {
        "model-value": $setup.model,
        class: "w-full",
        disabled: $setup.props.disabled,
        readonly: $setup.props.readOnly,
        "min-date": $setup.minDate,
        "max-date": $setup.maxDate,
        placeholder: $setup.props.placeholder,
        "show-icon": "",
        "date-format": $setup.props.dateFormat,
        view: $setup.props.view,
        "show-button-bar": $setup.props.showButtonBar,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($setup.handleDateChange($event))),
        onClearClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleDateChange(undefined)))
      }), null, 16 /* FULL_PROPS */, ["input-id", "model-value", "disabled", "readonly", "min-date", "max-date", "placeholder", "date-format", "view", "show-button-bar"])
    ], 2 /* CLASS */)
  ]))
}