import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "pl-5 col-6" }
const _hoisted_3 = {
  key: 0,
  class: "grid"
}
const _hoisted_4 = { class: "p-5 col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["AdvancedSearch"], {
          ref: "advancedSearch",
          parameters: $setup.service.parameters,
          "onUpdate:parameters": [
            _cache[0] || (_cache[0] = ($event: any) => (($setup.service.parameters) = $event)),
            _cache[1] || (_cache[1] = (p) => ($setup.service.parameters = p))
          ],
          title: $setup.service.selectedOption.searchTitle,
          fields: $setup.service.selectedFields,
          "emit-results": true,
          onSearchClicked: $setup.search
        }, null, 8 /* PROPS */, ["parameters", "title", "fields"])
      ])
    ]),
    ($setup.results)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_DataTable, {
              value: $setup.results,
              "data-key": $setup.dataKey,
              "responsive-layout": "scroll",
              lazy: "",
              paginator: "",
              loading: $setup.loading,
              "total-records": $setup.service.totalRecords,
              rows: 10,
              class: "mb-3",
              "row-class": $setup.isRowDeleted,
              onRowClick: $setup.onRowSelect,
              onPage: _cache[2] || (_cache[2] = ($event: any) => ($setup.onPage($event))),
              onSort: _cache[3] || (_cache[3] = ($event: any) => ($setup.onSort($event)))
            }, {
              empty: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.emptyText), 1 /* TEXT */)
              ]),
              loading: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.loadingText), 1 /* TEXT */)
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (col) => {
                  return (_openBlock(), _createBlock(_component_Column, {
                    key: col.field,
                    field: col.field,
                    header: col.header,
                    sortable: col.sortable
                  }, null, 8 /* PROPS */, ["field", "header", "sortable"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value", "data-key", "loading", "total-records", "row-class"])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}