import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex h-full"
}
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-8" }
const _hoisted_4 = { class: "flex justify-content-end align-content-center gap-2" }
const _hoisted_5 = { class: "flex flex-column gap-2" }
const _hoisted_6 = { class: "grid" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "flex justify-content-end gap-2" }
const _hoisted_11 = { class: "flex flex-column" }
const _hoisted_12 = { class: "flex gap-2 justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.contactStore.contact.contactDetails.contactId < 1)
      ? (_openBlock(), _createBlock($setup["RequiredEntity"], { key: 0 }))
      : _createCommentVNode("v-if", true),
    ($setup.contactStore.contact.contactDetails.contactId > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ScrollPanel, { class: "h-full w-full pb-2 pt-2" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_Button, {
                      icon: "pi pi-trash",
                      text: "",
                      severity: "secondary",
                      "aria-label": "Delete selected",
                      disabled: $setup.selectedTasks.length == 0,
                      onClick: $setup.startDeleteTask
                    }, null, 8 /* PROPS */, ["disabled"]),
                    _createVNode(_component_Button, {
                      label: "Add Task",
                      size: "small",
                      onClick: $setup.showAddTaskOverlay
                    })
                  ]),
                  _createVNode($setup["DataTable"], {
                    selection: $setup.selectedTasks,
                    "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedTasks) = $event)),
                    value: $setup.volunteerTasks
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        "selection-mode": "multiple",
                        "header-style": "width: 3rem"
                      }),
                      _createVNode(_component_Column, {
                        field: "caseTypeName",
                        header: "Task Type"
                      }),
                      _createVNode(_component_Column, {
                        field: "animalTypeName",
                        header: "Animal Type"
                      }),
                      _createVNode(_component_Column, {
                        field: "maxDistance",
                        header: "Distance (Miles)"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(slotProps.data.maxDistance ?? 'Unspecified/Unlimited'), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["selection", "value"])
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode($setup["OverlayPanel"], {
            ref: "addTaskOverlay",
            style: {"width":"300px"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode($setup["FormFieldSelect"], {
                      modelValue: $setup.newVolunteerTask.taskTypeId,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.newVolunteerTask.taskTypeId) = $event)),
                      "label-above": "",
                      "option-value": "caseTypeId",
                      "option-label": "name",
                      options: $setup.caseTypes,
                      label: "Task Type"
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode($setup["FormFieldSelect"], {
                      modelValue: $setup.newVolunteerTask.animalTypeId,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.newVolunteerTask.animalTypeId) = $event)),
                      "label-above": "",
                      "option-value": "petTypeId",
                      "option-label": "name",
                      options: $setup.petTypes,
                      label: "Animal Type"
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode($setup["FormField"], {
                      modelValue: $setup.newVolunteerTask.maxDistance,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.newVolunteerTask.maxDistance) = $event)),
                      type: "number",
                      "max-number": 10000000,
                      label: "Max distance (Miles)",
                      "label-above": ""
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_Button, {
                    label: "Cancel",
                    onClick: $setup.hideAddTaskOverlay
                  }),
                  _createVNode(_component_Button, {
                    label: "Save",
                    disabled: !$setup.newVolunteerTask?.taskTypeId,
                    onClick: $setup.saveNewTask
                  }, null, 8 /* PROPS */, ["disabled"])
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }, 512 /* NEED_PATCH */),
          _createVNode($setup["OverlayPanel"], {
            ref: "deleteTaskCheckPanel",
            style: {"width":"300px"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", null, " This will permanently delete " + _toDisplayString($setup.selectedTasks.length) + " record/s, are you sure? ", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_Button, {
                    label: "No",
                    onClick: $setup.startDeleteTask
                  }),
                  _createVNode(_component_Button, {
                    label: "Yes",
                    onClick: $setup.deleteTask
                  })
                ])
              ])
            ]),
            _: 1 /* STABLE */
          }, 512 /* NEED_PATCH */)
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}