import { createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-end gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: $setup.model,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (($setup.model) = $event)),
    modal: "",
    header: "Preferred pet",
    style: { width: '90%' }
  }, {
    default: _withCtx(() => [
      _createVNode($setup["GeneratedTable"], {
        client: $setup.client,
        "create-button-label": 'Create preferred pet',
        "validate-form": $setup.validateForm,
        "form-input-definition-overrides": $setup.formInputDefinitionOverrides,
        "retrieve-data-override": 
        (request) =>
          $setup.client.getForContactId(
            $setup.contactStore.contact.contactDetails.contactId,
            request
          )
      ,
        onCreateModalReset: $setup.resetCreateModal,
        onFormInputDefinitionsLoaded: $setup.onFormInputDefinitionsLoaded
      }, {
        "toolbar-start": _withCtx(() => [
          _createVNode($setup["EnquirerInfo"], {
            "enquirer-info": $setup.props.enquirerInfo
          }, null, 8 /* PROPS */, ["enquirer-info"])
        ]),
        "field-FormId": _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.value), 1 /* TEXT */)
        ]),
        "input-PetTypeId": _withCtx((slotProps) => [
          _createVNode(_component_Dropdown, {
            modelValue: slotProps.value.value,
            "onUpdate:modelValue": [
              ($event: any) => ((slotProps.value.value) = $event),
              _cache[0] || (_cache[0] = 
            (value) => ($setup.selectedPetTypeId = value)
          )
            ],
            class: "w-full",
            options: $setup.dropdownsStore.petTypes,
            "option-label": "name",
            "option-value": "petTypeId",
            placeholder: "Select a pet type"
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        "input-PetSizes": _withCtx((slotProps) => [
          _createVNode(_component_MultiSelect, {
            modelValue: slotProps.value.value,
            "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
            class: "w-full",
            options: $setup.dropdownsStore.petSizes,
            "option-label": "name",
            placeholder: "Select one or more pet sizes"
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        "input-PetCharacteristics": _withCtx((slotProps) => [
          _createVNode(_component_MultiSelect, {
            modelValue: slotProps.value.value,
            "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
            class: "w-full",
            options: $setup.dropdownsStore.petCharacteristics,
            "option-label": "name",
            display: "chip",
            placeholder: "Select one or more pet characteristics"
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        "input-PetBreeds": _withCtx((slotProps) => [
          _createVNode(_component_MultiSelect, {
            class: "w-full",
            "model-value": 
            slotProps.value.value?.filter(
              (pb) => pb.petTypeId === $setup.selectedPetTypeId
            )
          ,
            options: 
            $setup.availablePetBreeds.filter(
              (pb) => pb.petTypeId === $setup.selectedPetTypeId
            )
          ,
            disabled: !$setup.selectedPetTypeId,
            "option-label": "name",
            filter: "",
            display: 
            slotProps.value.value?.length > $setup.maximumLabelsForSelectedPetBreeds
              ? 'comma'
              : 'chip'
          ,
            "max-selected-labels": $setup.maximumLabelsForSelectedPetBreeds,
            "selected-items-label": "{0} Breeds selected...",
            placeholder: "Select one or more pet breeds",
            onChange: (e) => $setup.onPetBreedSelectionChange(e, slotProps)
          }, null, 8 /* PROPS */, ["model-value", "options", "disabled", "display", "onChange"])
        ]),
        "input-NotConsideredPetBreeds": _withCtx((slotProps) => [
          _createVNode(_component_MultiSelect, {
            class: "w-full",
            "model-value": 
            slotProps.value.value?.filter(
              (pb) => pb.petTypeId === $setup.selectedPetTypeId
            )
          ,
            options: 
            $setup.availableUnconsideredPetBreeds.filter(
              (pb) => pb.petTypeId === $setup.selectedPetTypeId
            )
          ,
            disabled: !$setup.selectedPetTypeId,
            "option-label": "name",
            filter: "",
            display: 
            slotProps.value.value?.length > $setup.maximumLabelsForSelectedPetBreeds
              ? 'comma'
              : 'chip'
          ,
            "max-selected-labels": $setup.maximumLabelsForSelectedPetBreeds,
            "selected-items-label": "{0} Breeds selected...",
            placeholder: "Select one or more not considered pet breeds",
            onChange: 
            (e) =>
              $setup.onPetBreedSelectionChange(
                e,
                slotProps,
                $setup.deselectPetBreedsFromSelectedPetBreeds
              )
          
          }, null, 8 /* PROPS */, ["model-value", "options", "disabled", "display", "onChange"])
        ]),
        "input-PetAgeCategories": _withCtx((slotProps) => [
          _createVNode(_component_MultiSelect, {
            modelValue: slotProps.value.value,
            "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
            class: "w-full",
            options: $setup.dropdownsStore.petAgeCategories,
            "option-label": "name",
            display: "chip",
            placeholder: "Select one or more pet age categories"
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        "input-PreferredPetMoreThanOneConsiderationId": _withCtx((slotProps) => [
          _createVNode(_component_Dropdown, {
            modelValue: slotProps.value.value,
            "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
            class: "w-full",
            options: $setup.props.preferredPetMoreThanOneConsiderations,
            "option-label": "name",
            "option-value": "id",
            placeholder: "Select a preference"
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        "input-PetGenderId": _withCtx((slotProps) => [
          _createVNode(_component_Dropdown, {
            modelValue: slotProps.value.value,
            "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
            class: "w-full",
            options: $setup.dropdownsStore.petGenders,
            "option-label": "name",
            "option-value": "petGenderId",
            placeholder: "Select a pet sex"
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"])
        ]),
        "input-Comment": _withCtx((slotProps) => [
          _createVNode(_component_InputText, {
            modelValue: slotProps.value.value,
            "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
            class: "w-full",
            placeholder: "Enter any additional comments"
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
        ]),
        "column-petTypeId": _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString($setup.AnimalTypeEnum[slotProps.value.data.petTypeId]), 1 /* TEXT */)
        ]),
        "column-petGenderId": _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString($setup.AnimalGenderEnum[slotProps.value.data.petGenderId]), 1 /* TEXT */)
        ]),
        "column-preferredPetMoreThanOneConsiderationId": _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString($setup.PreferredPetMoreThanOneConsiderationEnum[
            slotProps.value.data.preferredPetMoreThanOneConsiderationId
          ]), 1 /* TEXT */)
        ]),
        "column-petSizes": _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString($setup.displayCommaSeparatedListName(slotProps.value.data.petSizes)), 1 /* TEXT */)
        ]),
        "column-petBreeds": _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString($setup.displayCommaSeparatedListName(slotProps.value.data.petBreeds, 5)), 1 /* TEXT */)
        ]),
        "column-notConsideredPetBreeds": _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString($setup.displayCommaSeparatedListName(
            slotProps.value.data.notConsideredPetBreeds,
            5
          )), 1 /* TEXT */)
        ]),
        "column-petAgeCategories": _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString($setup.displayCommaSeparatedListName(slotProps.value.data.petAgeCategories)), 1 /* TEXT */)
        ]),
        "column-petCharacteristics": _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString($setup.displayCommaSeparatedListName(slotProps.value.data.petCharacteristics)), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["client", "retrieve-data-override"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          type: "button",
          label: "Cancel",
          severity: "secondary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.model = false))
        }),
        _createVNode(_component_Button, {
          type: "button",
          label: "Save",
          onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.model = false))
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}