
  import { defineComponent } from 'vue';
  import AutoComplete from 'primevue/autocomplete';
  import {
    ContactClient,
    ContactListViewModel
  } from '../../clients/client.gen';
  const client = new ContactClient();
  export default defineComponent({
    components: {
      AutoComplete
    },
    props: {
      placeholder: {
        type: String,
        required: false,
        default: 'Start typing contact name...'
      }
    },
    emits: ['selected'],
    data(): any {
      return {
        users: [] as ContactListViewModel[],
        user: null
      };
    },
    watch: {
      user(newVal) {
        this.$emit('selected', newVal?.contactId);
      }
    },
    methods: {
      display(contact: { contactId: number; firstName: any; lastName: any }) {
        if (!contact?.firstName && !contact?.lastName) return contact.contactId;
        return `${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`;
      },
      async search(event: any) {
        if (!event.query.trim().length) {
          this.users = [];
        } else {
          this.users = await client.search(event.query.toLowerCase());
        }
      }
    }
  });
