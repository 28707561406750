
  import { defineComponent } from 'vue';
  import { DropDownValues, FlattenedIncomeDto } from '@/clients/client.gen';
  import { dropdownsStore } from '../../../../stores/dropdowns.store';
  import { singleIncomeStore } from '../../../../stores/singleIncome.store';
  export default defineComponent({
    components: {},
    props: {
      readOnly: Boolean
    },
    emits: ['update:modelValue'],
    data() {
      return {
        pice100CT: 0,
        paymentMethods: [] as Array<DropDownValues>,
        titles: [] as Array<DropDownValues>,
        membershipProducts: [
          { id: 33528991, name: 'Memberships - Renewal', price: 10 },
          { id: 33529008, name: 'Memberships - Renewal - OAP', price: 5 },
          { id: 33529035, name: 'Memberships - New', price: 10 },
          { id: 33529056, name: 'Memberships - New - OAP', price: 5 },
          { id: 33529168, name: 'Memberships - Life Member', price: 100 }
        ]
      };
    },
    computed: {
      getSubTotalAmount() {
        return () => {
          if (this.readOnly) {
            return this.income.orderTotalAmount ?? 0;
          }
          const lineItemPrice =
            this.income?.lineItems?.reduce(
              (a, b) => a + b.quantity! * b.unitPrice!,
              0
            ) ?? 0;
          const fundsRaised = this.income?.fundsRaised ?? 0;
          const donationAmount = this.income?.donationAmount ?? 0;
          const membershipProduct = this.membershipProducts.find(
            (x) => x.id === this.income?.membershipProductId
          );
          const priceForCT = this.income.c100Annual ? this.pice100CT : 0;
          return (
            lineItemPrice +
            priceForCT +
            fundsRaised +
            donationAmount +
            (membershipProduct?.price ?? 0)
          );
        };
      },
      income: {
        get(): FlattenedIncomeDto {
          return singleIncomeStore.income;
        },
        set(newVal: FlattenedIncomeDto) {
          singleIncomeStore.setIncome(newVal);
        }
      },
      getOrderTotalAmount() {
        return () => {
          const lineItemPrice = this.getSubTotalAmount();
          if (!this.income.shippingAmount) return lineItemPrice;
          return lineItemPrice + this.income.shippingAmount;
        };
      }
    },

    async created() {
      this.pice100CT = await singleIncomeStore.getPice100CT();
      this.paymentMethods = await dropdownsStore.getSinglePaymentMethods();
      this.titles = await dropdownsStore.getTitles();
      if (this.income.lineItems && this.income.lineItems.length > 0) {
        this.income.shippingAmount = this.income?.shippingAmount ?? 4.5;
      }
    },
    methods: {
      updateVal() {
        if (this.income.giftAiderSameAsContact) {
          this.income.giftAiderTitle = 0;
          this.income.giftAiderLastName = '';
          this.income.giftAiderFirstName = '';
        }
        this.income.orderTotalAmount = this.getOrderTotalAmount();
        this.$emit('update:modelValue', this.income);
      }
    }
  });
