
  import { defineComponent } from 'vue';
  import FormFieldMixin from './FormFieldMixin';
  import InputNumber from 'primevue/inputnumber';
  import InputMask from 'primevue/inputmask';
  import Textarea from 'primevue/textarea';

  export default defineComponent({
    components: { InputNumber, InputMask },
    mixins: [FormFieldMixin],
    props: {
      type: {
        type: String,
        required: false,
        default: 'text'
      },
      multiline: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      maxNumber: {
        type: Number,
        default: 150,
        required: false
      },
      useGrouping: {
        type: Boolean,
        default: true,
        required: false
      }
    }
  });
