import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full h-full p-4" }
const _hoisted_2 = { class: "grid h-full" }
const _hoisted_3 = { class: "col-12 h-full" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "flex flex-column gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ScrollPanel, { style: {"height":"100%"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["GeneratedTable"], {
              ref: "petExpenseTableRef",
              client: $setup.client,
              "extend-get-all": $setup.extendGetAll,
              "form-input-definition-overrides": $setup.defaultFormValues,
              "highlight-specific-row": $setup.highlightExpense,
              "validate-form": $setup.validateForm,
              "manage-form": $setup.shouldHideInput,
              editable: $setup.currentUserStore.isAnimalWelfare2User ||
              $setup.currentUserStore.isFinanceUser ||
              $setup.currentUserStore.isSystemAdminUser
              ,
              deletable: $setup.currentUserStore.isSystemAdminUser,
              onCreateModalReset: $setup.resetDefaults
            }, {
              "column-createdDate": _withCtx((slotProps) => [
                _createElementVNode("span", null, _toDisplayString(new Date(slotProps.value.data.createdDate).toLocaleDateString()), 1 /* TEXT */)
              ]),
              "column-pets": _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.value.data.pets, (pet) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: pet.petId
                    }, [
                      _createVNode($setup["Chip"], {
                        label: pet.petName
                      }, null, 8 /* PROPS */, ["label"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]),
              "input-PetIds": _withCtx((slotProps) => [
                _createVNode($setup["PetSelector"], {
                  "owner-id": $setup.petStore.petOverview.ltfContactId,
                  "selected-pet-ids": slotProps.value.value,
                  placeholder: "Select relevant pets for this expense.",
                  "onUpdate:selectedPets": ($event: any) => (slotProps.value.value = $event)
                }, null, 8 /* PROPS */, ["owner-id", "selected-pet-ids", "onUpdate:selectedPets"])
              ]),
              "input-Supplier": _withCtx((slotProps) => [
                _createVNode($setup["VetSelector"], {
                  "selected-vet": slotProps.value.value,
                  placeholder: "Start typing for suggestions",
                  "onUpdate:selectedVet": ($event: any) => (
                  $setup.updateSelectedVet(slotProps.value, $event)
                  )
                }, null, 8 /* PROPS */, ["selected-vet", "onUpdate:selectedVet"])
              ]),
              "column-actions": _withCtx((slotProps) => [
                _createVNode($setup["EllipsisButton"], null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_Button, {
                        icon: "pi pi-pencil",
                        text: "",
                        label: "View Expense",
                        onClick: ($event: any) => ($setup.petExpenseTableRef?.startEdit(slotProps.id))
                      }, null, 8 /* PROPS */, ["onClick"]),
                      ($setup.deleteExpenseVisible)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            icon: "pi pi-trash",
                            text: "",
                            label: "Delete",
                            onClick: ($event: any) => ($setup.petExpenseTableRef?.startDelete(slotProps.id))
                          }, null, 8 /* PROPS */, ["onClick"]))
                        : _createCommentVNode("v-if", true),
                      ($setup.approveExpenseVisible(slotProps.data.expenseStatusId))
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 1,
                            text: "",
                            label: "Approve & Pay",
                            onClick: ($event: any) => ($setup.approve(slotProps.id)),
                            loading: $setup.approvalInProgress
                          }, null, 8 /* PROPS */, ["onClick", "loading"]))
                        : _createCommentVNode("v-if", true),
                      ($setup.rejectExpenseVisible(slotProps.data.expenseStatusId))
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 2,
                            text: "",
                            label: "Reject",
                            onClick: ($event: any) => ($setup.reject(slotProps.id)),
                            loading: $setup.rejectionInProgress
                          }, null, 8 /* PROPS */, ["onClick", "loading"]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["client", "form-input-definition-overrides", "highlight-specific-row", "editable", "deletable"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}