import { ContactRelationshipTypeEnum } from '@/clients/client.gen';
import { useCRMApi } from '@/composables/CRMApi';
import { Ref } from 'vue';

// todo: move these to a separate file
export interface Contact {
  id: number;
  customerId: number;
  firstName: string;
  lastName: string;
  email: string;
  alternativeMobile?: string;
  mobile?: string;
  directDial?: string;
  jobTitle?: string;
  contactTypeId?: number;
  fullName?: string;
}

export interface ContactType {
  id: number;
  name: string;
}

export default class ContactService {
  async getContacts() {
    const data = (await useCRMApi('/crm/contacts')) as Contact[];
    return data ?? [];
  }

  async getContactById(id: number) {
    const data = (await useCRMApi(`/crm/contacts/${id}`)) as Contact;
    return data ?? null;
  }

  async getContactTypes() {
    const data = (await useCRMApi('/crm/contacttypes')) as ContactType[];
    return data ?? [];
  }

  async updateContact(contact: Contact | null) {
    const newOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(contact)
    };

    const data = await useCRMApi(`/crm/contacts`, newOptions);
    return data ?? null;
  }

  async addContact(contact: Contact | null) {
    const newOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(contact)
    };

    const data = (await useCRMApi(`/crm/contacts`, newOptions)) as Contact;
    return data ?? null;
  }

  setCorrespondingRelation(
    newRelationshipTypeId: ContactRelationshipTypeEnum,
    relationshipTypeIdModel: Ref<number | undefined>
  ) {
    switch (newRelationshipTypeId) {
      case ContactRelationshipTypeEnum.Friend:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.Friend;
        break;

      case ContactRelationshipTypeEnum.Neighbour:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.Neighbour;
        break;

      case ContactRelationshipTypeEnum.Parent:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.Child;
        break;

      case ContactRelationshipTypeEnum.Child:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.Parent;
        break;

      case ContactRelationshipTypeEnum.Sibling:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.Sibling;
        break;

      case ContactRelationshipTypeEnum.Grandparent:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.Grandchild;
        break;

      case ContactRelationshipTypeEnum.Grandchild:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.Grandparent;
        break;

      case ContactRelationshipTypeEnum.Spouse:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.Spouse;
        break;

      case ContactRelationshipTypeEnum.POA:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.POA;
        break;

      case ContactRelationshipTypeEnum.Other:
        relationshipTypeIdModel.value = ContactRelationshipTypeEnum.Other;
        break;
    }
  }
}

export const contactService = new ContactService();
