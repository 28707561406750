import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "xl:text-5xl xl:text-3xl md:text-2xl" }
const _hoisted_2 = { class: "bottom-0 left-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Card"], { class: "summary-item shadow-2 w-full" }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString($props.title) + " ", 1 /* TEXT */),
        _createElementVNode("span", {
          class: _normalizeClass([$setup.countClass, "xl:text-5xl font-bold"])
        }, _toDisplayString($props.count), 3 /* TEXT, CLASS */)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "footer", { linkClass: "xl:text-2xl lg:text-lg md:text-base font-normal orange-link" })
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}