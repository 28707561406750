import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default", { showTaskPanel: $setup.showTaskPanel }, () => [
      _createVNode($setup["Button"], {
        label: "New",
        icon: "pi pi-plus",
        style: {"height":"35px"},
        disabled: $setup.props.contactId != undefined && $setup.props.contactId < 1,
        onClick: $setup.showTaskPanel
      }, null, 8 /* PROPS */, ["disabled"])
    ]),
    _createVNode($setup["TaskForm"], {
      ref: "taskFormModal",
      visible: $setup.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (($setup.visible) = $event)),
      mode: "create",
      "case-id": $setup.props.caseId,
      "contact-id": $setup.props.contactId,
      onCreated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('created', $event)))
    }, null, 8 /* PROPS */, ["visible", "case-id", "contact-id"])
  ]))
}