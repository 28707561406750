import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex align-content-end justify-content-end gap-1" }
const _hoisted_2 = {
  class: "flex gap-2",
  style: {"width":"200px","height":"50px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      value: $setup.props.documents,
      "selection-mode": "single",
      scrollable: "",
      "scroll-height": "flex",
      size: "small",
      onRowClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.viewFile($event)))
    }, {
      default: _withCtx(() => [
        _createVNode($setup["Column"], {
          field: "dateReceived",
          header: "Date Received"
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString($setup.formatDate(slotProps.data.DateReceived)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["Column"], {
          field: "dateCreated",
          header: "Date Uploaded"
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString($setup.formatDate(slotProps.data.dateCreated)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["Column"], {
          field: "fileName",
          header: "File Name"
        }),
        _createVNode($setup["Column"], {
          field: "categories",
          header: "Categories"
        }),
        _createVNode($setup["Column"], {
          field: "description",
          header: "Description"
        }),
        _createVNode($setup["Column"], {
          field: "createdByName",
          header: "Username"
        }),
        _createVNode($setup["Column"], null, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode($setup["EllipsisButton"], null, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Button, {
                      class: "flex",
                      text: "",
                      size: "small",
                      label: "Rename",
                      onClick: ($event: any) => ($setup.openRenamePanel($event, slotProps.data))
                    }, null, 8 /* PROPS */, ["onClick"])
                  ]),
                  ($setup.currentUserStore.isSystemAdminUser)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        class: "flex",
                        text: "",
                        size: "small",
                        severity: "danger",
                        label: "Delete",
                        onClick: ($event: any) => ($setup.deleteFile(slotProps.data))
                      }, null, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["value"]),
    _createVNode($setup["OverlayPanel"], { ref: "renamePanel" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_InputText, {
              modelValue: $setup.newFileName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.newFileName) = $event))
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_Button, {
              severity: "secondary",
              label: "Rename",
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.rename()))
            })
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */),
    _createVNode($setup["TallyFormRendering"], {
      title: $setup.tallyFormName,
      visible: $setup.tallyFormRenderDialogVisible,
      questions: $setup.tallyFormData,
      onCloseDialog: $setup.closeTallyDialog
    }, null, 8 /* PROPS */, ["title", "visible", "questions"])
  ], 64 /* STABLE_FRAGMENT */))
}