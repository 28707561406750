
  import { defineComponent } from 'vue';
  import DocumentList from '@/components/Common/DocumentList.vue';
  import { DocumentClient, FileParameter } from '@/clients/client.gen';
  import { caseStore } from '@/stores/case.store';

  export default defineComponent({
    components: {
      DocumentList
    },
    data(): any {
      return {};
    },
    computed: {
      caseStore() {
        return caseStore;
      }
    },
    async created() {},
    methods: {
      async update() {
        await this.caseStore.getDocumentData(caseStore.case.caseId);
      },
      async uploadFile(
        categories: string | undefined,
        description: string | undefined,
        dateReceived: Date | undefined,
        fileData: FileParameter
      ) {
        const client = new DocumentClient();
        const result = await client.uploadDocument(
          fileData,
          categories,
          description,
          dateReceived,
          null,
          caseStore.case.caseId,
          null,
          null,
          null
        );
        if (result) {
          await this.update();
          this.$refs.documentList.clearUpload();
          await this.caseStore.setNotes(caseStore.case.caseId);
        }
      }
    }
  });
