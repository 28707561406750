import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Chip"], null, {
    default: _withCtx(() => [
      _createElementVNode("a", {
        class: "note-link",
        href: $setup.getRouterLink($setup.props.id, $setup.props.routeName),
        target: "_blank"
      }, _toDisplayString($setup.props.label), 9 /* TEXT, PROPS */, _hoisted_1)
    ]),
    _: 1 /* STABLE */
  }))
}