import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createBlock(_component_ScrollPanel, { style: {"height":"100%"} }, {
    default: _withCtx(() => [
      _createVNode($setup["GeneratedTable"], {
        ref: "table",
        selection: $setup.selectedRows,
        "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedRows) = $event)),
        client: $setup.client,
        "extend-get-all": $setup.extendGetAll,
        "before-save": $setup.processModel,
        editable: true,
        deletable: false,
        selectable: "",
        "form-element-id": "co-beneficiary-form"
      }, {
        "toolbar-start": _withCtx(() => [
          _createVNode(_component_Button, {
            disabled: $setup.selectedRows.length == 0,
            icon: "pi pi-envelope",
            class: "mr-2",
            onClick: $setup.emailCoBeneficiaries
          }, null, 8 /* PROPS */, ["disabled"])
        ]),
        "column-leadBeneficiary": _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.value.data.leadBeneficiary ? 'Yes' : 'No'), 1 /* TEXT */)
        ]),
        additionalRowAction: _withCtx((slotProps) => [
          _createVNode(_component_Button, {
            class: "flex",
            text: "",
            size: "small",
            icon: "pi pi-envelope",
            onClick: () => $setup.emailCoBeneficiary(slotProps.item.email)
          }, null, 8 /* PROPS */, ["onClick"])
        ]),
        "input-Telephone": _withCtx((slotProps) => [
          _createVNode($setup["FormField"], {
            id: slotProps.value.slotName,
            modelValue: slotProps.value.value,
            "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
            type: "phone",
            "no-label": ""
          }, null, 8 /* PROPS */, ["id", "modelValue", "onUpdate:modelValue"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["selection", "client"])
    ]),
    _: 1 /* STABLE */
  }))
}