import { reactive } from 'vue';
import { Group, SystemUserModel, UsersClient } from '@/clients/client.gen';

export type AssignUserOption = {
  userId: string | undefined;
  name: string;
  isGroup: boolean;
};

export const userStore = reactive({
  users: [] as Array<SystemUserModel>,
  usersLastRetrieved: undefined as Date | undefined,
  getDataLock: false,
  userGroups: [] as Array<Group>,
  userGroupsLastRetrieved: undefined as Date | undefined,
  getUserGroupLock: false,

  async getData() {
    if (this.getDataLock) return; //we just return as everything should be referring to the reactive users array anyway.
    try {
      this.getDataLock = true;
      const currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() - 10);

      if (!this.usersLastRetrieved || this.usersLastRetrieved < currentDate) {
        const client = new UsersClient();
        this.users = await client.get();
        this.usersLastRetrieved = new Date();
      }
      this.getDataLock = false;
    } finally {
      this.getDataLock = false;
    }
  },

  async getUserGroups() {
    if (this.getUserGroupLock) return; //we just return as everything should be referring to the reactive users array anyway.
    try {
      this.getUserGroupLock = true;
      const currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() - 10);
      if (
        !this.userGroupsLastRetrieved ||
        this.userGroupsLastRetrieved < currentDate
      ) {
        const client = new UsersClient();
        this.userGroups = await client.getUsersAndGroups();
        this.userGroupsLastRetrieved = new Date();
      }
    } finally {
      this.getUserGroupLock = false;
    }
  },

  async getUsersAndGroups() {
    await this.getData();
    await this.getUserGroups();
  }
});
