import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "update-note-form",
  class: "flex flex-column gap-2 w-full"
}
const _hoisted_2 = { class: "flex gap-2 justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.commsAndNotesStore.noteToEditVisible,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (($setup.commsAndNotesStore.noteToEditVisible) = $event)),
    modal: "",
    header: "Edit Note",
    position: "bottomright",
    style: { width: '30vw', height: 'fit-content' }
  }, {
    default: _withCtx(() => [
      ($setup.commsAndNotesStore.noteToEdit && $setup.commsAndNotesStore.noteToEdit.note)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["FormTextArea"], {
              modelValue: $setup.commsAndNotesStore.noteToEdit.note.noteText,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.commsAndNotesStore.noteToEdit.note.noteText) = $event)),
              label: "Body",
              rows: 15,
              cols: 30
            }, null, 8 /* PROPS */, ["modelValue"]),
            ($setup.props.volunteers && $setup.isCaseProfile)
              ? (_openBlock(), _createBlock($setup["CommsTagMultiSelect"], {
                  key: 0,
                  modelValue: $setup.commsAndNotesStore.noteToEdit.note.noteVolunteers,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.commsAndNotesStore.noteToEdit.note.noteVolunteers) = $event)),
                  options: $setup.orderedTagVolunteerOptions,
                  "option-label": "name",
                  placeholder: "Tag Volunteers",
                  "show-filter": "",
                  "type-text": "volunteers"
                }, null, 8 /* PROPS */, ["modelValue", "options"]))
              : _createCommentVNode("v-if", true),
            ($props.cases.length)
              ? (_openBlock(), _createBlock($setup["CommsTagMultiSelect"], {
                  key: 1,
                  modelValue: $setup.commsAndNotesStore.noteToEdit.note.noteCases,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.commsAndNotesStore.noteToEdit.note.noteCases) = $event)),
                  options: $props.cases,
                  "option-label": (option) => `${option.description}`,
                  placeholder: "Tag Cases",
                  "show-filter": "",
                  "type-text": "cases"
                }, null, 8 /* PROPS */, ["modelValue", "options", "option-label"]))
              : _createCommentVNode("v-if", true),
            ($props.pets.length && !$setup.isPetProfile)
              ? (_openBlock(), _createBlock($setup["CommsTagMultiSelect"], {
                  key: 2,
                  modelValue: $setup.commsAndNotesStore.noteToEdit.note.notePets,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.commsAndNotesStore.noteToEdit.note.notePets) = $event)),
                  options: $setup.groupedPets,
                  "option-label": "petName",
                  "option-group-label": "groupName",
                  "option-group-children": "items",
                  placeholder: "Tag Pets",
                  "show-filter": "",
                  "type-text": "pets"
                }, null, 8 /* PROPS */, ["modelValue", "options"]))
              : _createCommentVNode("v-if", true),
            ($props.ltfPets.length && $setup.isPetProfile)
              ? (_openBlock(), _createBlock($setup["CommsTagMultiSelect"], {
                  key: 3,
                  modelValue: $setup.taggedPetsForLTFVolunteer,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.taggedPetsForLTFVolunteer) = $event)),
                  options: $setup.groupedOtherPetsForLTFVolunteerExcludingPetProfilePet,
                  "option-label": "petName",
                  "option-group-label": "groupName",
                  "option-group-children": "items",
                  placeholder: "Tag LTF Pets",
                  "show-filter": "",
                  "type-text": "pets"
                }, null, 8 /* PROPS */, ["modelValue", "options"]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createVNode(_component_Button, {
                  label: "Update",
                  onClick: $setup.updateNote
                })
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}