export { default as FormField } from './FormField.vue';
export { default as FormDatePicker } from './FormDatePicker.vue';
export { default as FormCheckBox } from './FormCheckBox.vue';
export { default as UploadPhoto } from './UploadPhoto.vue';
export { default as FormFieldYesNoRadio } from './FormFieldYesNoRadio.vue';
export { default as FormRating } from './FormRating.vue';
export { default as FormFieldSelect } from './FormFieldSelect.vue';
export { default as EmailModal } from './EmailModal.vue';
export { default as UploadFiles } from './UploadFiles.vue';
export { default as ContactAutocomplete } from './ContactAutocomplete.vue';
export { default as CaseAutocomplete } from './CaseAutocomplete.vue';
export { default as TaskList } from './Tasks/TaskList.vue';
export { default as EllipsisButton } from './EllipsisButton.vue';

export interface IFileUpload {
  name: string;
  data: string;
  objectURL: string;
  type: string;
  size: number;
}
