
  import { defineComponent } from 'vue';
  import FileUpload, { FileUploadSelectEvent } from 'primevue/fileupload';
  import { formatFileSize } from '../../composables/UtilityFunctions';
  import { IFileUpload } from '.';

  export default defineComponent({
    components: { FileUpload },
    emits: ['selected'],
    data() {
      return {
        files: [] as IFileUpload[]
      };
    },
    methods: {
      formatFileSize,
      removeSelected(file: IFileUpload) {
        this.files = this.files.filter(
          (f: { data: string }) => f.data !== file.data
        );
        this.onFilesUpdated();
        // I am having to manually remove those as they still persist
        (this.$refs.fileUplaod as any).$data.files = (
          this.$refs.fileUplaod as any
        ).$data.files.filter(
          (f: File) => f.size !== file.size && f.name !== file.name
        );
      },
      openFileDialog(event: any) {
        (this.$refs.fileUplaod as any).choose(event);
      },

      async newImageSelected(event: FileUploadSelectEvent) {
        const files = event.files;
        const promises = files.map(async (file: File) => {
          if (
            !this.files.find(
              (x) => x.size === file.size && x.name === file.name
            )
          ) {
            const result: any = await this.readFile(file);
            if (result) {
              this.files.push({
                name: file.name,
                data: result,
                size: file.size,
                type: file.type,
                objectURL: URL.createObjectURL(file)
              });
            }
          }
        });

        await Promise.all(promises);
        this.onFilesUpdated();
      },
      onFilesUpdated() {
        this.$emit('selected', this.files);
      },
      readFile(file: File) {
        const reader = new FileReader();
        return new Promise<string | null>((resolve, reject) => {
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      }
    }
  });
