import {
  AdvancedSearchCommand,
  SearchFilterEnum,
  SearchObjectMember,
  SearchParameter,
  SearchTypeEnum,
  SearchableCase,
  SearchableContact,
  SearchablePet,
  SearchableSupplier
} from '@/clients/client.gen';
import { ISearchOption, searchStore } from '@/stores/search.store';
import { formatTelephone } from '@/composables/UtilityFunctions';
import { computed, ref } from 'vue';

export interface TableColumn {
  field: string;
  header: string;
  sortable: boolean;
  formatData?: (data: any) => string;
}

export class AdvancedSearchService {
  constructor(defaultParams = false) {
    this.selectedOption = { ...searchStore.selectedOption };

    this.parameters.value = defaultParams
      ? AdvancedSearchService.getDefaultParameters(
          this.selectedOption.searchType
        )
      : (this.parameters.value = [...searchStore.parameters]);

    this.currentPage = 0;
    this.totalRecords = 0;
    this.searchType = searchStore.selectedOption.searchType;
    //is this even needed?
    if (this.parameters.value.length == 0) {
      this.parameters.value = AdvancedSearchService.getDefaultParameters(
        this.searchType
      );
    }
  }

  selectedFields = computed(() => {
    return this.selectedOption.fields as SearchObjectMember[];
  });

  selectedOption = searchStore.selectedOption;
  parameters = ref<Array<SearchParameter>>([]);
  searchType: SearchTypeEnum;
  currentPage: number;
  totalRecords: number;
  sortField: string | undefined;
  sortOrder: number | undefined;
  results:
    | SearchableCase[]
    | SearchableContact[]
    | SearchablePet[]
    | SearchableSupplier[]
    | undefined;

  clearSearchValues = () => {
    //loop parameters and ensure the values are empty,
    //there is a weird disconnect when switching between
    //and i cannot find the root cause, this will do for now.
    this.parameters.value.forEach((p) => {
      p.searchValue = '';
    });
  };
  performSearch = async () => {
    console.group('AdvancedSearchService.PerformSearch');
    try {
      if (this.parameters.value.length == 0) return;
      const request = new AdvancedSearchCommand();
      request.searchParameters = this.parameters.value;
      request.currentPage = this.currentPage;
      request.sortField = this.sortField;
      request.sortType = this.sortOrder;
      request.pageSize = 10;
      request.searchType = this.selectedOption.searchType;
      const result = await searchStore.performSearch(request);
      this.totalRecords = result.result?.total ?? 0;
      const castedResults = AdvancedSearchService.castSearchRecords(
        this.selectedOption,
        result
      );
      const processedResults = this.processResults(castedResults);
      return this.processResults(processedResults);
    } catch (error) {
      console.error('Error occurred while performing advanced search', error);
    }
    console.groupEnd();
  };

  processResults(
    newResults:
      | SearchableContact[]
      | SearchableCase[]
      | SearchablePet[]
      | SearchableSupplier[]
      | undefined
  ):
    | SearchableContact[]
    | SearchableCase[]
    | SearchablePet[]
    | SearchableSupplier[]
    | undefined {
    if (!newResults) {
      return newResults;
    }

    try {
      const columns = this.getColumns();

      const processedResults = newResults.map((result) => {
        const updatedResult = { ...result };
        Object.keys(result).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(result, key)) {
            const column = columns.find((col) => col.field === key);
            if (column && column.formatData) {
              //@ts-expect-error 'key' cannot easily or reliably be typed to an unknown/dynamic type in TS, it's not how TS works.
              updatedResult[key] = column.formatData(result[key]);
            }
          }
        });

        return updatedResult;
      });
      //@ts-expect-error As we haven't cast the models back to an actual type, TS complains about the types not matching the return types,
      //but this is reasonably safe here as we enforce the input types and check the undefined
      return processedResults;
    } catch (error) {
      console.error('Error processing returned results:', error);
    }
  }

  getLoadingText() {
    switch (this.selectedOption.searchType) {
      case SearchTypeEnum.Contact:
      default:
        return 'Loading contact data. Please wait.';
      case SearchTypeEnum.Case:
        return 'Loading case data. Please wait.';
      case SearchTypeEnum.Pet:
        return 'Loading pet data. Please wait.';
      case SearchTypeEnum.Supplier:
        return 'Loading supplier data. Please wait.';
    }
  }

  getColumns() {
    if (!this.selectedOption) this.selectedOption = searchStore.options[0];

    switch (this.selectedOption.searchType) {
      case SearchTypeEnum.Contact:
      default:
        return AdvancedSearchService.contactColumns;
      case SearchTypeEnum.Case:
        return AdvancedSearchService.caseColumns;
      case SearchTypeEnum.Pet:
        return AdvancedSearchService.petColumns;
      case SearchTypeEnum.Supplier:
        return AdvancedSearchService.supplierColumns;
    }
  }

  getEmptyText() {
    if (!this.selectedOption) this.selectedOption = searchStore.options[0];

    switch (this.selectedOption.searchType) {
      case SearchTypeEnum.Contact:
      default:
        return 'No contacts found.';
      case SearchTypeEnum.Case:
        return 'No cases found.';
      case SearchTypeEnum.Pet:
        return 'No pets found.';
      case SearchTypeEnum.Supplier:
        return 'No suppliers found.';
    }
  }

  static castSearchRecords = (option: ISearchOption, result: any) => {
    switch (option.searchType) {
      case SearchTypeEnum.Contact:
        return result.result?.data as SearchableContact[];
      case SearchTypeEnum.Case:
        return result.result?.data as SearchableCase[];
      case SearchTypeEnum.Supplier:
        return result.result?.data as SearchableSupplier[];
      case SearchTypeEnum.Pet:
        return result.result?.data as SearchablePet[];
      default:
        throw new Error(
          'Could not translate search result into relevant object type'
        );
    }
  };

  static caseColumns = [
    { field: 'caseId', header: 'ID', sortable: true },
    { field: 'caseTypeName', header: 'Case Type', sortable: true },
    { field: 'caseStatusName', header: 'Status', sortable: true },
    { field: 'caseStageName', header: 'Stage', sortable: true },
    { field: 'contactContactId', header: 'Contact Id', sortable: true },
    {
      field: 'contactAddressPostCode',
      header: 'Postcode',
      sortable: true
    },
    { field: 'contactFullName', header: 'Owner Name', sortable: true },
    { field: 'petNames', header: 'Pet Names', sortable: false }
  ] as TableColumn[];

  static supplierColumns = [
    { field: 'supplierId', header: 'ID', sortable: true },
    { field: 'name', header: 'Name', sortable: true },
    {
      field: 'telephone',
      header: 'Telephone',
      sortable: true,
      formatData: formatTelephone
    },
    {
      field: 'primaryContactName',
      header: 'Primary Contact Name',
      sortable: true
    },
    {
      field: 'primaryContactRole',
      header: 'Primary Contact Role',
      sortable: true
    },
    {
      field: 'primaryContactTelephone',
      header: 'Primary Contact Telephone',
      sortable: true,
      formatData: formatTelephone
    },
    { field: 'supplierTypeNames', header: 'Supplier Types', sortable: false }
  ] as TableColumn[];

  static contactColumns = [
    { field: 'addressPostCode', header: 'Post Code', sortable: true },
    { field: 'contactId', header: 'ID', sortable: true },
    { field: 'lastName', header: 'Surname', sortable: true },
    { field: 'firstName', header: 'First Name', sortable: true },
    {
      field: 'mobile',
      header: 'Telephone',
      sortable: true,
      formatData: formatTelephone
    },
    { field: 'email', header: 'Email Address', sortable: true },
    {
      field: 'contactTypesString',
      header: 'Contact Type',
      sortable: false
    }
  ] as TableColumn[];

  static petColumns = [
    { field: 'petId', header: 'Pet ID', sortable: true },
    { field: 'contactId', header: 'Contact ID', sortable: true },
    { field: 'contactFullName', header: 'Owner Name', sortable: true },
    { field: 'petName', header: 'Name', sortable: true },
    { field: 'petTypeName', header: 'Type', sortable: true },
    { field: 'petBreedName', header: 'Breed', sortable: true },
    { field: 'colour', header: 'Colour', sortable: true },
    { field: 'petGenderName', header: 'Sex', sortable: true },
    {
      field: 'contactAddressPostCode',
      header: 'Post Code',
      sortable: true
    },
    { field: 'contactAddressCounty', header: 'County', sortable: true },
    { field: 'vetName', header: 'Vet', sortable: false }
  ] as TableColumn[];

  static getDefaultParameters(searchType: SearchTypeEnum): SearchParameter[] {
    switch (searchType) {
      case SearchTypeEnum.Contact:
        return [...AdvancedSearchService.contactDefaultParameters()];
      case SearchTypeEnum.Pet:
        return [...AdvancedSearchService.petDefaultParameters()];
      case SearchTypeEnum.Case:
        return [...AdvancedSearchService.caseDefaultParameters()];
      case SearchTypeEnum.Supplier:
        return [...AdvancedSearchService.supplierDefaultParameters()];
      default:
        return [];
    }
  }

  static contactDefaultParameters(): SearchParameter[] {
    return [
      new SearchParameter({
        propertyName: 'FirstName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'LastName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'AddressPostCode',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'Email',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'Mobile',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      })
    ];
  }
  static caseDefaultParameters(): SearchParameter[] {
    return [
      new SearchParameter({
        propertyName: 'ContactFullName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'ContactAddressPostCode',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'CaseTypeName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'CaseStatusName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'CaseStageName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      })
    ];
  }
  static supplierDefaultParameters(): SearchParameter[] {
    return [
      new SearchParameter({
        propertyName: 'RefNumber',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'SupplierTypes',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'Name',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'AddressPostCode',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'EmailAddress',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'Telephone',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'AddressCounty',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      })
    ];
  }
  static petDefaultParameters(): SearchParameter[] {
    return [
      new SearchParameter({
        propertyName: 'PetName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'ContactFullName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'ContactAddressPostCode',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'PetTypeName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'PetBreedName',
        searchValue: '',
        filterId: SearchFilterEnum.TextContains
      }),
      new SearchParameter({
        propertyName: 'IsLTF',
        searchValue: '',
        filterId: 0
      })
    ];
  }
}
