import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Rating = _resolveComponent("Rating")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.computedId,
      class: _normalizeClass(_ctx.labelClass)
    }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_2),
    _createElementVNode("div", {
      id: _ctx.computedId,
      class: _normalizeClass(["col-9", { 'col-12': _ctx.labelAbove }])
    }, [
      _createVNode(_component_Rating, {
        modelValue: _ctx.internalValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event))
      }, null, 8 /* PROPS */, ["modelValue"])
    ], 10 /* CLASS, PROPS */, _hoisted_3)
  ]))
}