import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-3 flex gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex gap-2 overflow-hidden align-items-center", $setup.cardClass])
  }, [
    _createElementVNode("div", {
      class: "col cursor-pointer",
      style: {"max-height":"100px"},
      onClick: $setup.goToLink
    }, _toDisplayString($props.notification.message), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString($props.notification?.createdDuration), 1 /* TEXT */),
      _createVNode(_component_Button, {
        icon: $setup.readIcon,
        text: "",
        disabled: !$setup.notificationStore.isEditMode,
        "aria-label": "Toggle as read",
        onClick: $setup.onToggleRead
      }, null, 8 /* PROPS */, ["icon", "disabled"]),
      _createVNode(_component_Button, {
        icon: $setup.flagIcon,
        text: "",
        disabled: !$setup.notificationStore.isEditMode,
        "aria-label": "Toggle as flagged",
        onClick: $setup.onToggleFlagged
      }, null, 8 /* PROPS */, ["icon", "disabled"])
    ])
  ], 2 /* CLASS */))
}