import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TabView"], { lazy: "" }, {
      default: _withCtx(() => [
        _createVNode($setup["TabPanel"], { header: "Secondary Contacts" }, {
          default: _withCtx(() => [
            _createVNode($setup["SuppliersSecondaryContacts"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["TabPanel"], { header: "Documents" }, {
          default: _withCtx(() => [
            _createVNode($setup["SuppliersDocuments"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["TabPanel"], { header: "Expenses" }, {
          default: _withCtx(() => [
            _createVNode($setup["SupplierExpensesTab"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["TabPanel"], { header: "Income" }, {
          default: _withCtx(() => [
            _createVNode($setup["SupplierIncomeTab"])
          ]),
          _: 1 /* STABLE */
        }),
        ($setup.showPetsTab)
          ? (_openBlock(), _createBlock($setup["TabPanel"], {
              key: 0,
              header: "Pets"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["SupplierPetsTab"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["TabPanel"], { header: "Audit" }, {
          default: _withCtx(() => [
            _createVNode($setup["AuditHistory"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}