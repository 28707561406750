
  import { defineComponent, PropType } from 'vue';
  import Dialog from 'primevue/dialog';
  import { caseStore } from '@/stores/case.store';
  import { expenseStore } from '@/stores/expense.store';
  import NoInvoiceDetail from '@/components/Common/Expenses/NoInvoiceDetail.vue';
  import ExpenseItemsList from '@/components/Common/Expenses/ExpenseItemsList.vue';
  import ExpenseItemsSummary from '@/components/Common/Expenses/ExpenseItemsSummary.vue';
  import {
    AddExpenseCommand,
    ExpenseCodeDto,
    ExpenseDto,
    ExpenseItemDto,
    ExpenseTypeEnum,
    FlattenedCasePetDto,
    FlattenedCaseVolunteer
  } from '../../../clients/client.gen';
  import ExpenseTypePicker from '@/components/Common/Expenses/ExpenseTypePicker.vue';
  import InvoiceItemsList from '@/components/Common/Expenses/InvoiceItemsList.vue';
  import InvoiceDetail from '@/components/Common/Expenses/InvoiceDetail.vue';
  import { currentUserStore } from '@/stores/currentUser.store';

  export default defineComponent({
    components: {
      Dialog,
      NoInvoiceDetail,
      ExpenseItemsList,
      ExpenseItemsSummary
    },
    props: {
      dialogVisible: Boolean,
      expenseCommand: {
        type: AddExpenseCommand,
        required: false,
        default: { expense: new ExpenseDto() } as AddExpenseCommand
      },
      supplierId: {
        type: Number,
        required: false
      },
      editable: {
        type: Boolean,
        required: false,
        default: false
      },
      volunteers: {
        type: Array as PropType<Array<FlattenedCaseVolunteer>>,
        required: true
      },
      pets: {
        type: Array as PropType<Array<FlattenedCasePetDto>>,
        required: true
      }
    },
    emits: ['complete'],
    data(): any {
      return {
        currentUserStore,
        caseStore,
        expenseStore,
        expense: {} as ExpenseDto,
        selectedStep: 0 as number,
        baseSteps: [
          //This is defined so that we can utilize it to reset the component as needed.
          {
            component: ExpenseTypePicker,
            updateCallback: this.updateVal,
            expenseCode: null,
            visible: true
          }
        ],
        steps: [],
        noInvoiceMappedSteps: [
          //We can use the updateCallback to customise how we handle the data from specific returns if it's required.
          //We can use the visible property to pass either a true/false or a callback method to enable dynamic visibility for the different items.
          //This way, each one is responsible for it's own inclusion into the Steps array.
          {
            component: NoInvoiceDetail,
            updateCallback: this.updateVal,
            expenseCode: null,
            visible: true
          },
          {
            component: ExpenseItemsList,
            updateCallback: this.updateExpenseCode,
            expenseCode: this.getExpenseDtoWithType(ExpenseTypeEnum.Mileage),
            visible: () => this.checkForCode(ExpenseTypeEnum.Mileage)
          },
          {
            component: ExpenseItemsList,
            updateCallback: this.updateExpenseCode,
            expenseCode: this.getExpenseDtoWithType(ExpenseTypeEnum.Food),
            visible: () => this.checkForCode(ExpenseTypeEnum.Food)
          },
          {
            component: ExpenseItemsList,
            updateCallback: this.updateExpenseCode,
            expenseCode: this.getExpenseDtoWithType(ExpenseTypeEnum.Vet),
            visible: () => this.checkForCode(ExpenseTypeEnum.Vet)
          },
          {
            component: ExpenseItemsList,
            updateCallback: this.updateExpenseCode,
            expenseCode: this.getExpenseDtoWithType(
              ExpenseTypeEnum.Miscellaneous
            ),
            visible: () => this.checkForCode(ExpenseTypeEnum.Miscellaneous)
          },
          {
            component: ExpenseItemsSummary,
            updateCallback: null,
            expenseCode: null,
            visible: true
          }
        ],
        invoiceMappedSteps: [
          {
            component: InvoiceDetail,
            updateCallback: this.updateVal,
            expenseCode: null,
            visible: true
          },
          {
            component: InvoiceItemsList,
            updateCallback: this.updateVal,
            expenseCode: null,
            visible: true
          }
        ]
      };
    },
    created() {
      const expense = new ExpenseDto();
      if (this.expenseCommand.expense?.expenseId > 0) {
        this.expense = Object.assign(expense, this.expenseCommand.expense);
      }
      this.resetComponent();
    },
    methods: {
      resetComponent() {
        this.selectedStep = 0;
        this.steps = [...this.baseSteps];
        this.setSteps();
      },
      getExpenseDtoWithType(type: ExpenseTypeEnum) {
        //console.log("expense code", this.expenseCommand?.expense?.expenseCodes);
        let code = this.expenseCommand?.expense?.expenseCodes.find(
          (e: ExpenseCodeDto) => e.expenseTypeId === type
        );
        //console.log("Found code", code);
        if (!code) {
          code = new ExpenseCodeDto();
          code.expenseTypeId = type;
        }
        if (!code.expenseItems) code.expenseItems = [];
        return code;
      },
      checkForCode(type: ExpenseTypeEnum) {
        return this.expense === undefined
          ? false
          : this.expense.expenseCodes?.find(
              (e: ExpenseCodeDto) => e.expenseTypeId === type
            ) !== undefined;
      },
      updateExpenseCode(expenseCode: ExpenseCodeDto) {
        const ec = this.expense.expenseCodes.find(
          (c: ExpenseCodeDto) => c.expenseTypeId == expenseCode.expenseTypeId
        ) as ExpenseCodeDto | undefined;
        if (ec !== undefined) {
          ec.expenseItems = expenseCode.expenseItems;
          ec.codeTotal = ec.expenseItems.reduce(
            (total: number, e: ExpenseItemDto) => {
              const cost = e.costTotal ?? 0;
              return total + cost;
            },
            0
          );
        }
      },
      setSteps() {
        if (this.expense.expenseMode) {
          if (this.expense.expenseMode === 1) {
            //Non-invoiced
            this.steps = this.noInvoiceMappedSteps.filter((s: any) => {
              return (
                (s.visible instanceof Function && s.visible()) ||
                s.visible === true
              );
            });
          }
          if (this.expense.expenseMode === 2) {
            //Invoiced.
            this.steps = this.invoiceMappedSteps;
          }
        }
      },
      nextStep() {
        this.selectedStep++;
      },
      previousStep() {
        this.selectedStep--;
      },
      updateVal(data: ExpenseDto) {
        const entity = new ExpenseDto();
        const newExpense = Object.assign(entity, data);
        console.log('Update expense:', newExpense);
        this.expense = newExpense;
        this.setSteps();
      },
      save(sendFinance: boolean = false) {
        this.$emit('complete', {
          expense: this.expense,
          sendFinance: sendFinance
        });
        this.resetComponent();
      }
    }
  });
