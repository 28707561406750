import { reactive } from 'vue';
import {
  SupplierClient,
  UpdateSupplierModel,
  SupplierDto,
  SupplierTypeDto,
  AddSupplierModel,
  SupplierContactDto,
  DocumentClient,
  GetDocumentsResponse,
  NoteDto,
  SupplierDropDownDto,
  AuditEntryDto,
  AuditHistoryClient
} from '@/clients/client.gen';
export { SupplierTypeDto, SupplierContactDto } from '@/clients/client.gen';

export const supplierStore = reactive({
  loading: true,
  supplier: new SupplierDto(),
  supplierTypes: [] as SupplierTypeDto[],
  documents: {} as GetDocumentsResponse,
  mode: 'add',
  reload: false,
  auditHistory: [] as AuditEntryDto[],
  notes: [] as NoteDto[],
  client: new SupplierClient(),
  async getData(id: number): Promise<void> {
    if (!id) {
      this.mode = 'add';
      this.supplier = new SupplierDto();
      this.loading = false;
      return;
    }
    this.mode = 'update';
    this.loading = true;
    this.supplier = await this.client.getById(id);
    this.loading = false;
  },
  getDocumentData(id: number): void {
    const client = new DocumentClient();
    try {
      client.getSupplierDocuments(id).then((resp) => {
        if (resp.result) {
          this.documents = resp.result;
        }
      });
    } catch {
      this.documents = new GetDocumentsResponse();
    }
  },
  async getForSelector(): Promise<SupplierDropDownDto[]> {
    const client = new SupplierClient();
    return await client.getForSelector();
  },
  async addSupplierContact(contact: SupplierContactDto): Promise<void> {
    const result = await this.client.addSupplierContact(
      contact,
      this.supplier.supplierId
    );
    if (result.result) {
      this.supplier = result.result;
    }
  },
  async deleteSupplierContacts(contacts: SupplierContactDto[]): Promise<void> {
    if (!contacts.length) return;

    const result = await this.client.deleteSupplierContacts(
      contacts.map((x) => x.supplierContactId),
      this.supplier.supplierId
    );
    if (result.result) {
      this.supplier = result.result;
    }
  },
  async createSupplier(): Promise<void> {
    const command = { ...this.supplier } as unknown as AddSupplierModel;

    const result = await this.client.createSupplier(command);
    if (result.result) {
      this.supplier = result.result;
    }
  },
  async updateSupplier(): Promise<void> {
    const command = { ...this.supplier } as unknown as UpdateSupplierModel;

    const result = await this.client.updateSupplier(command);
    if (result.result) {
      this.supplier = result.result;
    }
  },
  async getSupplierTypes(): Promise<void> {
    if (this.supplierTypes.length === 0) {
      this.supplierTypes = await this.client.getSupplierTypes();
    }
  },
  async getAuditHistory(id: number) {
    const auditClient = new AuditHistoryClient();
    const result = await auditClient.getForSupplier(id);
    if (result.success && result.result) {
      this.auditHistory = result.result;
    } else {
      this.auditHistory = [];
    }
  },
  setSearchValue(value?: string) {
    if (value) {
      localStorage.setItem('supplier-search-value', value);
    } else {
      localStorage.removeItem('supplier-search-value');
    }
  },
  getSearchValue() {
    return localStorage.getItem('supplier-search-value') ?? undefined;
  }
});
