import {
  NoteClient,
  NoteDto,
  FileParameter,
  NoteTypeEnum,
  PaginatedResponseOfIListOfNoteDto,
  FlattenedContactCaseDto,
  SystemUserModel,
  EditNoteViewModel
} from '@/clients/client.gen';
import { reactive } from 'vue';
import { caseStore } from '@/stores/case.store';
import { MentionItem } from 'vue-mention/dist/Mentionable.vue';

export type FilterResult = {
  value: number;
  name: string;
};

export interface IAddNoteDto {
  noteTitle?: string | undefined;
  noteText?: string | undefined;
  dateCreated?: Date | undefined;
  createdById?: string | undefined;
  createdByName?: string | undefined;
  typeId: NoteTypeEnum;

  noteContactIds?: number[] | undefined;
  noteVolunteerContactIds?: number[] | undefined;
  noteCaseIds?: number[] | undefined;
  notePetIds?: number[] | undefined;
  noteSupplierIds?: number[] | undefined;
}

export class AddNoteDto implements IAddNoteDto {
  noteTitle?: string | undefined;
  noteText?: string | undefined;
  dateCreated?: Date | undefined;
  createdById?: string | undefined;
  createdByName?: string | undefined;
  typeId!: NoteTypeEnum;

  noteContactIds?: number[] | undefined;
  noteVolunteerContactIds?: number[] | undefined;
  noteCaseIds?: number[] | undefined;
  notePetIds?: number[] | undefined;
  noteSupplierIds?: number[] | undefined;
  noteExpenseIds?: number[] | undefined;

  constructor(data?: IAddNoteDto) {
    if (data) {
      for (const property in data) {
        if (Object.prototype.hasOwnProperty.call(data, property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.noteTitle = _data['noteTitle'];
      this.noteText = _data['noteText'];
      this.dateCreated = _data['dateCreated']
        ? new Date(_data['dateCreated'].toString())
        : <any>undefined;
      this.createdById = _data['createdById'];
      this.createdByName = _data['createdByName'];
      this.typeId = _data['typeId'];
      if (Array.isArray(_data['noteContactIds'])) {
        this.noteContactIds = [] as any;
        for (const item of _data['noteContactIds'])
          this.noteContactIds!.push(item);
      }
      if (Array.isArray(_data['noteVolunteerContactIds'])) {
        this.noteVolunteerContactIds = [] as any;
        for (const item of _data['noteVolunteerContactIds'])
          this.noteVolunteerContactIds!.push(item);
      }
      if (Array.isArray(_data['noteCaseIds'])) {
        this.noteCaseIds = [] as any;
        for (const item of _data['noteCaseIds']) this.noteCaseIds!.push(item);
      }
      if (Array.isArray(_data['notePetIds'])) {
        this.notePetIds = [] as any;
        for (const item of _data['notePetIds']) this.notePetIds!.push(item);
      }
      if (Array.isArray(_data['noteSupplierIds'])) {
        this.noteSupplierIds = [] as any;
        for (const item of _data['noteSupplierIds'])
          this.noteSupplierIds!.push(item);
      }
    }
  }

  static fromJS(data: any): AddNoteDto {
    data = typeof data === 'object' ? data : {};
    const result = new AddNoteDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};

    data['noteTitle'] = this.noteTitle;
    data['noteText'] = this.noteText;
    data['dateCreated'] = this.dateCreated
      ? this.dateCreated.toISOString()
      : <any>undefined;
    data['createdById'] = this.createdById;
    data['createdByName'] = this.createdByName;
    data['typeId'] = this.typeId;
    if (Array.isArray(this.noteContactIds)) {
      data['noteContactIds'] = [];
      for (const item of this.noteContactIds) data['noteContactIds'].push(item);
    }
    if (Array.isArray(this.noteVolunteerContactIds)) {
      data['noteVolunteerContactIds'] = [];
      for (const item of this.noteVolunteerContactIds)
        data['noteVolunteerContactIds'].push(item);
    }
    if (Array.isArray(this.noteCaseIds)) {
      data['noteCaseIds'] = [];
      for (const item of this.noteCaseIds) data['noteCaseIds'].push(item);
    }
    if (Array.isArray(this.notePetIds)) {
      data['notePetIds'] = [];
      for (const item of this.notePetIds) data['notePetIds'].push(item);
    }
    if (Array.isArray(this.noteSupplierIds)) {
      data['noteSupplierIds'] = [];
      for (const item of this.noteSupplierIds)
        data['noteSupplierIds'].push(item);
    }
    return data;
  }
}

export interface ICommsClient {
  getCases(id: number): Promise<FlattenedContactCaseDto[]>;
}

export const commsAndNotesStore = reactive({
  newNoteDescription: '',
  newNoteSubject: '',
  searchTerm: '',
  notes: [] as Array<NoteDto>,
  toDate: undefined,
  fromDate: undefined,
  selectedUserIds: undefined as string[] | undefined,
  tagSelectionselectedUserId: undefined,
  tagSelectionSearchedUsers: [] as SystemUserModel[],
  selectedTypeId: undefined as number | undefined,
  selectedCaseId: undefined as number | undefined,
  selectedPetId: undefined as number | undefined,
  selectedContactId: undefined as number | undefined,
  selectedSupplierId: undefined as number | undefined,
  selectedPONumber: undefined as number | undefined,

  assignedVolunteer: false,
  includeLTFContactNotes: false,
  showRelatedCaseNotesForCase: false,
  taggedVolunteer: false,
  showAll: false,
  taggedPet: false,

  filterTypeOptions: [] as FilterResult[],
  isSuggestingTagUser: false,
  mentionItems: [] as MentionItem[],

  expandedNotes: [] as number[],
  hasMoreContentResults: [] as boolean[],

  pageSize: 20,
  currentPage: 0,
  totalNotes: undefined as number | undefined,

  noteToEdit: undefined as EditNoteViewModel | undefined,
  noteToEditVisible: false,

  isFullScreen: false,

  highlightedNoteId: undefined as number | undefined,

  clear() {
    this.newNoteDescription = '';
    this.newNoteSubject = '';
    this.searchTerm = '';

    this.toDate = undefined;
    this.fromDate = undefined;

    this.tagSelectionselectedUserId = undefined;
    this.tagSelectionSearchedUsers = [];

    this.selectedUserIds = undefined;
    this.selectedTypeId = undefined;
    this.selectedCaseId = undefined;
    this.selectedPetId = undefined;
    this.selectedContactId = undefined;
    this.selectedSupplierId = undefined;
    this.isSuggestingTagUser = false;
    this.filterTypeOptions = [];

    this.assignedVolunteer = false;
    this.taggedVolunteer = false;
    this.showAll = false;
    this.includeLTFContactNotes = false;
    this.taggedPet = false;

    this.selectedPONumber = undefined;
    this.showRelatedCaseNotesForCase = false;

    this.mentionItems = [];
  },

  reset() {
    this.clear();

    this.expandedNotes = [];
    this.hasMoreContentResults = [];
    this.currentPage = 0;
    this.notes = [];
    this.totalNotes = undefined;
  },

  setHighlightedNote(noteId: number | undefined) {
    this.highlightedNoteId = noteId;

    if (noteId) {
      const url = new URL(window.location.toString());
      url.searchParams.set('highlightedNoteId', noteId.toString());
      history.pushState(null, '', url);
    }
  },

  async createNewNote(note: AddNoteDto, filesToUpload?: FileParameter[]) {
    const client = new NoteClient();

    const response = await client.addNote(
      note.noteTitle,
      note.noteText,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      note.dateCreated,
      note.createdById,
      note.typeId,
      note.noteContactIds,
      note.noteVolunteerContactIds,
      note.noteCaseIds,
      note.notePetIds,
      note.noteSupplierIds,
      note.noteExpenseIds,
      filesToUpload
    );

    console.log('response', response);
    return response.result;
  },

  async setNotes(data?: PaginatedResponseOfIListOfNoteDto) {
    const _lazyItems = [...this.notes];
    if (data && data.data) {
      data.data.forEach((note) => {
        if (_lazyItems.find((x) => x.noteId == note.noteId) == undefined) {
          _lazyItems.push(note);
        }
      });
    }
    this.notes = _lazyItems;
  },

  async searchCaseNotes(caseId: number, sortDirection: number = -1) {
    if (!caseId) {
      return;
    }
    const client = new NoteClient();

    let assignedVolunteerIds = undefined as number[] | undefined;
    if (this.assignedVolunteer) {
      const assignedVolunteers = caseStore.matchedVolunteers?.filter(
        (mv) => mv.statusName == 'Assigned'
      );
      assignedVolunteerIds = assignedVolunteers?.map((v) => v.contactId);

      if (assignedVolunteerIds?.length == 0) {
        assignedVolunteerIds = [0];
      }
    }

    return await client.searchNotes(
      null,
      [caseId],
      null,
      null,
      this.selectedContactId,
      this.selectedCaseId,
      this.selectedSupplierId,
      this.selectedPetId,
      this.selectedTypeId,
      this.selectedUserIds,
      this.selectedPONumber,
      this.assignedVolunteer,
      assignedVolunteerIds,
      this.taggedVolunteer,
      this.taggedPet,
      this.showAll,
      this.fromDate,
      this.toDate,
      this.currentPage,
      this.pageSize,
      'dateCreated',
      sortDirection,
      this.searchTerm
    );
  },

  async searchContactNotes(contactId: number, sortDirection: number = -1) {
    if (!contactId) {
      return;
    }
    const client = new NoteClient();

    return await client.searchNotes(
      contactId,
      null,
      null,
      null,
      this.selectedContactId,
      this.selectedCaseId,
      this.selectedSupplierId,
      this.selectedPetId,
      this.selectedTypeId,
      this.selectedUserIds,
      this.selectedPONumber,
      this.assignedVolunteer,
      undefined,
      this.taggedVolunteer,
      this.taggedPet,
      this.showAll,
      this.fromDate,
      this.toDate,
      this.currentPage,
      this.pageSize,
      'dateCreated',
      sortDirection,
      this.searchTerm
    );
  },

  async searchSupplierNotes(supplierId: number, sortDirection: number = -1) {
    if (!supplierId) {
      return;
    }
    const client = new NoteClient();

    return await client.searchNotes(
      null,
      null,
      supplierId,
      null,
      this.selectedContactId,
      this.selectedCaseId,
      this.selectedSupplierId,
      this.selectedPetId,
      this.selectedTypeId,
      this.selectedUserIds,
      this.selectedPONumber,
      this.assignedVolunteer,
      undefined,
      this.taggedVolunteer,
      this.showAll,
      this.taggedPet,
      this.fromDate,
      this.toDate,
      this.currentPage,
      this.pageSize,
      'dateCreated',
      sortDirection,
      this.searchTerm
    );
  },
  async searchPetNotes(
    petId: number,
    ltfContactId: number | null,
    petCaseIds: number[] | null,
    sortDirection: number = -1
  ) {
    if (!petId) {
      return;
    }
    const client = new NoteClient();

    return await client.searchNotes(
      ltfContactId,
      petCaseIds,
      null,
      petId,
      this.selectedContactId,
      this.selectedCaseId,
      this.selectedSupplierId,
      this.selectedPetId,
      this.selectedTypeId,
      this.selectedUserIds,
      this.selectedPONumber,
      this.assignedVolunteer,
      undefined,
      this.taggedVolunteer,
      this.taggedPet,
      this.showAll,
      this.fromDate,
      this.toDate,
      this.currentPage,
      this.pageSize,
      'dateCreated',
      sortDirection,
      this.searchTerm
    );
  }
});
