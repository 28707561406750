import { createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-0 pt-2 w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "m-2 mt-0" }, {
      content: _withCtx(() => [
        _createVNode($setup["TabView"], {
          lazy: "",
          class: "h-full w-full b-white"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["TabPanel"], { header: "Letter Stock" }, {
              default: _withCtx(() => [
                _createVNode($setup["LetterStock"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["TabPanel"], { header: "Letter Templates" }, {
              default: _withCtx(() => [
                _createVNode($setup["LetterTemplates"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}