
  import { defineComponent, PropType } from 'vue';
  //import { expenseStore } from "@/stores/expense.store";
  import {
    DocumentClient,
    ExpenseCodeDto,
    ExpenseItemDto,
    ExpenseTypeEnum,
    FileParameter
  } from '@/clients/client.gen';
  import { toGBDateString } from '../../../composables/UtilityFunctions';
  //import ColumnGroup from 'primevue/datatable';
  import Row from 'primevue/datatable';
  import ColumnGroup from 'primevue/columngroup';
  import ScrollPanel from 'primevue/scrollpanel';

  export default defineComponent({
    components: {
      ColumnGroup,
      Row,
      ScrollPanel
    },
    props: {
      expenseCode: {
        type: Object as PropType<ExpenseCodeDto>,
        required: true
      }
    },
    emits: ['update:modelValue'],
    data(): any {
      return {
        loading: true,
        blankRow: new ExpenseItemDto(),
        editingRows: [] as ExpenseItemDto[],
        expenses: this.expenseCode?.expenseItems ?? []
      };
    },
    computed: {
      ExpenseTypeEnum() {
        return ExpenseTypeEnum;
      },
      subTotal(): number {
        return this.expenses.reduce((total: number, e: ExpenseItemDto) => {
          const cost = e.costTotal ?? 0;
          return total + cost;
        }, 0);
      }
    },
    async mounted() {
      this.loading = false;
    },
    methods: {
      openFileDialog() {
        // if(this.readOnly) return;
        (this.$refs.file as any).click();
      },
      removeReceiptFile() {
        this.blankRow.receipt = undefined;
        this.blankRow.receiptId = undefined;
      },
      async newImageSelected(event: any) {
        //As part of this, we'll upload it to S3, and return a document ID which can be then attached to the lineitem.
        const files = event.target.files as FileList;
        const file = files[0];

        //we know there's actually only going to be one,
        const formData = new FormData();
        formData.append('file', file);
        //upload this to the api, so it can get us an s3 key.
        const client = new DocumentClient();
        const fileParameter: FileParameter = {
          data: file,
          fileName: file.name
        };
        const uploadResponse =
          await client.uploadStagingDocument(fileParameter);
        this.blankRow.receiptId = uploadResponse.result?.documentId;
        this.blankRow.receipt = uploadResponse.result;
      },
      toGBDateString,
      showDescriptionColumn() {
        switch (this.expenseCode.expenseTypeId) {
          case 1: //Miscellaneous
          case 3: //Vet
          case 4: //Food
            return true;
        }
        return false;
      },
      showMileageColumn() {
        switch (this.expenseCode.expenseTypeId) {
          case 2: //Mileage
            return true;
        }
        return false;
      },
      formatCurrency(value: number) {
        return value != undefined
          ? value.toLocaleString('en-GB', {
              style: 'currency',
              currency: 'GBP'
            })
          : 0;
      },
      calculateMileage(miles: number) {
        return miles * 0.45;
      },
      hasData() {
        return this.expenses && this.expenses.length > 0;
      },
      onRowEditSave(event: any) {
        console.log('Edit:', event.newData, event.index);
        const { newData, index } = event;
        if (this.showMileageColumn())
          newData.costTotal = this.calculateMileage(newData.itemValue);
        this.expenses[index] = newData;
        console.log('After edit:', this.expenses);
        this.updateVal();
      },
      addNewRecord() {
        //TODO: Needs some kind of validation

        this.blankRow.quantity = 1;
        if (this.showMileageColumn()) {
          this.blankRow.costTotal = this.calculateMileage(
            this.blankRow.itemValue
          );
          this.blankRow.description = 'mileage';
        } else {
          this.blankRow.itemValue = this.blankRow.costTotal;
        }
        this.expenses.push(this.blankRow);
        this.updateVal();

        this.blankRow = new ExpenseItemDto();
      },
      deleteRecord(data: ExpenseItemDto) {
        this.expenses = this.expenses.filter(function (item: ExpenseItemDto) {
          return item !== data;
        });
        this.updateVal();
      },
      updateVal() {
        let expenseCode = new ExpenseCodeDto();
        expenseCode = Object.assign({}, this.expenseCode);
        expenseCode.expenseItems = this.expenses;
        this.$emit('update:modelValue', expenseCode);
      }
    }
  });
