import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      value: $props.caseSuppliers,
      "sort-field": "status.name",
      "sort-order": 1,
      scrollable: "",
      "scroll-height": "flex",
      paginator: "",
      rows: 5
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          sortable: "",
          field: "supplierName",
          header: "Supplier Name"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "primaryContactName",
          header: "Primary Contact"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "primaryContactTelephone",
          header: "Telephone"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "primaryContactEmail",
          header: "Email"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "address.postCode",
          header: "Postcode"
        }),
        _createVNode(_component_Column, {
          field: "notes",
          header: "Notes"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "dateAdded",
          header: "Date Added"
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString($setup.toGBDateString(slotProps.data.dateAdded)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, { header: "" }, {
          body: _withCtx(({ data }) => [
            _createVNode(_component_Button, {
              icon: "pi pi-ellipsis-v",
              severity: "secondary",
              text: "",
              rounded: "",
              "aria-label": "More actions",
              onClick: ($event: any) => ($setup.toggleMoreActionsPanel($event, data))
            }, null, 8 /* PROPS */, ["onClick"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["value"]),
    ($setup.selectedSupplier)
      ? (_openBlock(), _createBlock($setup["OverlayPanel"], {
          key: 0,
          ref: "moreActionsPanel"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: {
        name: 'supplierProfile',
        params: { id: $setup.selectedSupplier.supplierId }
      },
              target: "_blank",
              class: "p-reset"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  class: "flex",
                  text: "",
                  size: "small",
                  label: "View"
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"]),
            _createVNode(_component_Button, {
              class: "flex",
              text: "",
              size: "small",
              label: "Edit",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.editCaseSupplier($setup.selectedSupplier)))
            }),
            _createVNode(_component_Button, {
              class: "flex",
              text: "",
              size: "small",
              severity: "danger",
              label: "Remove",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.deleteCaseSupplier($setup.selectedSupplier?.supplierId)))
            })
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}