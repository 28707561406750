
  import { defineComponent } from 'vue';
  import { FilterMatchMode, FilterOperator } from 'primevue/api';
  import DataTable, { DataTableFilterMeta } from 'primevue/datatable';
  import { toGBDateString } from '../../composables/UtilityFunctions';
  import { emailStore } from '../../stores/email.store';
  import Dropdown from 'primevue/dropdown';
  import InlineMessage from 'primevue/inlinemessage';
  import IconField from 'primevue/iconfield';
  import InputIcon from 'primevue/inputicon';
  import FormDatePicker from '../Common/FormDatePicker.vue';
  import router from '@/router';

  const baseFilter: DataTableFilterMeta = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdByName: { value: null, matchMode: FilterMatchMode.EQUALS },
    name: { value: null, matchMode: FilterMatchMode.EQUALS },
    body: { value: null, matchMode: FilterMatchMode.EQUALS },
    dateCreated: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
    }
  };
  export default defineComponent({
    components: {
      FormDatePicker,
      DataTable,
      Dropdown,
      InlineMessage,
      IconField,
      InputIcon
    },
    data() {
      return {
        filters: { ...baseFilter },
        selectedRow: null,
        emailStore,
        globalFilterFields: ['createdByName', 'name', 'body', 'dateCreated']
      };
    },
    computed: {
      availableCreatedBy() {
        const uniqueNames = new Set<string>();
        const availableCreatedBy: {
          name: string | null | undefined;
          id: string | null | undefined;
        }[] = [];

        emailStore.emails.forEach((c: any) => {
          if (c.createdByName && !uniqueNames.has(c.createdByName)) {
            uniqueNames.add(c.createdByName);
            availableCreatedBy.push({
              name: c.createdByName,
              id: c.createdByName
            });
          }
        });

        availableCreatedBy.push({ name: 'All users', id: null });
        return availableCreatedBy;
      },
      emails() {
        return emailStore.filteredEmails;
      },
      loading() {
        return emailStore.loading;
      }
    },
    async created() {
      await this.init();
    },
    methods: {
      async onRowSelect() {
        router.push({
          name: 'emailProfile',
          params: { id: (this.selectedRow as any).emailTemplateId }
        });
      },
      getData() {
        emailStore.getData();
      },
      toGBDateString,
      async clearFilter() {
        await this.init();
      },
      async init() {
        await this.getData();
        this.filters = { ...baseFilter };
      },
      onClearFiltersClick() {
        this.init();
        emailStore.resetFilter();
      }
    }
  });
