
  import { defineComponent } from 'vue';
  import { FlattenedIncomeDto, DropDownValues } from '@/clients/client.gen';
  import { dropdownsStore } from '../../../../stores/dropdowns.store';
  import { singleIncomeStore } from '../../../../stores/singleIncome.store';
  export default defineComponent({
    props: {
      readOnly: Boolean
    },
    emits: ['update:modelValue'],
    data() {
      return {
        selectedIncomeTypes: undefined as Array<number> | undefined,
        referenceNumber: '' as string,
        date: undefined as Date | undefined,
        incomeTypes: [] as Array<DropDownValues>,
        selectedIncomeSource: undefined as number | undefined,
        incomeSources: [] as Array<DropDownValues>
      };
    },
    computed: {
      income: {
        get(): FlattenedIncomeDto {
          return singleIncomeStore.income;
        },
        set(newVal: FlattenedIncomeDto) {
          singleIncomeStore.setIncome(newVal);
        }
      }
    },
    async created() {
      this.selectedIncomeTypes = this.income?.incomeTypes ?? [];
      this.referenceNumber = this.income?.referenceNumber ?? '';
      this.date = this.income?.date ?? new Date();
      this.selectedIncomeSource = this.income?.incomeSourceId ?? 2;
      this.incomeTypes = await dropdownsStore.getIncomeTypes();
      this.incomeSources = await dropdownsStore.getIncomeSources();
    },
    methods: {
      updateVal() {
        const newIncome = Object.assign({}, this.income);
        newIncome.incomeTypes = this.selectedIncomeTypes;
        // newIncome.referenceNumber = this.referenceNumber;
        newIncome.date = this.date;
        newIncome.incomeSourceId = this.selectedIncomeSource;
        this.$emit('update:modelValue', newIncome);
      }
    }
  });
