import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid" }
const _hoisted_2 = { class: "field col-auto" }
const _hoisted_3 = { class: "field col-1 pl-0 mt-2" }
const _hoisted_4 = { class: "h-full bg-white" }
const _hoisted_5 = { class: "field grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["PetPicker"], {
      ref: "petPicker",
      pets: $setup.ownersPetsExcludingKeepTogether,
      onSelected: $setup.onPetSelected
    }, null, 8 /* PROPS */, ["pets"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["Button"], {
          class: "p-button-rounded mt-2",
          icon: "pi pi-plus",
          size: "small",
          rounded: "",
          label: "Add Keep Together Pet",
          severity: "warning",
          onClick: $setup.showPetPicker
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["Button"], {
          class: "p-button-rounded",
          icon: "pi pi-trash",
          disabled: $setup.isDeleteDisabled,
          rounded: "",
          text: "",
          severity: "secondary",
          "aria-label": "Delete selected",
          onClick: $setup.onDeleteClick
        }, null, 8 /* PROPS */, ["disabled"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["DataTable"], {
          selection: $setup.selectedKeepTogethers,
          "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedKeepTogethers) = $event)),
          paginator: "",
          rows: 5,
          value: $setup.keepTogetherList,
          "data-key": "petId",
          class: "col-12 mb-3 md:col-9 md:mb-0 p-datatable-sm"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Column"], {
              "selection-mode": "multiple",
              "header-style": "width: 3rem"
            }),
            _createVNode($setup["Column"], {
              field: "linkPetId",
              header: "ID"
            }),
            _createVNode($setup["Column"], {
              field: "linkPetName",
              header: "Pet Name"
            }),
            _createVNode($setup["Column"], {
              field: "reason",
              header: "Reason"
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["selection", "value"])
      ])
    ])
  ]))
}