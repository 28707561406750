import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-4 pt-0 min-h-full bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$setup.currentUserStore.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["TasksTab"], { class: "p-4" })
      ]))
    : _createCommentVNode("v-if", true)
}