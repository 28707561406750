import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full p-4 bg-white" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-7" }
const _hoisted_4 = { class: "col-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AfterCareList = _resolveComponent("AfterCareList")!
  const _component_DLList = _resolveComponent("DLList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AfterCareList)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DLList)
      ])
    ])
  ]))
}