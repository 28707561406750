
  import { defineComponent } from 'vue';
  import PetDetails from './PetDetails.vue';
  import { petStore } from '../../stores/pet.store';
  import PetTabbedContent from './PetTabbedContent.vue';
  import Skeleton from 'primevue/skeleton';

  export default defineComponent({
    components: {
      PetDetails,
      PetTabbedContent,
      Skeleton
    },
    props: {
      id: [Number, String]
    },
    data(): any {
      return {
        petStore
      };
    },
    computed: {
      petId(): number {
        return this.id === undefined ? 0 : this.id;
      }
    },
    async created() {
      await this.getData();
    },
    methods: {
      async getData() {
        await this.petStore.getData(this.petId);
      }
    }
  });
