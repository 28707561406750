import { getCurrentInstance } from 'vue';
import { TinyEmitter } from 'tiny-emitter';

export default function useEmitter(): TinyEmitter {
  const internalInstance = getCurrentInstance();
  if (internalInstance == null) {
    return new TinyEmitter();
  }
  const emitter = internalInstance.appContext.config.globalProperties.emitter;

  return emitter;
}
