import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex gap-2 justify-content-between"
}
const _hoisted_2 = { class: "flex align-items-center" }
const _hoisted_3 = { style: {"flex-grow":"1"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Rating = _resolveComponent("Rating")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.formData, (value, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
      (!value.hidden)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, `field-${value.name}`, {
              value: value,
              formDetails: $props.formData
            }, () => [
              _createElementVNode("div", {
                class: _normalizeClass([{ flexColumn: $props.blockLabels }, "w-full mb-2"]),
                style: {"min-height":"50px"}
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, value.labelSlotName, {}, () => [
                    _createElementVNode("label", null, _toDisplayString(value.label), 1 /* TEXT */)
                  ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, value.inputSlotName, { value: value }, () => [
                    (value.type === 'String')
                      ? (_openBlock(), _createBlock($setup["InputText"], {
                          key: 0,
                          modelValue: value.value,
                          "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                          label: value.label,
                          disabled: value.readOnly
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "disabled"]))
                      : (value.type === 'Int32' || value.type === 'Decimal')
                        ? (_openBlock(), _createBlock(_component_InputNumber, {
                            key: 1,
                            modelValue: value.value,
                            "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                            class: "w-full",
                            readonly: value.readOnly === true
                          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "readonly"]))
                        : (value.type === 'Currency')
                          ? (_openBlock(), _createBlock(_component_InputNumber, {
                              key: 2,
                              id: value.slotName,
                              modelValue: value.value,
                              "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                              mode: "currency",
                              currency: "GBP",
                              class: "w-full",
                              locale: "en-GB",
                              readonly: value.readOnly === true
                            }, null, 8 /* PROPS */, ["id", "modelValue", "onUpdate:modelValue", "readonly"]))
                          : (value.type === 'Rating')
                            ? (_openBlock(), _createBlock(_component_Rating, {
                                key: 3,
                                modelValue: value.value,
                                "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                                readonly: value.readOnly
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "readonly"]))
                            : _createCommentVNode("v-if", true),
                    (value.type === 'DropDown')
                      ? (_openBlock(), _createBlock(_component_Dropdown, {
                          key: 4,
                          modelValue: value.value,
                          "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                          options: value.options,
                          label: value.label,
                          "option-label": "name",
                          "option-value": "id",
                          class: "w-full",
                          disabled: value.readOnly
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "label", "disabled"]))
                      : (value.type === 'Boolean')
                        ? (_openBlock(), _createBlock(_component_Checkbox, {
                            key: 5,
                            modelValue: value.value,
                            "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                            label: value.label,
                            binary: true,
                            readonly: value.readOnly
                          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "readonly"]))
                        : (
                  value.type === 'DateTime' || value.type === 'DateTimeOffset'
                )
                          ? (_openBlock(), _createBlock($setup["Calendar"], {
                              key: 6,
                              modelValue: value.value,
                              "onUpdate:modelValue": ($event: any) => ((value.value) = $event),
                              label: value.label,
                              readonly: value.readOnly,
                              class: "w-full"
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "readonly"]))
                          : _createCommentVNode("v-if", true)
                  ])
                ])
              ], 2 /* CLASS */)
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ], 64 /* STABLE_FRAGMENT */))
  }), 128 /* KEYED_FRAGMENT */))
}