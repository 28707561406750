
  import { defineComponent } from 'vue';
  import ExpenseGrid from '@/components/Common/Expenses/ExpenseGrid.vue';
  import { supplierStore } from '@/stores/supplier.store';

  export default defineComponent({
    components: {
      ExpenseGrid
    },
    data() {
      return {
        supplierStore
      };
    },
    computed: {},
    async mounted() {},
    methods: {}
  });
