import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flex flex-column" }
const _hoisted_3 = { class: "flex flex-column gap-2" }
const _hoisted_4 = {
  key: 0,
  class: "grid"
}
const _hoisted_5 = { class: "flex gap-2 justify-content-end" }
const _hoisted_6 = {
  key: 1,
  class: "text-center"
}
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    id: $setup.props.formElementId,
    onSubmit: _withModifiers($setup.submitForm, ["prevent"])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!$setup.isLoading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formData, (value, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (!value.hidden)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _renderSlot(_ctx.$slots, `field-${value.name}`, {
                          value: value,
                          formDetails: $setup.formData
                        }, () => [
                          _createVNode($setup["GeneratedInput"], {
                            modelValue: value.value,
                            "onUpdate:modelValue": [
                              ($event: any) => ((value.value) = $event),
                              _cache[0] || (_cache[0] = ($event: any) => (console.log($event)))
                            ],
                            "label-class": $setup.labelClass,
                            "input-definition": value,
                            "label-above": $props.labelAbove,
                            readonly: $setup.props.readonly,
                            "on-field-focus": $setup.onFieldFocus,
                            "on-field-updated": $setup.onFieldUpdated
                          }, {
                            [`${value.labelSlotName}`]: _withCtx((slotProps) => [
                              _renderSlot(_ctx.$slots, value.labelSlotName, {
                                value: slotProps.value
                              })
                            ]),
                            [`${value.inputSlotName}`]: _withCtx((slotProps) => [
                              _renderSlot(_ctx.$slots, value.inputSlotName, {
                                value: slotProps.value
                              })
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "label-class", "input-definition", "label-above", "readonly"])
                        ])
                      ]))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              }), 128 /* KEYED_FRAGMENT */)),
              _createElementVNode("div", _hoisted_5, [
                (!$setup.props.hideCancel)
                  ? (_openBlock(), _createBlock($setup["Button"], {
                      key: 0,
                      disabled: $setup.saving,
                      type: "button",
                      label: $props.cancelLabel,
                      onClick: $setup.cancel
                    }, null, 8 /* PROPS */, ["disabled", "label"]))
                  : _createCommentVNode("v-if", true),
                (!$setup.props.readonly)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      ($props.id === 0)
                        ? (_openBlock(), _createBlock($setup["Button"], {
                            key: 0,
                            loading: $setup.saving,
                            label: $props.createLabel,
                            onClick: $setup.submitForm
                          }, null, 8 /* PROPS */, ["loading", "label"]))
                        : _createCommentVNode("v-if", true),
                      ($props.id && $props.id > 0)
                        ? (_openBlock(), _createBlock($setup["Button"], {
                            key: 1,
                            loading: $setup.saving,
                            label: $props.saveLabel,
                            onClick: $setup.submitForm
                          }, null, 8 /* PROPS */, ["loading", "label"]))
                        : _createCommentVNode("v-if", true)
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true),
                _renderSlot(_ctx.$slots, "form-footer-buttons", {
                  id: $setup.props.id,
                  item: $setup.formData
                })
              ])
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, "Loading..."))
      ])
    ]),
    ($setup.isFormValid === false)
      ? (_openBlock(), _createElementBlock("p", _hoisted_7, " Please provide responses to the questions marked with a * "))
      : _createCommentVNode("v-if", true)
  ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
}