import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1422aa99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      outlined: "",
      raised: "",
      class: "align-items-center justify-content-center income-picker-button",
      label: "Add single payment Income",
      onClick: _ctx.selectSingleIncome
    }, null, 8 /* PROPS */, ["onClick"]),
    _createVNode(_component_Button, {
      outlined: "",
      raised: "",
      class: "align-items-center justify-content-center income-picker-button",
      label: "Add recurring payment income",
      onClick: _ctx.selectRecurringIncome
    }, null, 8 /* PROPS */, ["onClick"])
  ]))
}