
  import { defineComponent } from 'vue';
  import {
    DropDownValues,
    FlattenedIncomeDto,
    PaymentFrequencyEnum,
    RecurringPaymentMethodsEnum
  } from '@/clients/client.gen';
  import { mapEnumToObjects } from '@/composables/UtilityFunctions';
  import FormDatePicker from '@/components/Common/FormDatePicker.vue';
  export default defineComponent({
    components: { FormDatePicker },
    props: {
      income: FlattenedIncomeDto,
      readOnly: Boolean
    },
    emits: ['update:modelValue'],
    data() {
      return {
        referenceNumber: '',
        date: undefined as Date | undefined,
        incomeAmount: 0,
        selectedPaymentMethod: undefined as number | undefined,
        giftAid: false,
        dateFirstPayment: undefined as Date | undefined,
        dateLastPayment: undefined as Date | undefined,
        continueUntilFurtherNotice: false,
        selectedFrequency: undefined as number | undefined,
        membership: false,
        ct100: false,
        donation: false,
        description: '',
        paymentMethods: new Array<DropDownValues>(),
        frequencies: new Array<DropDownValues>()
      };
    },
    created() {
      if (!this.readOnly) {
        this.date = new Date();
        this.updateVal();
      }
      this.paymentMethods = mapEnumToObjects(RecurringPaymentMethodsEnum);
      this.frequencies = mapEnumToObjects(PaymentFrequencyEnum);

      //copy vals from income to local data
      if (this.income) {
        this.referenceNumber = this.income.referenceNumber ?? '';
        this.date = this.income.date;
        this.incomeAmount = this.income.recurringAmount ?? 0;
        this.selectedPaymentMethod = this.income.recurringPaymentMethodId;
        this.giftAid = this.income.giftAid;
        this.dateFirstPayment = this.income.dateFirstPayment;
        this.dateLastPayment = this.income.dateLastPayment;
        this.continueUntilFurtherNotice =
          this.income.continueUntilFurtherNotice;
        this.selectedFrequency = this.income.selectedFrequencyId;
        this.membership = this.income.isRecurringMembership;
        this.ct100 = this.income.isRecurringCT100;
        this.donation = this.income.isRecurringDonation;
        this.description = this.income.description ?? '';
      }
    },
    methods: {
      updateVal() {
        const newIncome = Object.assign({}, this.income);
        // newIncome.referenceNumber = this.referenceNumber;
        newIncome.date = this.date;
        newIncome.recurringAmount = this.incomeAmount;
        newIncome.recurringPaymentMethodId = this.selectedPaymentMethod;
        newIncome.giftAid = this.giftAid;
        newIncome.dateFirstPayment = this.dateFirstPayment;
        newIncome.dateLastPayment = this.dateLastPayment;
        newIncome.continueUntilFurtherNotice;
        newIncome.selectedFrequencyId = this.selectedFrequency;
        newIncome.isRecurringMembership = this.membership;
        newIncome.isRecurringCT100 = this.ct100;
        newIncome.isRecurringDonation = this.donation;
        newIncome.description = this.description;
        console.log(newIncome);
        this.$emit('update:modelValue', newIncome);
      }
    }
  });
