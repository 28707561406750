import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-column gap-2" }
const _hoisted_2 = { class: "w-full grid" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "col-12 flex justify-content-end" }
const _hoisted_7 = { class: "text-2xl" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "flex align-options-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.visible,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (($setup.visible) = $event)),
    modal: "",
    header: $setup.props.title,
    style: {"min-width":"600px","max-width":"600px"}
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_Button, {
          disabled: !$setup.selectedCase,
          label: "Submit",
          onClick: $setup.onLinkClick
        }, null, 8 /* PROPS */, ["disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["FormField"], {
              modelValue: $setup.search.caseId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search.caseId) = $event)),
              label: "Case Id",
              "label-above": ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode($setup["FormField"], {
              modelValue: $setup.search.ownerName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.search.ownerName) = $event)),
              label: "Owner Name",
              "label-above": ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["FormField"], {
              modelValue: $setup.search.postCode,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.search.postCode) = $event)),
              label: "Post code",
              "label-above": ""
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Button, {
              label: "Search",
              loading: $setup.searchInProgress,
              onClick: $setup.getPotentialCases
            }, null, 8 /* PROPS */, ["loading"])
          ])
        ]),
        _createVNode($setup["ListBox"], {
          modelValue: $setup.selectedCase,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.selectedCase) = $event)),
          filter: "",
          "filter-fields": ['ownerName', 'contactId', 'caseId'],
          options: $setup.filteredCases,
          "option-label": "ownerName",
          class: "w-full",
          "input-id": "caseId",
          "list-style": "min-height:100px; max-height:100px;",
          onFilter: $setup.getPotentialCases
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, "Case ID: " + _toDisplayString(slotProps.option.caseId), 1 /* TEXT */),
              _createTextVNode(" / "),
              _createElementVNode("span", _hoisted_9, "Contact ID: " + _toDisplayString(slotProps.option.contactId), 1 /* TEXT */),
              _createElementVNode("span", null, "(" + _toDisplayString(slotProps.option.ownerName) + ")", 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "options"]),
        _createVNode($setup["FormField"], {
          modelValue: $setup.reason,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.reason) = $event)),
          label: "Reason",
          "label-above": ""
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "header"]))
}