
  import { defineComponent } from 'vue';
  import { casesDashboard } from '../../stores/casesDashboard.store';
  import CasesTabSummary from './CasesTabSummary.vue';
  import CasesTabList from './CasesTabList.vue';
  export default defineComponent({
    components: { CasesTabList, CasesTabSummary },
    async created() {
      await this.getData();
    },
    methods: {
      async getData() {
        await casesDashboard.getData();
      }
    }
  });
