
  import { defineComponent, PropType } from 'vue';
  import { FormFieldSelect } from '../';
  import Checkbox from 'primevue/checkbox';
  import { dropdownsStore } from '../../../stores/dropdowns.store';
  import SupplierSelector from '../SupplierSelector.vue';
  import {
    DropDownValues,
    ExpenseCodeDto,
    ExpenseDto,
    FlattenedCasePetDto,
    FlattenedCaseVolunteer,
    StringDropDownValues
  } from '../../../clients/client.gen';
  import { currentUserStore } from '@/stores/currentUser.store';
  interface InvoiceDetailData {
    internalExpense: ExpenseDto;
    selectedExpenseTypes: Array<DropDownValues>;
    costCentres: Array<any>;
    expenseTypes: Array<DropDownValues>;
    nominalCodes: Array<any>;
    bankTaskRequired: boolean;
    approvalGroups: string[];
  }
  export default defineComponent({
    components: {
      Checkbox,
      FormFieldSelect,
      SupplierSelector
    },
    props: {
      volunteers: {
        type: Array as PropType<Array<FlattenedCaseVolunteer>>,
        required: false
      },
      supplierId: {
        type: Number,
        required: false
      },
      pets: {
        type: Array as PropType<Array<FlattenedCasePetDto>>,
        required: true
      },
      expense: {
        type: ExpenseDto,
        required: true
      }
    },
    emits: ['update:modelValue'],
    data(): InvoiceDetailData {
      return {
        internalExpense: {} as ExpenseDto,
        selectedExpenseTypes: [] as Array<DropDownValues>,
        costCentres: [],
        expenseTypes: new Array<DropDownValues>(),
        nominalCodes: [],
        bankTaskRequired: false,
        approvalGroups: ['Management', 'AnimalWelfare']
      };
    },
    computed: {
      maxDate() {
        return new Date();
      },
      minDate() {
        const currentDate = new Date();
        const yearsAgo = new Date(currentDate);
        yearsAgo.setFullYear(currentDate.getFullYear() - 1);
        return yearsAgo;
      }
    },
    watch: {
      'internalExpense.nominalCode': async function (newVal) {
        if (newVal) {
          this.costCentres = await dropdownsStore.getCostCentres(newVal);
          //Apply specific CT default, IF it exists.
          this.internalExpense.costCentreCode =
            this.costCentres.find(
              (cc: StringDropDownValues) => cc.code === '03'
            ).code ?? '';
        }
      }
    },
    async created() {
      console.log('InvoiceDetail', this.supplierId);
      if (this.supplierId != null) {
        this.internalExpense.supplierId = this.supplierId;
      }
      this.internalExpense = Object.assign({}, this.expense);
      await this.populateDropdowns();
    },
    methods: {
      clearCentres() {
        this.internalExpense.costCentreCode = '0';
      },
      async populateDropdowns() {
        this.expenseTypes = await dropdownsStore.getExpenseTypes(); //["Travel", "Food", "Vet", "Other"];
        this.nominalCodes = await dropdownsStore.getNominalCodes();
        this.internalExpense.nominalCode ??= this.nominalCodes[0].code;
        this.internalExpense.petIds = this.internalExpense.pets?.map(
          (pet) => pet.petId
        );
      },
      async isValidUser() {
        return (
          currentUserStore.isFinanceUser && currentUserStore.isAnimalWelfareUser
        );
      },
      selectTypes(types: any) {
        this.internalExpense.expenseCodes = types.value.map(
          (et: DropDownValues) => {
            const ec = new ExpenseCodeDto();
            ec.expenseTypeId = et.id;
            ec.codeTotal = 0;
            ec.expenseItems = [];
            return ec;
          }
        );
        this.updateVal();
      },
      updatePets(value: number[]) {
        this.internalExpense.petIds = value;
        this.updateVal();
      },
      updateCode(value: string) {
        this.internalExpense.nominalCode = value;
        this.updateVal();
      },
      updateCCC(value: string) {
        this.internalExpense.costCentreCode = value;
        this.updateVal();
      },
      updateSupplier(supplierId: number) {
        this.internalExpense.supplierId = supplierId;
        this.updateVal();
      },
      updateExpected(value: number) {
        this.internalExpense.expectedAmount = value;
        this.updateVal();
      },
      updateDescription(value: string) {
        this.internalExpense.description = value;
        this.updateVal();
      },
      updateApproval(value: boolean) {
        this.internalExpense.needsApproval = value;
        this.updateVal();
      },
      updateApprovalGroup(value: string) {
        this.internalExpense.approvalGroupId = value;
        this.updateVal();
      },
      updateVal() {
        const newExpense = Object.assign({}, this.expense);
        newExpense.petIds = this.internalExpense.petIds;
        newExpense.nominalCode = this.internalExpense.nominalCode;
        newExpense.costCentreCode = this.internalExpense.costCentreCode;
        newExpense.expectedAmount = this.internalExpense.expectedAmount;
        newExpense.supplierId = this.internalExpense.supplierId;
        newExpense.description = this.internalExpense.description;
        newExpense.needsApproval = this.internalExpense.needsApproval;
        newExpense.approvalGroupId = this.internalExpense.approvalGroupId;
        this.$emit('update:modelValue', newExpense);
      }
    }
  });
