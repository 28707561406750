
  import { defineComponent } from 'vue';
  import FormFieldMixin from './FormFieldMixin';

  export default defineComponent({
    mixins: [FormFieldMixin],
    props: {
      labelLeft: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  });
