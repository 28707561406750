import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex align-options-center" }
const _hoisted_2 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    modelValue: _ctx.user,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user) = $event)),
    class: "w-full",
    "option-label": _ctx.display,
    suggestions: _ctx.users,
    placeholder: _ctx.placeholder,
    onComplete: _ctx.search
  }, {
    option: _withCtx((slotProps) => [
      _createElementVNode("div", _hoisted_1, [
        (slotProps.option.photo)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              alt: slotProps.option.name,
              src: slotProps.option.photo,
              style: {"width":"18px"}
            }, null, 8 /* PROPS */, _hoisted_2))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", null, _toDisplayString(_ctx.display(slotProps.option)), 1 /* TEXT */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "option-label", "suggestions", "placeholder", "onComplete"]))
}