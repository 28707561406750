import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "pet-modal_travel-walking" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isValidProp('wearsCollarOrHarness'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 0,
          id: "wearsCollarOrHarness",
          modelValue: $setup.petStore.petProfile.wearsCollarOrHarness,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.petStore.petProfile.wearsCollarOrHarness) = $event)),
          "label-above": "",
          label: $setup.propFieldText('wearsCollarOrHarness')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('walkedOnCollarOrHarness'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 1,
          id: "walkedOnCollarOrHarness",
          modelValue: $setup.petStore.petProfile.walkedOnCollarOrHarness,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.petStore.petProfile.walkedOnCollarOrHarness) = $event)),
          "label-above": "",
          label: $setup.propFieldText('walkedOnCollarOrHarness')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('pullingWhileWalking'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 2,
          id: "pullingWhileWalking",
          modelValue: $setup.petStore.petProfile.pullingWhileWalking,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.petStore.petProfile.pullingWhileWalking) = $event)),
          "label-above": "",
          label: $setup.propFieldText('pullingWhileWalking')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('howEasyToWalk'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 3,
          id: "howEasyToWalk",
          modelValue: $setup.petStore.petProfile.howEasyToWalk,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.petStore.petProfile.howEasyToWalk) = $event)),
          "label-above": "",
          label: $setup.propFieldText('howEasyToWalk')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToOtherDogsWhileWalking'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 4,
          id: "reactionToOtherDogsWhileWalking",
          modelValue: $setup.petStore.petProfile.reactionToOtherDogsWhileWalking,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.petStore.petProfile.reactionToOtherDogsWhileWalking) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToOtherDogsWhileWalking')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToTraffic'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 5,
          id: "reactionToTraffic",
          modelValue: $setup.petStore.petProfile.reactionToTraffic,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.petStore.petProfile.reactionToTraffic) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToTraffic')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToWeather'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 6,
          id: "reactionToWeather",
          modelValue: $setup.petStore.petProfile.reactionToWeather,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.petStore.petProfile.reactionToWeather) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToWeather')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('walkLength'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 7,
          id: "walkLength",
          modelValue: $setup.petStore.petProfile.walkLength,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.petStore.petProfile.walkLength) = $event)),
          "label-above": "",
          label: $setup.propFieldText('walkLength')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    (
        $setup.isValidProp('canTakeInstructions') &&
        $setup.petStore.pet.petTypeId != $setup.AnimalTypeEnum.Equine
      )
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 8,
          id: "canTakeInstructions",
          modelValue: $setup.petStore.petProfile.canTakeInstructions,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.petStore.petProfile.canTakeInstructions) = $event)),
          "label-above": "",
          label: $setup.propFieldText('canTakeInstructions')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('recallCommand'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 9,
          id: "recallCommand",
          modelValue: $setup.petStore.petProfile.recallCommand,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.petStore.petProfile.recallCommand) = $event)),
          "label-above": "",
          label: $setup.propFieldText('recallCommand')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('travelInformation'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 10,
          id: "travelInformation",
          modelValue: $setup.petStore.petProfile.travelInformation,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.petStore.petProfile.travelInformation) = $event)),
          "label-above": "",
          label: $setup.propFieldText('travelInformation')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('travelInCarInformation'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 11,
          id: "travelInCarInformation",
          modelValue: $setup.petStore.petProfile.travelInCarInformation,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.petStore.petProfile.travelInCarInformation) = $event)),
          "label-above": "",
          label: $setup.propFieldText('travelInCarInformation')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('usedToTravelling'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 12,
          id: "usedToTravelling",
          modelValue: $setup.petStore.petProfile.usedToTravelling,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.petStore.petProfile.usedToTravelling) = $event)),
          "label-above": "",
          label: $setup.propFieldText('usedToTravelling')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('difficultlyInOutOfCarrier'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 13,
          id: "difficultlyInOutOfCarrier",
          modelValue: $setup.petStore.petProfile.difficultlyInOutOfCarrier,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.petStore.petProfile.difficultlyInOutOfCarrier) = $event)),
          "label-above": "",
          label: $setup.propFieldText('difficultlyInOutOfCarrier')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    (
        $setup.isValidProp('canBePickedUp') &&
        $setup.petStore.pet.petTypeId == $setup.AnimalTypeEnum.Cat
      )
      ? (_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 14,
          id: "canBePickedUp",
          modelValue: $setup.petStore.petProfile.canBePickedUp,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.petStore.petProfile.canBePickedUp) = $event)),
          label: $setup.propFieldText('canBePickedUp')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true)
  ]))
}