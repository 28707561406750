import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-end align-items-center m-2 gap-2" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createBlock(_component_ScrollPanel, {
    class: "h-full pb-4 pr-4",
    style: {"height":"100%"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Button, {
          label: "Save",
          loading: $setup.saving,
          size: "small",
          onClick: $setup.saveDetails
        }, null, 8 /* PROPS */, ["loading"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          ($setup.formDefinition.sections[0])
            ? (_openBlock(), _createBlock($setup["FormInputList"], {
                key: 0,
                "form-data": $setup.formDefinition.sections[0],
                "block-labels": false
              }, {
                "input-LegacyReference": _withCtx((slotProps) => [
                  (!slotProps.value.value)
                    ? (_openBlock(), _createBlock(_component_InputText, {
                        key: 0,
                        modelValue: slotProps.value.value,
                        "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
                        class: "w-full",
                        readonly: true,
                        placeholder: "Generated on save"
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                    : _createCommentVNode("v-if", true)
                ]),
                "input-LegacyNumber": _withCtx((slotProps) => [
                  (slotProps.value.value != 0)
                    ? (_openBlock(), _createBlock(_component_InputNumber, {
                        key: 0,
                        modelValue: slotProps.value.value,
                        "onUpdate:modelValue": ($event: any) => ((slotProps.value.value) = $event),
                        class: "w-full",
                        readonly: true,
                        disabled: true
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                    : (_openBlock(), _createBlock(_component_InputNumber, {
                        key: 1,
                        class: "w-full",
                        readonly: true,
                        placeholder: "Generated on save"
                      }))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["form-data"]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          ($setup.formDefinition.sections[1])
            ? (_openBlock(), _createBlock($setup["FormInputList"], {
                key: 0,
                "form-data": $setup.formDefinition.sections[1],
                "block-labels": false
              }, null, 8 /* PROPS */, ["form-data"]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          ($setup.formDefinition.sections[2])
            ? (_openBlock(), _createBlock($setup["FormInputList"], {
                key: 0,
                "form-data": $setup.formDefinition.sections[2],
                "block-labels": false
              }, {
                "input-PersonalRepresentative": _withCtx((slotProps) => [
                  (slotProps.value.value?.id)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, " ID: " + _toDisplayString(slotProps.value.value.id) + " Name: " + _toDisplayString(slotProps.value.value.name), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  _createVNode(_component_Dropdown, {
                    modelValue: slotProps.value.value,
                    "onUpdate:modelValue": [($event: any) => ((slotProps.value.value) = $event), $setup.setStorePersonalRepresentative],
                    options: $setup.personalRepresentativeSearchResults,
                    filter: "",
                    "filter-fields": ['id'],
                    "filter-placeholder": "Search using Id for Contact or Supplier",
                    "option-label": "name",
                    placeholder: "Search for a Personal Representative",
                    class: "w-full",
                    onFilter: $setup.searchForPersonalRepresentative
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["form-data"]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}