
  import { defineComponent } from 'vue';
  import { ExpenseDto, ExpenseTypeEnum } from '../../../clients/client.gen';
  import { dropdownsStore } from '../../../stores/dropdowns.store';
  import {
    toGBDateString,
    formatCurrency
  } from '../../../composables/UtilityFunctions';

  export default defineComponent({
    props: {
      expense: {
        type: ExpenseDto,
        required: true
      }
      // expenseCodes: {
      //     type: Array as PropType<Array<ExpenseCodeDto>>,
      //     required: true,
      // },
    },
    data(): any {
      return {
        expenseTypes: [] as ExpenseTypeEnum[]
      };
    },
    computed: {
      ExpenseTypeEnum() {
        return ExpenseTypeEnum;
      },
      getTotalExpenseCost() {
        let sum = 0;

        if (!this.expense.expenseCodes || !this.expense.expenseCodes.length) {
          return 0;
        }

        for (let i = 0; i < this.expense.expenseCodes.length; i++) {
          sum += this.expense.expenseCodes[i].codeTotal;
        }

        return sum;
      }
    },
    async mounted() {
      this.expenseTypes = await dropdownsStore.getExpenseTypes();
    },
    methods: {
      toGBDateString,
      formatCurrency
    }
  });
