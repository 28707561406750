<template>
  <template v-for="(part, index) in processedText" :key="index">
    <span v-if="part.type === 'text'">{{ part.content }}</span>

    <router-link v-if="part.type === 'link'" :to="generateRoute(part.link)">
      {{ part.content }}
    </router-link>
  </template>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    text: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    routeParams: {
      type: Object,
      default: () => ({})
    },
    queryParams: {
      type: Object,
      default: () => ({})
    },
    routeQuery: {
      type: Object,
      default: () => ({})
    },
    idParameterName: {
      type: String,
      required: true
    },
    isParam: {
      type: Boolean,
      default: true
    }
  });

  const generateRoute = (numericId) => {
    const params = {
      name: props.routeName,
      params: {
        ...props.routeParams
      },
      query: { ...props.queryParams }
    };
    if (props.isParam) {
      params.params[props.idParameterName] = numericId;
    } else {
      params.query[props.idParameterName] = numericId;
    }
    return params;
  };

  const processedText = computed(() => {
    const regex = /#(\d+)/g;

    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(props.text)) !== null) {
      if (match.index > lastIndex) {
        parts.push({
          type: 'text',
          content: props.text.slice(lastIndex, match.index)
        });
      }

      parts.push({
        type: 'link',
        content: match[0], // The number with the #
        link: match[1] // The number without the #
      });

      lastIndex = regex.lastIndex;
    }

    if (lastIndex < props.text.length) {
      parts.push({
        type: 'text',
        content: props.text.slice(lastIndex)
      });
    }

    return parts;
  });
</script>
