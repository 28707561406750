
  import { defineComponent } from 'vue';
  import Dialog from 'primevue/dialog';
  import {
    MappableCaseDto,
    VolunteerClient,
    CaseSearchModel
  } from '@/clients/client.gen';
  import ContactMap from './Map/ContactMap.vue';
  import { radiusRange } from '@/stores/data.store';
  import { FormFieldSelect } from './';
  import FormCheckBox from '@/components/Common/FormCheckBox.vue';
  import { contactStore } from '@/stores/contact.store';

  export default defineComponent({
    components: { FormCheckBox, FormFieldSelect, Dialog, ContactMap },
    props: {
      contactId: {
        type: Number,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      showVolunteerTaskMatching: {
        type: Boolean,
        required: false,
        default: false
      },
      caseId: { type: [String, Number], required: false }
    },
    emits: ['assign', 'cancel'],
    data(): any {
      return {
        loadRange: [
          { name: 'No cases', value: 0 },
          { name: 'Up to 3 cases', value: 3 },
          { name: 'Up to 5 cases', value: 5 },
          { name: 'Up to 10 cases', value: 10 },
          { name: 'Any case load', value: null }
        ],
        radiusRange: radiusRange.map((x) => ({ name: x.toString(), value: x })),
        miles: 5,
        isLoading: false,
        contactStore,
        currentCaseLoad: null,
        randomId: Math.random(),
        matchingCases: [] as MappableCaseDto[],
        volunteerCaseMatchDialogVisible: false,
        loadingDialog: false,
        matchVolunteerTask: false,
        conformToMaxDistance: false
      };
    },
    computed: {
      innerRadius(): number {
        return this.miles ? this.miles : radiusRange[0];
      },
      hasCases(): boolean {
        return this.matchingCases.some(
          (x: MappableCaseDto) => x.contactId !== this.contactId
        );
      }
    },
    async created() {
      if (this.showVolunteerCaseTaskMatching) {
        this.matchVolunteerTask = true;
        this.conformToMaxDistance = true;
      }
      await this.openVolunteerCaseMatchingDialog();
      this.$watch(
        (vm: any) => [
          vm.currentCaseLoad,
          vm.miles,
          vm.matchVolunteerTask,
          vm.conformToMaxDistance
        ],
        this.getCases,
        {
          deep: true
        }
      );
    },
    methods: {
      resetComponent() {
        this.volunteerCaseMatchDialogVisible = false;
        this.$emit('cancel');
      },
      async getCases() {
        console.log('Getting cases.');
        const search = new CaseSearchModel({
          miles: this.miles,
          conformToVolunteerMaxDistance: this.conformToMaxDistance,
          currentCaseLoad: this.currentCaseLoad,
          centralContactId: this.contactId,
          matchVolunteerTask: this.matchVolunteerTask
        });
        console.log('Search params:', search);

        const client = new VolunteerClient();
        this.isLoading = true;
        try {
          console.log('Get matching cases.');
          this.matchingCases = await client.getAllMatchingCases(search);
          this.isLoading = false;
        } catch (e) {
          console.log('Failed getting cases.', e);
          this.isLoading = false;
        }
      },
      async openVolunteerCaseMatchingDialog() {
        this.loadingDialog = true;
        try {
          await this.getCases();
        } catch {
          this.loadingDialog = false;
        }
        this.loadingDialog = false;
        this.volunteerCaseMatchDialogVisible = true;
      }
    }
  });
