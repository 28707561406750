import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.tasks, (task) => {
    return (_openBlock(), _createElementBlock("div", {
      key: task.userTaskId,
      class: "readonly-task-item flex flex-row align-items-center justify-content-between"
    }, [
      _createElementVNode("p", null, [
        _createElementVNode("a", {
          onClick: () => $setup.onTaskClick(task)
        }, _toDisplayString(task.description), 9 /* TEXT, PROPS */, _hoisted_1)
      ]),
      _createVNode($setup["Checkbox"], {
        readonly: "",
        binary: true,
        "model-value": task.userTaskStatusId,
        "true-value": $setup.TaskStatusEnum.Complete,
        "false-value": !$setup.TaskStatusEnum.Complete,
        "aria-readonly": "true",
        disabled: ""
      }, null, 8 /* PROPS */, ["model-value", "true-value", "false-value"])
    ]))
  }), 128 /* KEYED_FRAGMENT */))
}