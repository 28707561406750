import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createBlock(_component_ScrollPanel, { class: "h-full w-full" }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        value: _ctx.tableData,
        class: "h-full",
        style: {"max-width":"700px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "petPetName",
            header: "Pet",
            sortable: true
          }, {
            body: _withCtx(({ data }: { data: OwnerHistoryDto }) => [
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.goToPet(data.petId))
              }, _toDisplayString(data.petPetName), 9 /* TEXT, PROPS */, _hoisted_1)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_Column, {
            header: "Case",
            sortable: true
          }, {
            body: _withCtx(({ data }: { data: OwnerHistoryDto }) => [
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.goToCase(data.caseId))
              }, _toDisplayString(data.caseReference), 9 /* TEXT, PROPS */, _hoisted_2)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_Column, {
            header: "",
            style: {"width":"50px"}
          }, {
            body: _withCtx(({ data }: { data: OwnerHistoryDto }) => [
              _createVNode(_component_Button, {
                icon: "pi pi-pencil",
                label: "View comms and notes",
                class: "p-button-rounded p-button-success p-mr-2",
                onClick: ($event: any) => (_ctx.viewComms(data.petId))
              }, null, 8 /* PROPS */, ["onClick"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value"])
    ]),
    _: 1 /* STABLE */
  }))
}