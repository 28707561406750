import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.internalValue,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.internalValue) = $event)),
    modal: "",
    style: { width: '35vw', height: 'auto' },
    header: "Secondary contact details"
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          label: "Add",
          autofocus: "",
          severity: "success",
          onClick: _ctx.onAddClick
        }, null, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Cancel",
          outlined: "",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.internalValue = false))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FormField, {
          modelValue: _ctx.contact.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contact.name) = $event)),
          label: "Secondary contact name"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_FormField, {
          modelValue: _ctx.contact.role,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contact.role) = $event)),
          label: "Secondary contact role"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_FormField, {
          modelValue: _ctx.contact.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contact.email) = $event)),
          type: "email",
          label: "Secondary contact email"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_FormField, {
          modelValue: _ctx.contact.telephone,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contact.telephone) = $event)),
          type: "phone",
          label: "Secondary contact phone"
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}