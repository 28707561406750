import { reactive } from 'vue';
import {
  AuditEntryDto,
  AuditHistoryClient,
  CaseClient,
  CaseStageDto,
  CaseStatusDto,
  CaseTypeDto,
  CaseTypeEnum,
  DocumentClient,
  EditableCaseDto,
  FlattenedCasePetDto,
  FlattenedCaseVolunteer,
  FlattenedContactCaseDto,
  GetDocumentsResponse,
  LinkedCaseDto,
  LongTermFosterCaseStageDto,
  NoteDto,
  SimpleCaseDto,
  SimplePersonalRepresentativeDto,
  SystemUserModel,
  Title
} from '@/clients/client.gen';
import RecentlyViewedService, {
  IRecentlyViewedItem
} from '@/services/RecentlyViewedService';
import { dropdownsStore } from './dropdowns.store';
import { userStore } from './user.store';

const currentCase = new EditableCaseDto();

export interface RecentlyViewedCase extends IRecentlyViewedItem {
  caseId: number;
  description: string;
  status: number;
}

const recentlyViewedCase = new RecentlyViewedService<RecentlyViewedCase>(
  'Case'
);

export const createSimpleCase = (
  caseObj: FlattenedContactCaseDto | EditableCaseDto
) => {
  const ownerName = `${Title[caseObj.contactTitle as number] ?? ''} ${caseObj.contactFirstName} ${caseObj.contactLastName}`;
  const petNames = caseObj.assignedPets?.map((x) => x.petName).join(', ') ?? '';

  return new SimpleCaseDto({
    ...caseObj,
    petNames: petNames,
    description: `${caseObj.caseTypeAbbreviation} - ${ownerName} - ${petNames}`
  });
};

export const caseStore = reactive({
  loading: true,
  caseClient: new CaseClient(),
  case: currentCase,
  notes: [] as NoteDto[],
  caseStatuses: [] as CaseStatusDto[],
  longTermFosterCaseStages: [] as LongTermFosterCaseStageDto[],
  caseTypes: [] as CaseTypeDto[],
  caseStages: [] as CaseStageDto[],
  documents: {} as GetDocumentsResponse,
  users: [] as SystemUserModel[],
  auditHistory: [] as AuditEntryDto[],
  linkedCases: [] as LinkedCaseDto[],
  recentlyViewedCases: recentlyViewedCase.listOfItems as RecentlyViewedCase[],
  matchedVolunteers: [] as FlattenedCaseVolunteer[],
  showBanners: false,
  legacyPersonalRepresentative: undefined as
    | SimplePersonalRepresentativeDto
    | undefined,

  isLTFCase() {
    return this.case.caseTypeId == CaseTypeEnum.LTF;
  },
  isSTFCase() {
    return this.case.caseTypeId == CaseTypeEnum.STF;
  },
  async getPets(id: number) {
    const caseClient = new CaseClient();
    const response = await caseClient.getCasePets(id);

    if (response) {
      //SB 08/05/2024 - Removed the deceased filter here, as the customer needs to see deceased pets on the case screen. See comment - https://app.productive.io/15857-thought-quarter/projects/248938/tasks/task/7168918?board=505461&filter=NDE0Njk0&task-list=1106812&taskActivityId=118442230
      this.case.contactPets = response.contactPets;
      this.case.assignedPets = response.assignedPets;
      this.updatePetAssignments();
    }
  },
  saveNavigationHistory() {
    if (!this.case || !this.case.caseId) return;

    const caseType = this.caseTypes?.find(
      (x: CaseTypeDto) => x.caseTypeId == this.case.caseTypeId
    );

    const ownerName = `${Title[this.case.contactTitle as number] ?? ''} ${this.case.contactFirstName} ${this.case.contactLastName}`;
    const petNames = this.case.assignedPets?.map((x) => x.petName).join(', ');

    const myCase: RecentlyViewedCase = {
      uniqueIdentifier: this.case.caseId.toString(),
      caseId: this.case.caseId,
      status: this.case.caseStatusId,
      description: `${caseType?.name} - ${ownerName} - ${petNames}`
    };
    recentlyViewedCase.persist(myCase);
  },
  async getData(id: number) {
    if (id === undefined || id === null) {
      this.case = new EditableCaseDto();
      this.loading = false;
      return;
    }

    this.loading = true;
    const isExistingCase = id > 0;
    if (isExistingCase) {
      const caseClient = new CaseClient();
      const data = await caseClient.getForEdit(id);
      this.matchedVolunteers = await caseClient.getCaseVolunteers(id);
      this.case = data;
      this.getDocumentData(id);
    } else {
      this.case = new EditableCaseDto();
      this.matchedVolunteers = [];
      this.case.caseTypeId = 1;
      this.case.caseStatusId = 1;
      this.case.caseStageId = 1;
    }

    this.caseStatuses = dropdownsStore.caseStatuses;
    this.longTermFosterCaseStages = dropdownsStore.longTermFosterCaseStages;
    this.caseTypes = dropdownsStore.caseTypes;
    this.caseStages = dropdownsStore.caseStages;
    await userStore.getData();
    this.users = userStore.users;
    this.updatePetAssignments();

    this.loading = false;
  },
  async getAuditHistory(id: number) {
    const auditClient = new AuditHistoryClient();
    const result = await auditClient.getForCase(id);
    if (result.success && result.result) {
      this.auditHistory = result.result;
    } else {
      this.auditHistory = [];
    }
  },
  async getVolunteers(id: number) {
    const caseClient = new CaseClient();
    const data = await caseClient.getForEdit(id);
    this.case = data;
    this.matchedVolunteers = await caseClient.getCaseVolunteers(id);
  },
  getDocumentData(id: number) {
    const client = new DocumentClient();
    try {
      client.getCaseDocuments(id).then((resp) => {
        if (resp.result) {
          this.documents = resp.result;
        }
      });
    } catch {
      return new GetDocumentsResponse();
    }
  },
  updatePetAssignments() {
    if (this.case.contactPets && this.case.assignedPets) {
      this.case.contactPets.forEach((pet) => {
        if (this.case.assignedPets) {
          pet.isAssigned =
            this.case.assignedPets.filter((p: FlattenedCasePetDto) => {
              return p.petId === pet.petId;
            }).length > 0 ?? false;
        }
      });
    }
  }
});
