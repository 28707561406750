
  import { defineComponent } from 'vue';
  import { emailStore } from '../../stores/email.store';
  export default defineComponent({
    computed: {
      emailTemplate() {
        return emailStore.emailTemplate;
      }
    }
  });
