import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createBlock(_component_ScrollPanel, { style: {"height":"100%"} }, {
    default: _withCtx(() => [
      _createVNode($setup["GeneratedTable"], {
        ref: "table",
        client: $setup.client,
        "extend-get-all": $setup.extendGetAll,
        "before-save": $setup.processModel,
        editable: true,
        deletable: false,
        selectable: "",
        "form-element-id": "co-beneficiary-form"
      }, {
        "column-amount": _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString($setup.formatCurrency(slotProps.value.data.amount)), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["client"])
    ]),
    _: 1 /* STABLE */
  }))
}