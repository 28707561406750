import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid search-filter m-2 w-full" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = {
  key: 0,
  class: "col-3"
}
const _hoisted_4 = {
  key: 1,
  class: "col-5"
}
const _hoisted_5 = {
  key: 2,
  class: "col-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Dropdown, {
        modelValue: $setup.model.propertyName,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => (($setup.model.propertyName) = $event)),
          $setup.onUpdatePropertyName
        ],
        options: $setup.visibleFields,
        "option-label": "displayName",
        "option-value": "propertyName",
        placeholder: "Select a Parameter",
        class: "w-full"
      }, null, 8 /* PROPS */, ["modelValue", "options"])
    ]),
    ($setup.model.propertyName.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Dropdown, {
            modelValue: $setup.model.filterId,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => (($setup.model.filterId) = $event)),
              $setup.onUpdateFilterId
            ],
            options: $setup.filterFields,
            "option-label": "name",
            "option-value": "id",
            placeholder: "Select a filter type",
            class: "w-full"
          }, null, 8 /* PROPS */, ["modelValue", "options"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.model.propertyName.length > 0 && !$setup.noValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_InputText, {
            modelValue: $setup.model.searchValue,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => (($setup.model.searchValue) = $event)),
              _cache[3] || (_cache[3] = ($event: any) => ($setup.onUpdateSearchValue($event ?? '')))
            ]
          }, null, 8 /* PROPS */, ["modelValue"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.model.propertyName.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", {
            class: "pi pi-trash cursor-pointer",
            onClick: $setup.removeFilter
          })
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}