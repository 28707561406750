import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncomeGrid = _resolveComponent("IncomeGrid")!

  return (_openBlock(), _createBlock(_component_IncomeGrid, {
    data: _ctx.incomes,
    onSaveIncome: _ctx.saveIncome,
    onUpdateIncome: _ctx.updateIncome
  }, null, 8 /* PROPS */, ["data", "onSaveIncome", "onUpdateIncome"]))
}