import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full p-4 pt-0 bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CasesTab = _resolveComponent("CasesTab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CasesTab, { class: "p-4" })
  ]))
}