
  import { defineComponent } from 'vue';
  import Dropdown from 'primevue/dropdown';
  import FormFieldMixin from './FormFieldMixin';

  export default defineComponent({
    components: { Dropdown },
    mixins: [FormFieldMixin],
    props: {
      options: { required: true, type: Array },
      canAddNew: { type: Boolean, required: false, default: false },
      noLabel: { type: Boolean, required: false, default: false },
      showClear: { type: Boolean, required: false, default: true },
      showFilter: { type: Boolean, required: false, default: false },
      optionLabel: {
        type: String,
        required: true
      },
      optionValue: {
        type: String,
        required: true
      },
      editable: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['add'],
    computed: {
      isFreeTextValue(): boolean {
        if (!this.optionValue || !this.options) return false;
        const result = this.options.find(
          //@ts-ignore
          (x) => (x as any)[this.optionValue] === this.internalValue
        );
        return result == null;
      },
      searchQuery(): string {
        if (!this.internalValue || !this.isFreeTextValue) return '';
        if (typeof this.internalValue === 'string')
          return (this.internalValue as string).toLowerCase();
        return '';
      },
      filteredList(): unknown[] {
        if (
          !this.internalValue ||
          !this.isFreeTextValue ||
          !this.searchQuery ||
          !this.options.length
        )
          return this.options;
        const exactMatches = this.options.filter((option: any) => {
          return (
            option[this.optionLabel].toLowerCase() ===
            this.searchQuery.toLowerCase()
          );
        });

        const startsWithMatches = this.options.filter((option: any) => {
          return (
            option[this.optionLabel]
              .toLowerCase()
              .startsWith(this.searchQuery.toLowerCase()) &&
            !exactMatches.includes(option)
          );
        });

        const containsMatches = this.options.filter((option: any) => {
          return (
            option[this.optionLabel]
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) &&
            !exactMatches.includes(option) &&
            !startsWithMatches.includes(option)
          );
        });

        return [...exactMatches, ...startsWithMatches, ...containsMatches];
      },
      dropdownClass(): string {
        return this.labelAbove ? 'col-12' : 'col-9';
      }
    },
    methods: {
      highlightText(text: string) {
        const regex = new RegExp(`(${this.searchQuery})`, 'gi');
        return text.replace(
          regex,
          '<span class="text-bold text-orange-400">$1</span>'
        );
      },
      addNewItem() {
        this.$emit('add', this.internalValue);
      }
    }
  });
