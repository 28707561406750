import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-content-end mt-2" }
const _hoisted_2 = { class: "flex justify-content-end" }
const _hoisted_3 = { style: {"width":"300px"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex justify-content-end gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        icon: "pi pi-plus",
        onClick: $setup.openAddPanel
      })
    ]),
    _createVNode(_component_DataTable, { value: $setup.letterStocks }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Name"
        }),
        _createVNode(_component_Column, {
          field: "description",
          header: "Description"
        }),
        _createVNode(_component_Column, {
          field: "height",
          header: "Height (mm)"
        }),
        _createVNode(_component_Column, {
          field: "width",
          header: "Width (mm)"
        }),
        _createVNode(_component_Column, null, {
          body: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Button, {
                text: "",
                icon: "pi pi-pencil",
                onClick: ($event: any) => ($setup.openEditPanel($event, slotProps.data))
              }, null, 8 /* PROPS */, ["onClick"])
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["value"]),
    _createVNode($setup["OverlayPanel"], { ref: "createOrEditLetterStockPanel" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            (
            $setup.selectedLetterStock.id == 0 || $setup.selectedLetterStock.id == undefined
          )
              ? (_openBlock(), _createElementBlock("h2", _hoisted_4, " Add new letter stock "))
              : (_openBlock(), _createElementBlock("h2", _hoisted_5, "Edit letter stock"))
          ]),
          _createVNode($setup["FormField"], {
            modelValue: $setup.selectedLetterStock.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedLetterStock.name) = $event)),
            label: "Name"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode($setup["FormField"], {
            modelValue: $setup.selectedLetterStock.description,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedLetterStock.description) = $event)),
            label: "Description"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode($setup["FormField"], {
            modelValue: $setup.selectedLetterStock.height,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.selectedLetterStock.height) = $event)),
            label: "Height"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode($setup["FormField"], {
            modelValue: $setup.selectedLetterStock.width,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.selectedLetterStock.width) = $event)),
            label: "Width"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Button, {
              label: "Cancel",
              text: "",
              onClick: $setup.cancelEdit
            }),
            _createVNode(_component_Button, {
              label: "Save",
              loading: $setup.isSaving,
              "loading-icon": "pi pi-spin pi-spinner",
              onClick: $setup.save
            }, null, 8 /* PROPS */, ["loading"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */)
  ], 64 /* STABLE_FRAGMENT */))
}