
  import Button from 'primevue/button';
  import { defineComponent } from 'vue';
  import router from '@/router';
  export default defineComponent({
    props: ['message'],
    componenets: { Button },
    methods: {
      goBack() {
        router.go(-1);
      }
    }
  });
