import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid h-full m-0" }
const _hoisted_2 = { class: "col-4 h-full pt-0 pb-0" }
const _hoisted_3 = { class: "col h-full pb-0" }
const _hoisted_4 = {
  class: "grid pb-2 pt-2",
  style: {"height":"50%"}
}
const _hoisted_5 = { class: "col h-full bg-white" }
const _hoisted_6 = {
  class: "grid pb-0 pt-2",
  style: {"height":"50%"}
}
const _hoisted_7 = { class: "col h-full bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_SupplierDetails = _resolveComponent("SupplierDetails")!
  const _component_CommsAndNotes = _resolveComponent("CommsAndNotes")!
  const _component_SupplierTabbedContent = _resolveComponent("SupplierTabbedContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.supplierStore.loading)
        ? (_openBlock(), _createBlock(_component_Skeleton, {
            key: 0,
            width: "100%",
            height: "100%"
          }))
        : _createCommentVNode("v-if", true),
      (!_ctx.supplierStore.loading)
        ? (_openBlock(), _createBlock(_component_SupplierDetails, { key: 1 }))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.supplierStore.loading)
            ? (_openBlock(), _createBlock(_component_Skeleton, {
                key: 0,
                width: "100%",
                height: "100%"
              }))
            : _createCommentVNode("v-if", true),
          (!_ctx.supplierStore.loading)
            ? (_openBlock(), _createBlock(_component_CommsAndNotes, {
                key: 1,
                onOpenEmail: _ctx.openEmail,
                onSendEmail: _ctx.sentEmail,
                onNoteCreated: _ctx.onNoteCreated
              }, null, 8 /* PROPS */, ["onOpenEmail", "onSendEmail", "onNoteCreated"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_ctx.supplierStore.loading)
            ? (_openBlock(), _createBlock(_component_Skeleton, {
                key: 0,
                width: "100%",
                height: "100%"
              }))
            : _createCommentVNode("v-if", true),
          (!_ctx.supplierStore.loading && _ctx.displaySecondaryContacts)
            ? (_openBlock(), _createBlock(_component_SupplierTabbedContent, { key: 1 }))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ])
  ]))
}