
  // eslint ignore because this is actually used by the slotProps and breaks it if not included, side effect of the ':' in the template
  // eslint-disable-next-line
  import { OwnerHistoryDto } from "@/clients/client.gen";
  import { toGBDateString } from '@/composables/UtilityFunctions';
  import { petStore } from '@/stores/pet.store';
  import { defineComponent } from 'vue';
  import router from "@/router";

  export default defineComponent({
    props: {
      id: [Number, String]
    },
    computed: {
      petStore() {
        return petStore;
      },
      toGBDateString() {
        return toGBDateString;
      }
    },
    async created() {
      console.log('id', this.id);
      let id = this.id;
      if (id && typeof id === 'string') {
        id = parseInt(id);
      }

      if (id && typeof id === 'number') {
        await petStore.getOwnerHistory(id);
      }
    },
    methods: {
      goToContact(contactId: number) {
        const route = router.resolve({
          name: 'contactProfile',
          params: { id: contactId }
        });
        window.open(route.href, '_blank');
      },
      goToCase(caseId: number | undefined) {
        if (caseId === undefined) return;
        const route = router.resolve({
          name: 'caseProfile',
          params: { id: caseId }
        });
        window.open(route.href, '_blank');
      }
    }
  });
