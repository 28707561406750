import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid h-full m-0" }
const _hoisted_2 = { class: "col-4 h-full p-0" }
const _hoisted_3 = { class: "col-8 h-full" }
const _hoisted_4 = {
  class: "grid",
  style: {"height":"100%"}
}
const _hoisted_5 = { class: "col-12 h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PetDetails = _resolveComponent("PetDetails")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_PetTabbedContent = _resolveComponent("PetTabbedContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.petStore.loading)
        ? (_openBlock(), _createBlock(_component_PetDetails, { key: 0 }))
        : _createCommentVNode("v-if", true),
      (_ctx.petStore.loading)
        ? (_openBlock(), _createBlock(_component_Skeleton, {
            key: 1,
            width: "100%",
            height: "100%"
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.petStore.loading)
            ? (_openBlock(), _createBlock(_component_PetTabbedContent, {
                key: 0,
                id: _ctx.id
              }, null, 8 /* PROPS */, ["id"]))
            : _createCommentVNode("v-if", true),
          (_ctx.petStore.loading)
            ? (_openBlock(), _createBlock(_component_Skeleton, {
                key: 1,
                width: "100%",
                height: "100%"
              }))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ])
  ]))
}