import { reactive } from 'vue';
import {
  AddUpdatePetModel,
  AuditEntryDto,
  AuditHistoryClient,
  DocumentClient,
  GetDocumentsResponse,
  PetClient,
  PetDto,
  PetOverviewDto,
  NominatedPersonDto,
  KeepTogetherDto,
  KeepTogetherRequest,
  OwnerHistoryDto,
  PetProfileDto,
  AddVetModel,
  FlattenedContactCaseDto
} from '../clients/client.gen';

export { PetDto } from '../clients/client.gen';

export {
  ContactPetOverviewDto,
  PetOverviewDto,
  NominatedPersonDto,
  KeepTogetherDto
} from '../clients/client.gen';

export interface Vet {
  name: string;
  id: number;
}
export const petStore = reactive({
  loading: false,
  petTypeProperties: {} as { [key: string]: string },
  pet: new PetDto(),
  ltfPetCases: [] as FlattenedContactCaseDto[],
  petProfile: new PetProfileDto(),
  ownerHistory: [] as OwnerHistoryDto[],
  auditHistory: [] as AuditEntryDto[],
  petOverview: {} as PetOverviewDto,
  contactPetOverview: [] as PetOverviewDto[],
  petClient: new PetClient(),
  documents: {} as GetDocumentsResponse,
  createVet: false,
  vet: {} as AddVetModel,

  async getData(id: number) {
    if (!id) {
      this.petOverview = {} as PetOverviewDto;
      return;
    }
    this.loading = true;

    const [petOverviewResponse, auditHistoryResponse, ltfPetCasesResponse] =
      await Promise.all([
        this.petClient.getPetOverview(id),
        this.getAuditHistory(id),
        this.petClient.getLTFCases(id)
      ]);

    this.petOverview = petOverviewResponse;
    this.auditHistory = auditHistoryResponse;
    this.ltfPetCases = ltfPetCasesResponse;

    this.getDocumentData(id);
    this.loading = false;
  },

  async getOwnerHistory(id: number) {
    this.ownerHistory = await this.petClient.getOwnerHistory(id);
  },

  async getPetProfileData(contactId: number, id?: number) {
    if (!id) {
      this.pet = { contactId, petTypeId: 1 } as PetDto;
      this.petProfile = new PetProfileDto();
      return;
    }
    const petModel = await this.petClient.get(id);
    this.pet = petModel.pet;
    this.petProfile = petModel.petProfile;

    this.createVet = false;
  },

  async getPetProperties(petTypeId: number) {
    this.petTypeProperties =
      await this.petClient.getPetPropertiesByType(petTypeId);
  },

  async clonePet(contactId: number, petId?: number) {
    if (this.pet.petId !== petId) {
      await this.getPetProfileData(contactId, petId);
    }
    const petClone = new PetDto({ ...this.pet });
    const petProfileClone = new PetProfileDto({ ...this.petProfile });
    petClone.petId = 0;
    petProfileClone.petId = 0;
    petProfileClone.petProfileId = 0;

    petClone.petName = '';
    petClone.petPhotoFullUrl = '';
    petClone.assignedCases = undefined;
    petClone.ownerHistories = undefined;
    petClone.petProfile = undefined;

    this.pet = petClone;
    this.petProfile = petProfileClone;
  },

  async getAuditHistory(id: number) {
    const auditClient = new AuditHistoryClient();
    const result = await auditClient.getForPet(id);
    if (result.success && result.result) {
      return result.result;
    } else {
      return [];
    }
  },

  getDocumentData(id: number) {
    const client = new DocumentClient();
    try {
      client.getPetDocuments(id).then((resp) => {
        if (resp.result) {
          this.documents = resp.result;
        }
      });
    } catch {
      this.documents = new GetDocumentsResponse();
    }
  },

  async createPetProfile() {
    let result = undefined;
    this.pet = new PetDto(this.pet);
    const addUpdatePetModel = new AddUpdatePetModel({
      pet: this.pet,
      petProfile: new PetProfileDto(this.petProfile),
      createVet: this.createVet,
      vet: new AddVetModel(this.vet)
    });

    if (!this.pet.petId) {
      result = await this.petClient.createPet(addUpdatePetModel);
    } else {
      result = await this.petClient.updatePet(addUpdatePetModel);
    }

    if (result.success && result.result) {
      const petModel = result.result;
      this.pet = petModel.pet;
      this.petProfile = petModel.petProfile;
    }
  },

  async addNominatedPerson(person: NominatedPersonDto) {
    const result = await this.petClient.addNominatedPerson(
      person,
      this.pet.petId
    );
    if (result.result) {
      if (result.result.nominatedPersons)
        this.petOverview.petNominatedPersons = result.result.nominatedPersons;
    }
  },

  async deleteNominatedPersons(persons: NominatedPersonDto[]) {
    if (!persons.length) return;

    const result = await this.petClient.deleteNominatedPersons(
      persons.map((x) => x.nominatedPersonId),
      this.pet.petId
    );
    if (result.result) {
      if (result.result.nominatedPersons)
        this.petOverview.petNominatedPersons = result.result.nominatedPersons;
    }
  },

  async addKeepTogether(keepTogetherDto: KeepTogetherDto[]) {
    const keepTogetherRequest = new KeepTogetherRequest();
    keepTogetherRequest.keepTogetherDtos = keepTogetherDto;
    const result = await this.petClient.addKeepTogether(
      keepTogetherRequest,
      this.pet.petId
    );
    if (result.result && result.result.keepTogethers) {
      this.pet.keepTogethers = result.result.keepTogethers;
    }
  },

  async deleteKeepTogethers(keepTogethers: KeepTogetherDto[]) {
    if (!keepTogethers.length) return;

    const result = await this.petClient.deleteKeepTogethers(
      keepTogethers.map((x) => x.keepTogetherId),
      this.pet.petId
    );
    if (result.result && result.result.keepTogethers) {
      this.pet.keepTogethers = result.result.keepTogethers;
    }
  }
});
