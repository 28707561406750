import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-column gap-2" }
const _hoisted_2 = { class: "text-2xl" }
const _hoisted_3 = { class: "flex align-options-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock($setup["Dialog"], {
    visible: $setup.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (($setup.visible) = $event)),
    modal: "",
    header: "Select a pet",
    style: {"min-width":"600px","max-width":"600px"}
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Button, {
          disabled: !$setup.selectedPets,
          label: "Add Keep Together",
          severity: "warning",
          onClick: $setup.onLinkClick
        }, null, 8 /* PROPS */, ["disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ListBox"], {
          modelValue: $setup.selectedPets,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedPets) = $event)),
          filter: "",
          "filter-fields": ['petName', 'petId'],
          options: $setup.filteredPets,
          "option-label": "name",
          class: "w-full",
          "input-id": "petId",
          multiple: "",
          "list-style": "min-height:100px; max-height:100px;"
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(" Pet Name: "),
              _createElementVNode("span", null, _toDisplayString(slotProps.option.petName), 1 /* TEXT */),
              _createElementVNode("span", null, "(ID: " + _toDisplayString(slotProps.option.petId) + ")", 1 /* TEXT */)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue", "options"]),
        _createVNode($setup["FormField"], {
          modelValue: $setup.reason,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.reason) = $event)),
          label: "Reason",
          "label-above": ""
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}