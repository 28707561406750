
  import { defineComponent } from 'vue';
  import { ExpenseDto } from '@/clients/client.gen';
  export default defineComponent({
    props: {
      expense: {
        type: ExpenseDto,
        required: true
      }
    },
    emits: ['update:modelValue'],
    data(): any {
      return {};
    },
    methods: {
      updateMode(mode: number) {
        const updatableExpense = Object.assign({}, this.expense);
        updatableExpense.expenseMode = mode;
        this.$emit('update:modelValue', updatableExpense);
      }
    }
  });
