import {
  createWebHistory,
  createRouter,
  RouteLocationNormalized
} from 'vue-router';
import { Auth } from 'aws-amplify';

import DashboardHome from '@/components/Dashboard/DashboardHome.vue';
import AuthComponent from '@/components/AuthScreen.vue';
import ContactProfile from '@/components/Contact/ContactProfile.vue';
import ContactsDashboard from '@/components/Contact/ContactsDashboard.vue';
import UsersList from '@/components/Users/UsersList.vue';
import SuppliersList from '@/components/Suppliers/SuppliersList.vue';
import SupplierProfile from '@/components/Suppliers/SupplierProfile.vue';
import ReportsList from '@/components/Reports/ReportsList.vue';
import PurchaserList from '@/components/Purchaser/PurchaserList.vue';
import CasesList from '@/components/Cases/CasesList.vue';
import CaseProfile from '@/components/Cases/CaseProfile.vue';
import PetProfile from '@/components/Pets/PetProfile.vue';
import AdvancedSearchWrapper from '@/components/Dashboard/AdvancedSearchDashboard.vue';
import NotFoundPage from '../components/Common/NotFoundPage.vue';
import DashboardLayout from '../components/Common/DashboardLayout.vue';
import SettingsHome from '@/components/Settings/SettingsHome.vue';
import EmailProfile from '@/components/EmailTemplate/EmailProfile.vue';
import AfterCare from '@/components/Cases/AfterCareDashboard.vue';
import VolunteerExpenseExternalForm from '@/components/Forms/VolunteerExpenseExternalForm.vue';
import ExternalLayout from '@/components/Common/ExternalLayout.vue';

const routes = [
  {
    path: '/auth',
    component: AuthComponent
  },
  {
    path: '/forms',
    component: ExternalLayout,
    children: [
      {
        path: 'volunteer-expenses',
        name: 'volunteerExpensesExternalForm',
        component: VolunteerExpenseExternalForm
      }
    ]
  },
  {
    path: '/',
    component: DashboardLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/404/:message',
        name: 'notFound',
        props: true,
        component: NotFoundPage
      },
      {
        path: '/search',
        name: 'search',
        component: AdvancedSearchWrapper,
        meta: { requiresAuth: true }
      },
      {
        path: '/',
        component: DashboardHome,
        meta: { requiresAuth: true }
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: ContactsDashboard,
        meta: { requiresAuth: true }
      },
      {
        path: '/pets/profile/:id/:tab?/:tabContentId?',
        name: 'pets',
        props: true,
        component: PetProfile,
        meta: { requiresAuth: true }
      },
      {
        path: '/contacts/profile/:id',
        name: 'contactProfile',
        props: (route: RouteLocationNormalized) => ({
          id: Number(route.params.id),
          highlightedTaskId: Number(route.query.highlightedTaskId),
          highlightedNoteId: Number(route.query.highlightedNoteId),
          volunteerExpenseId: Number(route.query.volunteerExpenseId),
          tab: String(route.params.tab)
        }),
        component: ContactProfile,
        meta: { requiresAuth: true }
      },
      {
        path: '/contacts/profile',
        name: 'contactProfileCreate',
        component: ContactProfile,
        props: () => ({
          id: 0
        }),
        meta: { requiresAuth: true }
      },
      {
        path: '/users',
        name: 'users',
        component: UsersList,
        meta: { requiresAuth: true }
      },
      {
        path: '/purchasers',
        name: 'purchaser',
        component: PurchaserList,
        meta: { requiresAuth: true }
      },
      {
        path: '/suppliers',
        name: 'suppliers',
        component: SuppliersList,
        meta: { requiresAuth: true }
      },
      {
        path: '/suppliers/profile/:id?',
        name: 'supplierProfile',
        props: true,
        component: SupplierProfile,
        meta: { requiresAuth: true }
      },
      {
        path: '/reports',
        name: 'reports',
        component: ReportsList,
        meta: { requiresAuth: true }
      },
      {
        path: '/cases',
        name: 'cases',
        component: CasesList,
        meta: { requiresAuth: true }
      },
      {
        path: '/cases/profile/:id',
        name: 'caseProfile',
        props: (route: RouteLocationNormalized) => ({
          id: Number(route.params.id),
          contactId: Number(route.query.contactId),
          highlightedTaskId: Number(route.query.highlightedTaskId),
          highlightedNoteId: Number(route.query.highlightedNoteId),
          caseExpenseId: Number(route.query.caseExpenseId),
          tab: String(route.params.tab)
        }),
        component: CaseProfile,
        meta: { requiresAuth: true }
      },
      {
        path: '/aftercare',
        name: 'aftercare',
        component: AfterCare,
        meta: { requiresAuth: true }
      },
      {
        path: '/settings',
        name: 'settings',
        component: SettingsHome,
        meta: { requiresAuth: true }
      },
      {
        path: '/email/profile/:id?',
        name: 'emailProfile',
        props: true,
        component: EmailProfile,
        meta: { requiresAuth: true }
      }
    ]
  }
];

// 3. Create the router instance and pass the `routes` option
const router = createRouter({
  // Provide the history implementation to use. We are using the html5 history mode here.
  history: createWebHistory(),
  routes
});

// resolve guards - check if authentication is required and validate with AWS Amplify, otherwise redirect to /auth
// This is called before navigation is confirmed.
router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          path: '/auth'
        });
      });
  } else next();
});

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  document.title = (to.meta.title as string) || 'Cinnamon Trust';

  // Continue resolving the route
  next();
});

export default router;
