
  import { defineComponent } from 'vue';
  import SummaryItem from '../Common/SummaryItem.vue';
  import {
    assignedToMe,
    casesDashboard
  } from '../../stores/casesDashboard.store';

  export default defineComponent({
    components: { SummaryItem },
    data() {
      return {
        assignedToMe,
        ltfCount: 0 as number,
        summaryItems: [
          {
            title: 'Live Cases',
            //count: casesDashboard.getCasesCount(assignedToMe),
            count: 0,
            subtitle: 'Live cases have been assigned to you',
            severity: 'warning',
            linkText: 'See your cases ->',
            onClick: () => casesDashboard.changeView('standard')
          },
          {
            title: 'Open LTF Cases',
            count: this.ltfCount,
            subtitle: 'Open LTF Cases need action',
            severity: 'warning',
            linkText: 'See all Open LTF Cases ->',
            onClick: () => casesDashboard.changeView('ltf')
          }
        ]
      };
    },
    async created() {
      await this.getLTFCaseCount();
      await casesDashboard.changeView('standard');
    },
    methods: {
      async getLTFCaseCount() {
        const count = await casesDashboard.getLTFCasesCount();
        if (count) this.ltfCount = count;
      }
    }
  });
