import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"height":"calc(100% - 56px)"} }
const _hoisted_2 = { class: "flex justify-content-end align-items-center m-2" }
const _hoisted_3 = { class: "flex flex-column w-full gap-2" }
const _hoisted_4 = { class: "file-upload w-full" }
const _hoisted_5 = { style: {"font-size":"1.5em"} }
const _hoisted_6 = {
  key: 1,
  class: "selected-file p-3"
}
const _hoisted_7 = { class: "grid" }
const _hoisted_8 = { class: "col-4 flex justify-content-center" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 1,
  class: "pi pi-file",
  style: {"font-size":"150px"}
}
const _hoisted_11 = { class: "col-6 col-offset-1" }
const _hoisted_12 = { class: "flex flex-column gap-2" }
const _hoisted_13 = { class: "wrap-break-word" }
const _hoisted_14 = { class: "flex gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          label: "Upload document",
          onClick: $setup.showUploadDocumentPanel
        })
      ]),
      _createVNode($setup["DocumentAccordion"], {
        documents: $setup.props.documents,
        onUpdateRequested: $setup.update
      }, null, 8 /* PROPS */, ["documents"])
    ]),
    _createVNode($setup["Dialog"], {
      visible: $setup.showUploadPanel,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => (($setup.showUploadPanel) = $event)),
      position: "bottomright",
      header: "File upload",
      style: { width: '30vw', height: 'fit-content' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("Move to own component? "),
          _createElementVNode("div", _hoisted_4, [
            (!$setup.selectedFile)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "drop-zone",
                  onDrop: _cache[1] || (_cache[1] = ($event: any) => ($setup.dropHandler($event))),
                  onDragover: _cache[2] || (_cache[2] = ($event: any) => ($setup.dragOverHandler($event)))
                }, [
                  _createElementVNode("p", _hoisted_5, [
                    _createTextVNode(" Drag and drop or "),
                    _createElementVNode("a", {
                      class: "orange-link",
                      onClick: $setup.openBrowseFiles
                    }, "browse"),
                    _createTextVNode(" your files. "),
                    _createElementVNode("input", {
                      ref: "fileInput",
                      class: "d-none",
                      type: "file",
                      onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.browseFiles($event)))
                    }, null, 544 /* NEED_HYDRATION, NEED_PATCH */)
                  ])
                ], 32 /* NEED_HYDRATION */))
              : _createCommentVNode("v-if", true),
            ($setup.selectedFile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      ($setup.isImage())
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: $setup.selectedImageSrc
                          }, null, 8 /* PROPS */, _hoisted_9))
                        : _createCommentVNode("v-if", true),
                      (!$setup.isImage())
                        ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, " File Name : " + _toDisplayString($setup.selectedFile.name), 1 /* TEXT */),
                        _createElementVNode("div", null, "File Size : " + _toDisplayString($setup.selectedFile.size), 1 /* TEXT */),
                        _createElementVNode("div", null, "File Type : " + _toDisplayString($setup.selectedFile.type), 1 /* TEXT */),
                        _createElementVNode("div", null, "Last Modified : " + _toDisplayString($setup.formatLastModified()), 1 /* TEXT */),
                        _createVNode(_component_Button, {
                          class: "flex",
                          text: "",
                          size: "small",
                          severity: "danger",
                          label: "Clear file",
                          onClick: $setup.clearFile
                        })
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createVNode(_component_InputText, {
            modelValue: $setup.fileName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.fileName) = $event)),
            type: "text",
            placeholder: "File name"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_InputText, {
            modelValue: $setup.categories,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.categories) = $event)),
            type: "text",
            placeholder: "Categories"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode(_component_InputText, {
            modelValue: $setup.description,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.description) = $event)),
            type: "text",
            placeholder: "Description"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode($setup["FormDatePicker"], {
            modelValue: $setup.dateReceived,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.dateReceived) = $event)),
            placeholder: "Date Received"
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_Button, {
              label: "Upload",
              onClick: $setup.uploadFile
            }),
            _createVNode(_component_Button, {
              label: "Cancel",
              class: "accent",
              onClick: $setup.clearUpload
            })
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ], 64 /* STABLE_FRAGMENT */))
}