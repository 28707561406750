
  import { defineComponent } from 'vue';
  import RadioButton from 'primevue/radiobutton';
  import FormFieldMixin from './FormFieldMixin';

  export default defineComponent({
    components: { RadioButton },
    mixins: [FormFieldMixin],
    data() {
      return {
        internalValue: this.modelValue
      };
    },
    computed: {
      yesInputId(): string {
        return this.computedId + '-Yes';
      },
      noInputId(): string {
        return this.computedId + '-No';
      }
    }
  });
