import { Auth } from 'aws-amplify';

// potentially create a custom useFetch - https://vueuse.org/core/usefetch/#creating-a-custom-instance

// wrapper for useFetch to set crm base url
export const useCRMApi = async (path: string, options: any = {}) => {
  const crmApiUrl = process.env.VUE_APP_CRM_API_BASE_URL;

  const session = await Auth.currentSession();
  const jwtToken = session.getAccessToken().getJwtToken();

  const crmOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: 'Bearer ' + jwtToken
    }
  };

  try {
    const response = await fetch(`${crmApiUrl}${path}`, crmOptions);
    const text = await response.text(); // Parse it as text

    try {
      const json = JSON.parse(text);
      return json;
    } catch {
      // return text if not json
      return text;
    }
  } catch (error) {
    console.error(error);
  }
};
