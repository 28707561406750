import { reactive } from 'vue';
import { EmailClient, FromAddressViewModel } from '@/clients/client.gen';
import { FileParameter } from '@/clients/client.gen';

export enum EmailReferenceType {
  None,
  CaseVolunteers,
  Contact,
  CoBeneficiary
}

export const emailSenderStore = reactive({
  client: new EmailClient(),
  fromAddress: '' as string | undefined,
  fromAddresses: [] as FromAddressViewModel[],
  recipient: '' as string | undefined,
  //ccRecipients: "" as string | undefined,
  //bccRecipients: "" as string | undefined,
  emailId: undefined as number | undefined,
  body: '' as string | undefined,
  subject: '' as string | undefined,
  header: '',
  visible: false,
  readOnly: false,
  subjectPrefix: '',
  referenceId: undefined as number | undefined,
  referenceType: undefined as EmailReferenceType | undefined,

  async getData(emailId: number | undefined) {
    if (emailId) {
      //view
      const data = await this.client.getEmail(emailId);
      this.emailId = emailId;
      this.fromAddress = data.fromAddress;
      this.recipient = data.toAddresses;
      this.body = data.emailBody;
      this.subject = data.emailSubject;
      this.header = 'View email';
      this.readOnly = true;
    } else {
      //send.
      const data = await this.client.getModalData();
      this.emailId = undefined;
      this.fromAddress = data.fromAddress;
      this.fromAddresses = data.fromAddresses;
      this.header = 'Send email';
      this.readOnly = false;
    }
    // const fromAddressItem = this.fromAddresses.find((f: FromAddressViewModel) => {
    //     return f.emailAddress === this.fromAddress;
    // });
    // this.fromAddress = fromAddressItem? fromAddressItem.emailAddress: "";
    this.visible = true;
  },
  async showForSend(
    refId: number | undefined,
    refType: EmailReferenceType,
    emailAddress: string = ''
  ) {
    this.referenceId = refId;
    this.referenceType = refType;

    this.recipient = emailAddress;
    await this.getData(undefined);
  },
  async showForView(emailId: number) {
    await this.getData(emailId);
  },
  async hide() {
    this.visible = false;
  },
  async sendEmail(
    body: string,
    subject: string,
    templateId: number,
    attachments: FileParameter[] | undefined = undefined
  ) {
    if (attachments) {
      await this.client.sendEmailWithAttachments(
        this.fromAddress,
        this.recipient,
        subject,
        body,
        //, this.ccRecipients
        //, this.bccRecipients
        attachments
      );
    } else {
      await this.client.sendEmail(
        this.fromAddress,
        this.recipient,
        subject,
        body,
        null /*Template Id */,
        //, this.ccRecipients
        //, this.bccRecipients
        this.getCaseId() /* Case Id */,
        this.getContactId() /* Contact id */
      );
    }

    await this.hide();
  },
  getContactId() {
    return this.referenceType == EmailReferenceType.Contact
      ? this.referenceId
      : null;
  },
  getCaseId() {
    return this.referenceType == EmailReferenceType.CaseVolunteers ||
      this.referenceType == EmailReferenceType.CoBeneficiary
      ? this.referenceId
      : null;
  }
});
