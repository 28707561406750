import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid h-full m-0" }
const _hoisted_2 = { class: "col-4 h-full p-0" }
const _hoisted_3 = {
  class: "col-8 h-full",
  style: {"height":"calc(100%)"}
}
const _hoisted_4 = {
  class: "grid",
  style: {"height":"53%"}
}
const _hoisted_5 = { class: "col-6 h-full pt-0 p-3 pr-1" }
const _hoisted_6 = { class: "col-6 h-full pt-0 p-3 pl-1" }
const _hoisted_7 = {
  class: "grid",
  style: {"height":"49%"}
}
const _hoisted_8 = { class: "col-12 h-full p-0 pl-3 pr-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.banners, (banner, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (banner.visible)
          ? (_openBlock(), _createBlock($setup["InfoBanner"], {
              key: 0,
              items: banner.items,
              "class-name": banner.className,
              title: banner.title
            }, null, 8 /* PROPS */, ["items", "class-name", "title"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        ($setup.caseStore.loading)
          ? (_openBlock(), _createBlock($setup["Skeleton"], {
              key: 0,
              width: "100%",
              height: "100%"
            }))
          : (_openBlock(), _createBlock($setup["CaseDetails"], { key: 1 }))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            ($setup.caseStore.loading)
              ? (_openBlock(), _createBlock($setup["Skeleton"], {
                  key: 0,
                  width: "100%",
                  height: "100%"
                }))
              : _createCommentVNode("v-if", true),
            (!$setup.caseStore.loading)
              ? (_openBlock(), _createBlock($setup["CommsAndNotes"], {
                  key: 1,
                  "full-screen-dialog-visible": $setup.commsFullScreen,
                  volunteers: $setup.caseStore.matchedVolunteers,
                  onNoteCreated: $setup.onNoteCreated,
                  onOpenEmail: $setup.openEmail,
                  onSendEmail: $setup.sentEmail,
                  onFullScreen: $setup.toggleFulScreen
                }, null, 8 /* PROPS */, ["full-screen-dialog-visible", "volunteers"]))
              : _createCommentVNode("v-if", true),
            ($setup.commsFullScreen)
              ? (_openBlock(), _createBlock(_component_Dialog, {
                  key: 2,
                  visible: $setup.commsFullScreen,
                  "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (($setup.commsFullScreen) = $event)),
                  modal: "",
                  maximized: true,
                  closable: false,
                  style: {"width":"100%","height":"100%"},
                  pt: {
              header: 'p-1'
            }
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["CommsAndNotes"], {
                      "full-screen-dialog-visible": $setup.commsFullScreen,
                      volunteers: $setup.caseStore.matchedVolunteers,
                      onNoteCreated: $setup.onNoteCreated,
                      onOpenEmail: $setup.openEmail,
                      onSendEmail: $setup.sentEmail,
                      onFullScreen: $setup.toggleFulScreen
                    }, null, 8 /* PROPS */, ["full-screen-dialog-visible", "volunteers"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["visible"]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            ($setup.caseStore.loading)
              ? (_openBlock(), _createBlock($setup["Skeleton"], {
                  key: 0,
                  width: "100%",
                  height: "100%"
                }))
              : _createCommentVNode("v-if", true),
            (!$setup.caseStore.loading)
              ? (_openBlock(), _createBlock($setup["TaskList"], {
                  key: 1,
                  "case-id": $setup.caseStore.case.caseId
                }, null, 8 /* PROPS */, ["case-id"]))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            ($setup.caseStore.loading)
              ? (_openBlock(), _createBlock($setup["Skeleton"], {
                  key: 0,
                  width: "100%",
                  height: "100%"
                }))
              : _createCommentVNode("v-if", true),
            (!$setup.caseStore.loading)
              ? (_openBlock(), _createBlock($setup["CaseTabs"], {
                  key: 1,
                  "case-id": $props.id
                }, null, 8 /* PROPS */, ["case-id"]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}