import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "pet-modal_preferences" }
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = {
  class: "col-12 m-0",
  for: "additionalNotes"
}
const _hoisted_4 = { class: "col-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        $setup.isValidProp('sleepLocation') &&
        $setup.petStore.pet.petTypeId != $setup.AnimalTypeEnum.Cat &&
        $setup.petStore.pet.petTypeId != $setup.AnimalTypeEnum.Dog
      )
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 0,
          id: "sleepLocation",
          modelValue: $setup.petStore.petProfile.sleepLocation,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.petStore.petProfile.sleepLocation) = $event)),
          "label-above": "",
          label: $setup.propFieldText('sleepLocation')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('enclosedOverNight'))
      ? (_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 1,
          id: "enclosedOverNight",
          modelValue: $setup.petStore.petProfile.enclosedOverNight,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.petStore.petProfile.enclosedOverNight) = $event)),
          label: $setup.propFieldText('enclosedOverNight')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('stableType'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 2,
          id: "stableType",
          modelValue: $setup.petStore.petProfile.stableType,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.petStore.petProfile.stableType) = $event)),
          "label-above": "",
          label: $setup.propFieldText('stableType')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('leftAloneInfo'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 3,
          id: "leftAloneInfo",
          modelValue: $setup.petStore.petProfile.leftAloneInfo,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.petStore.petProfile.leftAloneInfo) = $event)),
          "label-above": "",
          label: $setup.propFieldText('leftAloneInfo')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('tvRadioLeftOn'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 4,
          id: "tvRadioLeftOn",
          modelValue: $setup.petStore.petProfile.tvRadioLeftOn,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.petStore.petProfile.tvRadioLeftOn) = $event)),
          "label-above": "",
          label: $setup.propFieldText('tvRadioLeftOn')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('allowedToFreeRoamWhenAlone'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 5,
          id: "allowedToFreeRoamWhenAlone",
          modelValue: $setup.petStore.petProfile.allowedToFreeRoamWhenAlone,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.petStore.petProfile.allowedToFreeRoamWhenAlone) = $event)),
          "label-above": "",
          label: $setup.propFieldText('allowedToFreeRoamWhenAlone')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('cleanedOutFrequency'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 6,
          id: "cleanedOutFrequency",
          modelValue: $setup.petStore.petProfile.cleanedOutFrequency,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.petStore.petProfile.cleanedOutFrequency) = $event)),
          "label-above": "",
          label: $setup.propFieldText('cleanedOutFrequency')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('beddingChangeFrequency'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 7,
          id: "beddingChangeFrequency",
          modelValue: $setup.petStore.petProfile.beddingChangeFrequency,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.petStore.petProfile.beddingChangeFrequency) = $event)),
          "label-above": "",
          label: $setup.propFieldText('beddingChangeFrequency')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('treatsWhenLeaving'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 8,
          id: "treatsWhenLeaving",
          modelValue: $setup.petStore.petProfile.treatsWhenLeaving,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.petStore.petProfile.treatsWhenLeaving) = $event)),
          "label-above": "",
          label: $setup.propFieldText('treatsWhenLeaving')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('sitByOwner'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 9,
          id: "sitByOwner",
          modelValue: $setup.petStore.petProfile.sitByOwner,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.petStore.petProfile.sitByOwner) = $event)),
          "label-above": "",
          label: $setup.propFieldText('sitByOwner')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('likesCuddles'))
      ? (_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 10,
          id: "likesCuddles",
          modelValue: $setup.petStore.petProfile.likesCuddles,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.petStore.petProfile.likesCuddles) = $event)),
          label: $setup.propFieldText('likesCuddles')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('sleepRoutine'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 11,
          id: "sleepRoutine",
          modelValue: $setup.petStore.petProfile.sleepRoutine,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.petStore.petProfile.sleepRoutine) = $event)),
          "label-above": "",
          label: $setup.propFieldText('sleepRoutine')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    (
        $setup.isValidProp('sleepLocation') &&
        ($setup.petStore.pet.petTypeId == $setup.AnimalTypeEnum.Cat ||
          $setup.petStore.pet.petTypeId == $setup.AnimalTypeEnum.Dog)
      )
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 12,
          id: "sleepLocation",
          modelValue: $setup.petStore.petProfile.sleepLocation,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.petStore.petProfile.sleepLocation) = $event)),
          "label-above": "",
          label: $setup.propFieldText('sleepLocation')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('hibernates'))
      ? (_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 13,
          id: "hibernates",
          modelValue: $setup.petStore.petProfile.hibernates,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.petStore.petProfile.hibernates) = $event)),
          label: $setup.propFieldText('hibernates')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('outdoorTime'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 14,
          id: "outdoorTime",
          modelValue: $setup.petStore.petProfile.outdoorTime,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.petStore.petProfile.outdoorTime) = $event)),
          "label-above": "",
          label: $setup.propFieldText('outdoorTime')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('responseToBeingKeptIn'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 15,
          id: "responseToBeingKeptIn",
          modelValue: $setup.petStore.petProfile.responseToBeingKeptIn,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.petStore.petProfile.responseToBeingKeptIn) = $event)),
          "label-above": "",
          label: $setup.propFieldText('responseToBeingKeptIn')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('scratchFurniture'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 16,
          id: "scratchFurniture",
          modelValue: $setup.petStore.petProfile.scratchFurniture,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.petStore.petProfile.scratchFurniture) = $event)),
          "label-above": "",
          label: $setup.propFieldText('scratchFurniture')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('scratchingPost'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 17,
          id: "scratchingPost",
          modelValue: $setup.petStore.petProfile.scratchingPost,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.petStore.petProfile.scratchingPost) = $event)),
          "label-above": "",
          label: $setup.propFieldText('scratchingPost')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('dislikes'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 18,
          id: "dislikes",
          modelValue: $setup.petStore.petProfile.dislikes,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($setup.petStore.petProfile.dislikes) = $event)),
          "label-above": "",
          label: $setup.propFieldText('dislikes')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('likes'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 19,
          id: "likes",
          modelValue: $setup.petStore.petProfile.likes,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.petStore.petProfile.likes) = $event)),
          "label-above": "",
          label: $setup.propFieldText('likes')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('favouriteToy'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 20,
          id: "favouriteToy",
          modelValue: $setup.petStore.petProfile.favouriteToy,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.petStore.petProfile.favouriteToy) = $event)),
          "label-above": "",
          label: $setup.propFieldText('favouriteToy')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString($setup.propFieldText('additionalNotes')), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Textarea, {
          id: "additionalNotes",
          modelValue: $setup.petStore.petProfile.additionalNotes,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($setup.petStore.petProfile.additionalNotes) = $event)),
          rows: "10",
          cols: "60"
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, $setup.isValidProp('additionalNotes')]
    ]),
    _createVNode($setup["PetsKeepTogethersList"])
  ]))
}