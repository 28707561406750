
  import { defineComponent } from 'vue';
  import {
    FlattenedIncomeDto,
    IncomeClient,
    IncomeLineItemDto,
    SimpleProductModel
  } from '@/clients/client.gen';
  import { FilterMatchMode } from 'primevue/api';
  import { singleIncomeStore } from '../../../../stores/singleIncome.store';
  export default defineComponent({
    props: {
      readOnly: Boolean
    },
    emits: ['update:modelValue', 'progress-stepper'],
    data(): any {
      return {
        selectedItem: {} as SimpleProductModel,
        item: '',
        quantity: 0,
        unitPrice: 0,
        catalogueItems: [] as Array<SimpleProductModel>,
        singleIncomeStore,
        pice100CT: 0.0,
        membershipProducts: [
          { id: 33528991, name: 'Memberships - Renewal', price: 10 },
          { id: 33529008, name: 'Memberships - Renewal - OAP', price: 5 },
          { id: 33529035, name: 'Memberships - New', price: 10 },
          { id: 33529056, name: 'Memberships - New - OAP', price: 5 },
          { id: 33529168, name: 'Memberships - Life Member', price: 100 }
        ],
        filters: {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          catalogueCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
          itemName: { value: null, matchMode: FilterMatchMode.CONTAINS },
          quantity: { value: null, matchMode: FilterMatchMode.CONTAINS }
        }
      };
    },
    computed: {
      pice100CTString(): string {
        return this.pice100CT ? `£${this.pice100CT}` : '';
      },
      isAddDisabled(): boolean {
        return !this.selectedItem.id || !this.quantity;
      },
      income: {
        get(): FlattenedIncomeDto {
          return singleIncomeStore.income;
        },
        set(newVal: FlattenedIncomeDto) {
          singleIncomeStore.setIncome(newVal);
        }
      }
    },
    async created() {
      this.pice100CT = await singleIncomeStore.getPice100CT();
      this.income.lineItems = this.income?.lineItems ?? [];
      this.income.membershipProductId = this.income?.membershipProductId ?? 0;
      this.income.membershipProductQuantity =
        this.income?.membershipProductQuantity ?? 0;
      this.income.fundsRaised = this.income?.fundsRaised ?? 0;
      this.income.donationAmount = this.income?.donationAmount ?? 0;
      this.income.c100Annual = this.income?.c100Annual ?? false;

      await this.getCatalogueItems();
    },
    methods: {
      deleteItem(item: any) {
        const indexToRemove =
          this.singleIncomeStore.income.lineItems.indexOf(item);
        if (indexToRemove !== -1) {
          this.singleIncomeStore.income.lineItems.splice(indexToRemove, 1);
          this.updateVal();
        }
      },
      addNewItem() {
        const item = new IncomeLineItemDto();
        item.catalogueCode = this.selectedItem.code;
        item.itemName = this.selectedItem.name;
        item.unitPrice = this.selectedItem.selling_price;
        item.sageProductId = this.selectedItem.id;
        item.quantity = this.quantity;
        this.singleIncomeStore.income.lineItems ??= [];
        this.singleIncomeStore.income.lineItems.push(Object.assign({}, item));
        console.log(this.singleIncomeStore.income.lineItems);
        this.selectedItem = new SimpleProductModel();
        this.item = '';
        this.quantity = 0;
        this.unitPrice = 0;
        this.updateVal();
      },
      async getCatalogueItems() {
        try {
          this.loadingCatalogueItems = true;
          const client = new IncomeClient();
          const response = await client.getCatalogueItems();
          this.catalogueItems = response;
          this.loadingCatalogueItems = false;
        } catch (e) {
          console.log(e);
          this.catalogueItems = [];
          this.loadingCatalogueItems = false;
        }
      },
      updateVal() {
        const newIncome = Object.assign({}, this.income);
        this.$emit('update:modelValue', newIncome);
      }
    }
  });
