import { reactive } from 'vue';
import {
  AgeRangeDropDownValues,
  DropDownValues,
  ExpenseClient,
  PetBreedDto,
  UserTaskStatusDto,
  StringDropDownValues,
  DropDownClient,
  UserTaskTypeDto,
  RepeatableTaskFrequencyDto,
  PetClient,
  ContactRelationshipTypeDto,
  CaseStageDto,
  CaseStatusDto,
  CaseTypeDto,
  PetTypeDto,
  PetGenderDto,
  PetSizeDto,
  PetProfileStatusDto,
  PetStrengthDto,
  PetCharacteristicDto,
  PetAgeCategoryDto
} from '@/clients/client.gen';
const expenseClient = new ExpenseClient();
export const dropdownsStore = reactive({
  taskStatuses: [] as Array<UserTaskStatusDto>,
  taskTypes: [] as Array<UserTaskTypeDto>,
  contactTypes: [] as Array<DropDownValues>,
  caseVolunteerStatuses: [] as Array<DropDownValues>,
  volunteerStatuses: [] as Array<DropDownValues>,
  workStatuses: [] as Array<DropDownValues>,
  awards: [] as Array<DropDownValues>,
  dofELevels: [] as Array<DropDownValues>,
  ageRanges: [] as Array<AgeRangeDropDownValues>,
  titles: [] as Array<DropDownValues>,
  contactSources: [] as Array<DropDownValues>,
  contactWorkTypes: [] as Array<DropDownValues>,
  longTermFosterCaseStages: [] as Array<DropDownValues>,
  longTermFosterStatuses: [] as Array<DropDownValues>,
  referenceStatuses: [] as Array<DropDownValues>,
  caseStages: [] as Array<CaseStageDto>,
  caseStatuses: [] as Array<CaseStatusDto>,
  caseTypes: [] as Array<CaseTypeDto>,
  singlePaymentMethods: [] as Array<DropDownValues>,
  recurringPaymentMethods: [] as Array<DropDownValues>,
  incomeSources: [] as Array<DropDownValues>,
  paymentFrequencies: [] as Array<DropDownValues>,
  orderStatuses: [] as Array<DropDownValues>,
  incomeTypes: [] as Array<DropDownValues>,
  nominalCodes: {} as Array<StringDropDownValues>,
  costCentres: {} as Array<StringDropDownValues>,
  propertyTypes: [] as Array<DropDownValues>,
  ownGardenDetails: [] as Array<DropDownValues>,
  expenseTypes: [] as Array<DropDownValues>,
  repeatableTaskFrequencies: [] as Array<RepeatableTaskFrequencyDto>,
  contactRelationshipTypes: [] as Array<ContactRelationshipTypeDto>,

  petBreeds: [] as Array<PetBreedDto>,
  petTypes: [] as Array<PetTypeDto>,
  petGenders: [] as Array<PetGenderDto>,
  petSizes: [] as Array<PetSizeDto>,
  petProfileStatuses: [] as Array<PetProfileStatusDto>,
  petStrengths: [] as Array<PetStrengthDto>,
  petCharacteristics: [] as Array<PetCharacteristicDto>,
  petAgeCategories: [] as Array<PetAgeCategoryDto>,

  async loadDropDowns() {
    try {
      const client = new DropDownClient();
      const dropdowns = await client.getDropdowns();
      this.taskStatuses = dropdowns.userTaskStatuses;
      this.taskTypes = dropdowns.userTaskTypes;
      this.contactTypes = dropdowns.contactTypes;
      this.caseVolunteerStatuses = dropdowns.caseVolunteerStatuses;
      this.volunteerStatuses = dropdowns.volunteerStatuses;
      this.workStatuses = dropdowns.workStatuses;
      this.awards = dropdowns.awards;
      this.dofELevels = dropdowns.dofELevels;
      this.ageRanges = dropdowns.ageRanges;
      this.titles = dropdowns.titles;
      this.contactSources = dropdowns.contactSources;
      // this.contactWorkTypes = dropdowns.contactWorkTypes;
      this.longTermFosterCaseStages = dropdowns.longTermFosterCaseStages;
      this.longTermFosterStatuses = dropdowns.longTermFosterStatuses;
      this.referenceStatuses = dropdowns.referenceStatuses;
      this.caseStages = dropdowns.caseStages;
      this.caseStatuses = dropdowns.caseStatuses;
      this.caseTypes = dropdowns.caseTypes;

      this.singlePaymentMethods = dropdowns.singlePaymentMethods;
      this.recurringPaymentMethods = dropdowns.recurringPaymentMethods;
      this.incomeSources = dropdowns.incomeSources;
      this.paymentFrequencies = dropdowns.paymentFrequencies;
      this.orderStatuses = dropdowns.orderStatuses;
      this.incomeTypes = dropdowns.incomeTypes;
      this.propertyTypes = dropdowns.propertyTypes;
      this.ownGardenDetails = dropdowns.ownGardenDetails;
      this.repeatableTaskFrequencies = dropdowns.repeatableTaskFrequencies;

      //This will need moving as well...
      this.contactRelationshipTypes = dropdowns.contactRelationshipTypes;

      //Expense stuff -> These can be a little weird, we'll need to evaluate them for this new structure, so i've left them as is for now.
      // this.nominalCodes = dropdowns.nominalCodes;
      // this.costCentres = dropdowns.costCentres;
      // this.expenseTypes = dropdowns.expenseTypes;

      //Pet breeds can be user added to so it fetched directly each time
      //this.petBreeds = dropdowns.petBreeds;
      this.petTypes = dropdowns.petTypes;
      this.petGenders = dropdowns.petGenders;
      this.petSizes = dropdowns.petSizes;
      this.petProfileStatuses = dropdowns.petProfileStatuses;
      this.petStrengths = dropdowns.petStrengths;
      this.petCharacteristics = dropdowns.petCharacteristics;
      this.petAgeCategories = dropdowns.petAgeCategories;
    } catch (error) {
      console.log(
        'error occurred while retrieving/parsing dropdown data.',
        error
      );
    }
  },
  //I've left most of these methods in place for now, as they're used throughout the system, so to prevent having to do a lot of extra work for now.
  async getNominalCodes(): Promise<Array<StringDropDownValues>> {
    if (!this.nominalCodes.length) {
      this.nominalCodes = await expenseClient.getNominalCodes();
    }
    return [...this.nominalCodes];
  },
  async getCostCentres(
    nominalCode: string
  ): Promise<Array<StringDropDownValues>> {
    this.costCentres = await expenseClient.getCostCentres(nominalCode);
    return [...this.costCentres];
  },
  async getPaymentFrequencies(): Promise<DropDownValues[]> {
    return [...this.paymentFrequencies];
  },
  async getOrderStatuses(): Promise<DropDownValues[]> {
    return [...this.orderStatuses];
  },
  async getSinglePaymentMethods(): Promise<DropDownValues[]> {
    return [...this.singlePaymentMethods];
  },
  async getRecurringPaymentMethods(): Promise<DropDownValues[]> {
    return [...this.recurringPaymentMethods];
  },
  async getIncomeSources(): Promise<DropDownValues[]> {
    return [...this.incomeSources];
  },
  async getIncomeTypes(): Promise<DropDownValues[]> {
    return [...this.incomeTypes];
  },
  async getTaskStatuses(): Promise<UserTaskStatusDto[]> {
    return [...this.taskStatuses];
  },
  async getLongTermFosterCaseStages(): Promise<DropDownValues[]> {
    return [...this.longTermFosterCaseStages];
  },
  async getLongTermFosterStatuses(): Promise<DropDownValues[]> {
    return [...this.longTermFosterStatuses];
  },
  async getTitles(): Promise<DropDownValues[]> {
    return [...this.titles];
  },
  async getContactWorkTypes(): Promise<DropDownValues[]> {
    return [...this.contactWorkTypes];
  },
  async getContactSources(): Promise<DropDownValues[]> {
    return [...this.contactSources];
  },
  async getVolunteerStatuses(): Promise<DropDownValues[]> {
    return [...this.volunteerStatuses];
  },
  async getCaseVolunteerStatuses(): Promise<DropDownValues[]> {
    return [...this.caseVolunteerStatuses];
  },
  async getCaseStages(): Promise<CaseStageDto[]> {
    return [...this.caseStages];
  },
  async getCaseStatuses(): Promise<CaseStatusDto[]> {
    return [...this.caseStatuses];
  },
  async getCaseTypes(): Promise<CaseTypeDto[]> {
    return [...this.caseTypes];
  },
  async getReferenceStatuses(): Promise<DropDownValues[]> {
    return [...this.referenceStatuses];
  },
  async getWorkStatuses(): Promise<DropDownValues[]> {
    return [...this.workStatuses];
  },
  async getAwards(): Promise<DropDownValues[]> {
    return [...this.awards];
  },
  async getDofELevels(): Promise<DropDownValues[]> {
    return [...this.dofELevels];
  },
  async getAgeRanges(): Promise<AgeRangeDropDownValues[]> {
    return [...this.ageRanges];
  },
  async getContactTypes(): Promise<DropDownValues[]> {
    return [...this.contactTypes];
  },
  async getTaskTypes(): Promise<UserTaskTypeDto[]> {
    return [...this.taskTypes];
  },
  async getExpenseTypes(): Promise<DropDownValues[]> {
    if (!this.expenseTypes.length) {
      const client = new ExpenseClient();
      const data = await client.getDropDowns();
      if (data.result?.expenseTypes) {
        this.expenseTypes = data.result?.expenseTypes;
      }
    }
    return [...this.expenseTypes];
  },
  async getPropertyTypes() {
    return [...this.propertyTypes];
  },
  async getPetBreeds(): Promise<PetBreedDto[]> {
    if (!this.petBreeds.length) {
      const petClient = new PetClient();
      this.petBreeds = await petClient.getPetBreeds();
    }
    return [...this.petBreeds];
  }
});
