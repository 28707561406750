import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "pet-modal_food-nutrition" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isValidProp('regularFood'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 0,
          id: "regularFood",
          modelValue: $setup.petStore.petProfile.regularFood,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.petStore.petProfile.regularFood) = $event)),
          "label-above": "",
          label: $setup.propFieldText('regularFood')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('feedingFrequency'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 1,
          id: "feedingFrequency",
          modelValue: $setup.petStore.petProfile.feedingFrequency,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.petStore.petProfile.feedingFrequency) = $event)),
          "label-above": "",
          label: $setup.propFieldText('feedingFrequency')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('whenFed'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 2,
          id: "whenFed",
          modelValue: $setup.petStore.petProfile.whenFed,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.petStore.petProfile.whenFed) = $event)),
          "label-above": "",
          label: $setup.propFieldText('whenFed')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('dietaryRequirements'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 3,
          id: "dietaryRequirements",
          modelValue: $setup.petStore.petProfile.dietaryRequirements,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.petStore.petProfile.dietaryRequirements) = $event)),
          "label-above": "",
          label: $setup.propFieldText('dietaryRequirements')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('seasonalFoods'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 4,
          id: "seasonalFoods",
          modelValue: $setup.petStore.petProfile.seasonalFoods,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.petStore.petProfile.seasonalFoods) = $event)),
          "label-above": "",
          label: $setup.propFieldText('seasonalFoods')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('whereFed'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 5,
          id: "whereFed",
          modelValue: $setup.petStore.petProfile.whereFed,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.petStore.petProfile.whereFed) = $event)),
          "label-above": "",
          label: $setup.propFieldText('whereFed')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('eatsAllFoodOrComesAndGoes'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 6,
          id: "eatsAllFoodOrComesAndGoes",
          modelValue: $setup.petStore.petProfile.eatsAllFoodOrComesAndGoes,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.petStore.petProfile.eatsAllFoodOrComesAndGoes) = $event)),
          "label-above": "",
          label: $setup.propFieldText('eatsAllFoodOrComesAndGoes')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('favouriteTreats'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 7,
          id: "favouriteTreats",
          modelValue: $setup.petStore.petProfile.favouriteTreats,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.petStore.petProfile.favouriteTreats) = $event)),
          "label-above": "",
          label: $setup.propFieldText('favouriteTreats')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('treatsGivenReasons'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 8,
          id: "treatsGivenReasons",
          modelValue: $setup.petStore.petProfile.treatsGivenReasons,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.petStore.petProfile.treatsGivenReasons) = $event)),
          "label-above": "",
          label: $setup.propFieldText('treatsGivenReasons')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true)
  ]))
}