import { serialize } from 'object-to-formdata';
import dayjs from 'dayjs';
import { DropDownValues } from '@/clients/client.gen';

export const getFormData = (object: any) => {
  const options = {
    noFilesWithArrayNotation: true,
    indices: true
  };
  const formData = serialize(object, options);

  console.log('form data...');
  console.log([...formData]);

  return formData;
};

export const formatDate = (
  dateToFormat: string | number | Date | dayjs.Dayjs | null | undefined,
  format: string = 'DD/MM/YYYY'
) => {
  return dateToFormat ? dayjs(dateToFormat).format(format) : null;
};
export const toGBDateString = (date?: Date) => {
  if (!date) return '';

  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};
export const formatCurrency = (value: number | undefined) => {
  return value != undefined
    ? value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
    : 0;
};
export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};
export const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};
export const isWithinWeek = (date: Date): boolean => {
  const lastWeek = dayjs(new Date()).subtract(7, 'days');
  return dayjs(date) >= lastWeek;
};

export const getAge = (
  dateOfBirth: Date | undefined,
  endDate: Date = new Date()
): number => {
  if (!dateOfBirth) return 0;
  const diff = endDate.getTime() - dateOfBirth.getTime();
  const age = Math.floor(diff / (365.25 * 24 * 60 * 60 * 1000));
  return age < 0 ? 0 : age;
};

export const getDobFromAge = (
  age: number | undefined,
  dateOfBirth: Date | undefined,
  endDate: Date = new Date()
): Date => {
  const currentYear = dayjs(endDate).year();
  let birthYear = currentYear - (age ?? 0);

  // Check if the birthday has passed this year
  if (dateOfBirth) {
    const birthDateThisYear = dayjs(
      new Date(currentYear, dateOfBirth.getMonth(), dateOfBirth.getDate())
    );
    if (birthDateThisYear.isAfter(endDate)) {
      birthYear--;
    }
  }

  const month = dateOfBirth?.getMonth() ?? endDate.getMonth();
  const date = dateOfBirth?.getDate() ?? endDate.getDate();

  const birthDate = dayjs(new Date(birthYear, month, date));
  return birthDate.toDate();
};

// Method to copy fields from a JavaScript object
export const copyFieldsFrom = <T>(existingObject: any, targetObject: T): T => {
  for (const key in existingObject) {
    if (Object.prototype.hasOwnProperty.call(existingObject, key)) {
      // Check if the key exists in the targetObject
      if ((targetObject as any).hasOwnProperty(key)) {
        // Copy the value from the existingObject to the targetObject
        (targetObject as any)[key] = existingObject[key];
      }
    }
  }
  return targetObject;
};

export const mapEnumToObjects = (enumObj: any): DropDownValues[] => {
  const result = new Array<DropDownValues>();

  for (const key in enumObj) {
    if (isNaN(Number(key))) {
      const value = new DropDownValues();
      value.name = key;
      value.id = enumObj[key];
      result.push(value);
    }
  }

  return result;
};

export const formatTelephone = (inputString: string | undefined): string => {
  if (!inputString) {
    return '';
  }
  // Remove non-digit characters from the input string
  const digitsOnly = inputString.replace(/\D/g, '');

  // Extract the first 5 digits, next 3 digits, and the remaining digits
  const firstPart = digitsOnly.substring(0, 5);
  const secondPart = digitsOnly.substring(5, 8);
  const remainingPart = digitsOnly.substring(8);

  // Construct the formatted string
  const formattedString = `${firstPart}-${secondPart}-${remainingPart}`;

  return formattedString;
};

export const isRowDeleted = (data: Partial<{ isDeleted: boolean }>): string => {
  if (data && data.isDeleted) {
    return 'row-deleted';
  }

  return '';
};

export const createDebounce = () => {
  let debounceTimer: NodeJS.Timeout | null = null;
  return (callback: () => void | Promise<void>) => {
    clearTimeout(debounceTimer as NodeJS.Timeout);
    // Set a new debounce timer
    debounceTimer = setTimeout(() => {
      callback();
      // Clear the debounce timer
    }, 500); // Adjust the delay as needed (250ms in this example)
  };
};

export const deepEqual = (obj1: unknown, obj2: unknown): boolean => {
  if (obj1 === obj2) {
    // Both are identical
    return true;
  }

  if (
    typeof obj1 !== 'object' ||
    obj1 === null ||
    typeof obj2 !== 'object' ||
    obj2 === null
  ) {
    // One is not an object or is null (not equal)
    return false;
  }

  // Both are objects and not null
  const obj1Keys = Object.keys(obj1 as Record<string, unknown>);
  const obj2Keys = Object.keys(obj2 as Record<string, unknown>);

  // Check if both objects have the same number of keys
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  // Check if all keys and values are identical
  for (const key of obj1Keys) {
    if (!obj2Keys.includes(key)) {
      return false;
    }
    // Deep compare the values of the keys
    const obj1Value = (obj1 as Record<string, unknown>)[key];
    const obj2Value = (obj2 as Record<string, unknown>)[key];
    if (!deepEqual(obj1Value, obj2Value)) {
      return false;
    }
  }

  return true;
};
