import { petStore } from '@/stores/pet.store';

export const isValidProp = (propName: string): boolean => {
  const value = petStore.petTypeProperties[propName.toLocaleLowerCase()];

  return value !== undefined;
};

export const propFieldText = (propName: string): string => {
  const value = petStore.petTypeProperties[propName.toLocaleLowerCase()];
  return value ?? '';
};
