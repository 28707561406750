import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, _mergeProps(_ctx.$attrs, {
    "model-value": $setup.modelValue,
    options: $setup.groupedUsers,
    filter: "",
    "option-label": "name",
    "option-group-label": "label",
    "option-group-children": "items",
    placeholder: $setup.props.placeholder,
    class: "w-full",
    loading: $setup.loading,
    disabled: $setup.props.readOnly,
    "onUpdate:modelValue": $setup.onSelected
  }), null, 16 /* FULL_PROPS */, ["model-value", "options", "placeholder", "loading", "disabled"]))
}