import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentList = _resolveComponent("DocumentList")!

  return (_openBlock(), _createBlock(_component_DocumentList, {
    ref: "documentList",
    documents: _ctx.caseStore.documents.documents,
    folders: _ctx.caseStore.documents.folders,
    class: "mr-2",
    onRequestUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.caseStore.getDocumentData(_ctx.caseStore.case.caseId))),
    onUploadFile: _ctx.uploadFile
  }, null, 8 /* PROPS */, ["documents", "folders", "onUploadFile"]))
}