import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex justify-content-between align-content-center" }
const _hoisted_4 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("h1", null, _toDisplayString($setup.searchStore.selectedOption.searchTitle), 1 /* TEXT */)
        ]),
        ($props.showClose)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_Button, {
                icon: "pi pi-times",
                text: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('close-clicked')))
              })
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.parameters, (parameter, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "w-full grid"
        }, [
          _createVNode($setup["SearchFilter"], {
            "model-value": parameter,
            fields: $props.fields,
            "set-defaults": index === 0,
            index: index,
            onRemove: $setup.removeFilter,
            "onUpdate:modelValue": ($event: any) => ($setup.updateFilter($event, index))
          }, null, 8 /* PROPS */, ["model-value", "fields", "set-defaults", "index", "onUpdate:modelValue"])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("a", {
      class: "blue-link",
      onClick: $setup.addParameter
    }, "+ Add condition"),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Button, {
        label: "Search",
        onClick: $setup.performSearch
      })
    ])
  ]))
}