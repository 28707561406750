
  import { defineComponent } from 'vue';
  import IncomeGrid from '@/components/Financial/Income/IncomeGrid.vue';
  import { FlattenedIncomeDto, IncomeClient } from '@/clients/client.gen';
  import { supplierStore } from '@/stores/supplier.store';

  interface IIncomeTabData {
    incomes: Array<FlattenedIncomeDto>;
  }

  export default defineComponent({
    components: {
      IncomeGrid
    },
    data(): IIncomeTabData {
      return {
        incomes: new Array<FlattenedIncomeDto>()
      };
    },
    async created() {
      await this.getData();
    },
    methods: {
      async saveIncome(income: FlattenedIncomeDto) {
        const client = new IncomeClient();
        income.supplierId = supplierStore.supplier.supplierId;
        await client.addNewIncome(income);
        await this.getData();
      },
      async updateIncome(income: FlattenedIncomeDto) {
        income.supplierId = supplierStore.supplier.supplierId;
        const client = new IncomeClient();
        await client.updateIncomeComm(income);
        await this.getData();
      },
      async getData() {
        const client = new IncomeClient();
        this.incomes = await client.getForSupplier(
          supplierStore.supplier.supplierId
        );
      }
    }
  });
