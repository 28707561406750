import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-column pb-4 pr-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.notifications, (notification) => {
      return (_openBlock(), _createBlock($setup["NotificationDetail"], {
        key: notification.userNotificationId,
        notification: notification,
        onReadToggled: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('fetchDashboardData'))),
        onFlagToggled: _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('fetchDashboardData')))
      }, null, 8 /* PROPS */, ["notification"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}