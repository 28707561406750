
  import { defineComponent } from 'vue';
  import { supplierStore } from '../../stores/supplier.store';
  import SupplierDetails from './SupplierDetails.vue';
  import SupplierTabbedContent from './SupplierTabbedContent.vue';
  import CommsAndNotes from '@/components/Common/Notes/CommsAndNotes.vue';
  import Skeleton from 'primevue/skeleton';
  import { NoteDto } from '@/clients/client.gen';
  import {
    emailSenderStore,
    EmailReferenceType
  } from '@/stores/emailSender.store';
  import {
    commsAndNotesStore,
    AddNoteDto
  } from '../../stores/commsAndNotes.store';
  export default defineComponent({
    components: {
      CommsAndNotes,
      SupplierDetails,
      SupplierTabbedContent,
      Skeleton
    },
    props: {
      id: String
    },
    data(): any {
      return {
        supplierStore
      };
    },
    computed: {
      displaySecondaryContacts(): boolean {
        return this.id;
      },
      notes(): NoteDto[] {
        return supplierStore.notes ?? [];
      }
    },
    async created() {
      await this.getData();
    },
    methods: {
      async getData() {
        const id = this.id === undefined ? 0 : this.id;
        if (this.supplierStore.supplier.supplierId !== id) {
          await this.supplierStore.getData(+id);
        }
        supplierStore.loading = false;
      },
      async openEmail() {
        await emailSenderStore.showForSend(
          supplierStore.supplier.supplierId,
          EmailReferenceType.None,
          supplierStore.supplier.emailAddress
        );
      },
      async sentEmail() {
        await this.refreshCommsList();
      },
      async refreshCommsList() {
        const data = await commsAndNotesStore.searchContactNotes(
          supplierStore.supplier.supplierId
        );
        await commsAndNotesStore.setNotes(data);
      },
      async onNoteCreated(note: AddNoteDto, files?: Array<File>) {
        //Add supplier id to the new note.
        note.noteSupplierIds = [supplierStore.supplier.supplierId];

        const filesToUpload = files?.map((f: File) => {
          return {
            data: f,
            fileName: f.name
          };
        });

        const result = await commsAndNotesStore.createNewNote(
          note,
          filesToUpload
        );

        try {
          //emit an event so the calling component can add it to their front-end.
          if (result !== undefined) {
            commsAndNotesStore.notes.unshift(result);
          }
        } catch (ex) {
          this.$toast.add({
            severity: 'warning',
            summary: 'Unknown issue.',
            detail:
              'The note was added but we cannot display it, please refresh your page to see it.',
            life: 3000
          });
          console.log(ex);
        }
      }
    }
  });
