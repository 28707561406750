import { Component } from 'vue';
import GeneralInformationVue from './GeneralInformation.vue';
import HistoryInformationVue from './HistoryInformation.vue';
import BehaviourInformationVue from './BehaviourInformation.vue';
import TravelAndWalkingVue from './TravelAndWalking.vue';
import HealthAndMobilityVue from './HealthAndMobility.vue';
import VetInformationVue from './VetInformation.vue';
import FoodAndNutritionVue from './FoodAndNutrition.vue';
import PreferencesInformationVue from './PreferencesInformation.vue';

export interface PetFormStep {
  label: string;
  component: Component;
}

export const availablePetForms: PetFormStep[] = [
  {
    label: 'General Information',
    component: GeneralInformationVue
  },
  {
    label: 'History',
    component: HistoryInformationVue
  },
  {
    label: 'Behaviours',
    component: BehaviourInformationVue
  },
  {
    label: 'Travel & Walking',
    component: TravelAndWalkingVue
  },
  {
    label: 'Health & Mobility',
    component: HealthAndMobilityVue
  },
  {
    label: 'Vets & Welfare',
    component: VetInformationVue
  },
  {
    label: 'Food & Nutrition',
    component: FoodAndNutritionVue
  },
  {
    label: 'Preferences',
    component: PreferencesInformationVue
  }
];

export { default as GeneralInformation } from './GeneralInformation.vue';
export { default as HistoryInformation } from './HistoryInformation.vue';
export { default as BehaviourInformation } from './BehaviourInformation.vue';
export { default as TravelAndWalking } from './TravelAndWalking.vue';
export { default as healthAndMobility } from './HealthAndMobility.vue';
export { default as VetInformation } from './VetInformation.vue';
export { default as FoodAndNutrition } from './FoodAndNutrition.vue';
export { default as preferencesInformation } from './PreferencesInformation.vue';
