
  import { defineComponent } from 'vue';
  import EmailDetails from './EmailDetails.vue';
  import EmailPreview from './EmailPreview.vue';
  import { emailStore } from '../../stores/email.store';
  export default defineComponent({
    components: { EmailDetails, EmailPreview },
    props: ['id'],
    created() {
      emailStore.getEmail(this.id);
      emailStore.getPlaceholders();
    }
  });
