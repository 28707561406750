import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"calc(100% - 56px)"} }
const _hoisted_2 = { class: "flex justify-content-between align-items-center m-2" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["Button"], {
        icon: "pi pi-plus",
        size: "small",
        label: "Request new",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showRequestNew()))
      })
    ]),
    _createVNode(_component_DataTable, {
      loading: $setup.loading,
      paginator: "",
      rows: 4,
      value: $setup.contacts,
      "data-key": "homeCheckId",
      size: "small",
      scrollable: "",
      "scroll-height": "flex"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          sortable: "",
          field: "checkStatusName",
          header: "Status"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "assignedName",
          header: "Assigned To"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "fullName",
          header: "Volunteer name"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "telephone",
          header: "Telephone"
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString($setup.formatTelephone(slotProps.data.telephone)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "telephone",
          header: "Other Telephone"
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString($setup.formatTelephone(slotProps.data.telephone)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "email",
          header: "Email"
        }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("a", {
              onClick: ($event: any) => (
              $setup.emailVolunteer(
                slotProps.data.email,
                slotProps.data.contactHomecheckId
              )
            )
            }, _toDisplayString(slotProps.data.email), 9 /* TEXT, PROPS */, _hoisted_3)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, { header: "Passed or Decliend" }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString($setup.displayPassedOrDeclined(slotProps.data.checkStatusId)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, { header: "" }, {
          body: _withCtx((slotProps) => [
            _createVNode($setup["EllipsisButton"], null, {
              default: _withCtx(() => [
                _createVNode($setup["Button"], {
                  class: "flex",
                  text: "",
                  size: "small",
                  label: "Email volunteer",
                  onClick: 
                () =>
                  $setup.emailVolunteer(
                    slotProps.data.email,
                    slotProps.data.contactHomecheckId
                  )
              
                }, null, 8 /* PROPS */, ["onClick"]),
                _createVNode($setup["Button"], {
                  class: "flex",
                  text: "",
                  size: "small",
                  label: "View/Edit",
                  onClick: () => $setup.editHomecheck(slotProps.data.contactHomecheckId)
                }, null, 8 /* PROPS */, ["onClick"]),
                _createVNode($setup["Button"], {
                  class: "flex",
                  text: "",
                  size: "small",
                  severity: "danger",
                  label: "Unlink",
                  onClick: ($event: any) => ($setup.removeHomeCheck(slotProps.data.contactHomecheckId))
                }, null, 8 /* PROPS */, ["onClick"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading", "value"]),
    ($setup.isVolunteerPickerVisible)
      ? (_openBlock(), _createBlock($setup["VolunteerPicker"], {
          key: 0,
          ref: "volunteerPicker",
          "contact-id": $setup.contactId,
          "include-central-contact": true,
          title: "Search for homecheck volunteers",
          onAssign: $setup.assignVolunteers,
          onCancel: _cache[1] || (_cache[1] = ($event: any) => ($setup.isVolunteerPickerVisible = false))
        }, null, 8 /* PROPS */, ["contact-id"]))
      : _createCommentVNode("v-if", true),
    ($setup.homecheckToEdit)
      ? (_openBlock(), _createBlock($setup["HomecheckContact"], {
          key: 1,
          homecheck: $setup.homecheckToEdit,
          onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.homecheckToEdit = undefined)),
          onUpdate: $setup.getContacts
        }, null, 8 /* PROPS */, ["homecheck"]))
      : _createCommentVNode("v-if", true)
  ]))
}