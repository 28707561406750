
  import { defineComponent } from 'vue';
  import {
    supplierStore,
    SupplierContactDto
  } from '../../stores/supplier.store';
  import Dialog from 'primevue/dialog';
  import FormField from '../Common/FormField.vue';
  interface Data {
    internalValue: boolean;
    contact: SupplierContactDto;
  }

  export default defineComponent({
    components: { Dialog, FormField },
    props: {
      modelValue: {
        type: Boolean,
        required: false
      }
    },
    data(): Data {
      return {
        internalValue: this.modelValue,
        contact: new SupplierContactDto()
      };
    },
    computed: {
      secondaryContacts() {
        return supplierStore.supplier.secondarySupplierContacts;
      }
    },
    watch: {
      modelValue(newVal) {
        this.internalValue = newVal;
      },
      internalValue(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    },
    methods: {
      async onAddClick() {
        await supplierStore.addSupplierContact(this.contact);

        this.internalValue = false;
      }
    }
  });
