import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      paginator: "",
      rows: 3,
      loading: _ctx.isLoading,
      value: _ctx.pets,
      "data-key": "petId",
      class: "col-7 mb-3 p-datatable-sm"
    }, {
      default: _withCtx(() => [
        (!_ctx.hidePetPhotoColumn)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              field: "petPhoto",
              header: ""
            }, {
              body: _withCtx(({ data }) => [
                (data.petPhoto)
                  ? (_openBlock(), _createBlock(_component_Avatar, {
                      key: 0,
                      image: data.petPhoto,
                      size: "xlarge",
                      shape: "circle"
                    }, null, 8 /* PROPS */, ["image"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_Column, {
          sortable: "",
          field: "petName",
          header: "Name"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "petType",
          header: "Pet Type"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "petBreed",
          header: "Breed"
        }),
        _createVNode(_component_Column, { header: "" }, {
          body: _withCtx(({ data }) => [
            _createVNode(_component_router_link, {
              target: "_blank",
              class: "flex flex-row-reverse",
              to: { name: 'pets', params: { id: data.petId } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  size: "small",
                  severity: "warning"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" View Pet ")
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading", "value"])
  ]))
}