
  import { defineComponent } from 'vue';
  import { FlattenedIncomeDto } from '@/clients/client.gen';
  import { singleIncomeStore } from '../../../../stores/singleIncome.store';
  export default defineComponent({
    emits: ['update:modelValue', 'progress-stepper'],
    computed: {
      income: {
        get(): FlattenedIncomeDto {
          return singleIncomeStore.income;
        },
        set(newVal: FlattenedIncomeDto) {
          singleIncomeStore.setIncome(newVal);
        }
      }
    },
    methods: {
      selectSingleIncome() {
        const newIncome = Object.assign({}, this.income);
        newIncome.incomeMode = 1;
        this.$emit('update:modelValue', newIncome);
        this.$emit('progress-stepper');
      },
      selectRecurringIncome() {
        const newIncome = Object.assign({}, this.income);
        newIncome.incomeMode = 2;
        this.$emit('update:modelValue', newIncome);
        this.$emit('progress-stepper');
      }
    }
  });
