
  import { defineComponent } from 'vue';
  import {
    DocumentClient,
    DropDownValues,
    ExpenseDto,
    FileParameter,
    InvoiceItemDto,
    InvoiceStatusEnum
  } from '@/clients/client.gen';
  import {
    mapEnumToObjects,
    toGBDateString
  } from '../../../composables/UtilityFunctions';
  import InlineMessage from 'primevue/inlinemessage';
  import { currentUserStore } from '@/stores/currentUser.store';

  interface InvoiceItemsListData {
    dtRows: InvoiceItemDto[];
    editingRow: InvoiceItemDto | undefined;
    editRowReference: InvoiceItemDto | undefined;
    statuses: DropDownValues[];
    pendingInvoicesApproved: boolean;
    currentUserStore: typeof currentUserStore;
    approvalMessage: boolean;
  }

  export default defineComponent({
    components: {
      InlineMessage
    },
    props: {
      expense: {
        type: ExpenseDto,
        required: true
      }
    },
    data(): InvoiceItemsListData {
      return {
        currentUserStore,
        dtRows: [] as Array<InvoiceItemDto>,
        editingRow: undefined as InvoiceItemDto | undefined,
        editRowReference: undefined as InvoiceItemDto | undefined,
        statuses: [] as Array<DropDownValues>,
        pendingInvoicesApproved: false,
        approvalMessage: false
      };
    },
    computed: {
      InvoiceStatusEnum() {
        return InvoiceStatusEnum;
      },
      paidInvoicesAmount() {
        return () => {
          let value = 0;
          this.dtRows.map((i: InvoiceItemDto) => {
            const amount = i.invoiceAmount === undefined ? 0 : i.invoiceAmount;
            value +=
              i.invoiceStatusId === InvoiceStatusEnum.Allocated ? amount : 0;
          });
          return value;
        };
      },

      pendingInvoicesAmount() {
        return () => {
          let value = 0;
          this.dtRows.map((i: InvoiceItemDto) => {
            const amount = i.invoiceAmount === undefined ? 0 : i.invoiceAmount;
            value +=
              i.invoiceStatusId === InvoiceStatusEnum.Pending ? amount : 0;
          });
          this.approvalMessage = value > 150 ? true : false;
          return value;
        };
      },
      totalInvoicedAmount() {
        return () => {
          let value = 0;
          this.dtRows.map((i: InvoiceItemDto) => {
            const amount = i.invoiceAmount === undefined ? 0 : i.invoiceAmount;
            value += amount;
          });
          return value;
        };
      },
      approvedInvoiceAmount() {
        return () => {
          let value = 0;
          this.dtRows.map((i: InvoiceItemDto) => {
            const amount = i.invoiceAmount === undefined ? 0 : i.invoiceAmount;
            value +=
              i.invoiceStatusId === InvoiceStatusEnum.Approved ||
              i.invoiceStatusId === InvoiceStatusEnum.Posted
                ? amount
                : 0;
          });
          return value;
        };
      },
      isEditing() {
        return (data: any) => {
          return this.editingRow === data;
        };
      }
    },
    created() {
      this.statuses = mapEnumToObjects(InvoiceStatusEnum);
      this.dtRows = this.expense.invoiceItems ?? new Array<InvoiceItemDto>();
      this.checkApproval();

      console.log('this.expense', this.expense);
    },
    methods: {
      toGBDateString,
      startEdit(data: InvoiceItemDto) {
        this.editingRow = data;

        this.editRowReference = Object.assign({}, this.editingRow);
      },
      async isValidUser() {
        return (
          currentUserStore.isFinanceUser && currentUserStore.isAnimalWelfareUser
        );
      },
      completeEdit() {
        this.editingRow = undefined;
        this.checkApproval();
        this.updateVal();
      },
      openFileDialog() {
        // if(this.readOnly) return;
        (this.$refs.file as any).click();
      },
      removeReceiptFile() {
        if (this.editingRow) {
          this.editingRow.document = undefined;
          this.editingRow.documentId = undefined;
        }
      },
      // cancelEdit(data: InvoiceItemDto) {
      //     // data= Object.assign({}, this.editRowReference);
      //     this.editingRow = undefined;
      //     this.editRowReference = undefined;
      // },
      deleteRow(data: InvoiceItemDto) {
        if (this.isEditing(data)) {
          this.editingRow = undefined;
        }
        //ToDo: Fix this.
        this.dtRows = this.dtRows.splice(this.dtRows.indexOf(data), 1);
        this.updateVal();
      },
      async newImageSelected(event: any) {
        //As part of this, we'll upload it to S3, and return a document ID which can be then attached to the lineitem.
        const files = event.target.files as FileList;
        const file = files[0];

        //we know there's actually only going to be one,
        const formData = new FormData();
        formData.append('file', file);
        //upload this to the api, so it can get us an s3 key.
        const client = new DocumentClient();
        const fileParameter: FileParameter = {
          data: file,
          fileName: file.name
        };
        const uploadResponse =
          await client.uploadStagingDocument(fileParameter);
        if (this.editingRow) {
          this.editingRow.documentId = uploadResponse.result?.documentId;
          this.editingRow.document = uploadResponse.result;
        }
      },
      addNewInvoice() {
        this.dtRows ??= new Array<InvoiceItemDto>();
        const newItem = new InvoiceItemDto();
        newItem.dateDue = new Date();
        newItem.dateIssued = new Date();
        newItem.invoiceStatusId = InvoiceStatusEnum.Pending;
        this.dtRows.push(newItem);
        this.editingRow = newItem;
        this.updateVal();
      },
      approvePending() {
        this.dtRows.map((i: InvoiceItemDto) => {
          if (i.invoiceStatusId === InvoiceStatusEnum.Pending) {
            i.invoiceStatusId = InvoiceStatusEnum.Approved;
          }
        });
        this.pendingInvoicesApproved = true;
        this.updateVal();
      },
      updateVal() {
        const newExpense = Object.assign({}, this.expense);
        newExpense.invoiceItems = this.dtRows;
        this.$emit('update:modelValue', newExpense);
      },
      checkApproval() {
        this.expense.invoiceItems?.find((i: InvoiceItemDto) => {
          if (i.invoiceStatusId === InvoiceStatusEnum.Pending) {
            this.pendingInvoicesApproved = false;
            return true;
          }
          return false;
        });
      }
    }
  });
