import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31adf282"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputMask = _resolveComponent("InputMask")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.noLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.computedId,
          class: _normalizeClass(_ctx.labelClass)
        }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass({
        'col-12': _ctx.labelAbove || _ctx.noLabel,
        'col-9': !_ctx.labelAbove
      })
    }, [
      (_ctx.type === 'text' && _ctx.multiline)
        ? (_openBlock(), _createBlock(_component_Textarea, {
            key: 0,
            id: _ctx.computedId,
            modelValue: _ctx.internalValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
            rows: 2,
            "auto-resize": "",
            disabled: _ctx.disabled || _ctx.readOnly
          }, null, 8 /* PROPS */, ["id", "modelValue", "disabled"]))
        : (_ctx.type === 'text')
          ? (_openBlock(), _createBlock(_component_InputText, {
              key: 1,
              id: _ctx.computedId,
              modelValue: _ctx.internalValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalValue) = $event)),
              disabled: _ctx.disabled || _ctx.readOnly
            }, null, 8 /* PROPS */, ["id", "modelValue", "disabled"]))
          : _createCommentVNode("v-if", true),
      (_ctx.type === 'number')
        ? (_openBlock(), _createBlock(_component_InputNumber, {
            key: 2,
            modelValue: _ctx.internalValue,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalValue) = $event)),
            "input-id": _ctx.computedId,
            min: 0,
            max: _ctx.maxNumber,
            "use-grouping": _ctx.useGrouping,
            readonly: _ctx.readOnly,
            class: "w-full",
            placeholder: _ctx.placeholder
          }, null, 8 /* PROPS */, ["modelValue", "input-id", "max", "use-grouping", "readonly", "placeholder"]))
        : _createCommentVNode("v-if", true),
      (_ctx.type === 'email')
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 3,
            id: _ctx.computedId,
            modelValue: _ctx.internalValue,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.internalValue) = $event)),
            type: "email",
            disabled: _ctx.disabled || _ctx.readOnly,
            onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["space"]))
          }, null, 8 /* PROPS */, ["id", "modelValue", "disabled"]))
        : _createCommentVNode("v-if", true),
      (_ctx.type === 'phone')
        ? (_openBlock(), _createBlock(_component_InputMask, {
            key: 4,
            id: _ctx.computedId,
            modelValue: _ctx.internalValue,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.internalValue) = $event)),
            mask: "99999-999-?99999",
            disabled: _ctx.disabled || _ctx.readOnly,
            placeholder: "",
            "auto-clear": false,
            unmask: true
          }, null, 8 /* PROPS */, ["id", "modelValue", "disabled"]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ]))
}