import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ContactAutocomplete = _resolveComponent("ContactAutocomplete")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Button, {
      label: "Create case",
      severity: "warning",
      onClick: _ctx.toggleOverlayPanel
    }, null, 8 /* PROPS */, ["onClick"]),
    _createVNode(_component_OverlayPanel, { ref: "create" }, {
      default: _withCtx(() => [
        _createVNode(_component_ContactAutocomplete, {
          class: "pt-3 pb-1",
          onSelected: _ctx.selectContact
        }, null, 8 /* PROPS */, ["onSelected"]),
        _createVNode(_component_Button, {
          disabled: _ctx.contactId == 0,
          class: "mt-3",
          label: "Add",
          onClick: _ctx.createCase
        }, null, 8 /* PROPS */, ["disabled", "onClick"])
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */)
  ]))
}