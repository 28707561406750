import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "pet-modal_health-mobility" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isValidProp('mobility'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 0,
          id: "mobility",
          modelValue: $setup.petStore.petProfile.mobility,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.petStore.petProfile.mobility) = $event)),
          "label-above": "",
          label: $setup.propFieldText('mobility')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('health'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 1,
          id: "health",
          modelValue: $setup.petStore.petProfile.health,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.petStore.petProfile.health) = $event)),
          "label-above": "",
          label: $setup.propFieldText('health')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('medication'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 2,
          id: "medication",
          modelValue: $setup.petStore.petProfile.medication,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.petStore.petProfile.medication) = $event)),
          "label-above": "",
          label: $setup.propFieldText('medication')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('allergies'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 3,
          id: "allergies",
          modelValue: $setup.petStore.petProfile.allergies,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.petStore.petProfile.allergies) = $event)),
          "label-above": "",
          label: $setup.propFieldText('allergies')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('sightDetails'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 4,
          id: "sightDetails",
          modelValue: $setup.petStore.petProfile.sightDetails,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.petStore.petProfile.sightDetails) = $event)),
          "label-above": "",
          label: $setup.propFieldText('sightDetails')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('hearingDetails'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 5,
          id: "hearingDetails",
          modelValue: $setup.petStore.petProfile.hearingDetails,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.petStore.petProfile.hearingDetails) = $event)),
          "label-above": "",
          label: $setup.propFieldText('hearingDetails')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('teethDetails'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 6,
          id: "teethDetails",
          modelValue: $setup.petStore.petProfile.teethDetails,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.petStore.petProfile.teethDetails) = $event)),
          "label-above": "",
          label: $setup.propFieldText('teethDetails')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('pawsNailsDetails'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 7,
          id: "pawsNailsDetails",
          modelValue: $setup.petStore.petProfile.pawsNailsDetails,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.petStore.petProfile.pawsNailsDetails) = $event)),
          "label-above": "",
          label: $setup.propFieldText('pawsNailsDetails')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('weightComments'))
      ? (_openBlock(), _createBlock($setup["FormFieldSelect"], {
          key: 8,
          id: "weightComments",
          modelValue: $setup.petStore.petProfile.weightComments,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.petStore.petProfile.weightComments) = $event)),
          options: $setup.weightOptions,
          "option-value": "name",
          "option-label": "name",
          "label-above": "",
          label: $setup.propFieldText('weightComments')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true)
  ]))
}