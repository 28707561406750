
  import { defineComponent } from 'vue';
  import { SupplierContactDto } from '@/clients/client.gen';
  import { supplierStore } from '../../stores/supplier.store';
  import SuppliersSecondaryContactDialog from './SuppliersSecondaryContactDialog.vue';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import Textarea from 'primevue/textarea';
  import Button from 'primevue/button';
  import { formatTelephone } from '@/composables/UtilityFunctions';

  interface Data {
    selectedContacts: Array<SupplierContactDto> | null;
    addContactVisible: boolean;
    saving: boolean;
  }

  export default defineComponent({
    components: {
      Button,
      Textarea,
      SuppliersSecondaryContactDialog,
      DataTable,
      Column
    },
    data(): Data {
      return {
        selectedContacts: null,
        addContactVisible: false,
        saving: false
      };
    },
    computed: {
      supplierStore() {
        return supplierStore;
      },
      secondaryContacts() {
        return supplierStore.supplier.secondarySupplierContacts;
      },
      isDeleteDisabled(): boolean {
        return !this.selectedContacts || !this.selectedContacts.length;
      }
    },
    methods: {
      formatTelephone,
      async onDeleteClick() {
        if (!this.selectedContacts?.length) return;
        await this.supplierStore.deleteSupplierContacts(this.selectedContacts);
        this.selectedContacts = null;
      },
      async saveAdditionalNotes() {
        this.saving = true;
        await this.supplierStore.updateSupplier();
        this.saving = false;
      }
    }
  });
