import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.loading)
    ? (_openBlock(), _createBlock($setup["ProgressSpinner"], { key: 0 }))
    : (_openBlock(), _createBlock($setup["Accordion"], {
        key: 1,
        id: "ltf-readonly-task-list",
        "active-index": $setup.notAllTasksCompleted() ? 0 : undefined,
        "aria-labelledby": "ltfTaskList"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["AccordionTab"], { header: "View LTF Task List" }, {
            default: _withCtx(() => [
              ($setup.taskStore.ltfTasks)
                ? (_openBlock(), _createBlock($setup["ReadOnlyTaskList"], {
                    key: 0,
                    tasks: $setup.taskStore.ltfTasks
                  }, null, 8 /* PROPS */, ["tasks"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["active-index"]))
}