import { reactive } from 'vue';
import { Auth } from 'aws-amplify';
import { SystemUserModel } from '@/clients/client.gen';

export const currentUserStore = reactive({
  loading: true,

  isHospitalStaysUser: false,
  isSystemAdminUser: false,
  isAdminUser: false,
  isEscalationUser: false,
  isAftercareAdmin: false,
  isAftercareUser: false,
  isSTFAftercareUser: false,
  isLTFAfterCareUser: false,
  isAppealsUser: false,
  isManagementUser: false,
  isAnimalWelfareUser: false,
  isAnimalWelfare2User: false,
  isFinanceUser: false,
  isVolunteerCoordinatorUser: false,

  isManager: false,

  user: undefined as SystemUserModel | undefined,

  async getCurrentUserGroups(): Promise<string[]> {
    const user = await Auth.currentAuthenticatedUser();
    // the array of groups that the user belongs to
    const groups =
      user.signInUserSession.accessToken.payload['cognito:groups'] ?? [];
    return groups;
  },
  async setUsersGroups() {
    const userGroups = await this.getCurrentUserGroups();
    this.setSystemAdminUser(userGroups);
    this.setHospitalStaysUser(userGroups);
    this.setEscalationUser(userGroups);
    this.setAftercareAdmin(userGroups);
    this.setAftercareUser(userGroups);
    this.setSTFAftercareUser(userGroups);
    this.setLTFAfterCareUser(userGroups);
    this.setAppealsUser(userGroups);
    this.setManagementUser(userGroups);
    this.setAdminUser(userGroups);
    this.setAnimalWelfareUser(userGroups);
    this.setAnimalWelfare2User(userGroups);
    this.setFinanceUser(userGroups);
    this.setVolunteerCoordinatorUser(userGroups);
  },
  ///We can extend these methods to ||'s where required for specific functionality!
  setSystemAdminUser(userGroups: string[]) {
    this.isSystemAdminUser = userGroups.includes('SystemAdmin');
  },
  setHospitalStaysUser(userGroups: string[]) {
    this.isHospitalStaysUser = userGroups.includes('HospitalStays');
  },
  setEscalationUser(userGroups: string[]) {
    this.isEscalationUser = userGroups.includes('Escalation');
  },
  setAftercareAdmin(userGroups: string[]) {
    this.isAftercareAdmin = userGroups.includes('AftercareAdmin');
  },
  setAftercareUser(userGroups: string[]) {
    this.isAftercareUser = userGroups.includes('Aftercare');
  },
  setSTFAftercareUser(userGroups: string[]) {
    this.isSTFAftercareUser = userGroups.includes('STFAftercare');
  },
  setLTFAfterCareUser(userGroups: string[]) {
    this.isLTFAfterCareUser = userGroups.includes('LTFAftercare');
  },
  setAppealsUser(userGroups: string[]) {
    this.isAppealsUser = userGroups.includes('Appeals');
  },
  setManagementUser(userGroups: string[]) {
    this.isManagementUser = userGroups.includes('Management');
  },
  setAdminUser(userGroups: string[]) {
    this.isAdminUser = userGroups.includes('Admin');
  },
  setAnimalWelfareUser(userGroups: string[]) {
    this.isAnimalWelfareUser = userGroups.includes('AnimalWelfare');
  },
  setAnimalWelfare2User(userGroups: string[]) {
    this.isAnimalWelfare2User = userGroups.includes('AnimalWelfare2');
  },
  setFinanceUser(userGroups: string[]) {
    this.isFinanceUser = userGroups.includes('Finance');
  },
  setVolunteerCoordinatorUser(userGroups: string[]) {
    this.isVolunteerCoordinatorUser = userGroups.includes(
      'VolunteerCoordinator'
    );
  },
  async checkSystemAdminUser(): Promise<boolean> {
    await this.setUsersGroups();
    return this.isSystemAdminUser;
  }
});
