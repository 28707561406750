import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "pet-modal_history" }
const _hoisted_2 = {
  key: 1,
  class: "col-offset-3 pb-3"
}
const _hoisted_3 = {
  key: 14,
  class: "col-offset-3 pb-3"
}
const _hoisted_4 = {
  key: 15,
  class: "field grid"
}
const _hoisted_5 = {
  for: "nviaDate",
  class: "col-3 mb-3"
}
const _hoisted_6 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isValidProp('hasMicrochip'))
      ? (_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 0,
          id: "hasMicrochip",
          modelValue: $setup.petStore.petProfile.hasMicrochip,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.petStore.petProfile.hasMicrochip) = $event)),
          label: $setup.propFieldText('hasMicrochip')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('hasMicrochip'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_InputText, {
            id: "microchip",
            modelValue: $setup.petStore.petProfile.microchip,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.petStore.petProfile.microchip) = $event)),
            disabled: !$setup.petStore.petProfile.hasMicrochip,
            placeholder: "Microchip number"
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('neutered'))
      ? (_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 2,
          id: "neutered",
          modelValue: $setup.petStore.petProfile.neutered,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.petStore.petProfile.neutered) = $event)),
          label: $setup.propFieldText('neutered')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('origin'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 3,
          id: "origin",
          modelValue: $setup.petStore.petProfile.origin,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.petStore.petProfile.origin) = $event)),
          "label-above": "",
          label: $setup.propFieldText('origin')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('ownedTime'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 4,
          id: "ownedTime",
          modelValue: $setup.petStore.petProfile.ownedTime,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.petStore.petProfile.ownedTime) = $event)),
          "label-above": "",
          label: $setup.propFieldText('ownedTime')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('houseTrained'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 5,
          id: "houseTrained",
          modelValue: $setup.petStore.petProfile.houseTrained,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.petStore.petProfile.houseTrained) = $event)),
          "label-above": "",
          label: $setup.propFieldText('houseTrained')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    (
        $setup.isValidProp('bitingHistory') &&
        ($setup.petStore.pet.petTypeId == $setup.AnimalTypeEnum.Cat ||
          $setup.petStore.pet.petTypeId == $setup.AnimalTypeEnum.Dog)
      )
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 6,
          id: "bitingHistory",
          modelValue: $setup.petStore.petProfile.bitingHistory,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.petStore.petProfile.bitingHistory) = $event)),
          "label-above": "",
          label: $setup.propFieldText('bitingHistory')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    (
        $setup.isValidProp('leftAloneInfo') &&
        $setup.petStore.pet.petTypeId != $setup.AnimalTypeEnum.Cat
      )
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 7,
          id: "leftAloneInfo",
          modelValue: $setup.petStore.petProfile.leftAloneInfo,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.petStore.petProfile.leftAloneInfo) = $event)),
          "label-above": "",
          label: $setup.propFieldText('leftAloneInfo')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('indoorOutdoor'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 8,
          id: "indoorOutdoor",
          modelValue: $setup.petStore.petProfile.indoorOutdoor,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.petStore.petProfile.indoorOutdoor) = $event)),
          "label-above": "",
          label: $setup.propFieldText('indoorOutdoor')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('liveAtAddress'))
      ? (_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 9,
          id: "liveAtAddress",
          modelValue: $setup.petStore.petProfile.liveAtAddress,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.petStore.petProfile.liveAtAddress) = $event)),
          label: $setup.propFieldText('liveAtAddress')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('addressOfLivery'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 10,
          id: "addressOfLivery",
          modelValue: $setup.petStore.petProfile.addressOfLivery,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.petStore.petProfile.addressOfLivery) = $event)),
          "label-above": "",
          label: $setup.propFieldText('addressOfLivery')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('emergencyContact'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 11,
          id: "emergencyContact",
          modelValue: $setup.petStore.petProfile.emergencyContact,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.petStore.petProfile.emergencyContact) = $event)),
          "label-above": "",
          label: $setup.propFieldText('emergencyContact')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('comments'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 12,
          id: "comments",
          modelValue: $setup.petStore.petProfile.comments,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.petStore.petProfile.comments) = $event)),
          "label-above": "",
          label: $setup.propFieldText('comments')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('nvia'))
      ? (_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 13,
          id: "nvia",
          modelValue: $setup.petStore.petProfile.nvia,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.petStore.petProfile.nvia) = $event)),
          label: $setup.propFieldText('nvia'),
          "read-only": !$setup.isSystemAdminOrManager,
          disabled: !$setup.isSystemAdminOrManager
        }, null, 8 /* PROPS */, ["modelValue", "label", "read-only", "disabled"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('nvia'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_InputText, {
            id: "nviaReason",
            modelValue: $setup.petStore.petProfile.nviaReason,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.petStore.petProfile.nviaReason) = $event)),
            placeholder: "NVIA reason",
            disabled: !$setup.isSystemAdminOrManager || !$setup.petStore.petProfile.nvia
          }, null, 8 /* PROPS */, ["modelValue", "disabled"])
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('nviaDate'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString($setup.propFieldText('nviaDate')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, [
            _createVNode($setup["FormDatePicker"], {
              id: "nviaDate",
              ref: "nviaDatePicker",
              modelValue: $setup.petStore.petProfile.nviaDate,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.petStore.petProfile.nviaDate) = $event)),
              disabled: !$setup.isSystemAdminOrManager || !$setup.petStore.petProfile.nvia,
              class: "w-full",
              "min-date": $setup.minDate,
              "max-date": $setup.maxDate,
              placeholder: "NVIA Date",
              "show-icon": "",
              "date-format": "dd/mm/yy"
            }, null, 8 /* PROPS */, ["modelValue", "disabled", "min-date", "max-date"])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}