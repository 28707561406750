import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-full",
  style: {"display":"block"}
}
const _hoisted_2 = { class: "flex justify-content-end" }
const _hoisted_3 = { class: "flex flex-column w-full" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  style: {"overflow-wrap":"anywhere"}
}
const _hoisted_7 = ["href"]
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock($setup["Dialog"], {
    draggable: false,
    closable: false,
    visible: $props.visible,
    modal: "",
    style: {"width":"600px"}
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", null, "Viewing: " + _toDisplayString($props.title), 1 /* TEXT */),
        _createVNode(_component_Divider)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          label: "Close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('closeDialog')))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.questions, (question, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("div", null, [
              _createElementVNode("h4", null, _toDisplayString(question.label ??
              'No label found (This indicates an issue with the Tally form)'), 1 /* TEXT */)
            ]),
            (question.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(question.value), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            (question.items)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.items, (item, itemIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: itemIndex,
                      class: "flex gap-2"
                    }, [
                      _createElementVNode("div", null, [
                        _createVNode($setup["Checkbox"], {
                          binary: "",
                          value: item.selected,
                          disabled: ""
                        }, null, 8 /* PROPS */, ["value"])
                      ]),
                      _createElementVNode("div", null, _toDisplayString(item.text), 1 /* TEXT */)
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true),
            (question.url)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("a", {
                    href: question.url,
                    target: "blank"
                  }, _toDisplayString(question.url), 9 /* TEXT, PROPS */, _hoisted_7),
                  _createElementVNode("img", {
                    class: "w-full",
                    src: question.url
                  }, null, 8 /* PROPS */, _hoisted_8)
                ]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_Divider)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}