import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: _ctx.petStore.ownerHistory,
      class: "ml-2",
      style: {"max-width":"500px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, { header: "Owner" }, {
          body: _withCtx(({data}: {data: OwnerHistoryDto}) => [
            _createElementVNode("a", {
              onClick: ($event: any) => (_ctx.goToContact(data.ownerId))
            }, _toDisplayString(data.ownerFirstName) + " " + _toDisplayString(data.ownerLastName), 9 /* TEXT, PROPS */, _hoisted_1)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, { header: "Date of fostering" }, {
          body: _withCtx(({data}: {data: OwnerHistoryDto}) => [
            _createTextVNode(_toDisplayString(_ctx.toGBDateString(data.fosterDate)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, { header: "Case" }, {
          body: _withCtx(({data}: {data: OwnerHistoryDto}) => [
            _createElementVNode("a", {
              onClick: ($event: any) => (_ctx.goToCase(data.caseId))
            }, _toDisplayString(data.caseReference), 9 /* TEXT, PROPS */, _hoisted_2)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["value"])
  ]))
}