
  export interface ExpensesTabData {
    expenseStore: any;
    expenses: ExpenseDto[];
    expenseToEdit: AddExpenseCommand;
    loading: boolean;
    isExpenseDialogVisible: boolean;
    expenseDialogStart: number;
    suppliers: SupplierDropDownDto[];
  }

  import { defineComponent } from 'vue';
  //import { caseStore } from "@/stores/case.store";
  import {
    toGBDateString,
    formatCurrency
  } from '../../../composables/UtilityFunctions';
  import ExpenseDialog from '@/components/Common/Expenses/ExpenseDialog.vue';
  import {
    ExpenseDto,
    ExpenseClient,
    AddExpenseCommand,
    ExpenseStatusEnum,
    //SimpleVolunteerDto,
    SupplierClient,
    SupplierDropDownDto,
    FlattenedCaseVolunteer,
    PetDto
  } from '../../../clients/client.gen';
  import Button from 'primevue/button';
  import { expenseStore } from '@/stores/expense.store';
  import EllipsisButton from '@/components/Common/EllipsisButton.vue';
  const client = new ExpenseClient();

  export default defineComponent({
    components: { EllipsisButton, /*AddInvoice,*/ Button, ExpenseDialog },
    props: {
      caseId: {
        type: Number,
        required: false
      },
      supplierId: {
        type: Number,
        required: false
      },
      volunteers: {
        type: Array
      },
      pets: {
        type: Array
      }
    },
    data(): ExpensesTabData {
      return {
        expenseStore,
        expenses: new Array<ExpenseDto>(),
        expenseToEdit: new AddExpenseCommand(),
        loading: false,
        isExpenseDialogVisible: false,
        expenseDialogStart: 0,
        suppliers: new Array<SupplierDropDownDto>()
      };
    },
    async mounted() {
      const supplierClient = new SupplierClient();
      this.suppliers = await supplierClient.getForSelector();
      await this.getExpenses();
    },
    methods: {
      toGBDateString,
      formatCurrency,
      async getExpenses() {
        if (this.caseId && this.supplierId) return;
        this.loading = true;
        console.log('caseid', this.caseId, 'supplierid', this.supplierId);
        if (this.caseId) this.expenses = await client.getExpenses(this.caseId);
        if (this.supplierId)
          this.expenses = await client.getSupplierExpenses(this.supplierId);
        this.loading = false;
      },
      getExpenseStatus(enumVal: number) {
        return ExpenseStatusEnum[enumVal];
      },
      async onComplete(event: { expense: ExpenseDto; sendFinance: boolean }) {
        this.isExpenseDialogVisible = false;
        const command = new AddExpenseCommand();
        command.expense = new ExpenseDto(event.expense);
        if (this.caseId) command.expense.caseId = this.caseId;
        if (this.supplierId) command.expense.supplierId = this.supplierId;
        command.sendFinance = event.sendFinance;
        console.log(command);
        let expense;
        if (command.expense.expenseId > 0)
          expense = await client.updateExpense(command);
        else expense = await client.addExpense(command);
        console.log(expense);

        await this.getExpenses();
      },
      getSupplierNameFromId(supplierId: number | undefined) {
        const supplierIdTrue = supplierId ?? this.supplierId;
        if (!supplierIdTrue) return '';
        const supplierMatch: SupplierDropDownDto | undefined =
          this.suppliers.find((s) => s.supplierId == supplierIdTrue);
        const name: string | undefined = supplierMatch
          ? supplierMatch.name
          : '-';
        return name;
      },
      getVolunteerName(volunteer: FlattenedCaseVolunteer | undefined) {
        if (!volunteer) return '';
        const name: string = volunteer.name;
        return name;
      },
      getPetNames(pets: PetDto[]) {
        const names: string[] = [];
        pets.forEach((v) => names.push(v.petName ?? ''));

        return names.join(', ');
      },
      getNominalCodes(expense: ExpenseDto) {
        const names: string[] = [];
        if (expense.nominalCode) return expense.nominalCode;

        expense.expenseCodes?.forEach((v) => {
          const code = v.nominalCodeId ? v.nominalCodeId.toString() : '';
          names.push(code);
        });

        return names.join(', ');
      },
      //  async removeExpense(expenseId: number) {
      //    client.removeExpense(expenseId).finally(async () => {
      //     await this.getExpenses();
      //  })
      //},

      editExpense(selectedExpense: ExpenseDto) {
        //const expense = this.expenses.find((e: ExpenseDto) => e.expenseId === expenseId);
        // if(expense) this.expenseToEdit = expense;
        const command = new AddExpenseCommand();
        command.expense = selectedExpense;
        this.expenseToEdit = command;
        this.expenseDialogStart = 2;
        this.isExpenseDialogVisible = true;
      },
      showExpenseNew() {
        this.expenseDialogStart = 0;
        this.isExpenseDialogVisible = true;
        expenseStore.expense = new ExpenseDto();
        const command = new AddExpenseCommand();
        command.expense = undefined;
        this.expenseToEdit = command;
      },
      addInvoice(selectedExpense: ExpenseDto) {
        const command = new AddExpenseCommand();
        command.expense = selectedExpense;
        this.expenseToEdit = command;
        this.isExpenseDialogVisible = true;
      },
      //toggleMoreActionsPanel(event: any, refName: string) {
      //  (this.$refs[refName] as any).toggle(event);
      //},
      emptyFieldSub(
        stringToCheck: string | null | undefined,
        substituteText: string
      ) {
        if (
          stringToCheck == null ||
          stringToCheck == undefined ||
          stringToCheck == ''
        )
          return substituteText;
        return stringToCheck;
      },
      getTotalExpenseCost(expense: ExpenseDto) {
        let sum = 0;

        if (expense.expectedAmount && expense.expectedAmount > 0) {
          return expense.expectedAmount;
        }

        if (!expense.expenseCodes || !expense.expenseCodes.length) {
          return 0;
        }

        for (let i = 0; i < expense.expenseCodes.length; i++) {
          const subTotal = expense.expenseCodes[i]
            ? expense.expenseCodes[i].codeTotal
            : 0;
          sum += subTotal ?? 0;
        }

        return sum;
      }
    }
  });
