
  import { defineComponent } from 'vue';
  import { petStore, NominatedPersonDto } from '../../stores/pet.store';
  import Dialog from 'primevue/dialog';
  import FormField from '../Common/FormField.vue';
  interface Data {
    internalValue: boolean;
    nominatedPerson: NominatedPersonDto;
  }

  export default defineComponent({
    components: { Dialog, FormField },
    props: {
      modelValue: {
        type: Boolean,
        required: false
      }
    },
    data(): Data {
      return {
        internalValue: this.modelValue,
        nominatedPerson: new NominatedPersonDto()
      };
    },
    computed: {
      nominatedPersons() {
        return petStore.pet.nominatedPersons;
      }
    },
    watch: {
      modelValue(newVal) {
        this.internalValue = newVal;
      },
      internalValue(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    },
    methods: {
      async onAddClick() {
        await petStore.addNominatedPerson(this.nominatedPerson);

        this.internalValue = false;
      }
    }
  });
