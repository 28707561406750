import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid mr-2" }
const _hoisted_2 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentList = _resolveComponent("DocumentList")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createBlock(_component_ScrollPanel, { class: "w-full h-full" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DocumentList, {
            ref: "documentList",
            documents: _ctx.supplierStore.documents.documents,
            folders: _ctx.supplierStore.documents.folders,
            class: "mr-2",
            onRequestUpdate: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.supplierStore.getDocumentData(_ctx.supplierStore.supplier.supplierId)
          )),
            onCreateFolder: _ctx.createNewFolder,
            onUploadFile: _ctx.uploadFile
          }, null, 8 /* PROPS */, ["documents", "folders", "onCreateFolder", "onUploadFile"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}