/* Amplify UI */
import AmplifyVue from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';

/* AWS Amplify */
import { Amplify } from 'aws-amplify';
// import awsConfig from './aws-exports';

//Amplify.Logger.LOG_LEVEL = 'DEBUG';

// Amplify.configure(awsConfig);

const userPoolId = process.env.VUE_APP_COGNITO_USER_POOL_ID;
const webClientId = process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID;

//Configure Amplify
Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    //identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: webClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    //signUpVerificationMethod: 'link', // 'code' | 'link'

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    //     // OPTIONAL - Cookie path
    //     path: '/',
    //     // OPTIONAL - Cookie expiration in days
    //     // expires: 365,
    //     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //     // sameSite: 'strict' | 'lax',
    //     // OPTIONAL - Cookie secure flag
    //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true,
    // },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH'

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: {myCustomKey: 'myCustomValue'},

    // OPTIONAL - Hosted UI configuration
    // oauth: {
    //     domain: 'your_cognito_domain',
    //     scope: [
    //         'phone',
    //         'email',
    //         'profile',
    //         'openid',
    //         'aws.cognito.signin.user.admin',
    //     ],
    //     redirectSignIn: 'http://localhost:3000/',
    //     redirectSignOut: 'http://localhost:3000/',
    //     responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    // },
  }
});

//Finish amplify configuration

/* Vue Router */
import router from './router';

/* create and mount root App instance */
import { createApp } from 'vue';
import App from './App.vue';

/* Prime Vue */
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import Panel from 'primevue/panel';
import Card from 'primevue/card';
import TabMenu from 'primevue/tabmenu';
import InputText from 'primevue/inputtext';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Checkbox from 'primevue/checkbox';
import ToggleButton from 'primevue/togglebutton';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import Divider from 'primevue/divider';
import FileUpload from 'primevue/fileupload';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import Toolbar from 'primevue/toolbar';
import MegaMenu from 'primevue/megamenu';
import ScrollPanel from 'primevue/scrollpanel';
import VirtualScroller from 'primevue/virtualscroller';
import ListBox from 'primevue/listbox';
import Autocomplete from 'primevue/autocomplete';
import Tag from 'primevue/tag';
import Rating from 'primevue/rating';
import Steps from 'primevue/steps';
import ToastService from 'primevue/toastservice';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import InputSwitch from 'primevue/inputswitch';
import Tooltip from 'primevue/tooltip';

import JsonViewer from 'vue3-json-viewer';
import 'vue3-json-viewer/dist/index.css';
/*Google maps extension */
import VueGoogleMaps from 'vue-google-maps-community-fork';

//import custom css to act as a theme
//import 'primevue/resources/primevue.min.css';
//import 'primevue/resources/themes/lara-light-teal/theme.css';
import './assets/themes/ct.scss';

import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'; // Import the PrimeVue layout utility library.

//floating vue styles for mentionJS
import 'floating-vue/dist/style.css';

// register custom global filters
import { FilterService } from 'primevue/api';
import { CustomFilterMatchMode, any, matchAnyId } from '@/composables/Filters';
import Toast from 'primevue/toast';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
FilterService.register(CustomFilterMatchMode.ANY, any);
FilterService.register(CustomFilterMatchMode.ANY_ID, matchAnyId);
import ConfirmationService from 'primevue/confirmationservice';

import { TinyEmitter } from 'tiny-emitter';

const emitter = new TinyEmitter();

const app = createApp(App);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);
app
  .use(router)
  .use(ToastService)
  .use(AmplifyVue)
  .use(PrimeVue)
  .use(JsonViewer)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_API_KEY
    }
  })
  .component('Dialog', Dialog)
  .component('DataTable', DataTable)
  .component('Column', Column)
  .component('Row', Row)
  .component('ColumnGroup', ColumnGroup)
  .component('Button', Button)
  .component('ButtonGroup', ButtonGroup)
  .component('Tree', Tree)
  .component('TreeTable', TreeTable)
  .component('Panel', Panel)
  .component('Card', Card)
  .component('TabMenu', TabMenu)
  .component('InputText', InputText)
  .component('IconField', IconField)
  .component('InputField', InputIcon)
  .component('Checkbox', Checkbox)
  .component('ToggleButton', ToggleButton)
  .component('Dropdown', Dropdown)
  .component('MultiSelect', MultiSelect)
  .component('InputNumber', InputNumber)
  .component('Calendar', Calendar)
  .component('Divider', Divider)
  .component('FileUpload', FileUpload)
  .component('RadioButton', RadioButton)
  .component('Textarea', Textarea)
  .component('Toolbar', Toolbar)
  .component('MegaMenu', MegaMenu)
  .component('ScrollPanel', ScrollPanel)
  .component('ListBox', ListBox)
  .component('Autocomplete', Autocomplete)
  .component('Tag', Tag)
  .component('Rating', Rating)
  .component('Steps', Steps)
  .component('Toast', Toast)
  .component('TabPanel', TabPanel)
  .component('TabView', TabView)
  .component('InputSwitch', InputSwitch)
  .component('Accordion', Accordion)
  .component('AccordionTab', AccordionTab)
  .component('VirtualScroller', VirtualScroller)
  .mount('#app');

app.config.globalProperties.emitter = emitter;
