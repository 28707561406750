import { createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full p-4 bg-white" }
const _hoisted_2 = {
  key: 0,
  class: "content"
}
const _hoisted_3 = { class: "grid h-full m-0 bg-white" }
const _hoisted_4 = { class: "col-2 h-full" }
const _hoisted_5 = { class: "h-full col-10 h-full flex flex-row-reverse" }
const _hoisted_6 = { class: "flex align-items-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ButtonGroup = _resolveComponent("ButtonGroup")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.suppliers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_DataTable, {
            filters: $setup.filters,
            "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => (($setup.filters) = $event)),
            selection: $setup.selectedRow,
            "onUpdate:selection": _cache[4] || (_cache[4] = ($event: any) => (($setup.selectedRow) = $event)),
            value: $setup.suppliersData,
            "selection-mode": "single",
            paginator: "",
            rows: 10,
            "data-key": "id",
            "filter-display": "menu",
            loading: $setup.loading,
            lazy: true,
            "total-records": $setup.totalRecords,
            "global-filter-fields": $setup.globalFilterFields,
            "row-class": $setup.isRowDeleted,
            onRowSelect: $setup.onRowSelect,
            onPage: $setup.onPageChange,
            onSort: $setup.applySort
          }, {
            empty: _withCtx(() => [
              _createTextVNode(" No suppliers found. ")
            ]),
            loading: _withCtx(() => [
              _createTextVNode(" Loading suppliers data. Please wait. ")
            ]),
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode($setup["IconField"], { "icon-position": "left" }, {
                    default: _withCtx(() => [
                      _createVNode($setup["InputIcon"], { class: "pi pi-search" }),
                      _createVNode(_component_InputText, {
                        modelValue: $setup.filters['global'].value,
                        "onUpdate:modelValue": [
                          _cache[0] || (_cache[0] = ($event: any) => (($setup.filters['global'].value) = $event)),
                          $setup.filter
                        ],
                        placeholder: "Search",
                        class: "p-inputtext-small p-ml-2 bg-white"
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_ButtonGroup, { class: "mr-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Button, {
                          label: "All suppliers",
                          severity: $setup.allSuppliersSeverity,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.updateSupplierType('All')))
                        }, null, 8 /* PROPS */, ["severity"]),
                        _createVNode(_component_Button, {
                          label: "Vets only",
                          severity: $setup.vetsOnlySeverity,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.updateSupplierType('Vet')))
                        }, null, 8 /* PROPS */, ["severity"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_Button, {
                      label: "Add",
                      severity: "warning",
                      onClick: $setup.onAddClick
                    })
                  ])
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "supplierId",
                header: "Supplier Id",
                sortable: ""
              }),
              _createVNode(_component_Column, {
                field: "name",
                header: "Supplier name",
                sortable: ""
              }),
              _createVNode(_component_Column, {
                field: "supplierTypes",
                header: "Supplier type",
                sortable: ""
              }),
              _createVNode(_component_Column, {
                field: "emailAddress",
                header: "Email",
                sortable: ""
              }),
              _createVNode(_component_Column, {
                field: "telephone",
                header: "Telephone",
                sortable: ""
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString($setup.formatTelephone(slotProps.data.telephone)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_Column, {
                field: "primaryContactName",
                header: "PC name",
                sortable: ""
              }),
              _createVNode(_component_Column, {
                field: "primaryContactRole",
                header: "PC role",
                sortable: ""
              }),
              _createVNode(_component_Column, {
                field: "primaryContactEmail",
                header: "PC email",
                sortable: ""
              }),
              _createVNode(_component_Column, {
                field: "primaryContactTelephone",
                header: "PC telephone",
                sortable: ""
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString($setup.formatTelephone(slotProps.data.primaryContactTelephone)), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_Column, {
                field: "registeredDate",
                header: "Registered date",
                sortable: ""
              }),
              ($setup.currentUserStore.isSystemAdminUser)
                ? (_openBlock(), _createBlock(_component_Column, {
                    key: 0,
                    header: ""
                  }, {
                    body: _withCtx((slotProps) => [
                      _createVNode($setup["EllipsisButton"], null, {
                        default: _withCtx(() => [
                          (!slotProps.data.isDeleted)
                            ? (_openBlock(), _createBlock(_component_Button, {
                                key: 0,
                                class: "flex",
                                text: "",
                                size: "small",
                                severity: "danger",
                                label: "Delete",
                                onClick: ($event: any) => ($setup.deleteSupplier(slotProps.data.supplierId))
                              }, null, 8 /* PROPS */, ["onClick"]))
                            : _createCommentVNode("v-if", true),
                          (slotProps.data.isDeleted)
                            ? (_openBlock(), _createBlock(_component_Button, {
                                key: 1,
                                class: "flex",
                                text: "",
                                size: "small",
                                severity: "danger",
                                label: "Undelete",
                                onClick: ($event: any) => ($setup.undeleteSupplier(slotProps.data.supplierId))
                              }, null, 8 /* PROPS */, ["onClick"]))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["filters", "selection", "value", "loading", "total-records", "global-filter-fields", "row-class"])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}