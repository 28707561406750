import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      modelValue: _ctx.selectedSupplier,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSupplier) = $event)),
      class: "w-full",
      filter: "",
      options: _ctx.suppliers,
      "option-value": "supplierId",
      "option-label": "name",
      placeholder: "Select a Supplier"
    }, null, 8 /* PROPS */, ["modelValue", "options"])
  ]))
}