export interface IRecentlyViewedItem {
  uniqueIdentifier: string;
}

export default class RecentlyViewed<T extends IRecentlyViewedItem> {
  private LOCAL_STORAGE_KEY = 'RecentlyViewed';
  listOfItems?: T[] = [];
  constructor(typeOflist: string) {
    this.LOCAL_STORAGE_KEY = `${this.LOCAL_STORAGE_KEY}_${typeOflist}`;
    this.getItemsFromLocalStorage();
  }
  private getItemsFromLocalStorage() {
    const localStoragelistOfItems = localStorage.getItem(
      this.LOCAL_STORAGE_KEY
    );
    if (localStoragelistOfItems) {
      this.listOfItems = JSON.parse(localStoragelistOfItems) as T[];
    }
  }
  persist(item: T) {
    if (!this.listOfItems) {
      this.listOfItems = [];
    }
    const existingItem = this.listOfItems.find(
      (existingItem) => existingItem.uniqueIdentifier === item.uniqueIdentifier
    );
    if (existingItem) {
      const index = this.listOfItems.indexOf(existingItem);
      if (index !== -1) {
        this.listOfItems.splice(index, 1);
      }
    }
    this.listOfItems.push(item);
    const listString = JSON.stringify(this.listOfItems);
    localStorage.setItem(this.LOCAL_STORAGE_KEY, listString);
  }
}
