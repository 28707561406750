import { ObjectUtils } from 'primevue/utils';
// ref https://github.com/primefaces/primevue/blob/fe50da913e3fdaee18e4ded82e9130da6f5bc703/components/api/FilterService.js for existing filters.

const CustomFilterMatchMode = {
  ANY: 'any',
  ANY_ID: 'anyId'
};

// checks whether any object in an array exists in filter array - similar to 'contains' filter but using objects. Borrows from 'in' filter.
// NOTE: object may contain child collections so this will not match unless filter contains the same data
const any = (value: [], filter: []) => {
  if (filter === undefined || filter === null || filter.length === 0) {
    return true;
  }

  for (let i = 0; i < filter.length; i++) {
    for (let j = 0; j < value.length; j++) {
      if (ObjectUtils.deepEquals(value[j], filter[i])) {
        return true;
      }
    }
  }

  return false;
};

// assumes object has an id property
const matchAnyId = (value: any[], filter: any[]) => {
  if (filter === undefined || filter === null || filter.length === 0) {
    return true;
  }

  const valueIds = value.map((a) => a.id);
  const filterIds = filter.map((a) => a.id);

  return valueIds.some((i) => filterIds.includes(i));
};

export { CustomFilterMatchMode, any, matchAnyId };
