import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "volunteer-marker-info" }
const _hoisted_3 = { class: "volunteer-marker selected" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($props.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["ProgressBar"], {
            mode: "indeterminate",
            style: {"height":"6px"}
          })
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["GoogleMap"], {
      ref: "mapRef",
      "api-key": $setup.googleMapApiKey,
      center: $setup.center,
      zoom: $setup.zoom,
      class: "h-full w-full",
      style: {"min-height":"28vh","max-height":"64vh"},
      "map-id": $setup.mapId,
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.unselectMarker()))
    }, {
      default: _withCtx(() => [
        _createVNode($setup["MarkerCluster"], null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.markers, (m, index) => {
              return (_openBlock(), _createBlock($setup["CustomMarker"], {
                key: index,
                options: {
            position: $setup.getPosition(m),
            anchorPoint: 'BOTTOM_CENTER'
          },
                onClick: ($event: any) => ($setup.toggleMarker($event, m))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["volunteer-marker", {
              'opacity-0':
                $props.selectedMarker && $props.selectedMarker?.contactId === m.contactId
            }])
                  }, [
                    _renderSlot(_ctx.$slots, "marker", { marker: m }, () => [
                      _createTextVNode(_toDisplayString(m.name), 1 /* TEXT */)
                    ])
                  ], 2 /* CLASS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["options", "onClick"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 3 /* FORWARDED */
        }),
        _createCommentVNode(" circles for the radius view, radius in meters "),
        _createVNode($setup["Circle"], { options: $setup.innerCircleOptions }, null, 8 /* PROPS */, ["options"]),
        _createVNode($setup["Circle"], { options: $setup.outerCircleOptions }, null, 8 /* PROPS */, ["options"]),
        ($props.selectedMarker)
          ? (_openBlock(), _createBlock($setup["CustomMarker"], {
              key: 0,
              options: {
          position: $setup.getPosition($props.selectedMarker),
          anchorPoint: 'BOTTOM_CENTER',
          zIndex: 999_999
        },
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleMarker($event, $setup.props.selectedMarker)))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "marker-info", { selectedMarker: $props.selectedMarker }, () => [
                    _createTextVNode(_toDisplayString($props.selectedMarker.name) + " Selected ", 1 /* TEXT */)
                  ])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "marker", { marker: $props.selectedMarker }, () => [
                    _createTextVNode(_toDisplayString($props.selectedMarker.name), 1 /* TEXT */)
                  ])
                ])
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["options"]))
          : _createCommentVNode("v-if", true),
        ($props.centralAddress.locationY && $props.centralAddress.locationX)
          ? (_openBlock(), _createBlock($setup["CustomMarker"], {
              key: 1,
              options: {
          zIndex: -1,
          position: {
            lat: $props.centralAddress.locationY,
            lng: $props.centralAddress.locationX
          },
          anchorPoint: 'BOTTOM_CENTER'
        }
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require('@/assets/images/Other icons/Map-marker.svg'),
                  width: "30",
                  height: "40"
                }, null, 8 /* PROPS */, _hoisted_4)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["options"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["api-key", "center", "zoom", "map-id"])
  ]))
}