import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createBlock(_component_MultiSelect, {
    modelValue: $setup.selectedPets,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedPets) = $event)),
      _cache[1] || (_cache[1] = ($event: any) => ($setup.emit('update:selectedPets', $event)))
    ],
    options: $setup.pets,
    display: "chip",
    class: "w-full",
    "option-label": "name",
    "option-value": "id",
    placeholder: $props.placeholder || 'Select Relevant Pets'
  }, null, 8 /* PROPS */, ["modelValue", "options", "placeholder"]))
}