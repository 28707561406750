import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$setup.revealed)
      ? (_openBlock(), _createBlock($setup["Password"], {
          key: 0,
          modelValue: $setup.model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
          disabled: $props.disabled
        }, null, 8 /* PROPS */, ["modelValue", "disabled"]))
      : _createCommentVNode("v-if", true),
    ($setup.revealed)
      ? (_openBlock(), _createBlock(_component_InputText, {
          key: 1,
          modelValue: $setup.model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.model) = $event)),
          disabled: $props.disabled
        }, null, 8 /* PROPS */, ["modelValue", "disabled"]))
      : _createCommentVNode("v-if", true),
    (!$setup.revealed)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 2,
          icon: $setup.PrimeIcons.EYE,
          style: {"min-width":"35px"},
          onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.revealed = !$setup.revealed))
        }, null, 8 /* PROPS */, ["icon"]))
      : _createCommentVNode("v-if", true),
    ($setup.revealed)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 3,
          icon: $setup.PrimeIcons.EYE_SLASH,
          style: {"min-width":"35px"},
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.revealed = !$setup.revealed))
        }, null, 8 /* PROPS */, ["icon"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_Button, {
      icon: $setup.PrimeIcons.COPY,
      style: {"min-width":"35px"},
      onClick: $setup.copyToClipboard
    }, null, 8 /* PROPS */, ["icon"])
  ]))
}