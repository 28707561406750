import { vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "pet-modal_behaviour-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        $setup.petStore.pet.petTypeId !== $setup.AnimalTypeEnum.Cat &&
        $setup.petStore.pet.petTypeId !== $setup.AnimalTypeEnum.Dog
      )
      ? _withDirectives((_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 0,
          id: "vocalSociable",
          modelValue: $setup.petStore.petProfile.vocalSociable,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.petStore.petProfile.vocalSociable) = $event)),
          label: $setup.propFieldText('vocalSociable')
        }, null, 8 /* PROPS */, ["modelValue", "label"])), [
          [_vShow, $setup.isValidProp('vocalSociable')]
        ])
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToAdults'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 1,
          id: "reactionToAdults",
          modelValue: $setup.petStore.petProfile.reactionToAdults,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.petStore.petProfile.reactionToAdults) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToAdults')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToChildren'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 2,
          id: "reactionToChildren",
          modelValue: $setup.petStore.petProfile.reactionToChildren,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.petStore.petProfile.reactionToChildren) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToChildren')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('experienceBeingRidden'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 3,
          id: "experienceBeingRidden",
          modelValue: $setup.petStore.petProfile.experienceBeingRidden,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.petStore.petProfile.experienceBeingRidden) = $event)),
          "label-above": "",
          label: $setup.propFieldText('experienceBeingRidden')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('wearsStirrupOrReigns'))
      ? (_openBlock(), _createBlock($setup["FormCheckBox"], {
          key: 4,
          id: "wearsStirrupOrReigns",
          modelValue: $setup.petStore.petProfile.wearsStirrupOrReigns,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.petStore.petProfile.wearsStirrupOrReigns) = $event)),
          "label-left": "",
          label: $setup.propFieldText('wearsStirrupOrReigns')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('showOrJumpTrained'))
      ? (_openBlock(), _createBlock($setup["FormCheckBox"], {
          key: 5,
          id: "showOrJumpTrained",
          modelValue: $setup.petStore.petProfile.showOrJumpTrained,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.petStore.petProfile.showOrJumpTrained) = $event)),
          "label-left": "",
          label: $setup.propFieldText('showOrJumpTrained')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToKnocking'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 6,
          id: "reactionToKnocking",
          modelValue: $setup.petStore.petProfile.reactionToKnocking,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.petStore.petProfile.reactionToKnocking) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToKnocking')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToVisitors'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 7,
          id: "reactionToVisitors",
          modelValue: $setup.petStore.petProfile.reactionToVisitors,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.petStore.petProfile.reactionToVisitors) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToVisitors')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('bitingHistory'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 8,
          id: "bitingHistory",
          modelValue: $setup.petStore.petProfile.bitingHistory,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.petStore.petProfile.bitingHistory) = $event)),
          "label-above": "",
          label: $setup.propFieldText('bitingHistory')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToOtherAnimals'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 9,
          id: "reactionToOtherAnimals",
          modelValue: $setup.petStore.petProfile.reactionToOtherAnimals,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.petStore.petProfile.reactionToOtherAnimals) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToOtherAnimals')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('fightHistory'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 10,
          id: "fightHistory",
          modelValue: $setup.petStore.petProfile.fightHistory,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.petStore.petProfile.fightHistory) = $event)),
          "label-above": "",
          label: $setup.propFieldText('fightHistory')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToSameAnimalsOutside'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 11,
          id: "reactionToSameAnimalsOutside",
          modelValue: $setup.petStore.petProfile.reactionToSameAnimalsOutside,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.petStore.petProfile.reactionToSameAnimalsOutside) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToSameAnimalsOutside')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToSameAnimalsInside'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 12,
          id: "reactionToSameAnimalsInside",
          modelValue: $setup.petStore.petProfile.reactionToSameAnimalsInside,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.petStore.petProfile.reactionToSameAnimalsInside) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToSameAnimalsInside')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToDogs'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 13,
          id: "reactionToDogs",
          modelValue: $setup.petStore.petProfile.reactionToDogs,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.petStore.petProfile.reactionToDogs) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToDogs')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToCats'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 14,
          id: "reactionToCats",
          modelValue: $setup.petStore.petProfile.reactionToCats,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.petStore.petProfile.reactionToCats) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToCats')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('reactionToSmallAnimals'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 15,
          id: "reactionToSmallAnimals",
          modelValue: $setup.petStore.petProfile.reactionToSmallAnimals,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.petStore.petProfile.reactionToSmallAnimals) = $event)),
          "label-above": "",
          label: $setup.propFieldText('reactionToSmallAnimals')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('experienceWithOtherPets'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 16,
          id: "experienceWithOtherPets",
          modelValue: $setup.petStore.petProfile.experienceWithOtherPets,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.petStore.petProfile.experienceWithOtherPets) = $event)),
          "label-above": "",
          label: $setup.propFieldText('experienceWithOtherPets')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('couldLiveWithAnotherPet'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 17,
          id: "couldLiveWithAnotherPet",
          modelValue: $setup.petStore.petProfile.couldLiveWithAnotherPet,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.petStore.petProfile.couldLiveWithAnotherPet) = $event)),
          "label-above": "",
          label: $setup.propFieldText('couldLiveWithAnotherPet')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('indoorOutdoor'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 18,
          id: "indoorOutdoor",
          modelValue: $setup.petStore.petProfile.indoorOutdoor,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($setup.petStore.petProfile.indoorOutdoor) = $event)),
          "label-above": "",
          label: $setup.propFieldText('indoorOutdoor')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('houseTrained'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 19,
          id: "houseTrained",
          modelValue: $setup.petStore.petProfile.houseTrained,
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.petStore.petProfile.houseTrained) = $event)),
          "label-above": "",
          label: $setup.propFieldText('houseTrained')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('preferredToiletLocation'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 20,
          id: "preferredToiletLocation",
          modelValue: $setup.petStore.petProfile.preferredToiletLocation,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.petStore.petProfile.preferredToiletLocation) = $event)),
          "label-above": "",
          label: $setup.propFieldText('preferredToiletLocation')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('howManyLitterTrays'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 21,
          id: "howManyLitterTrays",
          modelValue: $setup.petStore.petProfile.howManyLitterTrays,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($setup.petStore.petProfile.howManyLitterTrays) = $event)),
          "label-above": "",
          label: $setup.propFieldText('howManyLitterTrays')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('indicateToComeIn'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 22,
          id: "indicateToComeIn",
          modelValue: $setup.petStore.petProfile.indicateToComeIn,
          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($setup.petStore.petProfile.indicateToComeIn) = $event)),
          "label-above": "",
          label: $setup.propFieldText('indicateToComeIn')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('catFlap'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 23,
          id: "catFlap",
          modelValue: $setup.petStore.petProfile.catFlap,
          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($setup.petStore.petProfile.catFlap) = $event)),
          "label-above": "",
          label: $setup.propFieldText('catFlap')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('gardenAccess'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 24,
          id: "gardenAccess",
          modelValue: $setup.petStore.petProfile.gardenAccess,
          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($setup.petStore.petProfile.gardenAccess) = $event)),
          "label-above": "",
          label: $setup.propFieldText('gardenAccess')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    ($setup.isValidProp('keenHunter'))
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 25,
          id: "keenHunter",
          modelValue: $setup.petStore.petProfile.keenHunter,
          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (($setup.petStore.petProfile.keenHunter) = $event)),
          "label-above": "",
          label: $setup.propFieldText('keenHunter')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    (
        $setup.isValidProp('vocalSociable') &&
        ($setup.petStore.pet.petTypeId == $setup.AnimalTypeEnum.Cat ||
          $setup.petStore.pet.petTypeId == $setup.AnimalTypeEnum.Dog)
      )
      ? (_openBlock(), _createBlock($setup["FormFieldYesNoRadio"], {
          key: 26,
          id: "vocalSociable",
          modelValue: $setup.petStore.petProfile.vocalSociable,
          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (($setup.petStore.petProfile.vocalSociable) = $event)),
          label: $setup.propFieldText('vocalSociable')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true),
    (
        $setup.isValidProp('canTakeInstructions') &&
        $setup.petStore.pet.petTypeId != $setup.AnimalTypeEnum.Cat &&
        $setup.petStore.pet.petTypeId != $setup.AnimalTypeEnum.Dog
      )
      ? (_openBlock(), _createBlock($setup["FormField"], {
          key: 27,
          id: "canTakeInstructions",
          modelValue: $setup.petStore.petProfile.canTakeInstructions,
          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => (($setup.petStore.petProfile.canTakeInstructions) = $event)),
          "label-above": "",
          label: $setup.propFieldText('canTakeInstructions')
        }, null, 8 /* PROPS */, ["modelValue", "label"]))
      : _createCommentVNode("v-if", true)
  ]))
}