import { reactive } from 'vue';
import UCSession from 'ucclient-sdk';

interface IPhoneSystemStore {
  session: UCSession | null | undefined;
  connect(): void;
  makeCall(phoneNumber: string): void;
}

export const phoneSystemStore = reactive({
  session: null as UCSession | null | undefined,
  connect(): void {
    this.session ??= new UCSession();
    console.log('connect', this.session);

    const handleLoginEvent = (e: unknown) => {
      //console.log('login event', e);
    };

    const handleCallEvent = (e: unknown) => {
      const call = e;
      console.log('call event', call);
      // if (call.Event === "REMOVE") removeCall(call)
      // else if (call.Event === "UPDATE") upsertCall(call)
    };

    window.addEventListener('ucCall', handleCallEvent);

    window.addEventListener('ucLogin', handleLoginEvent);
    if (this.session !== null && this.session !== undefined) {
      this.session.connect();
    }
  },
  makeCall(phoneNumber: string): void {
    console.log('makeCall', this.session);
    if (this.session !== null && this.session !== undefined) {
      this.session.callController.makeCall(phoneNumber);
    }
  }
}) as IPhoneSystemStore;
