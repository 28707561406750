import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex justify-content-center"
}
const _hoisted_2 = { class: "flex justify-content-end align-items-center m-2 gap-3" }
const _hoisted_3 = { class: "grid mt-0" }
const _hoisted_4 = { class: "col-3" }
const _hoisted_5 = { class: "col-3" }
const _hoisted_6 = { class: "col-3" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.contactStore.contact.contactDetails.contactId < 1)
      ? (_openBlock(), _createBlock($setup["RequiredEntity"], { key: 0 }))
      : ($setup.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["ProgressSpinner"])
          ]))
        : (_openBlock(), _createBlock(_component_ScrollPanel, {
            key: 2,
            id: "volunteer-tab",
            class: "h-full pb-4 pr-4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode($setup["VolunteerAwards"]),
                _createVNode(_component_Button, {
                  label: "Search for Cases",
                  size: "small",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.volunteerCaseMatchDialogVisible = true))
                }),
                _createVNode(_component_Button, {
                  label: "View volunteer registration form",
                  size: "small",
                  onClick: $setup.showVolunteerRegForm
                }),
                _createVNode(_component_Button, {
                  size: "small",
                  label: "Save",
                  onClick: $setup.saveVolunteer
                })
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode($setup["FormFieldSelect"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.volunteerStatus,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.volunteerStatus) = $event)),
                    options: $setup.dropdownsStore.volunteerStatuses,
                    label: "Status",
                    "label-above": "",
                    "option-label": "name",
                    "option-value": "id"
                  }, null, 8 /* PROPS */, ["modelValue", "options"]),
                  _withDirectives(_createVNode($setup["FormField"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.doNotUseReason,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.doNotUseReason) = $event)),
                    "label-above": "",
                    label: "Do not use reason"
                  }, null, 8 /* PROPS */, ["modelValue"]), [
                    [_vShow, $setup.isDoNotUse]
                  ]),
                  _createVNode($setup["FormDatePicker"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.dateAgreementSigned,
                    "onUpdate:modelValue": [
                      _cache[3] || (_cache[3] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.dateAgreementSigned) = $event)),
                      $setup.onCtagUpdate
                    ],
                    label: "CTAG Date",
                    "label-above": ""
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createVNode($setup["FormDatePicker"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.renewalDate,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.renewalDate) = $event)),
                    label: "Renewal date",
                    "label-above": ""
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode($setup["FormTextArea"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.additionalNotes,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.additionalNotes) = $event)),
                    label: "General Comments",
                    "label-above": ""
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createVNode($setup["FormTextArea"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.medicalConditions,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.medicalConditions) = $event)),
                    label: "Medical conditions",
                    "label-above": ""
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createVNode($setup["FormFieldSelect"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.propertyType,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.propertyType) = $event)),
                    options: $setup.dropdownsStore.propertyTypes,
                    label: "Type of Property",
                    "label-above": "",
                    "option-label": "name",
                    "option-value": "id"
                  }, null, 8 /* PROPS */, ["modelValue", "options"]),
                  _createVNode($setup["FormFieldSelect"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.ownGardenDetails,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.ownGardenDetails) = $event)),
                    options: $setup.dropdownsStore.ownGardenDetails,
                    label: "Own Garden",
                    "label-above": "",
                    "option-label": "name",
                    "option-value": "id"
                  }, null, 8 /* PROPS */, ["modelValue", "options"]),
                  _createVNode($setup["FormField"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.ownPetsDetails,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.ownPetsDetails) = $event)),
                    "label-above": "",
                    label: "Own pet?"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode($setup["FormField"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.experience,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.experience) = $event)),
                    "label-above": "",
                    label: "Experience level"
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createVNode($setup["FormFieldSelect"], {
                    modelValue: $setup.contactStore.contact.contactDetails.workStatusId,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.contactStore.contact.contactDetails.workStatusId) = $event)),
                    options: $setup.dropdownsStore.workStatuses,
                    label: "Work status",
                    "label-above": "",
                    "option-label": "name",
                    "option-value": "id"
                  }, null, 8 /* PROPS */, ["modelValue", "options"]),
                  _createVNode($setup["FormField"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.occupation,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.occupation) = $event)),
                    label: "Occupation",
                    "label-above": ""
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode($setup["FormCheckBox"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.keen,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.keen) = $event)),
                    label: "Keen volunteer?"
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createVNode($setup["FormCheckBox"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.vulnerable,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.vulnerable) = $event)),
                    label: "Vulnerable"
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _withDirectives(_createVNode($setup["FormField"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.vulnerableDetails,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.vulnerableDetails) = $event)),
                    "label-above": "",
                    label: "Vulnerable Details"
                  }, null, 8 /* PROPS */, ["modelValue"]), [
                    [_vShow, $setup.contactStore.contact.volunteerDetails.vulnerable]
                  ]),
                  _createVNode($setup["FormCheckBox"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.supportWorker,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.supportWorker) = $event)),
                    label: "Support Worker"
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createVNode($setup["FormCheckBox"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.dofEActive,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.dofEActive) = $event)),
                    label: "DOE Active?"
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _withDirectives(_createVNode($setup["FormFieldSelect"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.dofELevelId,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.dofELevelId) = $event)),
                    options: $setup.dropdownsStore.dofELevels,
                    label: "Level",
                    "label-above": "",
                    "option-label": "name",
                    "option-value": "id"
                  }, null, 8 /* PROPS */, ["modelValue", "options"]), [
                    [_vShow, $setup.contactStore.contact.volunteerDetails.dofEActive]
                  ]),
                  _withDirectives(_createVNode($setup["FormField"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.dofECompletionTime,
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.dofECompletionTime) = $event)),
                    "label-above": "",
                    label: "Completion Time"
                  }, null, 8 /* PROPS */, ["modelValue"]), [
                    [_vShow, $setup.contactStore.contact.volunteerDetails.dofEActive]
                  ]),
                  _createVNode($setup["FormCheckBox"], {
                    modelValue: $setup.contactStore.contact.volunteerDetails.youngVolunteer,
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.contactStore.contact.volunteerDetails.youngVolunteer) = $event)),
                    label: "Young Volunteer?"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ])
              ])
            ]),
            _: 1 /* STABLE */
          })),
    ($setup.volunteerCaseMatchDialogVisible)
      ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
          _createVNode($setup["VolunteerCasePicker"], {
            "show-volunteer-task-matching": true,
            title: "Search case",
            "contact-id": $setup.contactStore.contact.contactDetails.contactId,
            onCancel: _cache[21] || (_cache[21] = ($event: any) => ($setup.volunteerCaseMatchDialogVisible = false))
          }, null, 8 /* PROPS */, ["contact-id"])
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["TallyFormRendering"], {
      title: "Volunteer Registration Form",
      visible: $setup.volunteerRegistrationFormDialogVisible,
      questions: $setup.volunteerRegistrationFormData,
      onCloseDialog: $setup.closeRegFormDialog
    }, null, 8 /* PROPS */, ["visible", "questions"])
  ], 64 /* STABLE_FRAGMENT */))
}