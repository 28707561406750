import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, withCtx as _withCtx, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full grid" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-12 flex justify-content-end" }
const _hoisted_6 = { class: "flex align-items-center w-full gap-3" }
const _hoisted_7 = { class: "flex justify-content-end gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: $setup.dialogVisible,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (($setup.dialogVisible) = $event)),
    closable: false,
    modal: "",
    header: $setup.props.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["FormField"], {
            modelValue: $setup.search.firstName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search.firstName) = $event)),
            label: "First name",
            "label-above": ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["FormField"], {
            modelValue: $setup.search.lastName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.search.lastName) = $event)),
            label: "Last name",
            "label-above": ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["FormField"], {
            modelValue: $setup.search.postCode,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.search.postCode) = $event)),
            label: "Post code",
            "label-above": ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            label: "Search",
            loading: $setup.searchInProgress,
            onClick: $setup.getPotentialContacts
          }, null, 8 /* PROPS */, ["loading"])
        ])
      ]),
      _createVNode($setup["ListBox"], {
        modelValue: $setup.selectedRow,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.selectedRow) = $event)),
        options: $setup.contacts,
        "option-label": "firstName",
        class: "w-full",
        "input-id": "searchContact",
        "list-style": "max-height:200px;",
        onFilter: _cache[4] || (_cache[4] = ($event: any) => ($setup.getPotentialContacts()))
      }, {
        option: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, _toDisplayString(slotProps.option.firstName), 1 /* TEXT */),
            _createElementVNode("div", null, _toDisplayString(slotProps.option.lastName), 1 /* TEXT */),
            _createElementVNode("div", null, _toDisplayString(slotProps.option.postCode), 1 /* TEXT */)
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "options"]),
      _createElementVNode("div", null, [
        _createVNode($setup["Paginator"], {
          rows: $setup.rowsPerPage,
          "total-records": $setup.totalRecords,
          onPage: $setup.changePage
        }, null, 8 /* PROPS */, ["rows", "total-records"])
      ]),
      _createCommentVNode(" need other stuff? Stick it in a damn slot. "),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Button, {
          label: "Close",
          severity: "secondary",
          onClick: $setup.hide
        }),
        _createVNode(_component_Button, {
          label: "Select",
          onClick: $setup.selectContact
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "header"]))
}