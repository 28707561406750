import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "flex align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.noLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.computedId,
          class: _normalizeClass(_ctx.labelClass)
        }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.dropdownClass)
    }, [
      _createVNode(_component_Dropdown, {
        id: _ctx.computedId + '-dropdown',
        modelValue: _ctx.internalValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
        class: "w-full",
        "option-value": _ctx.optionValue,
        "option-label": _ctx.optionLabel,
        options: _ctx.filteredList,
        "show-clear": _ctx.showClear,
        editable: _ctx.editable,
        value: _ctx.optionValue,
        "input-id": _ctx.computedId,
        placeholder: _ctx.label,
        disabled: _ctx.readOnly,
        filter: _ctx.showFilter
      }, {
        option: _withCtx(({ option }) => [
          _createElementVNode("span", {
            innerHTML: _ctx.highlightText(option[_ctx.optionLabel])
          }, null, 8 /* PROPS */, _hoisted_3)
        ]),
        empty: _withCtx(() => [
          (_ctx.canAddNew)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, " Add new " + _toDisplayString(_ctx.label?.toLowerCase()) + " item: " + _toDisplayString(_ctx.modelValue), 1 /* TEXT */),
                _createVNode(_component_Button, {
                  severity: "warning",
                  rounded: "",
                  size: "small",
                  text: "",
                  disabled: !_ctx.isFreeTextValue || !_ctx.internalValue,
                  icon: "pi pi-plus",
                  onClick: _ctx.addNewItem
                }, null, 8 /* PROPS */, ["disabled", "onClick"])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["id", "modelValue", "option-value", "option-label", "options", "show-clear", "editable", "value", "input-id", "placeholder", "disabled", "filter"])
    ], 2 /* CLASS */)
  ]))
}