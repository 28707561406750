import { Router } from 'vue-router';

const unfoundHandler = async (
  config: RequestInit | undefined,
  response: Response,
  router: Router
) => {
  if (!config || config.method !== 'GET') return response;

  if (response.status === 404) {
    const clonedResponse = response.clone();
    const resultBody = await clonedResponse.json();
    router.push({
      name: 'notFound',
      params: { message: resultBody.ErrorMessage }
    });
  }
  return response;
};

export default unfoundHandler;
