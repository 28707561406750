import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-content-end gap-2 mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.btnVisible)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          label: "Assign/Reassign",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.visible = true))
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_Dialog, {
      visible: $setup.visible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (($setup.visible) = $event)),
      modal: "",
      header: "Assign/Reassign Tasks",
      style: { width: '25rem' },
      draggable: false,
      onHide: $setup.onClose
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode($setup["UsersSelect"], {
            "assigned-user-id": $setup.selectedUser,
            "onUpdate:assignedUserId": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedUser) = $event)),
            "enable-groups": false,
            "enable-users": true,
            placeholder: "Assign to"
          }, null, 8 /* PROPS */, ["assigned-user-id"])
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Button, {
            type: "button",
            label: "Cancel",
            severity: "secondary",
            onClick: $setup.onClose
          }),
          _createVNode(_component_Button, {
            disabled: $setup.selectedUser == undefined,
            type: "button",
            label: "Save",
            onClick: $setup.onSaveClicked
          }, null, 8 /* PROPS */, ["disabled"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ]))
}