import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = {
  key: 0,
  class: "flex gap-2 align-items-center justify-content-center",
  style: {"height":"50px"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 1,
  class: "flex gap-2 align-items-center justify-content-center",
  style: {"height":"50px"}
}
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modal: "",
    style: { width: '60%' },
    visible: _ctx.dialogVisible
  }, {
    footer: _withCtx(() => [
      _createCommentVNode(" not zero, because zero is always the picker "),
      _createCommentVNode(" Potential future improvement: We can add these into objects, similar to how the modal steps are done, so that we tie them together in js, rather than using v-ifs etc "),
      _createCommentVNode(" tech debt: Add proper typing for the modal sections & buttons."),
      (_ctx.steps.length > 0 && _ctx.expense.expenseMode == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, [
              (_ctx.selectedStep > 0)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "mr-2",
                    outlined: "",
                    label: "Back",
                    severity: "warning",
                    onClick: _ctx.previousStep
                  }, null, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            (_ctx.selectedStep !== _ctx.steps.length - 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_Button, {
                    severity: "warning",
                    label: "Next",
                    onClick: _ctx.nextStep
                  }, null, 8 /* PROPS */, ["onClick"])
                ]))
              : _createCommentVNode("v-if", true),
            (
            _ctx.expense.expenseMode !== undefined &&
            _ctx.selectedStep === _ctx.steps.length - 1 &&
            _ctx.editable
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_Button, {
                    severity: "warning",
                    label: "Save and exit",
                    onClick: _cache[0] || (_cache[0] = () => _ctx.save())
                  }),
                  _createVNode(_component_Button, {
                    severity: "warning",
                    label: "Finish and send to finance",
                    onClick: _cache[1] || (_cache[1] = () => _ctx.save(true))
                  })
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.steps.length > 0 && _ctx.expense.expenseMode == 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", null, [
              (_ctx.selectedStep > 0)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "mr-2",
                    outlined: "",
                    label: "Back",
                    severity: "warning",
                    onClick: _ctx.previousStep
                  }, null, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true),
              (_ctx.selectedStep > 0)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    outlined: "",
                    severity: "warning",
                    label: "Save and exit",
                    onClick: _cache[2] || (_cache[2] = () => _ctx.save())
                  }))
                : _createCommentVNode("v-if", true),
              (
              _ctx.selectedStep > 0 &&
              (_ctx.currentUserStore.isFinanceUser ||
                _ctx.currentUserStore.isManagementUser)
            )
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 2,
                    class: "mr-2",
                    label: "Finish and send to finance",
                    severity: "warning",
                    onClick: _cache[3] || (_cache[3] = () => _ctx.save(true))
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            (_ctx.selectedStep !== _ctx.steps.length - 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    outlined: "",
                    severity: "warning",
                    label: "Save and exit",
                    onClick: _cache[4] || (_cache[4] = () => _ctx.save())
                  }),
                  _createVNode(_component_Button, {
                    severity: "warning",
                    label: "Add Invoices",
                    onClick: _ctx.nextStep
                  }, null, 8 /* PROPS */, ["onClick"])
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ScrollPanel, { class: "w-full h-full" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                (_ctx.selectedStep == index)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(step.component), {
                      key: 0,
                      pets: _ctx.pets,
                      volunteers: _ctx.volunteers,
                      "expense-code": step.expenseCode,
                      expense: _ctx.expense,
                      "supplier-id": _ctx.supplierId,
                      onProgressStepper: _ctx.nextStep,
                      onRegressStepper: _ctx.previousStep,
                      "onUpdate:modelValue": step.updateCallback
                    }, null, 40 /* PROPS, NEED_HYDRATION */, ["pets", "volunteers", "expense-code", "expense", "supplier-id", "onProgressStepper", "onRegressStepper", "onUpdate:modelValue"]))
                  : _createCommentVNode("v-if", true)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible"]))
}