import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid" }
const _hoisted_2 = { class: "field col-2" }
const _hoisted_3 = { class: "field col-1 pl-0 mt-2" }
const _hoisted_4 = { class: "h-full bg-white" }
const _hoisted_5 = { class: "field grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PetsNominatedPersonDialog = _resolveComponent("PetsNominatedPersonDialog")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PetsNominatedPersonDialog, {
      modelValue: _ctx.addNominatedPersonVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addNominatedPersonVisible) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Button, {
          class: "p-button-rounded mt-2",
          icon: "pi pi-plus",
          size: "small",
          rounded: "",
          label: "Add Nominated Person",
          severity: "warning",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addNominatedPersonVisible = true))
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Button, {
          class: "p-button-rounded",
          icon: "pi pi-trash",
          disabled: _ctx.isDeleteDisabled,
          rounded: "",
          text: "",
          severity: "secondary",
          "aria-label": "Delete selected",
          onClick: _ctx.onDeleteClick
        }, null, 8 /* PROPS */, ["disabled", "onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_DataTable, {
          selection: _ctx.selectedNominatedPersons,
          "onUpdate:selection": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedNominatedPersons) = $event)),
          paginator: "",
          rows: 5,
          value: _ctx.petStore.petOverview.petNominatedPersons,
          "data-key": "nominatedPersonId",
          class: "col-12 mb-3 md:col-9 md:mb-0 p-datatable-sm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              "selection-mode": "multiple",
              "header-style": "width: 3rem"
            }),
            _createVNode(_component_Column, {
              field: "name",
              header: "Name"
            }),
            _createVNode(_component_Column, {
              field: "role",
              header: "Role"
            }),
            _createVNode(_component_Column, {
              field: "email",
              header: "Email"
            }),
            _createVNode(_component_Column, { header: "Telephone" }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.formatTelephone(slotProps.data.telephone)), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["selection", "value"])
      ])
    ])
  ]))
}