import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.labelAbove)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.computedId,
          class: "col-12 w-full m-0"
        }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.labelAbove)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: "ml-2",
            for: _ctx.computedId
          }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_4))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_Textarea, {
        id: _ctx.computedId,
        modelValue: _ctx.internalValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
        rows: _ctx.rows,
        cols: _ctx.cols
      }, null, 8 /* PROPS */, ["id", "modelValue", "rows", "cols"])
    ])
  ]))
}