import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "p-3 pb-1" }
const _hoisted_3 = { class: "text-sm font-normal text-400" }
const _hoisted_4 = { class: "pr-2 due-date" }
const _hoisted_5 = {
  key: 0,
  class: "text-red-500 text-xl font-bold"
}
const _hoisted_6 = { class: "assignee" }
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 1,
  class: "mt-2 mr-6 font-bold task-title"
}
const _hoisted_9 = { class: "mt-2 mr-6 description" }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = {
  key: 2,
  class: "mt-2"
}
const _hoisted_12 = { class: "pr-2" }
const _hoisted_13 = { class: "text-orange-300 repeatable-task-frequency" }
const _hoisted_14 = { class: "mt-2" }
const _hoisted_15 = { class: "text-orange-300 pr-2 task-status" }
const _hoisted_16 = {
  key: 0,
  class: "text-400 task-status-completed"
}
const _hoisted_17 = { class: "mt-2 text-sm font-normal text-400 created-by" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    id: `task-${$setup.props.item.userTaskId}`,
    class: _normalizeClass(["task-item border-round-lg surface-100 shadow-0 mb-4 relative", `${$setup.isRowDeleted($props.item)} ${$setup.isHighlighted ? 'highlighted' : ''} ${$setup.showCursorPointer()}`]),
    onClick: $setup.openEditTask
  }, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.isEllipsisViewable)
        ? (_openBlock(), _createBlock($setup["EllipsisButton"], {
            key: 0,
            class: "absolute",
            style: {"right":"1rem"}
          }, {
            default: _withCtx(() => [
              ($setup.canUserEdit)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    text: "",
                    class: "flex",
                    size: "small",
                    label: "Edit Task",
                    onClick: $setup.openEditTask
                  }))
                : _createCommentVNode("v-if", true),
              ($setup.canUserDelete)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    text: "",
                    class: "flex",
                    size: "small",
                    label: "Delete Task",
                    severity: "danger",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.confirmTaskDeletion($props.item)))
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, "Due " + _toDisplayString($setup.displayDate), 1 /* TEXT */),
        ($props.item.isHighPriority)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, " ! "))
          : _createCommentVNode("v-if", true),
        _createElementVNode("span", _hoisted_6, [
          ($setup.assignee && $setup.assignedUserHref)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "ml-2",
                href: $setup.assignedUserHref
              }, [
                _createVNode($setup["Chip"], { label: $setup.assignee }, null, 8 /* PROPS */, ["label"])
              ], 8 /* PROPS */, _hoisted_7))
            : ($setup.assignee)
              ? (_openBlock(), _createBlock($setup["Chip"], {
                  key: 1,
                  class: "ml-2",
                  label: $setup.assignee
                }, null, 8 /* PROPS */, ["label"]))
              : _createCommentVNode("v-if", true)
        ])
      ]),
      ($props.item.taskTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($props.item.taskTitle), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_9, [
        (
            $props.item.userTaskTypeId == $setup.UserTaskTypeEnum.CaseQuote ||
            $props.item.userTaskTypeId == $setup.UserTaskTypeEnum.CaseInvoice
          )
          ? (_openBlock(), _createBlock($setup["HyperLinkedText"], {
              key: 0,
              text: $props.item.description,
              "route-name": "caseProfile",
              "route-params": { id: $props.item.caseId },
              "query-params": { tab: 'expenses' },
              "id-parameter-name": "caseExpenseId",
              "is-param": false
            }, null, 8 /* PROPS */, ["text", "route-params"]))
          : ($props.item.userTaskTypeId == $setup.UserTaskTypeEnum.VolunteerExpense)
            ? (_openBlock(), _createBlock($setup["HyperLinkedText"], {
                key: 1,
                text: $props.item.description,
                "route-name": "contactProfile",
                "route-params": { id: $props.item.contactId },
                "query-params": { tab: 'volunteerExpenses' },
                "id-parameter-name": "volunteerExpenseId",
                "is-param": false
              }, null, 8 /* PROPS */, ["text", "route-params"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString($props.item.description), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */)),
        ($props.item.expenseId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item?.taskExpense?.pets, (pet) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: pet.petId
                }, [
                  _createVNode(_component_router_link, {
                    to: {
                name: 'pets',
                params: {
                  id: pet.petId,
                  tab: 'expenses',
                  tabContentId: $props.item.expenseId
                }
              }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Pet " + _toDisplayString(pet.petName) + " - Invoice # " + _toDisplayString($props.item?.taskExpense?.invoiceNumber), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      ($setup.displayRepeatableTaskFrequency)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, [
              _createTextVNode(" Repeated: "),
              _createElementVNode("span", _hoisted_13, _toDisplayString($setup.repeatableTaskFrequencyName), 1 /* TEXT */)
            ])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("span", _hoisted_15, _toDisplayString($props.item.currentStatus), 1 /* TEXT */),
        ($props.item.userTaskStatusId == $setup.TaskStatusEnum.Complete)
          ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString($setup.formatDate($props.item.completedDate, 'DD/MM/YYYY')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_17, " Created on " + _toDisplayString($setup.formatDate($props.item.createdDate, 'DD/MM/YYYY HH:mm')) + " by " + _toDisplayString($props.item.createdByName), 1 /* TEXT */)
    ])
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}