import { TaskStatusEnum, UserTaskDto } from '@/clients/client.gen';

export default class TaskService {
  sortTasks(tasks: UserTaskDto[], ascending: boolean = true) {
    const sortOrder = ascending ? 1 : -1;

    const compareDates = (a: Date | undefined, b: Date | undefined) => {
      if (a === undefined && b === undefined) return 0;
      if (a === undefined) return sortOrder * -1;
      if (b === undefined) return sortOrder * 1;
      return sortOrder * (a.getTime() - b.getTime());
    };

    const compareTasks = (a: UserTaskDto, b: UserTaskDto) => {
      if (
        a.userTaskStatusId === TaskStatusEnum.Pending &&
        b.userTaskStatusId === TaskStatusEnum.Pending
      ) {
        if (a.isHighPriority !== b.isHighPriority) {
          return a.isHighPriority ? -1 : 1;
        }
        return compareDates(a.scheduledDate, b.scheduledDate);
      }

      if (a.userTaskStatusId === TaskStatusEnum.Pending) return -1;
      if (b.userTaskStatusId === TaskStatusEnum.Pending) return 1;

      return compareDates(a.scheduledDate, b.scheduledDate);
    };

    return tasks.sort(compareTasks);
  }
}

export const taskService = new TaskService();
