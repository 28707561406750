import { FlattenedIncomeDto, IncomeClient } from '@/clients/client.gen';
import { reactive } from 'vue';

export const singleIncomeStore = reactive({
  income: new FlattenedIncomeDto(),
  price100CT: 0.0,
  clear() {
    this.income = new FlattenedIncomeDto();
  },
  setIncome(val: FlattenedIncomeDto) {
    this.income = { ...val } as FlattenedIncomeDto;
  },
  async getPice100CT() {
    const client = new IncomeClient();
    if (!this.price100CT) this.price100CT = await client.get100CTPrice();
    return this.price100CT;
  }
});
