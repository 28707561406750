import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-column gap-2" }
const _hoisted_2 = { class: "flex justify-content-between" }
const _hoisted_3 = { for: "searchContact" }
const _hoisted_4 = { class: "w-full grid" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = { class: "col-4" }
const _hoisted_8 = { class: "col-12 flex justify-content-end" }
const _hoisted_9 = { class: "grid" }
const _hoisted_10 = { class: "col-4 contact-name" }
const _hoisted_11 = { class: "col contact-postcode" }
const _hoisted_12 = { class: "col contact-telephone" }
const _hoisted_13 = { class: "col contact-other-telephone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Dialog"], {
      visible: $setup.visible,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => (($setup.visible) = $event)),
      modal: "",
      draggable: false,
      closeable: false,
      class: "contact-picker",
      style: {"height":"600px"},
      header: $props.title,
      onHide: $setup.reset
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ScrollPanel"], null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("label", _hoisted_3, _toDisplayString($setup.props.searchText), 1 /* TEXT */),
                _createVNode(_component_Button, {
                  label: "Add new contact",
                  icon: "pi pi-plus",
                  onClick: $setup.addNewContact
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode($setup["FormField"], {
                    modelValue: $setup.search.firstName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search.firstName) = $event)),
                    label: "First name",
                    "label-above": ""
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode($setup["FormField"], {
                    modelValue: $setup.search.lastName,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.search.lastName) = $event)),
                    label: "Last name",
                    "label-above": ""
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode($setup["FormField"], {
                    modelValue: $setup.search.postCode,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.search.postCode) = $event)),
                    label: "Post code",
                    "label-above": ""
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Button, {
                    label: "Search",
                    loading: $setup.searchInProgress,
                    onClick: $setup.getPotentialContacts
                  }, null, 8 /* PROPS */, ["loading"])
                ])
              ]),
              _createVNode($setup["ListBox"], {
                modelValue: $setup.selectedRow,
                "onUpdate:modelValue": [
                  _cache[3] || (_cache[3] = ($event: any) => (($setup.selectedRow) = $event)),
                  _cache[4] || (_cache[4] = ($event: any) => ($setup.selectContact()))
                ],
                filter: "",
                options: $setup.contacts,
                "option-label": "firstName",
                class: "w-full",
                "input-id": "searchContact",
                "list-style": "max-height:100px;",
                onFilter: _cache[5] || (_cache[5] = ($event: any) => ($setup.getPotentialContacts()))
              }, {
                option: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(slotProps.option.titleString) + " " + _toDisplayString(slotProps.option.firstName) + " " + _toDisplayString(slotProps.option.lastName), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(slotProps.option.postCode), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(slotProps.option.mobile), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(slotProps.option.alternativeMobile), 1 /* TEXT */)
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue", "options"]),
              _renderSlot(_ctx.$slots, "default"),
              _createVNode(_component_Button, {
                class: "w-full",
                label: $setup.props.linkButtonText,
                onClick: $setup.sendContact
              }, null, 8 /* PROPS */, ["label"])
            ])
          ]),
          _: 3 /* FORWARDED */
        })
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["visible", "header"]),
    _createVNode($setup["NewContactModal"], {
      "modal-visible": $setup.newContactModalVisible,
      onProcessComplete: $setup.newContactCreated,
      onUpdateVisible: $setup.updateContactModalVisible
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modal-visible"])
  ], 64 /* STABLE_FRAGMENT */))
}