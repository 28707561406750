
  import { defineComponent } from 'vue';
  import { NominatedPersonDto } from '@/clients/client.gen';
  import { petStore } from '../../stores/pet.store';
  import PetsNominatedPersonDialog from './PetsNominatedPersonDialog.vue';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import Button from 'primevue/button';
  import { formatTelephone } from '@/composables/UtilityFunctions';

  interface Data {
    selectedNominatedPersons: Array<NominatedPersonDto> | null;
    addNominatedPersonVisible: boolean;
    saving: boolean;
  }

  export default defineComponent({
    components: {
      Button,
      PetsNominatedPersonDialog,
      DataTable,
      Column
    },
    data(): Data {
      return {
        selectedNominatedPersons: null,
        addNominatedPersonVisible: false,
        saving: false
      };
    },
    computed: {
      petStore() {
        return petStore;
      },
      isDeleteDisabled(): boolean {
        return (
          !this.selectedNominatedPersons ||
          !this.selectedNominatedPersons.length
        );
      }
    },
    methods: {
      formatTelephone,
      async onDeleteClick() {
        if (!this.selectedNominatedPersons?.length) return;
        await this.petStore.deleteNominatedPersons(
          this.selectedNominatedPersons
        );
        this.selectedNominatedPersons = null;
      }
    }
  });
