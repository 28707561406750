import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full b-white" }
const _hoisted_2 = { class: "grid h-full m-0 bg-white" }
const _hoisted_3 = { class: "col-2 h-full pr-3" }
const _hoisted_4 = { class: "h-full col-10 h-full flex flex-row-reverse" }
const _hoisted_5 = { class: "flex align-items-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineMessage = _resolveComponent("InlineMessage")!
  const _component_InputIcon = _resolveComponent("InputIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_IconField = _resolveComponent("IconField")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FormDatePicker = _resolveComponent("FormDatePicker")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InlineMessage, { severity: "info" }, {
      default: _withCtx(() => [
        _createTextVNode("What users should have access to this?")
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_DataTable, {
      filters: _ctx.filters,
      "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
      selection: _ctx.selectedRow,
      "onUpdate:selection": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedRow) = $event)),
      value: _ctx.emails,
      "selection-mode": "single",
      "data-key": "caseId",
      "responsive-layout": "scroll",
      "filter-display": "menu",
      "global-filter-fields": _ctx.globalFilterFields,
      paginator: true,
      rows: 10,
      loading: _ctx.loading,
      "sort-field": "dateAssigned",
      "sort-order": 1,
      "table-style": "min-width: 50rem",
      "table-class": "hidden_filter_footer",
      class: "mb-3 thin_header",
      onRowSelect: _ctx.onRowSelect
    }, {
      loading: _withCtx(() => [
        _createTextVNode(" Loading Emails. Please wait. ")
      ]),
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_IconField, { "icon-position": "left" }, {
              default: _withCtx(() => [
                _createVNode(_component_InputIcon, { class: "pi pi-search" }),
                _createVNode(_component_InputText, {
                  modelValue: _ctx.filters['global'].value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters['global'].value) = $event)),
                  placeholder: "Search emails",
                  class: "p-inputtext-small p-ml-2 bg-gray-200"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Button, {
                class: "mr-3",
                outlined: "",
                icon: "pi pi-times",
                label: "Clear filters",
                severity: "warning",
                onClick: _ctx.onClearFiltersClick
              }, null, 8 /* PROPS */, ["onClick"]),
              _createVNode(_component_router_link, { to: "/email/profile" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    label: "Add",
                    severity: "warning"
                  })
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Template name",
          sortable: ""
        }),
        _createVNode(_component_Column, {
          field: "body",
          header: "Body",
          sortable: ""
        }),
        _createVNode(_component_Column, {
          field: "createdByName",
          header: "Created by",
          sortable: "",
          "show-filter-match-modes": false,
          "filter-menu-class": "hidden_filter_footer"
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.createdByName), 1 /* TEXT */)
          ]),
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              placeholder: "All users",
              "option-label": "name",
              "option-value": "id",
              options: _ctx.availableCreatedBy,
              class: "p-column-filter",
              onChange: filterCallback
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          field: "dateCreated",
          header: "Date created",
          "data-type": "date",
          "filter-field": "dateCreated",
          style: {"min-width":"10rem"},
          sortable: ""
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.toGBDateString(slotProps.data.dateCreated)), 1 /* TEXT */)
          ]),
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createElementVNode("div", null, [
              _createVNode(_component_FormDatePicker, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                onChange: filterCallback
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange"])
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["filters", "selection", "value", "global-filter-fields", "loading", "onRowSelect"])
  ]))
}