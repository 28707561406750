import { withModifiers as _withModifiers, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["Button"], {
      icon: "pi pi-ellipsis-v",
      severity: "secondary",
      text: "",
      rounded: "",
      size: "small",
      "aria-label": "More actions",
      onClick: _withModifiers($setup.toggleOverlayPanel, ["stop"])
    }),
    _createVNode($setup["OverlayPanel"], { ref: "overlayRef" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3 /* FORWARDED */
    }, 512 /* NEED_PATCH */)
  ]))
}