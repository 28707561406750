import { reactive } from 'vue';
import {
  UserTaskDto,
  UserTaskClient,
  TaskStatusEnum
} from '@/clients/client.gen';
import { isToday, isWithinWeek } from '@/composables/UtilityFunctions';

export const open = (task: UserTaskDto) =>
  task.userTaskStatusId == TaskStatusEnum.Pending;

export const due = (task: UserTaskDto) =>
  task.userTaskStatusId == TaskStatusEnum.Pending &&
  task.scheduledDate &&
  isToday(task.scheduledDate);

export const highPriority = (task: UserTaskDto) =>
  task.userTaskStatusId === TaskStatusEnum.Pending && task.isHighPriority;

export const completed = (task: UserTaskDto) =>
  task.userTaskStatusId === TaskStatusEnum.Complete &&
  task.completedDate &&
  isWithinWeek(task.completedDate);

export const notCompletedOrCancelled = (task: UserTaskDto) =>
  task.userTaskStatusId !== TaskStatusEnum.Complete &&
  task.userTaskStatusId !== TaskStatusEnum.Cancelled;

export const tasksDashboard = reactive({
  tasks: [] as Array<UserTaskDto>,
  client: new UserTaskClient(),
  filteredTasks: [] as Array<UserTaskDto>,
  loading: false,
  isGroupView: false,

  async getData() {
    this.loading = true;
    try {
      this.tasks = await this.client.get(this.isGroupView);
      this.filteredTasks = [...this.tasks];

      this.setFilter(notCompletedOrCancelled);
    } catch {
      this.loading = false;
    }
    this.loading = false;
  },

  setFilter(filter: (task: UserTaskDto) => void) {
    const filtered = this.tasks.filter(filter);
    this.filteredTasks = filtered ? [...filtered] : [...this.tasks];
  },

  getTaskCount(filter: (task: UserTaskDto) => void) {
    return this.tasks.filter(filter).length;
  }
});
