import {
  UserNotificationDto,
  UserNotificationTypeEnum
} from '../clients/client.gen';

export default class NotificationService {
  isCaseNotification = (notification: UserNotificationDto) => {
    return (
      notification.userNotificationTypeId ===
        UserNotificationTypeEnum.CaseAssigned ||
      notification.userNotificationTypeId ===
        UserNotificationTypeEnum.CaseTaskCreated ||
      notification.userNotificationTypeId ===
        UserNotificationTypeEnum.CaseStatusChange
    );
  };

  isTaskNotification = (notification: UserNotificationDto) => {
    return (
      notification.userNotificationTypeId === UserNotificationTypeEnum.Task ||
      notification.userNotificationTypeId ===
        UserNotificationTypeEnum.TaskCancelled
    );
  };

  isEmailNotification = (notification: UserNotificationDto) => {
    return (
      notification.userNotificationTypeId === UserNotificationTypeEnum.Email
    );
  };
}

export const notificationService = new NotificationService();
