
  import { defineComponent } from 'vue';

  export default defineComponent({
    components: {},
    data(): any {
      return {};
    },
    async created() {}
  });
