import { reactive } from 'vue';
import {
  AdvancedSearchCommand,
  SearchClient,
  SearchFilterEnum,
  SearchObjectMember,
  SearchParameter,
  SearchTypeEnum,
  SearchableCase,
  SearchableContact,
  SearchablePet,
  SearchableSupplier
} from '@/clients/client.gen';

export interface ISearchOption {
  label: string;
  icon: NodeRequire;
  simpleSearchPlaceholder: string;
  searchType: SearchTypeEnum;
  fields: SearchObjectMember[];
  searchTitle: string;
}

const client = new SearchClient();

const options: ISearchOption[] = [
  {
    label: 'Contacts',
    icon: require('@/assets/images/Nav icons/Contact.svg'),
    simpleSearchPlaceholder: 'Search by Contact ID.',
    searchType: SearchTypeEnum.Contact,
    fields: [] as SearchObjectMember[],
    searchTitle: 'Contacts advanced search'
  },
  {
    label: 'Cases',
    icon: require('@/assets/images/Nav icons/Cases.svg'),
    simpleSearchPlaceholder: 'Search by Case ID.',
    searchType: SearchTypeEnum.Case,
    fields: [] as SearchObjectMember[],
    searchTitle: 'Cases advanced search'
  },
  {
    label: 'Pets',
    icon: require('@/assets/images/Nav icons/Contact.svg'),
    simpleSearchPlaceholder: 'Search by Pet ID.',
    searchType: SearchTypeEnum.Pet,
    fields: [] as SearchObjectMember[],
    searchTitle: 'Pets advanced search'
  },
  {
    label: 'Suppliers',
    icon: require('@/assets/images/Nav icons/Suppliers.svg'),
    simpleSearchPlaceholder: 'Search by Supplier ID.',
    searchType: SearchTypeEnum.Supplier,
    fields: [] as SearchObjectMember[],
    searchTitle: 'Suppliers advanced search'
  }
];

export const searchStore = reactive({
  selectedOption: options[0] as ISearchOption,
  options: options as ISearchOption[],
  parameters: new Array<SearchParameter>(),
  showAdvancedSearchPanel: false,
  searchRenderKey: 0,
  currentPage: 0,
  sortField: undefined as string | undefined,
  sortOrder: 1 as number | undefined,
  metaLoaded: false,
  reRenderSearchBar() {
    this.searchRenderKey += 1;
  },
  async loadSearchMeta(): Promise<void> {
    if (this.metaLoaded) return;
    const clientResponse = await client.getSearchMetaData();
    if (clientResponse.success && clientResponse.result) {
      this.options[0].fields = clientResponse.result.contactMeta;
      this.options[1].fields = clientResponse.result.caseMeta;
      this.options[2].fields = clientResponse.result.petMeta;
      this.options[3].fields = clientResponse.result.supplierMeta;
      this.metaLoaded = true;
    }
  },
  async performSearch(command: AdvancedSearchCommand) {
    const result = await client.performSearch(command);

    if (!result.success) throw new Error('The search failed.');
    return result;
  },
  async performStoredSearch() {
    const request = new AdvancedSearchCommand();
    request.searchParameters = this.parameters;
    request.currentPage = this.currentPage;
    request.pageSize = 10;
    request.searchType = this.selectedOption.searchType;
    return await this.performSearch(request);
  },
  async PerformSimpleSearch(id: string) {
    const request = createSimpleSearchRequest(
      this.selectedOption.searchType,
      id
    );

    const searchResult = await client.performSearch(request);
    if (
      searchResult.success &&
      searchResult.result &&
      searchResult.result.data
    ) {
      const result = searchResult.result.data[0];
      switch (this.selectedOption.searchType) {
        case SearchTypeEnum.Contact:
          return {
            name: 'contactProfile',
            params: { id: (result as SearchableContact).contactId }
          };
        case SearchTypeEnum.Case:
          return {
            name: 'caseProfile',
            params: { id: (result as SearchableCase).caseId }
          };
        case SearchTypeEnum.Supplier:
          return {
            name: 'supplierProfile',
            params: { id: (result as SearchableSupplier).supplierId }
          };
        case SearchTypeEnum.Pet:
          return {
            name: 'pets',
            params: { id: (result as SearchablePet).petId }
          };
        default:
          throw new Error(
            'Could not translate search result into relevant router link'
          );
      }
    }
    return undefined;
  }
});

function createSimpleSearchRequest(searchType: SearchTypeEnum, id: string) {
  const request = new AdvancedSearchCommand();
  request.searchType = searchType;
  request.pageSize = 1;
  request.currentPage = 0;
  const searchParameter = new SearchParameter();
  searchParameter.propertyName = getSearchPropertyName(searchType);
  searchParameter.filterId = SearchFilterEnum.TextIsExactly;
  searchParameter.searchValue = id;
  request.searchParameters.push(searchParameter);
  return request;
}

function getSearchPropertyName(searchType: SearchTypeEnum) {
  switch (searchType) {
    case SearchTypeEnum.Contact:
      return 'ContactId';
    case SearchTypeEnum.Case:
      return 'CaseId';
    case SearchTypeEnum.Pet:
      return 'PetId';
    case SearchTypeEnum.Supplier:
      return 'SupplierId';
    default:
      throw new Error('The requested search type is invalid');
  }
}
