import { CaseTypeEnum } from '@/clients/client.gen';
import { caseStore } from '@/stores/case.store';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useCommsRouterData = () => {
  const route = useRoute();

  const id = computed((): number => {
    const idString = Array.isArray(route.params.id)
      ? route.params.id[0]
      : route.params.id;
    return parseInt(idString);
  });

  const isContactProfile = computed((): boolean => {
    return route.name == 'contactProfile';
  });
  const isCaseProfile = computed((): boolean => {
    return route.name == 'caseProfile';
  });
  const isSupplierProfile = computed((): boolean => {
    return route.name == 'supplierProfile';
  });
  const isPetProfile = computed((): boolean => {
    return route.name == 'pets';
  });
  const isLegacyCaseProfile = computed((): boolean => {
    return (
      route.name == 'caseProfile' &&
      caseStore.case.caseTypeId === CaseTypeEnum.Legacy
    );
  });

  return {
    id,
    isContactProfile,
    isCaseProfile,
    isSupplierProfile,
    isPetProfile,
    isLegacyCaseProfile
  };
};
