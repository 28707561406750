import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"calc(100% - 56px)"} }
const _hoisted_2 = { class: "flex justify-content-end align-items-center m-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_EllipsisButton = _resolveComponent("EllipsisButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ExpenseDialog = _resolveComponent("ExpenseDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "",
        icon: "pi pi-plus",
        size: "small",
        label: "New expense",
        onClick: _ctx.showExpenseNew
      }, null, 8 /* PROPS */, ["onClick"])
    ]),
    _createVNode(_component_DataTable, {
      loading: _ctx.loading,
      paginator: "",
      rows: 5,
      value: _ctx.expenses,
      "data-key": "expenseId",
      class: "col-12 mb-3 p-datatable-sm"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          sortable: "",
          field: "createdDate",
          header: "Date Created"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.toGBDateString(data.createdDate)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "pets",
          header: "Pet(s)"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.emptyFieldSub(_ctx.getPetNames(data.pets), 'n/a')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "nominalCode",
          header: "Nominal Code"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.emptyFieldSub(_ctx.getNominalCodes(data), 'n/a')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "supplier",
          header: "Supplier"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.emptyFieldSub(_ctx.getSupplierNameFromId(data.supplierId), 'n/a')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "volunteer",
          header: "Volunteer"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.emptyFieldSub(_ctx.getVolunteerName(data.caseVolunteer), 'n/a')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "expenseStatusId",
          header: "Status"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.getExpenseStatus(data.expenseStatusId)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "description",
          header: "Description"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.emptyFieldSub(data.description, '-')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "quoteRef",
          header: "Quote Reference Number"
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "expectedAmount",
          header: "Expected Amount"
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(_ctx.formatCurrency(_ctx.getTotalExpenseCost(data))), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, {
          sortable: "",
          field: "invoicedAmount",
          header: "Invoiced Amount"
        }, {
          body: _withCtx(({ data }) => [
            _createElementVNode("span", {
              class: _normalizeClass({
              'over-invoiced':
                data.expenseMode == 2 &&
                data.expectedAmount > 0 &&
                data.invoicedAmount > 0
                  ? data.invoicedAmount / data.expectedAmount > 0.9 &&
                    data.invoicedAmount / data.expectedAmount < 1.1
                  : false,
              'under-invoiced':
                data.expenseMode == 2 && data.invoicedAmount <= 0
            })
            }, _toDisplayString(data.expenseMode == 2
                ? _ctx.formatCurrency(data.invoicedAmount) || _ctx.formatCurrency(0)
                : 'n/a'), 3 /* TEXT, CLASS */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_Column, { header: "" }, {
          body: _withCtx(({ data }) => [
            _createVNode(_component_EllipsisButton, null, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  class: "flex",
                  text: "",
                  size: "small",
                  label: "View/Edit",
                  onClick: () => _ctx.editExpense(data)
                }, null, 8 /* PROPS */, ["onClick"]),
                (data.expenseMode == 2)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "flex",
                      text: "",
                      size: "small",
                      severity: "danger",
                      label: "Add Invoice",
                      onClick: () => _ctx.addInvoice(data)
                    }, null, 8 /* PROPS */, ["onClick"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading", "value"]),
    _createCommentVNode(" v-if here destroys the component after close, enabling us to have built-in reactive resetting, rather than having to specify a method for it. "),
    (_ctx.isExpenseDialogVisible)
      ? (_openBlock(), _createBlock(_component_ExpenseDialog, {
          key: 0,
          "dialog-visible": _ctx.isExpenseDialogVisible,
          editable: true,
          "expense-command": _ctx.expenseToEdit,
          volunteers: _ctx.volunteers,
          "supplier-id": _ctx.supplierId,
          pets: _ctx.pets,
          onComplete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onComplete($event))),
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isExpenseDialogVisible = $event.data))
        }, null, 8 /* PROPS */, ["dialog-visible", "expense-command", "volunteers", "supplier-id", "pets"]))
      : _createCommentVNode("v-if", true)
  ]))
}