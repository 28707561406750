import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-2 px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["MultiSelect"], {
    "model-value": $setup.props.modelValue,
    options: $setup.props.options,
    "option-label": $setup.props.optionLabel,
    "option-value": $setup.props.optionValue,
    "option-group-label": $setup.props.optionGroupLabel,
    "option-group-children": $setup.props.optionGroupChildren,
    placeholder: $setup.props.placeholder,
    filter: $setup.props.showFilter,
    display: "chip",
    class: "w-full",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', $event)))
  }, {
    chip: _withCtx((slotProps) => [
      _renderSlot(_ctx.$slots, "option", {
        index: `selected-${slotProps.value}`
      }, () => [
        _createElementVNode("span", null, _toDisplayString($setup.getOptionLabel(slotProps.value)), 1 /* TEXT */)
      ])
    ]),
    option: _withCtx((slotProps) => [
      _renderSlot(_ctx.$slots, "option", {
        index: slotProps.index
      }, () => [
        _createElementVNode("span", null, _toDisplayString($setup.getOptionLabel(slotProps.option)), 1 /* TEXT */)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("b", null, _toDisplayString($props.modelValue?.length ?? 0), 1 /* TEXT */),
        _createTextVNode(" " + _toDisplayString($props.typeText) + " selected. ", 1 /* TEXT */)
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["model-value", "options", "option-label", "option-value", "option-group-label", "option-group-children", "placeholder", "filter"]))
}