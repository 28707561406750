import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid h-full w-full" }
const _hoisted_2 = { class: "col-8" }
const _hoisted_3 = { class: "grid m-0 h-full search-bar-container" }
const _hoisted_4 = { class: "col-5 h-full" }
const _hoisted_5 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "ml-2" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex align-items-center" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "ml-2" }
const _hoisted_12 = { class: "col-7" }
const _hoisted_13 = { class: "col-4 flex justify-content-start align-items-center gap-2" }
const _hoisted_14 = { class: "advanced-search-component grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_input_text = _resolveComponent("input-text")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Dropdown, {
              modelValue: $setup.searchStore.selectedOption,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchStore.selectedOption) = $event)),
              options: $setup.searchStore.options,
              "option-label": "name",
              class: "w-full h-full search-drop-down"
            }, {
              value: _withCtx((slotProps) => [
                (slotProps.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("img", {
                        src: slotProps.value.icon,
                        class: "w-1rem h-1rem"
                      }, null, 8 /* PROPS */, _hoisted_6),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(slotProps.value.label), 1 /* TEXT */)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(slotProps.placeholder), 1 /* TEXT */))
              ]),
              option: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("img", {
                    src: slotProps.option.icon,
                    class: "w-2rem h-2rem"
                  }, null, 8 /* PROPS */, _hoisted_10),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(slotProps.option.label), 1 /* TEXT */)
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_input_text, {
              modelValue: $setup.searchValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.searchValue) = $event)),
              class: "search-bar-input",
              placeholder: $setup.searchStore.selectedOption.simpleSearchPlaceholder,
              onKeyup: $setup.enterKeyPressed
            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_Button, {
          label: "Search",
          class: "search-bar-button",
          severity: "info",
          onClick: $setup.simpleSearch
        }),
        _withDirectives(_createElementVNode("a", { onClick: $setup.openAdvancedSearchPanel }, " Advanced search ", 512 /* NEED_PATCH */), [
          [_vShow, !$setup.isSearchPage]
        ])
      ])
    ]),
    _createVNode($setup["Dialog"], {
      visible: $setup.searchStore.showAdvancedSearchPanel,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (($setup.searchStore.showAdvancedSearchPanel) = $event)),
      modal: "",
      class: "advanced-search-dialog",
      position: "top",
      onShow: $setup.onShow
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_14, [
          _createVNode($setup["AdvancedSearch"], {
            parameters: $setup.searchStore.parameters,
            "onUpdate:parameters": _cache[2] || (_cache[2] = ($event: any) => (($setup.searchStore.parameters) = $event)),
            "show-close": "",
            title: $setup.searchStore.selectedOption.searchTitle,
            fields: $setup.searchStore.selectedOption.fields,
            onCloseClicked: _cache[3] || (_cache[3] = ($event: any) => ($setup.searchStore.showAdvancedSearchPanel = false))
          }, null, 8 /* PROPS */, ["parameters", "title", "fields"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ], 64 /* STABLE_FRAGMENT */))
}