import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bottom-row-tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TabView"], {
      activeIndex: $setup.activeTab,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => (($setup.activeTab) = $event)),
      lazy: "",
      scrollable: true
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.visibleTabs, (tab) => {
          return (_openBlock(), _createBlock($setup["TabPanel"], {
            key: tab.key,
            header: tab.header
          }, {
            default: _withCtx(() => [
              _createCommentVNode(" Dynamically render the component "),
              (_openBlock(), _createBlock(_resolveDynamicComponent(tab.component), _normalizeProps(_guardReactiveProps(tab.props)), null, 16 /* FULL_PROPS */))
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["header"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["activeIndex"])
  ]))
}