import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["DocumentList"], {
    ref: "documentList",
    documents: $setup.contactStore.documents.documents,
    folders: $setup.contactStore.documents.folders,
    class: "mr-2",
    onRequestUpdate: _cache[0] || (_cache[0] = ($event: any) => (
      $setup.contactStore.getDocumentData(
        $setup.contactStore.contact.contactDetails.contactId
      )
    )),
    onUploadFile: $setup.uploadFile
  }, null, 8 /* PROPS */, ["documents", "folders"]))
}