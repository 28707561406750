
  import { defineComponent } from 'vue';
  import AutoComplete from 'primevue/autocomplete';
  import { CaseClient, CaseOverviewDto } from '../../clients/client.gen';
  const client = new CaseClient();
  export default defineComponent({
    components: {
      AutoComplete
    },
    props: {
      placeholder: {
        type: String,
        required: false,
        default: 'Start typing case id or contact name...'
      },
      caseStatus: {
        type: String,
        required: false,
        default: null
      }
    },
    emits: ['selected'],
    data(): any {
      return {
        cases: [] as CaseOverviewDto[],
        filteredCases: [] as CaseOverviewDto[],
        selectedCase: null
      };
    },
    watch: {
      selectedCase(newVal) {
        this.$emit('selected', newVal);
      }
    },
    async created() {
      await this.getData();
    },
    methods: {
      async getData() {
        this.cases = await client.getAll();
      },
      display(caseData: CaseOverviewDto) {
        return `${caseData?.ownerName}(Case #${caseData?.caseId})`;
      },
      async search(event: { query: string }) {
        return setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredCases = [...this.cases];
          } else {
            const searchValue = event.query.toLowerCase();

            let filteredCases = this.cases.filter(
              (c: CaseOverviewDto) =>
                c.ownerName?.toLowerCase().includes(searchValue) ||
                c.contactId?.toString().includes(searchValue) ||
                c.caseId?.toString().includes(searchValue)
            );
            if (this.caseStatus) {
              filteredCases = filteredCases.filter(
                (c: CaseOverviewDto) =>
                  c.caseStatusName?.toString() == this.caseStatus
              );
            }

            this.filteredCases = filteredCases;
          }
        }, 250);
      }
    }
  });
