
  import { defineComponent } from 'vue';
  import AfterCareList from './AfterCareList.vue';
  import DLList from './DLList.vue';

  interface Data {}

  export default defineComponent({
    components: { AfterCareList, DLList },
    data(): Data {
      return {};
    },
    computed: {},
    methods: {}
  });
