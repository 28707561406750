import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-0"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.props.label)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString($setup.props.label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["Tree"], {
      selectionKeys: $setup.selectedTasks,
      "onUpdate:selectionKeys": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedTasks) = $event)),
      expandedKeys: $setup.expandedKeys,
      "onUpdate:expandedKeys": _cache[1] || (_cache[1] = ($event: any) => (($setup.expandedKeys) = $event)),
      value: $setup.nodes,
      "selection-mode": "checkbox",
      class: "w-full",
      loading: $setup.loading,
      "meta-key-selection": $setup.checked,
      filter: true,
      "filter-mode": "lenient"
    }, {
      default: _withCtx((slotProps) => [
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(slotProps.node.label) + " ", 1 /* TEXT */),
          (!slotProps.node.data)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString($setup.nodeTaskCount(slotProps.node)) + ") ", 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      url: _withCtx((slotProps) => [
        _createElementVNode("a", {
          class: "link",
          onClick: ($event: any) => ($setup.urlClicked($event, slotProps.node.data))
        }, [
          _createTextVNode(_toDisplayString(slotProps.node.label) + " ", 1 /* TEXT */),
          (!slotProps.node.data.userTaskId)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (" + _toDisplayString($setup.nodeTaskCount(slotProps.node)) + ") ", 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_3)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["selectionKeys", "expandedKeys", "value", "loading", "meta-key-selection"])
  ]))
}