
  import { defineComponent } from 'vue';
  import { ContactPetOverviewDto, PetClient } from '@/clients/client.gen';
  import { supplierStore } from '@/stores/supplier.store';

  export default defineComponent({
    data(): any {
      return {
        pets: [] as ContactPetOverviewDto[],
        isLoading: false,
        showDeceased: false
      };
    },
    async created() {
      await this.getData();
    },
    methods: {
      async getData() {
        this.isLoading = true;
        const client = new PetClient();
        this.pets = await client.getPetsForVet(
          supplierStore.supplier.supplierId
        );

        this.isLoading = false;
      }
    }
  });
