import {
  UserTaskClient,
  UserTaskDto,
  UserTaskTypeEnum
} from '@/clients/client.gen';
import { reactive } from 'vue';

export const contactTaskTypes = [
  UserTaskTypeEnum.Contact,
  UserTaskTypeEnum.HomeCheck,
  UserTaskTypeEnum.DofE,
  UserTaskTypeEnum.LapsedVolunteer,
  UserTaskTypeEnum.Rereg,
  UserTaskTypeEnum.SendVolunteerPack,
  UserTaskTypeEnum.SendIDBadge,
  UserTaskTypeEnum.WelcomeNewVolunteer,
  UserTaskTypeEnum.SendCTAGandHandbook,
  UserTaskTypeEnum.CTAGChase,
  UserTaskTypeEnum.CompletedVolunteerRegForm,
  UserTaskTypeEnum.Dormant,
  UserTaskTypeEnum.KeenVolunteer,
  UserTaskTypeEnum.Registration,
  UserTaskTypeEnum.Internal,
  UserTaskTypeEnum.Personal
];

export const caseTaskTypes = [
  UserTaskTypeEnum.Case,
  UserTaskTypeEnum.Escalation,
  UserTaskTypeEnum.LTFCase,
  UserTaskTypeEnum.LTFChecklist,
  UserTaskTypeEnum.FollowUp,
  UserTaskTypeEnum.VetInvoice,
  UserTaskTypeEnum.BigWalk,
  UserTaskTypeEnum.CatCare,
  UserTaskTypeEnum.DogWalking,
  UserTaskTypeEnum.TemporaryDogWalking,
  UserTaskTypeEnum.Event,
  UserTaskTypeEnum.FollowUp,
  UserTaskTypeEnum.Fundraising,
  UserTaskTypeEnum.HospitalStay,
  UserTaskTypeEnum.LTF,
  UserTaskTypeEnum.LTFBehaviourist,
  UserTaskTypeEnum.LTFHolidayCover,
  UserTaskTypeEnum.Medication,
  UserTaskTypeEnum.MeetAndGreet,
  UserTaskTypeEnum.PetProfile,
  UserTaskTypeEnum.TerminalPetProfile,
  UserTaskTypeEnum.STF,
  UserTaskTypeEnum.STFFollowUp,
  UserTaskTypeEnum.Transport,
  UserTaskTypeEnum.Talk,
  UserTaskTypeEnum.Other,
  UserTaskTypeEnum.Monitor,
  UserTaskTypeEnum.Internal,
  UserTaskTypeEnum.Personal
];

export const taskStore = reactive({
  userTaskClient: new UserTaskClient(),
  tasks: [] as UserTaskDto[],
  ltfTasks: undefined as UserTaskDto[] | undefined,
  highlightedTaskId: undefined as number | undefined,

  hasLTFTasks() {
    return this.ltfTasks != undefined && this.ltfTasks.length > 0;
  },

  reset() {
    this.tasks = [];
    this.ltfTasks = undefined;
  },

  async getAllTasksForContactOrCase(contactId?: number, caseId?: number) {
    this.tasks = await this.userTaskClient.getAllTasksForContactOrCase(
      contactId,
      caseId
    );
    this.ltfTasks = this.tasks.filter(
      (t: UserTaskDto) => t.userTaskTypeId === UserTaskTypeEnum.LTFChecklist
    );
  },

  setHighlightedTask(userTaskId: number | undefined) {
    this.highlightedTaskId = userTaskId;

    if (userTaskId) {
      const url = new URL(window.location.toString());
      url.searchParams.set('highlightedTaskId', userTaskId.toString());
      history.pushState(null, '', url);
    }
  }
});
