
  import { Hub } from 'aws-amplify';
  import { defineComponent } from 'vue';
  import { responseHandler } from './composables/ResponseHandler';
  import { phoneSystemStore } from './stores/phoneSystem.store';
  import router from './router';

  export default defineComponent({
    name: 'App',
    data() {
      return {};
    },
    beforeCreate() {
      Hub.listen('auth', (data) => {
        const { payload } = data;
        if (payload.event === 'signIn') {
          router.push('/');
        }
        if (payload.event === 'signOut') {
          router.push('/auth');
        }
      });
    },
    created() {
      responseHandler(this.$toast, router);
      phoneSystemStore.connect();
    }
  });
