
  import { defineComponent } from 'vue';
  import Rating from 'primevue/rating';
  import FormFieldMixin from './FormFieldMixin';

  export default defineComponent({
    components: {
      Rating
    },
    mixins: [FormFieldMixin],
    props: {
      modelValue: {
        type: Number,
        required: false,
        default: 0
      }
    },
    data() {
      return {
        internalValue: this.modelValue
      };
    }
  });
