import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { style: {"height":"calc(100% - 56px)"} }
const _hoisted_2 = {
  key: 0,
  class: "flex gap-1"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex flex-column h-full" }
const _hoisted_9 = {
  key: 0,
  class: "flex gap-2 align-items-center justify-content-center",
  style: {"height":"50px"}
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "flex w-full flex-column gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode(" Hidden for now #1115 CT may want in the future "),
      _createCommentVNode(" <div class=\"flex justify-content-end align-items-center m-2\">\n      <Button label=\"Add Income\" @click=\"showModal($event, undefined)\" />\n    </div> "),
      _createVNode($setup["DataTable"], {
        selection: $setup.selectedIncome,
        "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedIncome) = $event)),
        value: $setup.props.incomes,
        paginator: "",
        "selection-mode": "single",
        class: "p-datatable-sm",
        scrollable: "",
        "scroll-height": "flex",
        rows: 4,
        "row-class": $setup.isRowDeleted
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "date",
            header: "Date"
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString($setup.formatDate(slotProps.data.date)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_Column, {
            field: "incomeType",
            header: "Income Type"
          }, {
            body: _withCtx((slotProps) => [
              (slotProps.data.incomeMode === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.incomeTypes, (incomeType, index) => {
                      return (_openBlock(), _createBlock($setup["Chip"], {
                        key: index,
                        label: $setup.getIncomeTypeLabel(incomeType)
                      }, null, 8 /* PROPS */, ["label"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode($setup["Chip"], { label: "Recurring" })
                  ]))
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_Column, { header: "Payment Method" }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString($setup.getPaymentMethod(slotProps.data)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_Column, {
            field: "orderTotalAmount",
            header: "Amount"
          }, {
            body: _withCtx((slotProps) => [
              (slotProps.data.incomeMode == 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, " £" + _toDisplayString(slotProps.data.orderTotalAmount), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (slotProps.data.incomeMode == 2)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " £" + _toDisplayString(slotProps.data.recurringAmount), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_Column, {
            field: "referenceNumber",
            header: "Reference"
          }),
          _createVNode(_component_Column, {
            field: "deliveryInstructions",
            header: "Description"
          }, {
            body: _withCtx((slotProps) => [
              (slotProps.data.incomeMode == 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(slotProps.data.deliveryInstructions), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (slotProps.data.incomeMode == 2)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(slotProps.data.description), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_Column, {
            field: "orderStatusName",
            header: "Status"
          }),
          _createVNode(_component_Column, null, {
            body: _withCtx((slotProps) => [
              _createVNode($setup["EllipsisButton"], null, {
                default: _withCtx(() => [
                  _createVNode(_component_Button, {
                    class: "flex",
                    text: "",
                    size: "small",
                    label: "View",
                    onClick: ($event: any) => ($setup.viewIncome($event, slotProps.data))
                  }, null, 8 /* PROPS */, ["onClick"]),
                  _createVNode(_component_Button, {
                    class: "flex",
                    text: "",
                    size: "small",
                    label: "Edit communication",
                    onClick: ($event: any) => ($setup.editComm($event, slotProps.data))
                  }, null, 8 /* PROPS */, ["onClick"]),
                  (
                !slotProps.data.isDeleted && $setup.currentUserStore.isSystemAdminUser
              )
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        class: "flex",
                        text: "",
                        size: "small",
                        label: "Edit Income",
                        onClick: ($event: any) => ($setup.editIncome($event, slotProps.data))
                      }, null, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true),
                  (
                !slotProps.data.isDeleted && $setup.currentUserStore.isSystemAdminUser
              )
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        class: "flex",
                        text: "",
                        size: "small",
                        severity: "danger",
                        label: "Delete",
                        onClick: ($event: any) => ($setup.confirmDeleteIncome(slotProps.data.id))
                      }, null, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true),
                  (
                slotProps.data.isDeleted && $setup.currentUserStore.isSystemAdminUser
              )
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 2,
                        class: "flex",
                        text: "",
                        size: "small",
                        severity: "danger",
                        label: "Undelete",
                        onClick: ($event: any) => ($setup.undeleteIncome(slotProps.data.id))
                      }, null, 8 /* PROPS */, ["onClick"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["selection", "value", "row-class"])
    ]),
    _createVNode($setup["Dialog"], {
      visible: $setup.showDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (($setup.showDialog) = $event)),
      modal: "",
      class: "flex flex-column",
      header: $setup.steps[$setup.selectedStep].header,
      style: {"width":"600px","max-height":"80vh"}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _createVNode($setup["ScrollPanel"], { style: {"height":"100%"} }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.steps, (step, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    ($setup.selectedStep == index)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(step.component), {
                          key: 0,
                          income: $setup.income,
                          "read-only": $setup.readOnly,
                          onProgressStepper: $setup.nextStep,
                          onRegressStepper: $setup.previousStep,
                          "onUpdate:modelValue": $setup.updateVal
                        }, null, 40 /* PROPS, NEED_HYDRATION */, ["income", "read-only"]))
                      : _createCommentVNode("v-if", true)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ]),
          ($setup.selectedStep !== 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", null, [
                  (($setup.readOnly && $setup.selectedStep > 1) || !$setup.readOnly)
                    ? (_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        label: "Back",
                        onClick: $setup.previousStep
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                ($setup.selectedStep !== $setup.steps.length - 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_Button, {
                        label: "Continue",
                        onClick: $setup.nextStep
                      })
                    ]))
                  : _createCommentVNode("v-if", true),
                ($setup.selectedStep === $setup.steps.length - 1 && !$setup.readOnly)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_Button, {
                        label: "Finish and send to finance",
                        onClick: $setup.finish
                      })
                    ]))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible", "header"]),
    _createVNode($setup["OverlayPanel"], {
      ref: "editCommsPanel",
      style: {"width":"300px"}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          ($setup.selectedIncome)
            ? (_openBlock(), _createBlock(_component_Textarea, {
                key: 0,
                modelValue: $setup.selectedIncome.communication,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => (($setup.selectedIncome.communication) = $event)),
                  $setup.updateComms
                ]
              }, null, 8 /* PROPS */, ["modelValue"]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_Button, {
            label: "Add communication",
            onClick: $setup.saveComms
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */)
  ], 64 /* STABLE_FRAGMENT */))
}