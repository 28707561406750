
  import { SupplierClient, SupplierDropDownDto } from '@/clients/client.gen';
  import { defineComponent } from 'vue';
  import Dropdown from 'primevue/dropdown';

  interface SupplierSelectorData {
    selectedSupplier: number;
    suppliers: SupplierDropDownDto[];
  }

  const supplierClient = new SupplierClient();

  export default defineComponent({
    components: {
      Dropdown
    },
    props: {
      modelValue: {
        type: Number,
        required: true
      }
    },
    emits: ['supplierChanged'],
    data(): SupplierSelectorData {
      return {
        selectedSupplier: 0,
        suppliers: new Array<SupplierDropDownDto>()
      };
    },
    watch: {
      selectedSupplier() {
        this.$emit('supplierChanged', this.selectedSupplier);
      }
    },
    async created() {
      this.suppliers = await supplierClient.getForSelector();
      this.selectedSupplier = this.modelValue;
    }
  });
