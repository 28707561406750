import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field grid" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "col-12"
}
const _hoisted_4 = ["for"]
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.labelAbove)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.computedId,
          class: "col-12 w-full"
        }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    (!_ctx.labelLeft)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Checkbox, {
            modelValue: _ctx.internalValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
            "input-id": _ctx.computedId,
            binary: true,
            disabled: _ctx.readOnly
          }, null, 8 /* PROPS */, ["modelValue", "input-id", "disabled"]),
          (!_ctx.labelAbove && !_ctx.labelLeft)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                class: "ml-2",
                for: _ctx.computedId
              }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_4))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (!_ctx.labelAbove && _ctx.labelLeft)
      ? (_openBlock(), _createElementBlock("label", {
          key: 2,
          class: "col-3",
          for: _ctx.computedId
        }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_5))
      : _createCommentVNode("v-if", true),
    (!_ctx.labelAbove && _ctx.labelLeft)
      ? (_openBlock(), _createBlock(_component_Checkbox, {
          key: 3,
          modelValue: _ctx.internalValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalValue) = $event)),
          class: "ml-2",
          "input-id": _ctx.computedId,
          binary: true,
          disabled: _ctx.readOnly
        }, null, 8 /* PROPS */, ["modelValue", "input-id", "disabled"]))
      : _createCommentVNode("v-if", true)
  ]))
}