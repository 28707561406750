import { Router } from 'vue-router';
import UnfoundResponseHandler from './UnfoundResponseHandler';
import { ToastServiceMethods } from 'primevue/toastservice';
export const responseHandler = (toast: ToastServiceMethods, router: Router) => {
  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    const [resource, config] = args;
    let response = await originalFetch(resource, config);

    response = await UnfoundResponseHandler(config, response, router);

    if (
      !config ||
      (config.method !== 'POST' &&
        config.method !== 'PUT' &&
        config.method !== 'DELETE')
    ) {
      return response;
    }

    try {
      if (response.status === 200) {
        const clonedResponse = response.clone();
        const resultBody = await clonedResponse.json();
        if (resultBody.success === undefined) return response;

        if (resultBody.success && resultBody.suppressed == false) {
          const userFriendlyMessage =
            resultBody.userFriendlyMessage ?? 'Successfully completed request';

          toast.add({
            severity: 'success',
            summary: userFriendlyMessage,
            life: 5000
          });
        }

        if (resultBody.success == false && resultBody.suppressed == false) {
          const userFriendlyMessage =
            resultBody.userFriendlyMessage ??
            'There was an issue with your request';

          toast.add({
            severity: 'error',
            summary: userFriendlyMessage,
            life: 10000
          });
        }
      }
    } catch {
      console.log(
        'Exception occurred within the Response handler, this is likely due to a third parties network calls.'
      );
    }

    return response;
  };
};
