import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TabView"], {
      activeIndex: $setup.activeTab,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => (($setup.activeTab) = $event)),
      lazy: ""
    }, {
      default: _withCtx(() => [
        ($setup.petStore.ltfPetCases?.length > 0)
          ? (_openBlock(), _createBlock($setup["TabPanel"], {
              key: 0,
              header: "LTF"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["PetLTFNotes"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.petStore.ltfPetCases?.length > 0)
          ? (_openBlock(), _createBlock($setup["TabPanel"], {
              key: 1,
              header: "Invoices"
            }, {
              default: _withCtx(() => [
                ($setup.petStore.pet.petId)
                  ? (_openBlock(), _createBlock($setup["PetExpenses"], {
                      key: 0,
                      "highlight-expense-id": 
            $setup.tabContentId ? parseInt($setup.tabContentId) : undefined
          
                    }, null, 8 /* PROPS */, ["highlight-expense-id"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.petStore.ltfPetCases?.length > 0)
          ? (_openBlock(), _createBlock($setup["TabPanel"], {
              key: 2,
              header: "Owner History"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["PetOwnerHistory"], { id: $props.id }, null, 8 /* PROPS */, ["id"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["TabPanel"], { header: "Cases" }, {
          default: _withCtx(() => [
            _createVNode($setup["PetCasesTab"], { id: $props.id }, null, 8 /* PROPS */, ["id"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["TabPanel"], { header: "Nominated" }, {
          default: _withCtx(() => [
            _createVNode($setup["PetsNominatedPersons"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["TabPanel"], { header: "Keep Together" }, {
          default: _withCtx(() => [
            _createVNode($setup["PetsKeepTogethers"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["TabPanel"], { header: "Documents" }, {
          default: _withCtx(() => [
            _createVNode($setup["PetDocuments"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["TabPanel"], { header: "Audit" }, {
          default: _withCtx(() => [
            _createVNode($setup["AuditHistory"], {
              data: $setup.petStore.auditHistory
            }, null, 8 /* PROPS */, ["data"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["activeIndex"])
  ]))
}