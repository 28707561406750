import { ContactPetOverviewDto } from '@/clients/client.gen';
import { dropdownsStore } from '@/stores/dropdowns.store';
interface petGrouping {
  groupName: string;
  items?: ContactPetOverviewDto[];
}

export default class PetService {
  sortPets<
    T extends { isAssigned?: boolean; deceased: boolean; petName: string }
  >(pets: T[] | undefined, alphabetical: boolean = true): T[] | undefined {
    //As per comment - https://app.productive.io/15857-thought-quarter/projects/248938/tasks/task/7168918?board=505461&filter=NDE0Njk0&task-list=1106812&taskActivityId=118442230
    //This sorting now moves assigned pets to the top of the list (Including deceased & assigned), and any deceased pets are moved to the bottom of the list

    if (!pets) return undefined;

    return pets.sort((pet1, pet2) => {
      const pet1Priority = this.getPetSortPriority(pet1);
      const pet2Priority = this.getPetSortPriority(pet2);

      // If both pets have the same priority, sort alphabetically
      if (pet1Priority === pet2Priority) {
        if (alphabetical) {
          if (pet1.petName < pet2.petName) {
            return -1;
          } else if (pet1.petName > pet2.petName) {
            return 1;
          } else {
            return 0;
          }
        }
      }

      // Sort by priority
      return pet1Priority - pet2Priority;
    });
  }

  getPetSortPriority<
    T extends { isAssigned?: boolean; deceased: boolean; petName: string }
  >(pet: T): number {
    if (pet.isAssigned && !pet.deceased) {
      // Assigned and not deceased
      return 0;
    } else if (pet.isAssigned && pet.deceased) {
      // Assigned and deceased
      return 1;
    } else if (!pet.isAssigned && !pet.deceased) {
      // Not assigned and not deceased
      return 2;
    } else {
      // Not assigned and deceased
      return 3;
    }
  }

  getPetProfileStatusName(
    petProfileStatusId: number | undefined
  ): string | undefined {
    return dropdownsStore.petProfileStatuses.find(
      (x) => x.petProfileStatusId == petProfileStatusId
    )?.name;
  }

  groupPetsOnDeceased<T extends ContactPetOverviewDto>(
    pets: T[] | undefined
  ): petGrouping[] {
    const alive: ContactPetOverviewDto[] = [];
    const dead: ContactPetOverviewDto[] = [];
    pets?.forEach((p) => (p.deceased ? dead.push(p) : alive.push(p)));
    return [
      { groupName: '', items: alive },
      { groupName: 'Deceased', items: dead }
    ];
  }
}
export const petService = new PetService();
