import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  id: "create-note-form",
  class: "flex flex-column gap-2"
}
const _hoisted_2 = { class: "grid align-content-center" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "flex justify-content-start gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock($setup["OverlayPanel"], {
    ref: "newNotePanel",
    style: {"min-width":"350px"},
    dismissable: false,
    "show-close-icon": false
  }, {
    default: _withCtx(() => [
      _createCommentVNode(" these should be generated from a server-side enum "),
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode($setup["Mentionable"], {
            keys: ['@'],
            items: $setup.commsAndNotesStore.mentionItems,
            offset: "6",
            "insert-space": "",
            onOpen: $setup.onOpen
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Textarea, {
                modelValue: $setup.commsAndNotesStore.newNoteDescription,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.commsAndNotesStore.newNoteDescription) = $event)),
                placeholder: "Enter note details here",
                rows: "5",
                cols: "30"
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["items"])
        ]),
        ($setup.isLegacyCaseProfile && $setup.caseStore.legacyPersonalRepresentative?.id)
          ? (_openBlock(), _createBlock($setup["FormCheckBox"], {
              key: 0,
              modelValue: $setup.tagPersonalRepresentative,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.tagPersonalRepresentative) = $event)),
              label: "Tag Personal Representative"
            }, null, 8 /* PROPS */, ["modelValue"]))
          : _createCommentVNode("v-if", true),
        ($setup.props.volunteers?.length && !$setup.isPetProfile && !$setup.isLegacyCaseProfile)
          ? (_openBlock(), _createBlock($setup["CommsTagMultiSelect"], {
              key: 1,
              modelValue: $setup.taggedVolunteers,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.taggedVolunteers) = $event)),
              options: $setup.orderedTagVolunteerOptions,
              placeholder: "Tag Volunteers",
              "type-text": "volunteers",
              "show-filter": "",
              "option-label": 
          (option) =>
            `${option.name} - ${option.statusName}`
        
            }, null, 8 /* PROPS */, ["modelValue", "options", "option-label"]))
          : _createCommentVNode("v-if", true),
        ($props.cases.length && !$setup.isPetProfile)
          ? (_openBlock(), _createBlock($setup["CommsTagMultiSelect"], {
              key: 2,
              modelValue: $setup.taggedCases,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.taggedCases) = $event)),
              options: $setup.groupedTagCaseOptions,
              placeholder: "Tag Cases",
              "option-group-label": "group",
              "option-group-children": "items",
              "type-text": "cases",
              "show-filter": "",
              "option-label": 
          (option) => `${option.shortDescription}`
        
            }, null, 8 /* PROPS */, ["modelValue", "options", "option-label"]))
          : _createCommentVNode("v-if", true),
        ($props.pets.length && !$setup.isPetProfile && !$setup.isLegacyCaseProfile)
          ? (_openBlock(), _createBlock($setup["CommsTagMultiSelect"], {
              key: 3,
              modelValue: $setup.taggedPets,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.taggedPets) = $event)),
              options: $setup.groupedPets,
              "option-label": "petName",
              "option-group-label": "groupName",
              "option-group-children": "items",
              placeholder: "Tag Pets",
              "show-filter": "",
              "type-text": "pets"
            }, null, 8 /* PROPS */, ["modelValue", "options"]))
          : _createCommentVNode("v-if", true),
        ($props.ltfPets.length && $setup.isPetProfile)
          ? (_openBlock(), _createBlock($setup["CommsTagMultiSelect"], {
              key: 4,
              modelValue: $setup.taggedPetsForLTFVolunteer,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.taggedPetsForLTFVolunteer) = $event)),
              options: $setup.groupedOtherPetsForLTFVolunteerExcludingPetProfilePet,
              "option-label": "petName",
              "option-group-label": "groupName",
              "option-group-children": "items",
              placeholder: "Tag LTF Pets",
              "show-filter": "",
              "type-text": "pets"
            }, null, 8 /* PROPS */, ["modelValue", "options"]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["UploadAttachments"], { onNewFileUploaded: $setup.filesReceived })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            disabled: !$setup.commsAndNotesStore.newNoteDescription,
            onClick: $setup.onSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Create note ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"]),
          _createVNode(_component_Button, { onClick: $setup.hideNewNotePanel }, {
            default: _withCtx(() => [
              _createTextVNode(" Cancel")
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}