import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-3 flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.formData)
      ? (_openBlock(), _createBlock($setup["FormInputList"], {
          key: 0,
          "form-data": $setup.formData,
          "block-labels": false
        }, {
          "input-Age": _withCtx(() => [
            _createVNode(_component_InputText, {
              value: $setup.getAge($setup.contactStore.contact.contactDetails.dateOfBirth),
              label: "Age",
              disabled: true
            }, null, 8 /* PROPS */, ["value"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["form-data"]))
      : _createCommentVNode("v-if", true)
  ]))
}