import { defineComponent } from 'vue';

export const FormFieldProps = {
  id: {
    type: String,
    required: false
  },
  label: {
    type: String,
    required: false
  },
  readOnly: {
    type: Boolean,
    required: false,
    default: false
  },
  noLabel: {
    type: Boolean,
    required: false,
    default: false
  },
  labelAbove: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  placeholder: {
    type: String,
    required: false
  }
};

export default defineComponent({
  props: {
    modelValue: {
      type: [Object, String, Number, Boolean],
      required: false
    },
    id: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    noLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    labelAbove: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      internalValue: this.modelValue
    };
  },
  computed: {
    computedId(): string {
      return this.id
        ? this.id
        : this.label
          ? this.label.replace(/\s/g, '_')
          : new Date().getTime().toString();
    },
    labelClass(): string {
      return this.labelAbove ? 'col-12 m-0' : 'col-3 mb-3';
    }
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      this.$emit('update:modelValue', newVal);
    }
  }
});
