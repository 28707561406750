
  import { defineComponent } from 'vue';
  import AddressLookup from '@/components/AddressLookup.vue';
  import { AddressDto } from '@/clients/client.gen';
  import FormField from '../../../Common/FormField.vue';
  import { singleIncomeStore } from '../../../../stores/singleIncome.store';
  import { DropDownValues } from '../../../../clients/client.gen';
  import { dropdownsStore } from '../../../../stores/dropdowns.store';

  export default defineComponent({
    components: { AddressLookup, FormField },
    props: {
      readOnly: Boolean
    },
    emits: ['update:modelValue', 'progress-stepper'],
    data() {
      return {
        singleIncomeStore,
        orderStatuses: [] as Array<DropDownValues>
      };
    },
    computed: {
      address: {
        get(): AddressDto {
          const address = new AddressDto();
          address.addressLine1 = singleIncomeStore.income.addressAddressLine1;
          address.addressLine2 = singleIncomeStore.income.addressAddressLine2;
          address.addressLine3 = singleIncomeStore.income.addressAddressLine3;
          address.town = singleIncomeStore.income.addressTown;
          address.city = singleIncomeStore.income.addressCity;
          address.country = singleIncomeStore.income.addressCountry;
          address.postCode = singleIncomeStore.income.addressPostCode;
          address.county = singleIncomeStore.income.addressCounty;
          address.locationX = singleIncomeStore.income.addressLocationX;
          address.locationY = singleIncomeStore.income.addressLocationY;

          return address;
        },
        set(address?: AddressDto) {
          if (address) {
            this.singleIncomeStore.income.addressAddressLine1 =
              address.addressLine1;
            this.singleIncomeStore.income.addressAddressLine2 =
              address.addressLine2;
            this.singleIncomeStore.income.addressAddressLine3 =
              address.addressLine3;
            this.singleIncomeStore.income.addressTown = address.town;
            this.singleIncomeStore.income.addressCity = address.city;
            this.singleIncomeStore.income.addressCountry = address.country;
            this.singleIncomeStore.income.addressPostCode = address.postCode;
            this.singleIncomeStore.income.addressCounty = address.county;
            this.singleIncomeStore.income.addressLocationX = address.locationX;
            this.singleIncomeStore.income.addressLocationY = address.locationY;
          } else {
            this.resetAddress();
          }
        }
      }
    },
    async created() {
      this.orderStatuses = await dropdownsStore.getOrderStatuses();
    },
    methods: {
      updateAddress(val: AddressDto) {
        this.address = val;
      },
      resetAddress() {
        this.singleIncomeStore.income.addressAddressLine1 = '';
        this.singleIncomeStore.income.addressAddressLine2 = '';
        this.singleIncomeStore.income.addressAddressLine3 = '';
        this.singleIncomeStore.income.addressTown = '';
        this.singleIncomeStore.income.addressCity = '';
        this.singleIncomeStore.income.addressCountry = '';
        this.singleIncomeStore.income.addressPostCode = '';
        this.singleIncomeStore.income.addressCounty = '';
        this.singleIncomeStore.income.addressLocationX = undefined;
        this.singleIncomeStore.income.addressLocationY = undefined;
      },
      updateVal() {
        if (this.singleIncomeStore.income.useContactAddress) {
          this.singleIncomeStore.income.shippingName = '';
          this.resetAddress();
        }
        this.$emit('update:modelValue', this.singleIncomeStore.income);
      }
    }
  });
