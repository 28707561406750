import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full flex gap-2 align-content-center"
}
const _hoisted_2 = {
  key: 2,
  class: "pi pi-spin pi-spinner",
  style: {"font-size":"2rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      ref: "file",
      type: "file",
      style: {"display":"none"},
      onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.newImageSelected($event)))
    }, null, 544 /* NEED_HYDRATION, NEED_PATCH */),
    (!$setup.fileUploading && !$setup.internalValue.documentId)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          icon: "pi pi-cloud-upload",
          style: {"width":"40px"},
          onClick: $setup.openFileDialog
        }))
      : _createCommentVNode("v-if", true),
    (!$setup.fileUploading && $setup.internalValue.documentId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            class: "flex align-items-center justify-content-center",
            style: {"cursor":"pointer"},
            onClick: $setup.viewImage
          }, _toDisplayString($setup.internalValue.fileName), 1 /* TEXT */),
          _createVNode(_component_Button, {
            icon: "pi pi-trash",
            onClick: $setup.deleteFile
          })
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.fileUploading)
      ? (_openBlock(), _createElementBlock("i", _hoisted_2))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}