import {
  CreateOrUpdateEmail,
  EmailClient,
  EmailTemplateDto,
  OperationResultOfEmailTemplateDto,
  TokenDto
} from '@/clients/client.gen';
import { reactive } from 'vue';
export const emailStore = reactive({
  emailTemplate: new EmailTemplateDto(),
  client: new EmailClient(),
  placeholders: [] as TokenDto[],
  loading: false,
  filteredEmails: [] as EmailTemplateDto[],
  emails: [] as EmailTemplateDto[],
  async getPlaceholders(): Promise<TokenDto[]> {
    if (this.placeholders.length) return [...this.placeholders];
    this.placeholders = await this.client.getTokens();

    //Dirty hide Petname and petType options
    this.placeholders = this.placeholders.filter(
      (x) => x.value != 'PetName' && x.value != 'PetType'
    );
    return [...this.placeholders];
  },
  async getEmail(id: number) {
    this.loading = true;
    try {
      if (id) {
        this.emailTemplate = await this.client.getTemplate(id);
      } else {
        this.emailTemplate = new EmailTemplateDto();
      }
    } catch {
      this.loading = false;
    }
    this.loading = false;
  },
  async saveEmail(commmand: CreateOrUpdateEmail) {
    this.loading = true;
    try {
      let result: OperationResultOfEmailTemplateDto;
      if (!commmand.emailTemplateId) {
        result = await this.client.addTemplate(commmand);
      } else {
        result = await this.client.updateTemplate(commmand);
      }

      if (result.result) {
        this.emailTemplate = result.result;
      }

      await this.getData();
    } catch {
      this.loading = false;
    }
    this.loading = false;
  },
  async getData() {
    this.loading = true;
    try {
      this.emails = await this.client.getTemplates();

      this.filteredEmails = [...this.emails];
    } catch {
      this.loading = false;
    }
    this.loading = false;
  },
  resetFilter() {
    this.filteredEmails = this.emails;
  }
});
