import { ExpenseDto, ExpenseClient } from '@/clients/client.gen';
import { reactive } from 'vue';

const getNewExpense = (): ExpenseDto => {
  const expense = new ExpenseDto();
  expense.costCentreCode = '1';
  expense.expenseStatusId = 1;
  return expense;
};

export const expenseStore = reactive({
  loading: true,
  expenseClient: new ExpenseClient(),
  costCentreId: '0',
  expense: getNewExpense(),
  bankTaskRequired: false,
  clear() {
    this.bankTaskRequired = false;
  }
});
